import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CompiereDataFieldType, DataStore, DataStoreStatus } from '@compiere-ws/models/compiere-data-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { PrimeAutocompleteComponent } from '@iupics-components/overrided/prime-autocomplete/prime-autocomplete.component';
import { ProcessUiComponent } from '@iupics-components/specific/window/process-ui/process-ui.component';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsZoomInfo } from '@iupics-manager/models/iupics-data';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { Utils } from '@iupics-util/tools/util';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { cloneDeep } from 'lodash';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'iu-autocomplete-ui',
  templateUrl: './autocomplete-ui.component.html',
  styleUrls: ['./autocomplete-ui.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  itemData: DynamicComponent;
  suggestionsFilter: any;
  @ViewChild('autoComplete', { static: true })
  autoComplete: PrimeAutocompleteComponent;
  @Input()
  suggestions: any[];
  @Input()
  scrollHeight = '240px';
  @Input()
  columnName: string;
  @Input()
  iconClass: string;
  @Input()
  limitSuggestions = 19;
  @Input()
  isAdvancedSearch = false;
  @Input()
  isAccountSearch = false;
  @Input()
  readonly = false;
  @Input()
  disable = false;
  @Input()
  multiple = false;
  @Input()
  isRendererView = false;
  @Input()
  isInsideOverflow = false;
  subscription;
  first = true;

  zoomInfo: IupicsZoomInfo;
  private searchTimeout: NodeJS.Timer;

  @Output()
  autoCompleteEmitter = new EventEmitter<any>();

  @Output()
  checkGridRendererEmitter = new EventEmitter<any>();
  @Output()
  blurEmitter = new EventEmitter<any>();
  top;
  left;
  width;
  itemSuggestionsCache = null;
  @Input('fieldValue')
  set fieldValue(value: any) {
    this.autoComplete.value = value;
    if (value !== undefined && value !== null && value.displayValue !== undefined) {
      this.autoComplete.inputFieldValue = value.displayValue;
    } else {
      this.autoComplete.inputFieldValue = value;
    }
    if (
      this.DOMParentComponent !== undefined &&
      (this.DOMParentComponent.DOMParentComponent !== undefined &&
        (<any>this.DOMParentComponent.DOMParentComponent).isAccordion) === true
    ) {
      (<any>this.DOMParentComponent.DOMParentComponent).setMandatory();
    }
  }

  get fieldValue() {
    return this.autoComplete.value;
  }

  dataContainers: AbstractDataContainerCallout;
  allDataRecovered = false;

  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;

  //#region custo
  formCreateOwnerID: any;
  //#endregion custo
  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    renderer: Renderer2,
    private config: AppConfig,
    private translateService: TranslateService,
    protected po: PoService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
    this.isAutocompleteField = true;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.data) {
      if (this.label && this.label.indexOf('_From') >= 0) {
        this.label = this.label.replace('_From', this.translateService.instant('ranged-value.calendar.from'));
      } else if (this.label && this.label.indexOf('_To') >= 0) {
        this.label = this.label.replace('_To', this.translateService.instant('ranged-value.calendar.to'));
      }
      this.isAdvancedSearch = this.data.urlSearch ? true : false;
      if (this.isForInfoWindow) {
        this.fieldType = this.isAdvancedSearch ? CompiereDataFieldType.COLUMN_INFO : this.fieldType;
      }
      this.isAccountSearch = this.data.isAccount ? true : false;
      //#region custo
      if (this.data.columnName === 'C_BPartner_ID') {
        this.formCreateOwnerID = this.config.getConstant(
          'PrimeAutocompleteComponent#SpecificWindow' + this.data.columnName + '_FormID#TabId' + this.data.tabId
        );
      }
      //#endregion custo
    }
    if (this.isStandalone && this.data && this.data.isMandatory && this.data.items && this.data.items.length > 0) {
      this.fieldValue = this.data.items[0];
    }
    this.subscriptions.push(
      this.afterDVInit$.subscribe(() => {
        this.setFieldMandatory();
      })
    );
    if (this.data.fieldTypeForm) {
      this.fieldType = CompiereDataFieldType.FORM_ITEM;
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      this.data.callouts.callouts.forEach((callout) => {
        this.autoComplete.inputEL.nativeElement.addEventListener(callout.calloutevent, () => {
          const calloutFn = new Function('Field', 'Fields', 'DBSelect', 'Utils', callout.script);
          calloutFn(this, this.dataContainers, this.uiCreatorService, Utils);
        });
      });
    }
    // ? à commenter si on ne veut pas charger les données d'une form pour affichage instantanné au clic.
    if (!Global.isMobile() && this.autoComplete && this.autoComplete.checkSpecificView && this.autoComplete.formId) {
      this.subscriptions.push(this.uiCreatorService.getSpecificWindow(this.autoComplete.formId).subscribe());
    }
  }

  setFieldMandatory() {
    if (this.data && this.data.mandatoryLogic) {
      this.data.isMandatory = LogicEvaluator.evaluateLogic(
        this.getCurrentContext(this.dataStored, false),
        this.data.mandatoryLogic
      );
    }
    if (this.fieldValue) {
      this.mandatoryCss = ' iu-field ';
      this.first = false;
    } else {
      if (this.data && this.data.isMandatory) {
        if (
          this.first &&
          this.data &&
          this.data.urlList &&
          (!this.data.items || this.data.items.length <= 0 || this.data.validationCode)
        ) {
          let validationCode;
          if (this.data.validationCode) {
            validationCode = LogicEvaluator.replaceVariables(
              this.data.validationCode,
              this.connectorService.getIupicsUserContext(),
              this.getCurrentContext()
            );
          }
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
          const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
          this.subscription = this.store
            .getAutocompleteData(this.fieldType, entityId, this.data.isSearch, null, validationCode)
            .subscribe((dataWs) => {
              this.suggestions = [];
              this.data.items = dataWs;
              if (
                this.dataStored.status === DataStoreStatus.NEWRECORD &&
                this.data.items.length === 1 &&
                (this.data.columnName !== 'AD_Client_ID' || this.data.defaultValue === undefined)
              ) {
                this.dataChange(this.data.items[0]);
              }
              if (this.fieldValue) {
                this.mandatoryCss = ' iu-field ';
              } else {
                this.mandatoryCss = ' iu-field-mandatory ';
              }
              if (this.autoComplete) {
                this.autoComplete.setMandatoryCss(this.mandatoryCss);
              }
            });
          this.first = false;
        } else {
          this.mandatoryCss = ' iu-field-mandatory ';
        }
      } else {
        this.mandatoryCss = ' iu-field ';
      }
    }
    if (this.autoComplete) {
      this.autoComplete.setMandatoryCss(this.mandatoryCss);
    }
  }

  dataChange(value) {
    if (this.multiple && !Array.isArray(value)) {
      this.fieldValue = this.fieldValue === undefined || this.fieldValue === null ? [] : this.fieldValue;
      value = [...(cloneDeep(this.fieldValue) as any[]), value];
    }
    super.dataChange(value);
    this.autoCompleteEmitter.emit(this.fieldValue);
  }

  calloutEventChange() {
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      this.data.callouts.callouts.forEach((callout) => {
        if (callout.calloutevent === 'change') {
          const calloutFn = new Function('Field', 'Fields', 'DBSelect', 'Utils', callout.script);
          calloutFn(this, this.dataContainers, this.uiCreatorService, Utils);
        }
      });
    }
  }

  /**
   * Update le store avec ce qu'on reçoit du search panel
   * @param id id qu'on reçoit depuis l'emitter de ui-prime-autocomplete
   */
  setSearchSelectItem(event) {
    let id = event.data['Data_UUID'];
    if (id) {
      id = id.split(',')[1];
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const validation = LogicEvaluator.replaceVariables(
      this.data.validationCode,
      this.connectorService.getIupicsUserContext(),
      this.getCurrentContext()
    );
    const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
    this.subscription = this.store.getAutocompleteDataById(this.fieldType, entityId, id, validation).subscribe((dataWS) => {
      this.dataChange(dataWS[0]);
      // this.updateStore(dataWS[0]);
    });
  }
  /**
   * @description get text value linked to an id
   * @param id id received from url
   */
  getSearchItem(idValue: any): Observable<any> {
    const id = parseInt(idValue, 10) ? parseInt(idValue, 10) : idValue;
    const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
    return this.store
      .getAutocompleteDataById(
        this.fieldType,
        entityId,
        id,
        LogicEvaluator.replaceVariables(
          this.data.validationCode,
          this.connectorService.getIupicsUserContext(),
          this.getCurrentContext()
        )
      )
      .pipe(map((dataWS) => dataWS.find((data) => data.id === id)));
  }

  /**
   * Lancé au focus du champ ui-prime-autocomplete
   * @param event
   */
  search(event: { originalEvent: Event; query: string }, forceRefresh = false) {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = undefined;
    }
    this.searchTimeout = setTimeout(
      () => {
        this.searchTimeout = undefined;
        const regex = '.*'.concat(
          Utils.cleanUpSpecialChars(event.query || '', true)
            .split(' ')
            .join('.*'),
          '.*'
        );
        //#region custo
        if (this.formCreateOwnerID) {
          this.autoComplete.loading = false;
          this.showFormPanel(this.formCreateOwnerID);
        } else {
          //#endregion custo
          if (!this.data['events']) {
            if (!this.isContextMenu) {
              if (
                this.data &&
                this.data.urlList &&
                (forceRefresh ||
                  this.data.validationCode ||
                  (event.query !== null && event.query !== undefined && event.query.length > 0) ||
                  ((event.query === null || event.query === undefined || event.query.length === 0) &&
                    this.itemSuggestionsCache === null) ||
                  !this.data.items ||
                  this.data.items.length <= 0)
              ) {
                let validationCode;
                if (this.data.validationCode) {
                  if (!this.isInUniversalFilter || this.checkVariablesForUF(this.data.validationCode, this.getCurrentContext())) {
                    validationCode = LogicEvaluator.replaceVariables(
                      this.data.validationCode,
                      this.connectorService.getIupicsUserContext(),
                      this.getCurrentContext()
                    );
                  }
                }
                if (this.subscription) {
                  this.subscription.unsubscribe();
                }
                const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
                this.subscription = this.store
                  .getAutocompleteData(this.fieldType, entityId, this.data.isSearch, event.query, validationCode)
                  .subscribe((dataWs) => {
                    this.suggestions = [];
                    this.data.items = dataWs;

                    if ((!event.query || event.query === '') && !this.data.isMandatory) {
                      if (!this.isStandalone) {
                        this.suggestions.push({ id: -2, displayValue: '' });
                      }
                      if (this.data.items) {
                        this.data.items.forEach((propose) => {
                          this.suggestions.push(propose);
                        });
                      }
                    } else {
                      if (this.data.items) {
                        for (let i = 0; i < this.data.items.length; i++) {
                          const propose = this.data.items[i];
                          /*TODO pq vérifier que cela match alors que le ws nous renvoie les résultats qui match */
                          // if (this.data.isSearch || Utils.cleanUpSpecialChars(propose.displayValue).match(regex)) {
                          //   this.suggestions.push(propose);
                          // }
                          this.suggestions.push(propose);
                        }
                      }
                    }
                    if ((this.isAdvancedSearch || this.isAccountSearch) && this.suggestions.length > this.limitSuggestions) {
                      this.suggestions = this.suggestions.splice(0, this.limitSuggestions);
                      this.suggestions.push({
                        id: -1,
                        displayValue: this.translateService.instant('generic.moreResults')
                      });
                    }
                    if (this.DOMParentComponent) {
                      this.autoComplete.handleSuggestionsChange();
                    } else {
                      this.checkGridRenderer();
                    }
                    if (dataWs.length === 1 && this.data.isMandatory && !this.autoComplete.overlayVisible) {
                      this.fieldValue = this.multiple ? [] : this.data.items[0];
                      super.dataChange(this.fieldValue);
                    }
                    if (event.query !== null && event.query !== undefined && event.query.length === 0) {
                      if (this.data.items) {
                        this.itemSuggestionsCache = [...this.data.items];
                      }
                    }
                  });
              } else {
                if (this.itemSuggestionsCache !== null && this.itemSuggestionsCache.length > 0) {
                  this.data.items = this.itemSuggestionsCache;
                }
                this.suggestions = [];
                if ((!event.query || event.query === '') && !this.data.isMandatory) {
                  if (!this.isStandalone) {
                    this.suggestions.push({ id: -2, displayValue: '' });
                  }
                  if (this.data.items) {
                    this.data.items.forEach((propose) => {
                      this.suggestions.push(propose);
                    });
                  }
                } else {
                  for (let i = 0; i < this.data.items.length; i++) {
                    const propose = this.data.items[i];
                    if (this.data.isSearch || Utils.cleanUpSpecialChars(propose.displayValue).match(regex)) {
                      this.suggestions.push(propose);
                    }
                  }
                }
                if ((this.isAdvancedSearch || this.isAccountSearch) && this.suggestions.length > this.limitSuggestions) {
                  this.suggestions = this.suggestions.splice(0, this.limitSuggestions);
                  this.suggestions.push({
                    id: -1,
                    displayValue: this.translateService.instant('generic.moreResults')
                  });
                }

                if (this.DOMParentComponent) {
                  this.autoComplete.handleSuggestionsChange();
                } else {
                  this.checkGridRenderer();
                }
              }
            } else {
              this.autoComplete.loading = false;
              this.isContextMenu = false;
            }
          }
          //#region custo
        }
        //#endregion custo
      },
      this.isStandalone || event.originalEvent instanceof FocusEvent ? 300 : 375
    );
  }

  selectByIdentifier(identifier: string, value: any) {
    if (identifier && value && this.data.items) {
      for (let i = 0; i < this.data.items.length; i++) {
        const propose = this.data.items[i];
        if (propose[identifier] === value) {
          this.fieldValue = propose;
        }
      }
    }
  }

  zoomAcross() {
    this.isZoom = true;
    let record_id = -1;
    if (this.fieldValue) {
      record_id = this.fieldValue.id;
    }
    this.subscriptions.push(
      this.uiCreatorService.zoomAcross(this.data.details.tableName, this.data.details.keyColumn, record_id).subscribe(
        (dataWs) => {
          if (dataWs && dataWs.length > 0) {
            this.zoomInfo = {
              // tableName: this.data.detailZoom.tableName,
              windowId: dataWs[0].Window_ID,
              dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
              record_id: record_id,
              children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null
            };
            if (this.data.IsParam || this.container instanceof SpecificWindowUiComponent) {
              this.isZoom = false;
              Global.workspace.urlParams.dataGridRequest = null;
              Global.workspace.openTargetSearch({
                cat: { id: parseInt(dataWs[0].Window_ID, 10) },
                source: {
                  id: record_id !== -1 ? dataWs[0].Record_ID : 'newRecord'
                } // dataWs[0].Record_ID
              });
            } else {
              this.subscriptions.push(
                this.uiCreatorService.getWindow(parseInt(dataWs[dataWs.length - 1]['Window_ID'], 10)).subscribe((tabs) => {
                  const item: DynamicComponent = {
                    container: this.container,
                    DOMParentComponent: this.container,
                    linkedComponents: [this],
                    component: 'EditViewUiComponent',
                    cssClass: 'iupics-blade-content',
                    isCssOnComponent: false,
                    tabId: tabs[0].tabId,
                    zoomInfo: this.zoomInfo
                  };
                  this.componentEmitter.emit({
                    type: IupicsTypeEvent.showEditView,
                    item: item
                  });
                })
              );
            }
          }
        },
        (err) => {
          this.isZoom = false;
        }
      )
    );
  }

  refreshZoomInfo() {
    let record_id = -1;
    if (this.fieldValue) {
      record_id = this.fieldValue.id;
    }
    this.zoomInfo.record_id = record_id;
    this.zoomInfo.dataUUID = this.data.details.keyColumn.split('.')[1] + ',' + record_id;
    if (!this.zoomInfo.children && this.zoomInfo.record_id !== -1) {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.selectZoomChange,
        item: {
          container: null,
          dataStoreKey: null,
          zoomInfo: this.zoomInfo
        }
      });
    }
  }

  setNewData(dataStored: DataStore) {
    if (!dataStored.isCopied) {
      this.first = true;
    }
    super.setNewData(dataStored);
    if (this.autoComplete) {
      this.autoComplete.setMandatoryCss(this.mandatoryCss);
      this.checkAccordionAutocomplete();
    }
  }

  checkAccordionAutocomplete() {
    if (
      this.DOMParentComponent !== undefined &&
      (this.DOMParentComponent.DOMParentComponent !== undefined &&
        (<any>this.DOMParentComponent.DOMParentComponent).isAccordion) === true
    ) {
      (<any>this.DOMParentComponent.DOMParentComponent).setMandatory();
    }
  }

  onSiblingUpdate(event: any) {
    if (event && event.refreshZoom) {
      this.resetAutocompleteFromZoom(event.id);
    } else if (event && event.type === IupicsTypeEvent.expandEvent) {
      this.isZoom = false;
      if (this.editViewParent && this.editViewParent.editTabs && this.editViewParent.editTabs.length > 1) {
        this.editViewParent.editTabs.forEach((tab) => {
          tab.expandTab();
        });
        this.editViewParent.smartButtons.resizeSmartButton(IupicsTypeEvent.expandEvent);
      }
    } else if (event && event.type === IupicsTypeEvent.collapseEvent) {
      if (this.editViewParent && this.editViewParent.editTabs && this.editViewParent.editTabs.length > 1) {
        this.editViewParent.editTabs.forEach((tab) => {
          tab.collaspTab();
        });
        this.editViewParent.smartButtons.resizeSmartButton(IupicsTypeEvent.collapseEvent);
      }
    }
  }

  showConflictPanel(ev: any) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }

  showSearchPanel() {
    const editParent = this.container.DOMChildrenComponent.find(
      (DOMChild) => DOMChild instanceof EditViewUiComponent && DOMChild.tabId === this.data.tabId
    );
    if (editParent) {
      editParent.showSearchPanel(this);
    } else if (
      this.container &&
      (this.container instanceof ProcessUiComponent || this.container instanceof SpecificWindowUiComponent)
    ) {
      this.container.showSearchPanel(this);
    } else if (this.isInUniversalFilter) {
      this.container.DOMChildrenComponent[0].showSearchPanel(this);
    }
  }

  showFormPanel(formID) {
    if (this.data.isAccount) {
      this.container['accountEditor_ColumnSearch'] = this.data.columnName;
    }
    if (this.container instanceof SpecificWindowUiComponent) {
      (<SpecificWindowUiComponent>this.container).updateModalDisplay(
        { key: 'displayFormUI', value: true, sourceComponent: this },
        { key: 'formId', value: formID }
      );
    } else if (this.container instanceof ProcessUiComponent) {
      (<ProcessUiComponent>this.container).updateModalDisplay(
        { key: 'displayFormUI', value: true, sourceComponent: this },
        { key: 'formId', value: formID }
      );
    } else {
      let editView = <EditViewUiComponent>(
        this.container.DOMChildrenComponent.find(
          (DOMChild) => DOMChild instanceof EditViewUiComponent && DOMChild.tabId === this.data.tabId
        )
      );
      editView = editView ? editView : this.editViewParent;
      editView.updateModalDisplay({ key: 'displayFormUI', value: true, sourceComponent: this }, { key: 'formId', value: formID });
    }
  }

  onInputClick(event) {
    const rect = this.elementRef.nativeElement.firstChild.getBoundingClientRect();
    this.top = rect.y + 26;
    this.left = rect.x;
    this.width = rect.width;
  }

  checkGridRenderer() {
    if (this.suggestions != null) {
      const event = {
        overlayVisible: true,
        label: this.label,
        isLabelDisplay: this.isFieldDisplay,
        scrollHeight: this.scrollHeight,
        suggestions: this.suggestions,
        field: this.autoComplete.field,
        itemTemplate: this.autoComplete.itemTemplate,
        noResults: this.autoComplete.noResults,
        emptyMessage: this.autoComplete.emptyMessage,
        top: this.top,
        left: this.left,
        width: this.width,
        linkAutocomplete: this
      };
      this.checkGridRendererEmitter.emit(event);
    }
  }

  private checkVariablesForUF(validationCode: string, data: any): boolean {
    Object.keys(data)
      .filter((key) => key.startsWith('##'))
      .forEach((key) => {
        if (!data.hasOwnProperty(key.substring(1))) {
          data[key.substring(1)] = data[key];
        }
      });
    const variables = validationCode.match(/(@\S*?@)/gm);
    if (variables && variables.length > 0) {
      for (let i = 0; i < variables.length; i++) {
        const variable = variables[i];
        const v = variable.replace(/@/g, '');
        if (data[v] === undefined || data[v] === null) {
          return false;
        }
      }
    }
    return true;
  }
}
