<div [ngClass]="['ui-steps ui-widget ui-helper-clearfix', readonly ? 'ui-steps-readonly' : '', styleClass]" [ngStyle]="style">
  <ul role="tablist" data-cy="workflow-stepper">
    <li
      *ngFor="let item of model; let i = index"
      #menuitem
      [ngClass]="[
        i === activeIndex ? 'ui-state-highlight ui-steps-current' : 'ui-state-default',
        i < activeIndex ? 'ui-state-complete' : '',
        item.disabled || (i !== activeIndex && readonly) ? 'ui-state-disabled ui-steps-incomplete' : '',
        'ui-steps-item'
      ]"
    >
      <a
        *ngIf="!item.routerLink"
        [href]="item.url || '#'"
        class="ui-menuitem-link"
        (click)="itemClick($event, item, i)"
        [attr.target]="item.target"
        [attr.id]="item.id"
        (contextmenu)="showCMChecker(item, i) ? onContextMenu($event) : null"
      >
        <span class="ui-steps-number">{{ getDisplayIndex(i) }}</span> <span class="ui-steps-title">{{ item.label }}</span>
      </a>
      <a
        *ngIf="item.routerLink"
        [routerLink]="item.routerLink"
        [queryParams]="item.queryParams"
        [routerLinkActive]="'ui-state-active'"
        [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
        class="ui-menuitem-link"
        (click)="itemClick($event, item, i)"
        [attr.target]="item.target"
        [attr.id]="item.id"
      >
        <span class="ui-steps-number">{{ i + 1 }}</span> <span class="ui-steps-title">{{ item.label }}</span>
      </a>
    </li>
  </ul>
</div>
