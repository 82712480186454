<div class="iu-specific-window iu-specific-window-container">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <a *ngIf="vcrOptional.length > 0" class="settings" (click)="isSidebarOpen = !isSidebarOpen"><i class="fas fa-cog"></i></a>
  <p-scrollPanel [style]="{ width: '100%' }">
    <div class="ui-g-12">
      <label class="specific-title"
        ><b>{{ title }}</b></label
      >
      <div class="fields">
        <ng-template iuDynamicContainer></ng-template>
      </div>
    </div>
  </p-scrollPanel>
  <div class="iu-specific-btn-container">
    <iu-button-ui
      *ngIf="isModal"
      class="iu-btn-process btnOk ui-g-12 ui-md-3 ui-lg-3"
      (click)="$event.preventDefault(); $event.stopPropagation(); processConfirm()"
      label="{{ 'generic.ok' | translate }}"
      icon="fa fa-check"
    >
    </iu-button-ui>
  </div>
  <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="6000" position="right" styleClass="ui-sidebar-md optionalFilterPanel">
    <h1 class="iu-notification-center-title">
      Optionel filters
    </h1>
    <div class="optionalFields">
      <ng-template #optional></ng-template>
    </div>
  </p-sidebar>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="closeModalSearch()"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div class="specificModal" [ngClass]="{ hidden: !displayFormUI }">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false,sourceComponent:this })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
