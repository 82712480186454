import { Injectable } from '@angular/core';
import { CompiereLocationCity, CompiereLocationCountry, CompiereLocationRegion } from '@compiere-ws/models/compiere-location';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private urlCountries: string;
  private urlCities: string;
  private urlLocation: string;

  constructor(private http: ApiService, private config: AppConfig) {
    this.urlCountries = this.config.getBackendResource('countries');
    this.urlCities = this.config.getBackendResource('cities');
    this.urlLocation = this.config.getBackendResource('location');
  }

  /**
   * @returns {Observable<any>} L'ensemble des pays de compiere
   */
  getLocation(c_location_id: number): Observable<any> {
    return this.http.get<any>(this.urlLocation + '/' + c_location_id);
  }

  /**
   * @returns {Observable<CompiereLocationCountry[]>} L'ensemble des pays de compiere
   */
  getCountries(): Observable<CompiereLocationCountry[]> {
    return this.http.get<CompiereLocationCountry[]>(this.urlCountries);
  }

  /**
   *
   * @param {number} country_id L'ID du pays
   * @returns {Observable<CompiereLocationRegion>} L'ensemble des regions du pays renseigné
   */
  getRegions(country_id: number): Observable<CompiereLocationRegion> {
    return this.http.get<CompiereLocationRegion>(this.urlCountries + `/${country_id}`);
  }

  /**
   *
   * @param {number} country_id L'ID du pays
   * @returns {Observable<CompiereLocationCity[]>} L'ensemble des villes du pays renseigné
   */
  getCities(country_id: number): Observable<CompiereLocationCity[]> {
    return this.http.get<CompiereLocationCity[]>(this.urlCities + `/${country_id}`);
  }
  /**
   *
   * @param {number} city_id L'ID de la ville
   * @returns {Observable<CompiereLocationCity[]>} ville trouvée
   */
  getCity(city_id: number): Observable<CompiereLocationCity[]> {
    return this.http.get<CompiereLocationCity[]>(this.urlCities + `/${city_id}`);
  }
}
