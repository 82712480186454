<div class="iu-specific-window" [ngClass]="{ 'iu-specific-window-container': !isModal }">
  <p-progressBar
  *ngIf="!isModal && isLoading"
  [mode]="'indeterminate'"
  [value]="0"
  [showValue]="false"
  [styleClass]="'loading-progressbar'"
></p-progressBar>
<p-scrollPanel [style]="{ width: '100%' }" [ngClass]="{ 'iu-modal': isModal }">
  <div class="ui-g-12">
    <div class="ui-g-12">
      <p class="specific-title">
        <b>{{ 'gpsProject.title' | translate }}</b>
      </p>
    </div>

    <div class="ui-g-12">
      <ng-template #filtersDistance class="specific-input "></ng-template>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12">{{ 'gpsProject.or' | translate }}</span>
    </div>
    <div class="ui-g-12">
      <ng-template #filtersDistance2 class="specific-input "></ng-template>
    </div>
    <div class="ui-g-12">
      <span class="ui-g-12">{{ 'gpsProject.and' | translate }}</span>
    </div>
    <div class="ui-g-12 margin-bottom">
      <ng-template #filters></ng-template>
    </div>

    <div class="ui-g-12">
      <p class="specific-title" style="font-size: 18px">
        <b>{{ 'gpsProject.titleTableProject' | translate }}</b>
      </p>
      <ng-template #tableParent></ng-template>
    </div>

    <div class="ui-g-12">
      <ng-template #actions></ng-template>
    </div>
  </div>
</p-scrollPanel>
</div>
