import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MessageManagerService {
  public static messageSource = new Subject<any>();

  constructor() {}

  public static newMessageStatic(messages: any | any[]) {
    this.messageSource.next(messages);
  }

  newMessage(messages: any | any[]) {
    MessageManagerService.messageSource.next(messages);
  }
}
