import { Component, ComponentFactoryResolver, OnInit } from "@angular/core";
import { CompiereProcessService } from "@compiere-ws/services/compiere-process/compiere-process.service";
import { ProcessInProgressService } from "@compiere-ws/services/process-in-progress/process-in-progress.service";
import { SocketService } from "@compiere-ws/services/socket/socket.service";
import { GridViewUiComponent } from "@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component";
import { DataStoreService } from "@iupics-manager/managers/data-store/data-store.service";
import { SecurityManagerService } from "@iupics-manager/managers/security-manager/security-manager.service";
import { UICreatorService } from "@iupics-manager/managers/ui-creator/ui-creator.service";
import { WindowFactoryService } from "@iupics-manager/managers/ui-creator/window-factory/window-factory.service";
import { TranslateService } from "@ngx-translate/core";
import { SpecificWindowUiComponent } from "../specific-window-ui/specific-window-ui.component";
@Component({
  selector: "iu-create-from-open-invoice-st",
  templateUrl: "./create-from-open-invoice-st.component.html",
  styleUrls: ["./create-from-open-invoice-st.component.scss"]
})
export class CreateFromOpenInvoiceSTComponent extends SpecificWindowUiComponent
  implements OnInit {
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  notifyFromRowSelected(rowSelected: any) {
    const selections = this.dataStore.data["selections"].find(
      grid =>
        grid["AD_FormDetail_ID"] ===
        (<GridViewUiComponent>this.gridViews[0]).data["AD_FormDetail_ID"]
    );
    if (selections && selections.selection.length > 0) {
      let total = 0;
      selections.selection.forEach(selection => {
        total += selection["Open"];
      });
      const totalDisplay = total.toFixed(4);
      total = +totalDisplay;
      this.setDatacontainerValue("totalSelected", total);
    }
    if(selections && selections.selection.length ==0){
      this.setDatacontainerValue("totalSelected", 0);
    }
  
  }
}
