import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { InputSwitchUiComponent } from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import { GridTabInfinityScrollUiComponent } from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { NumberEditorComponent } from '../cell-editor/number-editor.component';
import { AutocompleteRendererComponent } from '../renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from '../renderer/button-renderer.component';
import { CalendarRendererComponent } from '../renderer/calendar-renderer.component';
import { NumberRendererComponent } from '../renderer/number-renderer.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-accounting-viewer-window-ui',
  templateUrl: './accounting-viewer-window-ui.component.html',
  styleUrls: ['./accounting-viewer-window-ui.component.scss']
})
export class AccountingViewerWindowUiComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @ViewChild('sortByAutoComplete', { read: AutocompleteUiComponent, static: true })
  sortByAutoComplete: AutocompleteUiComponent;
  @ViewChild('sortByAutoComplete2', { read: AutocompleteUiComponent, static: true })
  sortByAutoComplete2: AutocompleteUiComponent;
  @ViewChild('isExpandedInput', { read: InputSwitchUiComponent })
  isExpandedInput: InputSwitchUiComponent;
  @ViewChild('isTotalInput', { read: InputSwitchUiComponent })
  isTotalInput: InputSwitchUiComponent;
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  vcrBot: ViewContainerRef;
  @ViewChild('search', { read: ViewContainerRef, static: true })
  vcrSearch: ViewContainerRef;
  columnNames;
  groupBySelected;
  groupBySelected2;
  frameworkComponents: any;
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    private uiCreatorService: UICreatorService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
    // on veut le total de ces colonnes
    this.pinnedColKeys = ['AmtAcctDr', 'AmtAcctCr'];
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent,
      calendarRenderer: CalendarRendererComponent,
      numberRenderer: NumberRendererComponent,
      numberEditor: NumberEditorComponent
    };
  }
  ngOnInit() {
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    let recordId = null;
    let documentType = null;
    let adTableId = null;
    let adOrgId = null;
    let adTableValue = null;
    if (this.parentComponent instanceof EditViewUiComponent) {
      recordId = (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data['Data_UUID'].split(',')[1];
      adOrgId = (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data['AD_Org_ID'];
      adTableId = {
        id: (<EditViewUiComponent>this.parentComponent).editTabs[0].data['ADTableID'],
        displayValue: (<EditViewUiComponent>this.parentComponent).editTabs[0].data.label
      };
      adTableValue = (<EditViewUiComponent>this.parentComponent).editTabs[0].data['label'];
      documentType = { displayValue: adTableValue, id: adTableId };

      this.dataStore.data['AD_Org_ID'] = adOrgId;
      this.dataStore.data['Record_ID'] = recordId;
      this.dataStore.data['DocumentType'] = documentType;
      this.dataStore.data['AD_Table_ID'] = adTableId;
    }
    this.dataStore.data['SelectDocument'] = 'Y';
    this.uiCreatorService.getSpecificWindow(this.formId).subscribe(specificWindow => {
      specificWindow.items.forEach(item => {
        if (item.component === 'GridViewUiComponent') {
          this.customDesignArray.push({
            vcr: 'vcrBot',
            type: CustomDesignItemType.GRID,
            tableName: item.name,
            cssClass: item.cssClass,
            frameworkComponents: this.frameworkComponents
          });
        } else if (item.component === 'ButtonUiComponent') {
          this.customDesignArray.push({
            vcr: item.data.columnName === 'Search' ? 'vcrSearch' : 'vcrBot',
            type: CustomDesignItemType.FIELD,
            columnName: item.data.columnName,
            cssClass: 'ui-g-12 ui-md-4 ui-lg-2'
          });
        } else {
          let vcr = 'vcrTop';
          if (item.data.IsOptional) {
            vcr = 'vcrOptional';
          }
          this.customDesignArray.push({
            vcr: vcr,
            type: CustomDesignItemType.FIELD,
            columnName: item.data.columnName,
            cssClass: 'ui-g-12 ui-md-4 ui-lg-2'
          });
        }
      });
      this.showSpecificWindow();
    });
  }
  applySubTotalDebitCredit(event) {
    if (this.gridViews[0]) {
      const gridTabInfinity = this.gridViews[0].GridTabInfinityScrollUiComponent;
      const debitColumn = gridTabInfinity.columns.find(column => column.field === 'AmtAcctDr');
      const creditColumn = gridTabInfinity.columns.find(column => column.field === 'AmtAcctCr');
      let valueCols = [];
      if (event === 'Y') {
        valueCols = [
          { aggFunc: 'sum', displayName: creditColumn.headerName, field: 'AmtAcctCr', id: 'AmtAcctCr' },
          { aggFunc: 'sum', displayName: debitColumn.headerName, field: 'AmtAcctDr', id: 'AmtAcctDr' }
        ];
      }
      this.gridViews[0].GridTabInfinityScrollUiComponent.valueCols = valueCols;
      gridTabInfinity.applyFilter(gridTabInfinity.sortModel, gridTabInfinity.filterModel, gridTabInfinity.groupColumn, valueCols);
    }
  }
  expandAll(event) {
    const gridTabInfinity = <GridTabInfinityScrollUiComponent>this.gridViews[0].GridTabInfinityScrollUiComponent;
    gridTabInfinity.agGrid.api.forEachNode(node => {
      if (node.group) {
        node.setExpanded(event === 'Y' ? true : false);
      }
    });
  }
  notifyFromGridRefresh(gridView: GridViewUiComponent) {
    if (
      this.gridViews[0].GridTabInfinityScrollUiComponent &&
      this.gridViews[0].GridTabInfinityScrollUiComponent.groupColumn.length === 0
    ) {
      this.isExpandedInput.fieldValue = 'N';
      this.isTotalInput.fieldValue = 'N';
    }
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  onBtnClick1(e) {}
}
