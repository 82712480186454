import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { ButtonUiComponent } from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { ProcessUiComponent } from '../process-ui/process-ui.component';
import { RowSelectionType, SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.scss']
})
export class EventSearchComponent extends SpecificWindowUiComponent implements OnInit {
  SumSelectedRows = 0;
  processUiComponent: ProcessUiComponent;
  ButtonUiComponent: ButtonUiComponent;
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService
  ) {
    super(windowFactory, resolver, uiCreator, store, processService, socketService, connectorService, pp, translateService);
    this.isModal = false;
  }
  ngOnInit() {
    this.customDesignArray = [
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Role_ID',
        cssClass: 'ui-g-12 ui-md-6 ui-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_ProjectType_ID',
        cssClass: 'ui-g-12 ui-md-6 ui-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'S_Training_ID',
        cssClass: 'ui-g-12 ui-md-6 ui-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'S_Training_Class_ID',
        cssClass: 'ui-g-12 ui-md-6 ui-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Value',
        cssClass: 'ui-g-12 ui-md-6 ui-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Name',
        cssClass: 'ui-g-12 ui-md-6 ui-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Org_ID',
        cssClass: 'ui-g-12 ui-md-6 ui-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Z_PeriodeForm_ID',
        cssClass: 'ui-g-12 ui-md-6 ui-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.GRID,
        tableName: 'View Event Table',
        cssClass: 'ui-g-12'
      }
    ];
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'ui-g-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    if (this.sourceComponent && this.sourceComponent.dataStored) {
      if (!this.dataStore) {
        this.dataStore = this.store.newSpecificWindowData(this.formId, null);
        this.dataStore.data['AD_Org_ID'] = this.sourceComponent.dataStored.data['AD_Org_ID'];
      }
    }
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }

  /**
   * Implementation de la sélection d'une cellule
   * @param gridView grid sur laquelle s'est fait la sélection
   */
  notifySelect(gridView: GridViewUiComponent, row: any) {
    const data = row?.data[0];
    if (this.isModal && data) {
      this.setRowSelection(gridView.data.columnName, RowSelectionType.SINGLE);
      let identifiant = null;
      if (data['Data_UUID']) {
        identifiant = data['Data_UUID'].split(',')[0];
      }
      if (!identifiant) {
        identifiant = gridView.data.columnsDetails.keys[0];
      }
      const sourceFieldName = this.sourceComponent.data.columnName;
      if (sourceFieldName) {
        const event = { data: { Data_UUID: sourceFieldName + ',' + data[identifiant] } };
        (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(event);
        this.closeModalEmitter.emit();
      }
    }
  }

  selectEvent(p_C_Project_ID: number) {
    this.dataStore.data.selections[0].selection = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows();
    const columnName = this.sourceComponent.data.columnName;

    if (columnName && p_C_Project_ID) {
      const event = { data: { Data_UUID: columnName + ',' + p_C_Project_ID } };
      (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(event);
    }
  }
}
