import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { InfoDialogType } from '../info-dialog/info-dialog.component';
import { ProcessParams } from '../process-ui/process-ui.component';
import { AutocompleteRendererComponent } from '../renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from '../renderer/button-renderer.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-staff-management',
  templateUrl: './staff-management.component.html',
  styleUrls: ['./staff-management.component.scss']
})
export class StaffManagementComponent extends SpecificWindowUiComponent implements OnInit {
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private config: AppConfig,
    private ppService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      ppService,
      translateService
    );
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent
    };

    const processTransfertRequest: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_Process',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: ['ZStaffManagementTransfertAFF'],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };

    this.store.getDataGrid(processTransfertRequest).subscribe((res) => {
      if (res && res.data && res.data.length > 0) {
        this.transfert_Process_ID = res.data[0]['AD_PROCESS_ID'];
      }
    });

    const processNewAnimRequest: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_Process',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: ['ZStaffManagementAddAFF'],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };

    this.store.getDataGrid(processNewAnimRequest).subscribe((res) => {
      if (res && res.data && res.data.length > 0) {
        this.newAnim_Process_ID = res.data[0]['AD_PROCESS_ID'];
      }
    });
  }
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  vcrBot: ViewContainerRef;

  sectionList = [];
  fonctionList = [];
  frameworkComponents: any;

  clotureFonction_Process_ID = 0;
  transfert_Process_ID = 0;
  newAnim_Process_ID = 0;
  Z_BP_Link_AFF_NewAnim = 0;

  end;

  ngOnInit() {
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        if (item.component === 'GridViewUiComponent') {
          item.data.hasCheckbox = false;
          const editorColumns = [];

          const colTargetSection = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'TargetSection';
          });
          if (colTargetSection) {
            const targetSectionDetail = item.data.columnsDetails.get('TargetSection');
            editorColumns.push('TargetSection');
            colTargetSection.editable = false;
            colTargetSection.cellRenderer = 'autocompleteRenderer';
            colTargetSection.cellRendererParams = {
              onSelect: this.onSelect.bind(this),
              onSearch: this.onSearch.bind(this),
              closePanel: this.closePanel.bind(this),
              label: '',
              templates: {
                items: null,
                columnName: 'TargetSection',
                urlList: targetSectionDetail.field.urlList,
                urlSearch: targetSectionDetail.field.urlSearch,
                isSearch: true,
                columnId: targetSectionDetail.field.field.AD_Column_ID,
                fieldTypeForm: true,
                validationCode: targetSectionDetail.field.field.ValidationCode
              }
            };
          }

          const colTargetFonction = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'TargetFonction';
          });
          if (colTargetFonction) {
            const targetFonctionDetail = item.data.columnsDetails.get('TargetFonction');
            editorColumns.push('TargetFonction');
            colTargetFonction.editable = false;
            colTargetFonction.cellRenderer = 'autocompleteRenderer';
            colTargetFonction.cellRendererParams = {
              onSelect: this.onSelect.bind(this),
              onSearch: this.onSearch.bind(this),
              closePanel: this.closePanel.bind(this),
              label: '',
              templates: {
                items: null,
                columnName: 'TargetFonction',
                urlList: targetFonctionDetail.field.urlList,
                urlSearch: targetFonctionDetail.field.urlSearch,
                isSearch: true,
                columnId: targetFonctionDetail.field.field.AD_Column_ID,
                fieldTypeForm: true
              }
            };
          }

          const colCloseFonction = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'CloseFonction';
          });
          if (colCloseFonction) {
            this.clotureFonction_Process_ID = item.data.columnsDetails.get('CloseFonction').AD_Process_ID;
            const label = colCloseFonction.headerName;
            editorColumns.push('CloseFonction');
            colCloseFonction.editable = false;
            // colCloseFonction.headerName = '                ';
            colCloseFonction.cellRenderer = 'buttonRenderer';
            colCloseFonction.cellRendererParams = {
              onSelect: this.onSelect.bind(this),
              label: label
            };
          }

          this.customDesignArray.push({
            vcr: 'vcrMiddle',
            type: CustomDesignItemType.GRID,
            tableName: 'Passage à la carte - Table',
            cssClass: 'ui-g-12',
            shouldSelectFirst: false,
            editorColumns: editorColumns,
            frameworkComponents: this.frameworkComponents,
            isFitResize: true
          });
        } else if (item.data.columnName === 'AD_Org_ID') {
          // const fieldSize = Global.isMobileWidth() ? 'ui-g-12' : 'ui-g-4';
          const fieldSize = 'ui-g-12  ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'AD_Org_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'Membre') {
          // const fieldSize = Global.isMobileWidth() ? 'ui-g-12' : 'ui-g-4';
          const fieldSize = 'ui-g-12';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'Membre',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'C_BPartner_ID') {
          const fieldSize = 'ui-g-12 ui-md-3 ui-lg-2  forcedHeight';
          this.customDesignArray.push({
            vcr: 'vcrBot',
            type: CustomDesignItemType.FIELD,
            columnName: 'C_BPartner_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'DDN') {
          const fieldSize = 'ui-g-12 ui-md-3 ui-lg-2  ';
          this.customDesignArray.push({
            vcr: 'vcrBot',
            type: CustomDesignItemType.FIELD,
            columnName: 'DDN',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'SECTION') {
          const fieldSize = 'ui-g-12 ui-md-3 ui-lg-2  ';
          this.customDesignArray.push({
            vcr: 'vcrBot',
            type: CustomDesignItemType.FIELD,
            columnName: 'SECTION',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'FONCTION') {
          const fieldSize = 'ui-g-12 ui-md-3 ui-lg-2  ';
          this.customDesignArray.push({
            vcr: 'vcrBot',
            type: CustomDesignItemType.FIELD,
            columnName: 'FONCTION',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'TargetFonction') {
          const fieldSize = 'ui-g-12 ui-md-3 ui-lg-2  ';
          this.customDesignArray.push({
            vcr: 'vcrBot',
            type: CustomDesignItemType.FIELD,
            columnName: 'TargetFonction',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'TargetSection') {
          const fieldSize = 'ui-g-12 ui-md-3 ui-lg-2  ';
          this.customDesignArray.push({
            vcr: 'vcrBot',
            type: CustomDesignItemType.FIELD,
            columnName: 'TargetSection',
            cssClass: fieldSize
          });
        }
      });
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'ui-g-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };
      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
  }

  notifyFromDataChange(item?: any) {
    // }
    if (item.data['columnName'] === 'C_BPartner_ID' && this.dataStore && this.dataStore.data['C_BPartner_ID']) {
      const calloutRequest: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'Y_Liste_Membres',
          filterModel: {
            C_BPARTNER_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data['C_BPartner_ID'].id],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };
      const affPrincRequest: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'Z_BP_Link_Affiliation',
          filterModel: {
            C_BPARTNER_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data['C_BPartner_ID'].id],
              operators: [OperatorFilterType.EQUALS]
            },
            ISACTIVE: {
              filterType: CompiereDataGridFilterType.SET,
              values: ['Y'],
              operators: [OperatorFilterType.EQUALS]
            },
            ISPRINC: {
              filterType: CompiereDataGridFilterType.SET,
              values: ['Y'],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };

      forkJoin(this.store.getDataGrid(calloutRequest), this.store.getDataGrid(affPrincRequest)).subscribe(([res, aff]) => {
        if (res && res.data && res.data.length > 0) {
          this.dataStore.data['DDN'] = res.data[0]['DATE_NAISS'];
          this.dataStore.data['FONCTION'] = res.data[0]['FONCTION'];
          this.dataStore.data['SECTION'] = res.data[0]['SECTION'];
          this.dataStore.data['TargetSection'] = null;
          this.dataStore.data['TargetFonction'] = null;
          this.Z_BP_Link_AFF_NewAnim =
            aff.data && aff.data.length > 0 && aff.data[0]['Z_BP_LINK_AFFILIATION_ID']
              ? aff.data[0]['Z_BP_LINK_AFFILIATION_ID']
              : 0;
          if (this.dataStore.dataChange) {
            const dataModified = {
              DDN: res.data[0]['DATE_NAISS'],
              FONCTION: res.data[0]['FONCTION'],
              SECTION: res.data[0]['SECTION'],
              TargetSection: null,
              TargetFonction: null
            };
            this.dataStore.dataChange.emit({
              dataModified: dataModified,
              bypassValidation: false,
              isFromCallout: true
            });
          }
        }
      });
    } else if (item.data['columnName'] === 'AD_Org_ID') {
      this.dataStore.data['DDN'] = null;
      this.dataStore.data['FONCTION'] = null;
      this.dataStore.data['SECTION'] = null;
      this.dataStore.data['TargetSection'] = null;
      this.dataStore.data['TargetFonction'] = null;
      this.dataStore.data['C_BPartner_ID'] = null;
      if (this.dataStore.dataChange) {
        const dataModified = {
          DDN: null,
          FONCTION: null,
          SECTION: null,
          TargetSection: null,
          TargetFonction: null,
          C_BPartner_ID: null
        };
        this.dataStore.dataChange.emit({
          dataModified: dataModified,
          bypassValidation: false,
          isFromCallout: true
        });
      }
    } else if (
      (item.data['columnName'] === 'TargetSection' || item.data['columnName'] === 'TargetFonction') &&
      this.dataStore.data['TargetFonction'] &&
      this.dataStore.data['TargetSection']
    ) {
      Global.infoDialog.message = {
        summary: this.translateService.instant('staffManagement.dialogs.addAnim.title'),
        detail:
          this.translateService.instant('staffManagement.dialogs.addAnim.message') +
          this.dataStore.data['C_BPartner_ID'].displayValue +
          ' :  ' +
          this.dataStore.data['TargetFonction'].displayValue +
          ' - ' +
          this.dataStore.data['TargetSection'].displayValue +
          ' ?'
      };
      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();

      const valSubscr = Global.infoDialog.confirm.subscribe((event) => {
        this.processService.getProcess(this.newAnim_Process_ID).subscribe((success) => {
          if (success) {
            let paramsMap: ProcessParams = {
              ad_process_id: null,
              className: null,
              record_id: null,
              tableName: null,
              tables: null,
              params: null,
              windowCtx: null,
              ad_tab_id: null
            };
            const classname = success.process.ClassName;
            const tableName = this.isModal ? this.parentComponent.editTabs[0].data.TableName : null;
            paramsMap = {
              record_id: null,
              ad_process_id: null,
              params: {
                Z_BP_Link_Affiliation_ID: this.Z_BP_Link_AFF_NewAnim,
                M_Warehouse_ID: this.dataStore.data['TargetSection'].id,
                Z_Fonction_ID: this.dataStore.data['TargetFonction'].id,
                C_BPartner_ID: this.dataStore.data['C_BPartner_ID'].id,
                AD_Org_ID: this.dataStore.data['AD_Org_ID'].id
              },
              className: classname,
              tableName: tableName,
              tables: this.tablesForm,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };

            const obs = this.uiCreator.executeProcess(paramsMap);
            this.subscriptions.push(
              obs.subscribe((instance) => {
                this.updateLoading(true);
                const pg = this.ppService.watchProcessInProgress().subscribe((pings) => {
                  const me = this.connectorService.getIupicsUserAccount();
                  const ctx = this.connectorService.getIupicsUserContext();
                  const ping = pings.find((p) => {
                    return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === this.newAnim_Process_ID&&p.Status === 'finish';
                  });
                  if (ping) {
                    this.dataStore.data['DDN'] = null;
                    this.dataStore.data['FONCTION'] = null;
                    this.dataStore.data['SECTION'] = null;
                    this.dataStore.data['TargetSection'] = null;
                    this.dataStore.data['TargetFonction'] = null;
                    this.dataStore.data['C_BPartner_ID'] = null;
                    if (this.dataStore.dataChange) {
                      const dataModified = {
                        DDN: null,
                        FONCTION: null,
                        SECTION: null,
                        TargetSection: null,
                        TargetFonction: null,
                        C_BPartner_ID: null
                      };
                      this.dataStore.dataChange.emit({
                        dataModified: dataModified,
                        bypassValidation: false,
                        isFromCallout: true
                      });
                    }
                    pg.unsubscribe();
                    this.updateLoading(false);
                    this.onEndProcess(ping);
                  }
                });
              })
            );
            this.tablesForm.pop();
          }
        });

        valSubscr.unsubscribe();
        canSubscr.unsubscribe();
      });

      const canSubscr = Global.infoDialog.cancel.subscribe((event) => {
        canSubscr.unsubscribe();
        valSubscr.unsubscribe();
      });
    }
    if (item.data.isLaunchSearchGrid) {
      this.refreshGrids(this.dataStore, false, item.data);
    }
  }

  onSelect(e) {
    if (e.columnName === 'CloseFonction') {
      Global.infoDialog.message = {
        summary: this.translateService.instant('staffManagement.dialogs.Cloturer.title'),
        detail:
          this.translateService.instant('staffManagement.dialogs.Cloturer.message') +
          e.rowData.Name +
          ' ' +
          e.rowData.FirstName +
          '?'
      };
      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();

      const valSubscr = Global.infoDialog.confirm.subscribe((event) => {
        this.processService.getProcess(this.clotureFonction_Process_ID).subscribe((success) => {
          if (success) {
            let paramsMap: ProcessParams = {
              ad_process_id: null,
              className: null,
              record_id: null,
              tableName: null,
              tables: null,
              params: null,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };
            const classname = success.process.ClassName;
            const tableName = this.isModal ? this.parentComponent.editTabs[0].data.TableName : null;
            paramsMap = {
              record_id: null,
              ad_process_id: null,
              params: {
                Z_BP_Link_Affiliation_ID: e.rowData['Z_BP_Link_Affiliation_ID']
              },
              className: classname,
              tableName: tableName,
              tables: this.tablesForm,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };

            const obs = this.uiCreator.executeProcess(paramsMap);
            this.subscriptions.push(
              obs.subscribe((instance) => {
                this.updateLoading(true);
                const pg = this.ppService.watchProcessInProgress().subscribe((pings) => {
                  const me = this.connectorService.getIupicsUserAccount();
                  const ctx = this.connectorService.getIupicsUserContext();
                  const ping = pings.find((p) => {
                    return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === this.clotureFonction_Process_ID&&p.Status === 'finish';
                  });
                  if (ping) {
                    // this.socketService.closeDataChannel(this.channel_id);
                    pg.unsubscribe();
                    this.updateLoading(false);
                    this.onEndProcess(ping);
                  }
                });
              })
            );
            this.tablesForm.pop();
          }
        });

        valSubscr.unsubscribe();
        canSubscr.unsubscribe();
      });

      const canSubscr = Global.infoDialog.cancel.subscribe((event) => {
        canSubscr.unsubscribe();
        valSubscr.unsubscribe();
      });
    } else if (
      (e.columnName === 'TargetFonction' || e.columnName === 'TargetSection') &&
      e.rowData.TargetFonction &&
      e.rowData.TargetSection
    ) {
      Global.infoDialog.message = {
        summary: this.translateService.instant('staffManagement.dialogs.Transfert.title'),
        detail:
          this.translateService.instant('staffManagement.dialogs.Transfert.message') +
          e.rowData.Name +
          '  ' +
          e.rowData.FirstName +
          '  ?'
      };
      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();

      const valSubscr = Global.infoDialog.confirm.subscribe((event) => {
        this.processService.getProcess(this.transfert_Process_ID).subscribe((success) => {
          if (success) {
            let paramsMap: ProcessParams = {
              ad_process_id: null,
              className: null,
              record_id: null,
              tableName: null,
              tables: null,
              params: null,
              windowCtx:null,
              ad_tab_id: null
            };
            const classname = success.process.ClassName;
            const tableName = this.isModal ? this.parentComponent.editTabs[0].data.TableName : null;
            paramsMap = {
              record_id: null,
              ad_process_id: null,
              params: {
                Z_BP_Link_Affiliation_ID: e.rowData.Z_BP_Link_Affiliation_ID,
                M_Warehouse_ID: e.rowData.TargetSection,
                Z_Fonction_ID: e.rowData.TargetFonction,
                Name: e.rowData.Name,
                FirstName: e.rowData.FirstName
              },
              className: classname,
              tableName: tableName,
              tables: this.tablesForm,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };

            const obs = this.uiCreator.executeProcess(paramsMap);
            this.subscriptions.push(
              obs.subscribe((instance) => {
                this.updateLoading(true);
                const pg = this.ppService.watchProcessInProgress().subscribe((pings) => {
                  const me = this.connectorService.getIupicsUserAccount();
                  const ctx = this.connectorService.getIupicsUserContext();
                  const ping = pings.find((p) => {
                    return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === this.transfert_Process_ID&&p.Status === 'finish';
                  });
                  if (ping) {
                    // this.socketService.closeDataChannel(this.channel_id);
                    pg.unsubscribe();
                    this.updateLoading(false);
                    this.onEndProcess(ping);
                  }
                });
              })
            );
            this.tablesForm.pop();
          }
        });

        valSubscr.unsubscribe();
        canSubscr.unsubscribe();
      });

      const canSubscr = Global.infoDialog.cancel.subscribe((event) => {
        canSubscr.unsubscribe();
        valSubscr.unsubscribe();
      });
    }
  }
  closePanel() {
    this.gridViews[0].overlayVisible = false;
  }
  onSearch(e) {
    this.gridViews[0].overlayVisible = e.overlayVisible;
    this.gridViews[0].setPosition(e.top, e.left, e.width);
    this.gridViews[0].label = e.label;
    this.gridViews[0].isLabelDisplay = e.isLabelDisplay;
    this.gridViews[0].scrollHeight = e.scrollHeight;
    this.gridViews[0].suggestions = e.suggestions;
    this.gridViews[0].field = e.field;
    this.gridViews[0].itemTemplate = e.itemTemplate;
    this.gridViews[0].noResults = e.noResults;
    this.gridViews[0].emptyMessage = e.emptyMessage;
    this.gridViews[0].linkAutocompleteRenderer = e.linkAutocomplete;
  }
}
