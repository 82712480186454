import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Terminal, TerminalService } from 'primeng/terminal';

@Component({
  selector: 'iu-prime-terminal',
  templateUrl: './prime-terminal.component.html',
  styleUrls: ['./prime-terminal.component.scss']
})
export class PrimeTerminalComponent extends Terminal implements OnInit, OnDestroy {
  constructor(public el: ElementRef, public terminalService: TerminalService) {
    super(el, terminalService);
  }
  @Input()
  welcomeMessage: string;

  @Input()
  isInputBlocked = false;

  @Input()
  prompt: string;

  @Input()
  style: any;

  @Input()
  styleClass: string;

  ngOnInit() {}

  handleCommand(event: KeyboardEvent) {
    this.commands.push({ text: this.command });
    this.terminalService.sendCommand(this.command);
    this.command = '';
    event.stopPropagation();
  }

  blockInput(event: KeyboardEvent) {
    if (event.keyCode < 112 || event.keyCode > 123) {
      event.preventDefault();
    }
    event.stopPropagation();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
