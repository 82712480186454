import { Component, ComponentFactoryResolver, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, zip } from 'rxjs';
import { ProcessParams } from '../process-ui/process-ui.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

export enum ActionType {
  INSCRIT = 'IsInscrit',
  PAID = 'IsPaid',
  PRESENT = 'IsPresent'
}

@Component({
  selector: 'iu-participant-management',
  templateUrl: './participant-management.component.html',
  styleUrls: ['./participant-management.component.scss']
})
export class ParticipantManagementComponent extends SpecificWindowUiComponent implements OnInit {
  /**
   * Map contenant les appels au ws déjà en cours pour une action et un id donné
   */
  private callMap = new Map<string, Subscription>();
  @ViewChild('firstGrid', { read: ViewContainerRef, static: true })
  vcrFirstGrid: ViewContainerRef;
  @ViewChild('secondGrid', { read: ViewContainerRef, static: true })
  vcrSecondGrid: ViewContainerRef;
  @ViewChildren('vcrRole', { read: ViewContainerRef })
  vcrRole: QueryList<ViewContainerRef>;
  @ViewChild('vcrButtonInscrire', { read: ViewContainerRef, static: true })
  vcrButtonInscrire: ViewContainerRef;
  updateAllProcessID = null;
  registerEnabled = false;
  fonctionLines: { name: string; nb: number; values?: String[] }[] = [];
  displayCustoModal = false;
  datacontainersRoleEvenement: {
    datacontainer: AbstractDataContainer;
    dataUUIDs: String[];
  }[] = [];
  buttonIsInscrit = true;
  buttonIsPaid = true;
  buttonIsPresent = true;
  buttonIsPaidReadOnly = false;
  buttonIsPresentReadOnly = false;
  buttonIsInscritReadOnly = false;
  nbPresent = 0;
  nbInscrit = 0;
  nbPaid = 0;
  isCadreFed = false;
  dataStoreRequest: DataStoreRequest;
  sub: any;
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private ppService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      ppService,
      translateService
    );
    this.isModal = false;
    this.customDesignArray.push({
      vcr: 'vcr',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Project_ID',
      cssClass: 'ui-g-12'
    });
  }

  ngOnInit() {
    if (this.connectorService.getIupicsUserContext()['#AD_Role_Name'].startsWith('Cadre fédéral')) {
      this.isCadreFed = true;
    }
    super.ngOnInit();
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      const itemFound = specificWindow.items.find((item) => item.component === 'GridViewUiComponent');
      if (itemFound) {
        itemFound.data.hasCheckbox = false;
        const editorColumns = [];

        const colIsInscrit = itemFound.data.columnsTableHeader.find(function (element) {
          return element.field === 'IsInscrit';
        });
        if (colIsInscrit && !this.isCadreFed) {
          editorColumns.push('IsInscrit');
          colIsInscrit.editable = true;
        }
        const colIsPaid = itemFound.data.columnsTableHeader.find(function (element) {
          return element.field === 'IsPaid';
        });
        if (colIsPaid && !this.isCadreFed) {
          editorColumns.push('IsPaid');
          colIsPaid.editable = true;
        }
        const colIsPresent = itemFound.data.columnsTableHeader.find(function (element) {
          return element.field === 'IsPresent';
        });
        if (colIsPresent) {
          editorColumns.push('IsPresent');
          colIsInscrit.editable = true;
        }
        this.customDesignArray.push({
          vcr: 'vcrFirstGrid',
          type: CustomDesignItemType.GRID,
          tableName: 'Gestion des participants - Table',
          cssClass: 'ui-g-12',
          shouldSelectFirst: false,
          editorColumns: editorColumns,
          frameworkComponents: this.frameworkComponents,
          isFitResize: true
        });
      }
      const updateAllFunctionItemFound = specificWindow.items.find((item) => item.data.columnName === 'updateAllFunction');
      this.updateAllProcessID = updateAllFunctionItemFound.processId;
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'ui-g-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };
      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
  }
  updateAll(e, action: string, cellInfo?: any) {
    if (!cellInfo || (cellInfo.event && cellInfo.event.target && cellInfo.event.target.tagName === 'INPUT')) {
      if (e) {
        e.stopPropagation();
      }
      // check si bouton ReadOnly
      if (this['button' + action + 'ReadOnly'] === true) {
        return;
      }
      let participantId;
      // récupération des infos de la cellule
      if (
        cellInfo &&
        cellInfo.node &&
        cellInfo.node.data &&
        cellInfo.node.data['Z_Participant_ID'] !== null &&
        cellInfo.node.data['Z_Participant_ID'] !== undefined
      ) {
        participantId = cellInfo.node.data['Z_Participant_ID'];
      }
      // Check d'un processus déjà en cours pour cette celulle
      if (this.callMap.get(participantId + action) !== undefined) {
        if (cellInfo && cellInfo.event.target.tagName === 'INPUT') {
          cellInfo.event.target.checked = !cellInfo.event.target.checked;
        }
        return;
      } else {
        this.initCell(cellInfo);
      }
      // Init des paramètres à passer au processus
      const params = {
        C_Project_ID: this.dataStore.data['C_Project_ID'] ? this.dataStore.data['C_Project_ID'].id : null,
        Z_Participant_ID: participantId
      };
      let isUpdateAll = true;
      if (participantId !== null && participantId !== undefined) {
        isUpdateAll = false;
        params[action] = cellInfo.node.data[cellInfo.column.colId];
        this.updatePanelWithCellInfo(cellInfo, action);
      } else {
        params[action] = this['button' + action] ? 'Y' : 'N';
        this['button' + action] = !this['button' + action];
        this.setButtonReadOnly(action, true);
      }
      this.callMap.set(participantId + action, null);
      this.processService.getProcess(this.updateAllProcessID).subscribe(
        (success) => {
          if (success) {
            let paramsMap: ProcessParams = {
              ad_process_id: null,
              className: null,
              record_id: null,
              tableName: null,
              tables: null,
              params: null,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };
            const classname = success.process.ClassName;
            const tableName = this.isModal ? this.parentComponent.editTabs[0].data.TableName : null;
            paramsMap = {
              record_id: null,
              ad_process_id: null,
              params: params,
              className: classname,
              tableName: tableName,
              tables: this.tablesForm,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };
            const obs = this.uiCreator.executeProcess(paramsMap);
            this.subscriptions.push(
              obs.subscribe((instance) => {
                if (this.callMap.size === 0) {
                  this.updateLoading(true);
                }
                if (this.callMap.get(participantId + action) === null) {
                  this.callMap.set(
                    participantId + action,
                    this.ppService.watchProcessInProgress().subscribe((pings) => {
                      const me = this.connectorService.getIupicsUserAccount();
                      const ping = pings.find((p) => {
                        return p.AD_User_ID.id === me.id && p.AD_Process_ID && p.AD_Process_ID.id === this.updateAllProcessID;
                      });
                      if (ping) {
                        (async () => {
                          for await (const notification of this.socketService.enableRoleNotifications()) {
                            if (
                              notification &&
                              notification.sourceTypeId &&
                              notification.sourceTypeId === this.updateAllProcessID
                            ) {
                              if (this.callMap.get(participantId + action)) {
                                if (notification.isError) {
                                  this.refreshGrids();
                                }
                                if (isUpdateAll) {
                                  this.refreshGrids();
                                  this.setButtonReadOnly(action, false);
                                }
                                this.callMap.get(participantId + action).unsubscribe();
                                this.callMap.delete(participantId + action);
                                if (this.callMap.size === 0) {
                                  setTimeout(() => {
                                    this.updateLoading(false);
                                    this.notifyFromResponse();
                                  }, 100);
                                }
                              }
                              // channel.unsubscribe();
                            }
                          }
                        })();
                      }
                    })
                  );
                }
              })
            );
            this.tablesForm.pop();
          }
        },
        (err) => {
          this.resetCell(cellInfo);
        }
      );
    }
  }

  updatePanelWithCellInfo(cellInfo, action) {
    switch (action) {
      case 'IsInscrit':
        this.nbInscrit += cellInfo.node.data[cellInfo.column.colId] === 'Y' ? 1 : -1;
        break;
      case 'IsPresent':
        this.nbPresent += cellInfo.node.data[cellInfo.column.colId] === 'Y' ? 1 : -1;
        break;
      case 'IsPaid':
        this.nbPaid += cellInfo.node.data[cellInfo.column.colId] === 'Y' ? 1 : -1;
        break;
      default:
        break;
    }
  }
  setButtonReadOnly(action: string, value: boolean) {
    this['button' + action + 'ReadOnly'] = value;
  }
  initCell(cellInfo) {
    if (cellInfo && cellInfo.node) {
      cellInfo.node.data[cellInfo.column.colId] = cellInfo.node.data[cellInfo.column.colId] === 'Y' ? 'N' : 'Y';
    }
  }
  resetCell(cellInfo) {
    if (cellInfo && cellInfo.node) {
      cellInfo.node.data[cellInfo.column.colId] = cellInfo.node.data[cellInfo.column.colId] === 'Y' ? 'N' : 'Y';
      if (cellInfo && cellInfo.event.target.tagName === 'INPUT') {
        cellInfo.event.target.checked = cellInfo.node.data[cellInfo.column.colId] === 'Y' ? false : true;
      }
    }
  }
  notifyFromCellClicked(gridView: GridViewUiComponent, event: any) {
    if (event && event.column.colId !== null && event.column.colId !== undefined) {
      if (['IsInscrit', 'IsPaid', 'IsPresent'].includes(event.column.colId)) {
        this.updateAll(event.event, event.column.colId, event);
      }
    }
  }
  notifyFromResponse() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    const zipArray = [];
    ['IsInscrit', 'IsPaid', 'IsPresent'].forEach((key) => {
      const compiereRequest = Object.assign({}, this.dataStoreRequest.compiereRequest);
      compiereRequest.valueCols = [
        {
          id: key,
          aggFunc: 'count',
          displayName: key,
          field: key
        }
      ];
      compiereRequest.filterModel = {
        [key]: {
          filterType: CompiereDataGridFilterType.TEXT,
          values: ['Y'],
          operators: [OperatorFilterType.EQUALS]
        }
      };
      compiereRequest.startRow = 0;
      compiereRequest.endRow = 0;
      this.dataStoreRequest.compiereRequest = compiereRequest;
      zipArray.push(this.store.getDataGrid(this.dataStoreRequest));
    });
    // ["IsInscrit","IsPaid","IsPresent"]
    this.sub = zip(...zipArray).subscribe((response: any[]) => {
      this.nbInscrit = response[0].data[0]['IsInscrit'];
      this.nbPaid = response[1].data[0]['IsPaid'];
      this.nbPresent = response[2].data[0]['IsPresent'];
      this.sub.unsubscribe();
    });
  }
  notifyFromGridRefresh(gridView: GridViewUiComponent, dataStoreRequest: DataStoreRequest) {
    let callWS = false;
    if (this.dataStoreRequest === undefined) {
      callWS = true;
    }
    this.dataStoreRequest = Object.assign({}, dataStoreRequest);
    if (callWS) {
      this.notifyFromResponse();
    }
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
