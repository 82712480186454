import { MouseEvent } from "@agm/core";
import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { LocationService } from "@compiere-ws/services/compiere-location/location.service";
import { CompiereProcessService } from "@compiere-ws/services/compiere-process/compiere-process.service";
import { ProcessInProgressService } from "@compiere-ws/services/process-in-progress/process-in-progress.service";
import { SocketService } from "@compiere-ws/services/socket/socket.service";
import { CustomDesignItemType } from "@iupics-components/models/custom-design";
import { EditViewUiComponent } from "@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component";
import { DataStoreService } from "@iupics-manager/managers/data-store/data-store.service";
import { SecurityManagerService } from "@iupics-manager/managers/security-manager/security-manager.service";
import { UICreatorService } from "@iupics-manager/managers/ui-creator/ui-creator.service";
import { WindowFactoryService } from "@iupics-manager/managers/ui-creator/window-factory/window-factory.service";
import { DynamicComponent } from "@iupics-manager/models/dynamic-component";
import { IupicsTypeEvent } from "@iupics-manager/models/iupics-event";
import { TranslateService } from "@ngx-translate/core";
import { SpecificWindowUiComponent } from "./../specific-window-ui/specific-window-ui.component";

@Component({
  selector: "iu-get-map-info-camp",
  templateUrl: "./get-map-info-camp.component.html",
  styleUrls: ["./get-map-info-camp.component.scss"]
})
export class GetMapInfoCampComponent extends SpecificWindowUiComponent
  implements OnInit {
  @ViewChild("top", { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;

  zoom = 8;
  marker: marker;
  lat = 49.926263;
  lng = 5.297816;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService,
    translateService: TranslateService,
    private locationService: LocationService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      pp,
      translateService
    );
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: "vcrTop",
        type: CustomDesignItemType.FIELD,
        columnName: "Geo_Location_Lat",
        cssClass: "ui-g-6"
      },
      {
        vcr: "vcrTop",
        type: CustomDesignItemType.FIELD,
        columnName: "Geo_Location_Lng",
        cssClass: "ui-g-6"
      }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.marker = {
      lat: this.lat,
      lng: this.lng,
      label: "A",
      draggable: true
    };
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: "SpecificWindowUiComponent",
      cssClass: "ui-g-12",
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });

    if (this.dataStore.data["Latitude"] && this.dataStore.data["Longitude"]) {
      this.marker.lat = this.dataStore.data["Latitude"];
      this.marker.lng = this.dataStore.data["Longitude"];
      this.lat = parseFloat(this.dataStore.data["Latitude"]);
      this.lng = parseFloat(this.dataStore.data["Longitude"]);
    } else {
      if (this.dataStore.data["C_Project_Location_ID"]) {
        this.locationService
          .getLocation(this.dataStore.data["C_Project_Location_ID"].id)
          .subscribe(response => {
            const addressData = [
              response["Address1"],
              response["Address2"],
              response["Address3"],
              response["Address4"],
              response["City"],
              response["Postal"]
            ];
            const address = addressData
              .filter(
                item => item !== undefined && item !== null && item !== ""
              )
              .join(",");
            const geocode = new google.maps.Geocoder();
            geocode.geocode({ address }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                this.marker.lat = results[0].geometry.location.lat();
                this.marker.lng = results[0].geometry.location.lng();
                this.lat = results[0].geometry.location.lat();
                this.lng = results[0].geometry.location.lng();
                this.updateData();
              }
            });
          });
      }
    }
    this.updateData();
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {
    this.marker = {
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    };
    this.updateData();
  }

  updateData() {
    // TODO update data & number rotate
    if (this.marker.lat && this.marker.lng) {
      this.dataStore.data["Geo_Location_Lat"] = parseFloat(
        (
          Math.round(this.marker.lat * Math.pow(10, 7)) / Math.pow(10, 7)
        ).toFixed(7)
      );
      this.dataStore.data["Geo_Location_Lng"] = parseFloat(
        (
          Math.round(this.marker.lng * Math.pow(10, 7)) / Math.pow(10, 7)
        ).toFixed(7)
      );
      this.dataContainers.forEach(datacontainer => {
        datacontainer.dataChange(
          this.dataStore.data[datacontainer.data.columnName]
        );
      });
    }
  }

  save() {
    (<EditViewUiComponent>(
      this.parentComponent
    )).editTabs[0].dataContainers.forEach(datacontainer => {
      if (datacontainer.data.columnName === "Latitude") {
        datacontainer.updateStore(
          this.dataStore.data["Geo_Location_Lat"] ||
            this.dataStore.data["Latitude"]
        );
      } else if (datacontainer.data.columnName === "Longitude") {
        datacontainer.updateStore(
          this.dataStore.data["Geo_Location_Lng"] ||
            this.dataStore.data["Longitude"]
        );
      }
    });
  }
}

// just an interface for type safety.

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
