<div class="kanban-card-container ui-g">
  <div *ngIf="datasKanban.image !== null" class="img-container ui-g-4 ui-md-4 ui-lg-4">
    <img
      class="iu-image"
      [src]="
        datasKanban.image !== ''
          ? datasKanban.image
          : tableName === 'C_BPartner'
          ? 'assets/noImage-c_bpartner.png'
          : 'assets/imageError.webp'
      "
      (error)="datasKanban.image = ''"
    />
  </div>
  <div [ngClass]="[datasKanban.image === null ? 'ui-g-12' : 'ui-g-8 ui-md-8 ui-lg-8', 'other-container']">
    <ng-container *ngFor="let dataKanban of datasKanban.rows">
      <div *ngIf="dataKanban.dataAlignLeft.length > 0 || dataKanban.dataAlignright.length > 0" class="board-card-line ui-g">
        <h4
          *ngIf="dataKanban.dataAlignLeft.length > 0"
          [ngClass]="[dataKanban.dataAlignright.length > 0 ? 'ui-g-6' : '', 'board-card-line-left']"
        >
          <ng-container *ngFor="let dataAlignLeft of dataKanban.dataAlignLeft; let first = first">
            <div #element *ngIf="dataAlignLeft.type === 'text'" [ngClass]="[first ? 'first' : '', 'board-card-line-data']">
              <span
                *ngIf="isMobile"
                tooltipPosition="top"
                showDelay="250"
                life="3"
                tooltipZIndex="8000"
                pTooltip="{{ dataAlignLeft.value }}"
                >{{ dataAlignLeft.value | textLimit: 80 }}</span
              >
              <span *ngIf="!isMobile">{{ dataAlignLeft.value }}</span>
            </div>
            <img
              *ngIf="dataAlignLeft.type === 'image'"
              class="iu-image"
              [src]="dataAlignLeft.value ? dataAlignLeft.value : 'assets/imageError.webp'"
              (error)="dataAlignLeft.value = ''"
            />
          </ng-container>
        </h4>
        <div
          *ngIf="dataKanban.dataAlignright.length > 0"
          [ngClass]="[dataKanban.dataAlignLeft.length > 0 ? 'ui-g-6' : '', 'board-card-line-right']"
        >
          <ng-container *ngFor="let dataAlignright of dataKanban.dataAlignright; let first = first">
            <div #element *ngIf="dataAlignright.type === 'text'" [ngClass]="[first ? 'first' : '', 'board-card-line-data']">
              <span
                *ngIf="isMobile"
                tooltipZIndex="8000"
                tooltipPosition="top"
                showDelay="250"
                life="3"
                pTooltip="{{ dataAlignright.value }}"
                >{{ dataAlignright.value | textLimit: 80 }}</span
              >
              <span *ngIf="!isMobile">{{ dataAlignright.value }}</span>
            </div>
            <img
              *ngIf="dataAlignright.type === 'image'"
              class="iu-image"
              [src]="dataAlignright.value ? dataAlignright.value : 'assets/imageError.webp'"
              (error)="dataAlignright.value = ''"
            />
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
