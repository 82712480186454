<div #specificContainer class="iu-specific-window iu-specific-window-container" [attr.data-cy-specific-window-name]="name">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="fields">
      <p-tabView>
        <p-tabPanel header="{{ 'specificWindow.mainFilters' | translate }}">
          <div class="top-container-AccountingViewer">
            <div class="ui-g-12">
              <ng-template #top></ng-template>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'specificWindow.optionalFilters' | translate }}">
          <div class="second-container-AccountingViewer">
            <div class="ui-g-12"><ng-template #optional></ng-template></div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'specificWindow.accountingViewer.groupBy' | translate }}">
          <div class="top-container-AccountingViewer">
            <div
              *ngIf="gridViews && gridViews[0] && gridViews[0].GridTabInfinityScrollUiComponent"
              class="ui-g-12 ui-md-4 ui-lg-2"
            >
              <iu-input-switch-ui
                [isReadOnly]="
                  !gridViews[0].GridTabInfinityScrollUiComponent ||
                  gridViews[0].GridTabInfinityScrollUiComponent.groupColumn.length === 0
                "
                [isContextMenuDisabled]="true"
                #isTotalInput
                [isStandalone]="true"
                [fieldValue]="'N'"
                (fieldValueModified)="
                  !gridViews[0].GridTabInfinityScrollUiComponent ||
                    gridViews[0].GridTabInfinityScrollUiComponent.groupColumn.length === 0 ||
                    applySubTotalDebitCredit($event);
                  isExpandedInput.fieldValue = 'N'
                "
                label="{{ 'specificWindow.accountingViewer.applySubTotal' | translate }}"
              ></iu-input-switch-ui>
              <iu-input-switch-ui
                [isReadOnly]="
                  !gridViews[0].GridTabInfinityScrollUiComponent ||
                  gridViews[0].GridTabInfinityScrollUiComponent.groupColumn.length === 0
                "
                [isContextMenuDisabled]="true"
                #isExpandedInput
                [isStandalone]="true"
                [fieldValue]="'N'"
                (fieldValueModified)="
                  !gridViews[0].GridTabInfinityScrollUiComponent ||
                    gridViews[0].GridTabInfinityScrollUiComponent.groupColumn.length === 0 ||
                    expandAll($event)
                "
                label="{{
                  (isExpandedInput.fieldValue === 'Y'
                    ? 'specificWindow.accountingViewer.collapseAll'
                    : 'specificWindow.accountingViewer.expandAll'
                  ) | translate
                }}"
              ></iu-input-switch-ui>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
      <div class="ui-g-12">
        <ng-template #search></ng-template>
      </div>
      <div class="ui-g-12"><ng-template #bot> </ng-template></div>
      <ng-template iuDynamicContainer></ng-template>
    </div>
  </p-scrollPanel>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
