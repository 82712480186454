<ul class="smartbutton ui-g">
  <ng-container #smartButtonsVCR></ng-container>
</ul>
<div [ngStyle]="{ display: isMoreButton ? 'inline-block' : 'none' }" class="tabManage">
  <ul class="smartbuttonMore">
    <li>
      <a href="#" class="ico-more-title" (click)="$event.preventDefault()">...</a>
      <ul>
        <ng-container #smartButtonsMoreVCR></ng-container>
      </ul>
    </li>
  </ul>
</div>
