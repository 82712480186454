import { IToolPanel, IToolPanelParams } from '@ag-grid-enterprise/all-modules';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { GridPreferencesAction, GridPreferencesService } from '@compiere-ws/services/grid-preferences/grid-preferences.service';

@Component({
  selector: 'iu-preferences-tool-panel',
  templateUrl: './preferences-tool-panel.component.html',
  styleUrls: ['./preferences-tool-panel.component.scss']
})
export class PreferencesToolPanelComponent implements IToolPanel {
  private params: IToolPanelParams;
  public columnState = [];
  public showReset = false;
  constructor(private gridPreferenceService: GridPreferencesService) {}
  agInit(params: IToolPanelParams): void {
    this.params = params;
    this.params.api.addEventListener('displayedColumnsChanged', this.updateGrid.bind(this));
  }
  updateGrid() {
    this.columnState = (<any>this.params).getColumnApi().getColumnState();
    this.columnState.forEach((column) => {
      column.label = this.params.api.getColumnDef(column.colId) ? this.params.api.getColumnDef(column.colId).headerName : '';
    });
  }
  save(): void {
    (this.params.api as any).myObsrvr.next(GridPreferencesAction.SAVE);
  }
  delete(): void {
    (this.params.api as any).myObsrvr.next(GridPreferencesAction.DELETE);
  }
  reset(): void {
    (this.params.api as any).myObsrvr.next(GridPreferencesAction.RESET);
  }
  refresh() {}
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      (<any>this.params).getColumnApi().setColumnState(this.columnState);
    }
  }
  hide(columnState) {
    columnState.hide = !columnState.hide;
    (<any>this.params).getColumnApi().setColumnState(this.columnState);
  }
}
