import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterList'
})
export class FilterListPipe implements PipeTransform {
  transform(objList: any[], options: { value: any; operator: FilterListOperator; objProperties: string[] }): any[] {
    if (!options || !options.operator) {
      return objList;
    } else {
      return objList.filter((obj) => this.compareProperties(obj, options));
    }
  }
  compareProperties(value: any, options: FilterListOptions): boolean {
    const operator = options.operator;
    const valueToCompare = options.value;
    const objProperties = options.objProperties;
    if (operator) {
      let response = false;
      //Comparison of properties
      if (objProperties && value instanceof Object) {
        objProperties.forEach((prop) => {
          if (this.getComparisonResult(value[prop], valueToCompare, operator)) {
            response = true;
            return;
          }
        });
        //Simple Comparison
      } else {
        response = this.getComparisonResult(value, valueToCompare, operator);
      }
      return response;
    } else {
      return true;
    }
  }
  getComparisonResult(value: any, valueToCompare: any, operator: FilterListOperator): boolean {
    const valueToLowered = value.toLowerCase ? value.toLowerCase() : value;
    const valueToCompareToLowered = valueToCompare.toLowerCase ? valueToCompare.toLowerCase() : valueToCompare;
    switch (operator) {
      case FilterListOperator.EQUALS:
        return valueToLowered === valueToCompareToLowered;
      case FilterListOperator.NOT_EQUALS:
        return valueToLowered !== valueToCompareToLowered;
      case FilterListOperator.LIKE:
        const regex: RegExp =
          valueToCompareToLowered instanceof RegExp ? valueToCompareToLowered : new RegExp(valueToCompareToLowered);
        return valueToLowered.match(regex);
      case FilterListOperator.BIGGER:
        return valueToLowered > valueToCompareToLowered;
      case FilterListOperator.BIGGER_EQUALS:
        return valueToLowered >= valueToCompareToLowered;
      case FilterListOperator.LESS:
        return valueToLowered < valueToCompareToLowered;
      case FilterListOperator.LESS_EQUALS:
        return valueToLowered <= valueToCompareToLowered;
      case FilterListOperator.CONTAINS:
        return valueToLowered.includes(valueToCompareToLowered);
      case FilterListOperator.NOT_CONTAINS:
        return !valueToLowered.includes(valueToCompareToLowered);
      case FilterListOperator.STARTS_WITH:
        return valueToLowered.startsWith(valueToCompareToLowered);
      case FilterListOperator.ENDS_WITH:
        return valueToLowered.endsWith(valueToCompareToLowered);
      default:
        return true;
    }
  }
}

export interface FilterListOptions {
  value: any;
  operator: FilterListOperator;
  objProperties: string[];
}
export enum FilterListOperator {
  LIKE = 'like',
  LESS = 'lessThan',
  LESS_EQUALS = 'lessThanOrEqual',
  BIGGER = 'biggerThan',
  BIGGER_EQUALS = 'biggerThanOrEqual',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  EQUALS = 'equals',
  NOT_EQUALS = 'notEqual',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith'
}
