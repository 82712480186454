import { Component, forwardRef, Inject, ViewEncapsulation } from '@angular/core';
import { ContextMenu, ContextMenuSub } from 'primeng/contextmenu';
import { DomHandler } from 'primeng/dom';

@Component({
  selector: 'iu-prime-contextMenuSub',
  templateUrl: './prime-contextmenusub.component.html',
  styleUrls: ['./prime-contextmenusub.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrimeContextMenuSubComponent extends ContextMenuSub {
  constructor(@Inject(forwardRef(() => PrimeContextMenuComponent)) contextMenu) {
    super(contextMenu);
  }
}

@Component({
  selector: 'iu-prime-contextMenu',
  templateUrl: './prime-contextmenu.component.html',
  styleUrls: ['./prime-contextmenu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrimeContextMenuComponent extends ContextMenu {
  position(event?: any) {
    if (event) {
      if (event.srcEvent) {
        event = event.srcEvent;
      }
      let left = event.pageX + 1;
      let top = event.pageY + 1;
      let width = this.containerViewChild.nativeElement.offsetParent
        ? this.containerViewChild.nativeElement.offsetWidth
        : DomHandler.getHiddenElementOuterWidth(this.containerViewChild.nativeElement);
      let height = this.containerViewChild.nativeElement.offsetParent
        ? this.containerViewChild.nativeElement.offsetHeight
        : DomHandler.getHiddenElementOuterHeight(this.containerViewChild.nativeElement);
      let viewport = DomHandler.getViewport();

      //flip
      if (left + width - document.body.scrollLeft > viewport.width) {
        left -= width;
      }

      //flip
      if (top + height - document.body.scrollTop > viewport.height) {
        top -= height;
      }

      //fit
      if (left < document.body.scrollLeft) {
        left = document.body.scrollLeft;
      }

      //fit
      if (top < document.body.scrollTop) {
        top = document.body.scrollTop;
      }

      this.containerViewChild.nativeElement.style.left = left + 'px';
      this.containerViewChild.nativeElement.style.top = top + 'px';
    }
  }
}
