import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { InfoDialogType } from '../info-dialog/info-dialog.component';
import { SpecificWindowUiComponent } from './../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-inscription-post-evenement',
  templateUrl: './inscription-post-evenement.component.html',
  styleUrls: ['./inscription-post-evenement.component.scss']
})
export class InscriptionPostEvenementComponent extends SpecificWindowUiComponent implements OnInit {
  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    pp: ProcessInProgressService,
    translateService: TranslateService
  ) {
    super(windowFactory, resolver, uiCreator, store, processService, socketService, connectorService, pp, translateService);
  }
  ngOnInit() {
    super.ngOnInit();
  }
  processregisterSelected(item) {
    Global.infoDialog.message = {
      summary: 'Confirmation',

      detail: 'Etes-vous sur de vouloir faire cette action?'
    };

    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;

    Global.infoDialog.showInfoDialog();

    const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      // FAIS TON TRAITEMENT ICI
      const button = this.getDatacontainer('registerSelected');
     
      this.executeProcess(item.processId);
      //#128646
      if (button) {
        button.isReadOnly = true;
      }
      //#128646
      confirm.unsubscribe();

      cancel.unsubscribe();
    });

    const cancel = Global.infoDialog.cancel.subscribe(e => {
      // ANNULATION

      confirm.unsubscribe();

      cancel.unsubscribe();
    });
  }

  onEndProcess() {
    const button = this.getDatacontainer('registerSelected');
    if (button) {
      button.isReadOnly = false;
    }
    this.refreshGrids(this.dataStore, false);
    this.clearAllGridsSelection();
  }

  notifyFromDataChange(item?: any) {
    if (item.data['columnName'] === 'AD_Org_ID') {
      let champ = this.dataContainers.find(datacontainer => datacontainer.data.columnName === 'Z_Warehouse_ID');
      champ.dataChange(null);

      champ = this.dataContainers.find(datacontainer => datacontainer.data.columnName === 'Z_Project_ID');
      champ.dataChange(null);
    }
    super.notifyFromDataChange(item);
  }
}
