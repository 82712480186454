<ng-container *ngIf="isPreviewDisplayed">
  <iu-modal-ui
    *ngIf="modal"
    (closeModalEmitter)="isPreviewDisplayed = false; showError = false"
    [appendTo]="'body'"
    [angularStyle]="modalStyle"
  >
    <ng-container *ngIf="isImagePreview">
      <img data-cy="modal-preview-data" style="max-width: fit-content; height: fit-content;" [src]="url" [alt]="fileName" />
    </ng-container>
    <ng-container *ngIf="isDocPreview">
      <object data-cy="modal-preview-data" [data]="url" type="application/pdf" height="100%" width="100%">
        <p>
          Your browser cannot preview {{ extension }}. <a target="_blank" [href]="url">Download the {{ extension }}</a
          >.
        </p>
      </object>
    </ng-container>
    <ng-template *ngIf="showError">
      <p>
        File Without extension.
      </p>
    </ng-template>
  </iu-modal-ui>
  <ng-container *ngIf="!modal">
    <ng-container *ngIf="isImagePreview">
      <img
        data-cy="preview-data"
        style="max-width: fit-content; height: fit-content;"
        [ngStyle]="imgStyle"
        [src]="url"
        [alt]="fileName"
      />
    </ng-container>
    <ng-container *ngIf="isDocPreview">
      <object data-cy="preview-data" [data]="url" type="application/pdf" height="100%" width="100%">
        <p>
          Your browser cannot preview {{ extension }}. <a target="_blank" [href]="url">Download the {{ extension }}</a
          >.
        </p>
      </object>
    </ng-container>
    <ng-template *ngIf="showError">
      <p>
        File Without extension.
      </p>
    </ng-template>
  </ng-container>
</ng-container>
