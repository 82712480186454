import { CompiereDataGridFilterType, CompiereDataGridType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { UploadedFile } from '@iupics-components/models/uploaded-file';

export function getOperationDetail(Z_Production_Operation_ID: number, ad_language: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'Z_Production_Operation',
      startRow: 0,
      endRow: 1,
      ad_language,
      filterModel: {
        Z_Production_Operation_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [[Z_Production_Operation_ID]]
        }
      }
    }
  };
}
/**
 * Z_Production_Component
 */
export function getOPComponents(Z_Production_Operation_ID: number, ad_language: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'Z_Production_OutLine',
      ad_language,
      filterModel: {
        Z_Production_Operation_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [[Z_Production_Operation_ID]]
        }
      }
    }
  };
}

/**
 * Z_Production_OpRessource
 */
export function getOPRessources(Z_Production_Operation_ID: number, ad_language: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'Z_Production_OutTime',
      ad_language,
      filterModel: {
        Z_Production_Operation_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [[Z_Production_Operation_ID]]
        }
      }
    }
  };
}

export interface Operation {
  components: OperationComponent[];
  ressources: OperationRessource[];
  attachedFile: UploadedFile[];
  description: string;
  deltaTime: string;
  note: string;
}

export interface OperationComponent {
  name: string;
  baseQty: number;
  usedQty: number;
  unit: string;
}

export interface OperationRessource {
  name: string;
  baseTime: number;
  usedTime: number;
}
