<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window']"
  [attr.data-cy-specific-window-name]="name"
>
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="container">
      <div class="row">
        <div
          class="col-md-12"
          style="text-align: center; vertical-align: middle; font-size: 40px; flex: 0 0 100%; max-width: 100%;"
        >
          Point of Sale
        </div>
      </div>
      <div class="row">
        <div class="ui-g-6 orderDetail">
          <div class="row" style="overflow: auto; height: 565px; max-height: 565px;">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th scope="col" class="text-left">Article</th>
                  <th scope="col" class="text-right">Prix unitaire</th>
                </tr>
              </thead>
              <tbody id="basket">
                <tr *ngFor="let orderline of orderLines">
                  <td class="text-left">{{ orderline.product['NAME'] }}</td>
                  <td class="text-right">{{ orderline.price | currency: 'EUR' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="totalItem" style="width: 100%; margin-bottom: 5px; margin-right: 5px;">
              <div class="totalLabel">Total :</div>
              <div class="totalAmount">
                <span id="totalAmount">{{ !grandtotal ? (grandtotal | number: '1.2') : (grandtotal | currency: 'EUR') }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <button
              id="validOrderButton"
              type="button"
              class="btn btn-success"
              style="width: 100%; margin-bottom: 5px; margin-right: 5px;"
              (click)="validateOrder()"
            >
              Valider la commande
            </button>
          </div>
        </div>
        <div id="productList" class="ui-g-6 productList">
          <button
            *ngFor="let product of products$ | async"
            (click)="addToBasket(product)"
            type="button"
            class="btn btn-info product"
          >
            {{ product['NAME'] }}<br />{{ getPrice(product['M_PRODUCT_ID']) | currency: 'EUR' }}
          </button>
        </div>
      </div>
    </div>
  </p-scrollPanel>
</div>
