<div
  [ngClass]="[
    'iu-banner',
    type === 'success' ? 'iu-success' : '',
    type === 'error' ? 'iu-error' : '',
    type === 'message' ? 'iu-message' : '',
    type === 'warning' ? 'iu-warning' : '',
    position === 'top' ? 'iu-top' : '',
    position === 'bottom' ? 'iu-bottom' : ''
  ]"
  (click)="cancelCloseDelay($event)"
>
  <i *ngIf="closeable" class="icon-ico-close" style="cursor: pointer;" (click)="closeBanner($event)"></i>
  <div class="iu-banner-message">
    <i
      class="{{
        icon !== undefined
          ? 'iu-banner-icon ' + icon
          : type === 'success'
          ? 'iu-banner-icon fa-lg fas fa-check'
          : type === 'message'
          ? 'iu-banner-icon fa-lg far fa-sticky-note'
          : 'iu-banner-icon fa-lg fas fa-exclamation-triangle'
      }}"
      (click)="showHiddenMessage($event)"
    ></i>
    <span class="iu-banner-message-text"> <ng-content></ng-content> </span>
  </div>
  <div *ngIf="hiddenMessageDisplay"><pre class="iu-banner-hidden-message" [innerHTML]="hiddenMessage"></pre></div>
</div>
