<div [ngClass]="[!isDisplayed ? 'isHidden' : '', 'title']" *ngIf="isSpecificGrid">
  <label
    ><b>{{ gridTitle }}</b></label
  >
</div>
<div
  #divContent
  (swipeleft)="startAnimation('slideOutLeft')"
  (swiperight)="startAnimation('slideOutRight')"
  [ngClass]="[!isDisplayed ? 'isHidden' : '', 'compContainer']"
  style.width="{{ cssWidth }}"
  [attr.data-cy-grid-tablename]="
    this.data
      ? this.data['AD_FormDetail_ID']
        ? this.data.columnName
        : this.data.TableName
        ? this.data.TableName
        : this.data.tableName
      : 'none'
  "
>
  <div
    *ngIf="!noColumnToDisplay"
    [ngClass]="[isGridCollapsed ? 'gridCollapsed' : '', cssClass || '']"
    style.width="{{ cssWidth }}"
  >
    <iu-menu-bar-ui
      *ngIf="isMenuDisplay"
      [isFromForm]="fromForm"
      (changeViewEvent)="switchViewType($event)"
      (newEmitter)="openNew()"
      (removeEmitter)="remove()"
      (refreshEmitter)="refreshGrid()"
      (openExportDataModalEmitter)="openExportDataModal($event)"
      [(visibleTreeButton)]="this.isTree"
      [visibleButton]="visibleButtons"
      (filterChange)="displayHideFilters($event)"
      [isTabTopLevel]="isTabTopLevel"
      [isSplitView]="isSplitView"
      [activeTabID]="container?.activeTab?.id"
      [isReadOnly]="IsReadOnly"
      [IsInsertRecord]="IsInsertRecord"
      [IsDeleteable]="this.IsDeleteable"
      [tabId]="tabId"
      [hasUniversalFilter]="hasUniversalFilter"
      [gridView]="this"
    ></iu-menu-bar-ui>
    <p-progressBar
      *ngIf="isLoading"
      [mode]="'indeterminate'"
      [value]="0"
      [showValue]="false"
      [styleClass]="'loading-progressbar'"
    ></p-progressBar>
    <iu-tree-ui *ngIf="viewType === 'tree'" (selectItem)="forceOpenEditView($event)" [data]="this.data"></iu-tree-ui>
    <iu-grid-tab-infinity-scroll-ui
      [ngStyle]="{ display: viewType === 'grid' ? 'block' : 'none' }"
      [style.height.%]="100"
      *ngIf="gridPaginator"
      [tabId]="tabId"
      [isTabTopLevel]="false"
      [data]="data"
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [container]="container"
      [query]="data ? data.query : undefined"
      [rowSelection]="rowSelection"
      [DOMParentComponent]="this"
      (multiSelectEmitter)="displayHideMultiButton($event)"
      [windowType]="windowType"
      (setFilterEmitter)="setFilterFromView($event)"
      [initFromSwitch]="initFromSwitch"
      [initRequest]="initRequest"
      [isChangeLog]="isChangelog"
      (gridViewCellClicked)="gridCellClicked($event)"
      (gridRefreshed)="gridRefreshed.emit($event)"
      (gridCellEditingStopped)="gridCellEditingStopped.emit($event)"
      [suppressRowClickSelection]="suppressRowClickSelection"
      (notifyGridTabAfterViewInitEmitter)="notifyGridTabAfterViewInitEmitter($event)"
    ></iu-grid-tab-infinity-scroll-ui>
    <iu-grid-tab-infinity-scroll-ui
      [ngStyle]="{ display: viewType === 'grid' ? 'block' : 'none' }"
      *ngIf="!gridPaginator"
      [isTabTopLevel]="true"
      [tabId]="tabId"
      [data]="data"
      [gridTabFilter]="gridTabFilter"
      [container]="container"
      [query]="data ? data.query : undefined"
      [DOMParentComponent]="this"
      [rowSelection]="rowSelection"
      (multiSelectEmitter)="displayHideMultiButton($event)"
      [windowType]="windowType"
      (setFilterEmitter)="setFilterFromView($event)"
      [initFromSwitch]="initFromSwitch"
      [initRequest]="initRequest"
      [isChangeLog]="isChangelog"
      (gridViewCellClicked)="gridCellClicked($event)"
      (gridRefreshed)="gridRefreshed.emit($event)"
      (gridCellEditingStopped)="gridCellEditingStopped.emit($event)"
      [suppressRowClickSelection]="suppressRowClickSelection"
      (notifyGridTabAfterViewInitEmitter)="notifyGridTabAfterViewInitEmitter($event)"
    ></iu-grid-tab-infinity-scroll-ui>
    <iu-kanban-ui
      *ngIf="viewType === 'kanban'"
      [tabId]="tabId"
      [tableName]="this.data.TableName"
      [container]="container"
      [columns_display_AD]="this.data.columns_display_AD"
      [kanbanImageColumn]="this.data.kanbanImageColumn"
      [filter]="filter"
      (clickEmitter)="forceOpenEditView($event)"
      class="kanban-board"
      [initRequest]="initRequest"
      (setFilterEmitter)="setFilterFromView($event)"
    ></iu-kanban-ui>
    <iu-calendar-view-ui
      *ngIf="viewType === 'calendar'"
      [filter]="filter"
      [data]="data"
      [tabId]="tabId"
      [container]="container"
      (setFilterEmitter)="setFilterFromView($event)"
      [initRequest]="initRequest"
      (clickEmitter)="forceOpenEditView($event)"
    ></iu-calendar-view-ui>
    <iu-chart-ui
      *ngIf="viewType === 'chart'"
      [filter]="filter"
      [tabId]="tabId"
      [container]="container"
      [initRequest]="initRequest"
      (setFilterEmitter)="setFilterFromView($event)"
    ></iu-chart-ui>
  </div>
  <div *ngIf="displayExportDataModal">
    <iu-modal-ui
      #exportDataModal
      [title]="'exportData.title'"
      [hasCloseBtn]="false"
      (closeModalEmitter)="displayExportDataModal = false"
      [angularStyle]="{ 'iu-modal-body': { overflow: 'unset' } }"
    >
      <iu-export-data-ui
        [ids]="exportIds"
        [tableId]="this.data.ADTableID"
        [tabId]="this.tabId"
        [whereClause]="whereClause"
        [sourceModal]="exportDataModal"
        (cancelExportDataEmitter)="displayExportDataModal = false"
      ></iu-export-data-ui>
    </iu-modal-ui>
  </div>

  <div
    #panel
    *ngIf="overlayVisible"
    class="ui-autocomplete-panel ui-widget-content ui-corner-all ui-shadow {{
      label !== undefined && label !== null && label.trim().length > 0 && isLabelDisplay ? 'ui-autocomplete-with-label' : ''
    }}"
    [style.max-height]="scrollHeight"
    style="position: fixed; z-index: 100;"
    [style.top]="topPosition"
    [style.left]="leftPosition"
    [style.min-width]="widthPosition"
    [@overlayAnimationAutocomplete]="'visible'"
    (@overlayAnimationAutocomplete.start)="onOverlayAnimationAutocompleteStart($event)"
    (@overlayAnimationAutocomplete.done)="onOverlayAnimationAutocompleteDone($event)"
  >
    <ul class="ui-autocomplete-items ui-autocomplete-list ui-widget-content ui-widget ui-corner-all ui-helper-reset">
      <li
        *ngFor="let option of suggestions; let idx = index"
        [ngClass]="[
          option.id === -1 ? 'moreResults' : '',
          'ui-autocomplete-list-item ui-corner-all',
          highlightOption == option ? 'ui-state-highlight' : ''
        ]"
        (mouseenter)="highlightOption = option"
        (mouseleave)="highlightOption = null"
        (click)="selectItem(option)"
      >
        <span *ngIf="!itemTemplate && option.id !== -1">{{
          field ? ObjectUtils.resolveFieldData(option, field) : option.displayValue
        }}</span>
        <span class="moreResultsSpan" *ngIf="!itemTemplate && option.id === -1">{{
          field ? ObjectUtils.resolveFieldData(option, field) : option.displayValue
        }}</span>
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option, index: idx }"></ng-container>
      </li>
      <li *ngIf="noResults && emptyMessage" class="ui-autocomplete-list-item ui-corner-all">
        {{ emptyMessage }}
      </li>
    </ul>
  </div>

  <div
    #panelCalendar
    *ngIf="inline || overlayCalendarVisible"
    [ngStyle]="panelStyle"
    style="position: fixed; z-index: 100; background-color: white;"
    [style.top]="topPosition"
    [style.left]="leftPosition"
    [ngClass]="[
      'ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all',
      inline ? 'ui-datepicker-inline' : 'ui-shadow',
      timeOnly ? 'ui-datepicker-timeonly' : '',
      this.numberOfMonths > 1 ? 'ui-datepicker-multiple-month' : '',
      view === 'month' ? 'ui-datepicker-monthpicker' : '',
      panelStyleClass || ''
    ]"
    [@overlayAnimationCalendar]="{
      value: this.linkCalendarRenderer.calendar.touchUI ? 'visibleTouchUI' : 'visible',
      params: {
        showTransitionParams: this.linkCalendarRenderer.calendar.showTransitionOptions,
        hideTransitionParams: this.linkCalendarRenderer.calendar.hideTransitionOptions
      }
    }"
  >
    <div class="ui-datepicker-group ui-widget-content" *ngFor="let month of months; let i = index">
      <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
        <ng-content select="p-header"></ng-content>
        <a
          class="ui-datepicker-prev ui-corner-all"
          href="#"
          (click)="$event.preventDefault(); navBackward($event)"
          *ngIf="i === 0"
        >
          <span class="pi pi-chevron-left"></span>
        </a>
        <a
          class="ui-datepicker-next ui-corner-all"
          href="#"
          (click)="$event.preventDefault(); navForward($event)"
          *ngIf="numberOfMonths === 1 ? true : i === numberOfMonths - 1"
        >
          <span class="pi pi-chevron-right"></span>
        </a>
        <div class="ui-datepicker-title">
          <span class="ui-datepicker-month" *ngIf="!monthNavigator && view !== 'month'">{{
            locale.monthNames[month.month]
          }}</span>
          <select
            class="ui-datepicker-month"
            *ngIf="monthNavigator && view !== 'month' && numberOfMonths === 1"
            (change)="onMonthDropdownChange($event.target.value)"
          >
            <option [value]="i" *ngFor="let monthName of locale.monthNames; let i = index" [selected]="i === month.month">{{
              monthName
            }}</option>
          </select>
          <select
            class="ui-datepicker-year"
            *ngIf="yearNavigator && numberOfMonths === 1"
            (change)="onYearDropdownChange($event.target.value)"
          >
            <option [value]="year" *ngFor="let year of yearOptions" [selected]="year === currentYear">{{ year }}</option>
          </select>
          <span class="ui-datepicker-year" *ngIf="!yearNavigator">{{ view === 'month' ? currentYear : month.year }}</span>
        </div>
      </div>
      <div class="ui-datepicker-calendar-container" *ngIf="view === 'date'">
        <table class="ui-datepicker-calendar">
          <thead>
            <tr>
              <th scope="col" *ngFor="let weekDay of weekDays; let begin = first; let end = last">
                <span>{{ weekDay }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let week of month.dates">
              <td
                *ngFor="let date of week"
                [ngClass]="[
                  date.otherMonth ? 'ui-datepicker-other-month' : '',
                  this.linkCalendarRenderer.calendar.isSelected(date) ? 'ui-datepicker-current-day' : '',
                  date.today ? 'ui-datepicker-today' : ''
                ]"
              >
                <ng-container *ngIf="date.otherMonth ? showOtherMonths : true">
                  <a
                    *ngIf="date.selectable"
                    [ngClass]="[
                      this.linkCalendarRenderer.calendar.isSelected(date) ? 'ui-state-active' : '',
                      date.today ? 'ui-state-highlight' : '',
                      'ui-state-default'
                    ]"
                    (click)="this.linkCalendarRenderer.calendar.onDateSelect($event, date); this.overlayCalendarVisible = false"
                    draggable="false"
                  >
                    <ng-container *ngIf="!dateTemplate">{{ date.day }}</ng-container>
                    <ng-container *ngTemplateOutlet="dateTemplate; context: { $implicit: date }"></ng-container>
                  </a>
                  <span class="ui-state-default ui-state-disabled" *ngIf="!date.selectable"> {{ date.day }} </span>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="ui-monthpicker" *ngIf="view === 'month'">
      <a
        href="#"
        *ngFor="let m of monthPickerValues; let i = index"
        (click)="this.linkCalendarRenderer.calendar.onMonthSelect($event, i)"
        [ngClass]="[this.linkCalendarRenderer.calendar.isMonthSelected(i) ? 'ui-state-active' : '', 'ui-monthpicker-month']"
      >
        {{ m }}
      </a>
    </div>
  </div>
  <div *ngIf="displayProcessUI">
    <iu-modal-ui
      (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
      [hasCloseBtn]="true"
      [angularStyle]="{ 'iu-modal-body': { padding: 0 } }"
    >
      <iu-process-ui
        (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
        [windowId]="processId"
        [parentComponent]="this"
        [isModal]="true"
      ></iu-process-ui>
    </iu-modal-ui>
  </div>
  <div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
    <iu-modal-ui
      [title]="specificWindowTitle"
      [isModalDisplay]="displayFormUI"
      (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
      [modalClass]="modalClass"
    >
      <ng-template #vcrSpecific></ng-template>
    </iu-modal-ui>
  </div>
</div>

<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [hidden]="!displaySearch"
    [title]="searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="this.displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="
        searchLinkedComponent.setSearchSelectItem($event); displaySearch = false; searchPanelValidation = undefined
      "
      [data]="searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="searchLinkedComponent.autoComplete.dataStored"
      [windowType]="windowType"
      rowSelection="single"
      [validation]="searchPanelValidation"
      [hasCheckBox]="searchLinkedComponent.autoComplete.data.columnName === 'M_Product_ID'"
    ></iu-grid-tab-infinity-scroll-ui>
    <!-- <button
      pButton
      *ngIf="searchLinkedComponent.autoComplete.data.columnName === 'M_Product_ID'"
      label="Historique des prix"
      style="width: 20%; margin-right: 5px;"
      class="ui-button primary historyPriceButton"
      (click)="$event.preventDefault(); $event.stopPropagation(); showPriceHistory(gridTab)"
    ></button> -->
  </iu-modal-ui>
</ng-container>
