import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { DataKanban, KanbanGroup } from '@iupics-components/models/kanban-interface';
import { BladeUiComponent } from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsColumnKanban, IupicsField } from '@iupics-manager/models/iupics-data';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { KanbanUtils } from '../utils/kanban.utils';

@Component({
  selector: 'iu-kanban-board-ui',
  templateUrl: './kanban-board-ui.component.html',
  styleUrls: ['./kanban-board-ui.component.scss']
})
export class KanbanBoardUiComponent extends AbstractDynamicComponent implements OnInit {
  @Input()
  draggedElement: any;
  @Input()
  tableName: string;

  @Output()
  draggedElementChange: EventEmitter<any> = new EventEmitter();

  @Input()
  columns_display_AD: IupicsColumnKanban[];
  @Input()
  kanbanImageColumn: string;

  @Input()
  kanbanGroup: KanbanGroup;
  @Input()
  isFlexDesign: boolean;

  @Output()
  clickEmitter = new EventEmitter<any>();
  @Output()
  changeGroupEmitter = new EventEmitter<any>();

  @ViewChild('boardList')
  boardListDOM: ElementRef;

  constructor(private store: DataStoreService, private config: AppConfig, private uiCreatorService: UICreatorService) {
    super();
  }

  ngOnInit() {
    if (this.kanbanGroup.datas.length <= 0) {
      this.moreData();
    }
  }

  onClick(dataKanban: DataKanban) {
    this.clickEmitter.emit(dataKanban.data['Data_UUID']);
  }

  dragstart(event, element, dataKanban: DataKanban) {
    if (this.kanbanGroup.columnName) {
      this.uiCreatorService.getTab(this.tabId).subscribe(tab => {
        this.draggedElement = element;
        this.draggedElement['dataStore'] = dataKanban;
        let field = null;
        if (tab) {
          field = this.findDataField(tab[0].editView.children);
        }
        const fieldData = field ? field.data : null;
        if (fieldData && this.isFieldReadOnly(fieldData, this.draggedElement['dataStore'])) {
          event.preventDefault();
          this.draggedElement.classList.remove('dragged');
        } else {
          event.dataTransfer.setData('fireEvent', 'dragend');
          this.draggedElement.classList.add('dragged');
          this.draggedElementChange.emit(this.draggedElement);
        }
      });
    }
  }

  dragend() {
    if (this.kanbanGroup.columnName) {
      this.draggedElement.classList.remove('dragged');
    }
  }

  dragOver(event) {
    if (this.kanbanGroup.columnName) {
      let liComponent = event.toElement;
      while (liComponent.nodeName !== 'LI') {
        liComponent = liComponent.parentElement;
      }
      if (event.offsetY < liComponent.offsetHeight / 2) {
        liComponent.before(this.draggedElement);
      } else {
        liComponent.after(this.draggedElement);
      }
      event.preventDefault();
    }
  }

  dragOverEndList(event) {
    if (this.kanbanGroup.columnName) {
      this.boardListDOM.nativeElement.append(this.draggedElement);
    }
    event.preventDefault();
  }

  changeGroup() {
    if (
      this.kanbanGroup.columnName &&
      this.draggedElement['dataStore'] &&
      this.draggedElement['dataStore'].data &&
      this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']].id !== this.kanbanGroup.groupValue.id
    ) {
      const dataModified = {};
      dataModified[this.kanbanGroup.columnName['field']] = this.kanbanGroup.groupValue;
      const dataStoreKey = this.store.generateDataStoreKey(
        (<BladeUiComponent>this.container).infoComponent.windowId,
        this.tabId,
        this.draggedElement['dataStore'].data['Data_UUID'],
        null
      );
      this.uiCreatorService.getTab(this.tabId).subscribe(tab => {
        let field = null;
        if (tab) {
          field = this.findDataField(tab[0].editView.children);
        }
        const fieldData = field ? field.data : null;
        if (!fieldData || !this.isFieldReadOnly(fieldData, this.draggedElement['dataStore'])) {
          this.store.updateStoreWithoutFields(dataStoreKey, this.draggedElement['dataStore'], dataModified);
          this.subscriptions.push(
            this.store.saveWindowData([dataStoreKey]).subscribe(
              res => {},
              err => {
                this.changeGroupEmitter.emit();
              }
            )
          );
        }
      });
    }
  }
  isFieldReadOnly(fieldData: any, dataStored: DataStore): boolean {
    if (
      dataStored.data.DocStatus !== undefined &&
      dataStored.data.DocStatus !== null &&
      dataStored.data.DocStatus.id !== 'DR' &&
      fieldData.isAlwaysUpdatable !== undefined &&
      fieldData.isAlwaysUpdatable !== null &&
      fieldData.isAlwaysUpdatable === false
    ) {
      return true;
    }
    if (fieldData.ReadOnlyLogic) {
      return LogicEvaluator.evaluateLogic(dataStored.data, fieldData.ReadOnlyLogic);
    }
    return false;
  }

  moreData() {
    this.subscriptions.push(
      this.store.getDataGrid(this.kanbanGroup.dataStoreRequest).subscribe(response => {
        if (response.data) {
          const kanbanDatas = <DataKanban[]>(
            KanbanUtils.transformDataForKanbanView(response.data, this.columns_display_AD, this.kanbanImageColumn)
          );
          this.kanbanGroup.datas.push(...kanbanDatas);
          this.kanbanGroup.isMoreData = response.lastRow <= -1;
          if (this.kanbanGroup.isMoreData) {
            this.kanbanGroup.dataStoreRequest.compiereRequest.startRow = this.kanbanGroup.dataStoreRequest.compiereRequest.endRow;
            this.kanbanGroup.dataStoreRequest.compiereRequest.endRow =
              this.kanbanGroup.dataStoreRequest.compiereRequest.endRow +
              this.config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize');
          }
        }
      })
    );
  }

  findDataField(fields: IupicsField[]) {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.data.columnName === this.kanbanGroup.columnName['field']) {
        return field;
      } else if (field.children) {
        const value = this.findDataField(field.children);
        if (value) {
          return value;
        }
      }
    }
    return null;
  }

  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}
}
