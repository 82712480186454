import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
  transform(value: string): any {
    return value.replace(new RegExp(' ', 'g'), '(s) ') + '(s)';
  }
}
