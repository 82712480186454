import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { CompiereProcessService } from "@compiere-ws/services/compiere-process/compiere-process.service";
import { ProcessInProgressService } from "@compiere-ws/services/process-in-progress/process-in-progress.service";
import { SocketService } from "@compiere-ws/services/socket/socket.service";
import { CustomDesignItemType } from "@iupics-components/models/custom-design";
import { DataStoreService } from "@iupics-manager/managers/data-store/data-store.service";
import { SecurityManagerService } from "@iupics-manager/managers/security-manager/security-manager.service";
import { UICreatorService } from "@iupics-manager/managers/ui-creator/ui-creator.service";
import { WindowFactoryService } from "@iupics-manager/managers/ui-creator/window-factory/window-factory.service";
import { DynamicComponent } from "@iupics-manager/models/dynamic-component";
import { Global } from "@iupics-manager/models/global-var";
import { IupicsTypeEvent } from "@iupics-manager/models/iupics-event";
import { TranslateService } from "@ngx-translate/core";
import { AutocompleteRendererComponent } from "../renderer/autocomplete-renderer.component";
import { ButtonRendererComponent } from "../renderer/button-renderer.component";
import { SpecificWindowUiComponent } from "../specific-window-ui/specific-window-ui.component";

@Component({
  selector: "iu-ethias-management",
  templateUrl: "./ethias-management.component.html",
  styleUrls: ["./ethias-management.component.scss"]
})
export class EthiasManagementComponent extends SpecificWindowUiComponent
  implements OnInit {
  @ViewChild("filter", { read: ViewContainerRef, static: true })
  vcrFilter: ViewContainerRef;
  @ViewChild("tableDeclarants", { read: ViewContainerRef, static: true })
  vcrTableDeclarants: ViewContainerRef;
  @ViewChild("tableNonDeclarants", { read: ViewContainerRef, static: true })
  vcrTableNonDeclarants: ViewContainerRef;
  @ViewChild("actions", { read: ViewContainerRef, static: true })
  vcrActions: ViewContainerRef;

  frameworkComponents: any;
  processId: number;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService,
    translateService: TranslateService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      pp,
      translateService
    );
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent
    };
  }

  ngOnInit() {
    this.uiCreator.getSpecificWindow(this.formId).subscribe(specificWindow => {
      specificWindow.items.forEach(item => {
        switch (item.data.columnName) {
          case "AD_Org_ID":
            this.customDesignArray.push({
              vcr: "vcrFilter",
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: Global.isMobileWidth() ? "ui-g-12" : "ui-g-4"
            });
            break;

          case "Execute":
            this.processId = item.processId;
            this.customDesignArray.push({
              vcr: "vcrActions",
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: Global.isMobileWidth() ? "ui-g-12" : 'ui-g-12 ui-md-3 ui-lg-3',
              events: {
                click: this.lunchProcess.bind(this)
              }
            });
            break;

          default:
            switch (item.name) {
              case "Gestion des déclarants Éthias Table Declarants":
                const editorColumns = [];

                const colAction = item.data.columnsTableHeader.find(function(
                  element
                ) {
                  return element.field === "Action";
                });

                if (colAction) {
                  const actionDetail = item.data.columnsDetails.get("Action");
                  editorColumns.push("Action");
                  colAction.editable = false;
                  colAction.cellRendererParams = {
                    onSearch: this.onSearch.bind(this),
                    closePanel: this.closePanel.bind(this),
                    label: "",
                    templates: {
                      columnName: "Action",
                      urlList: actionDetail.field.urlList,
                      columnId: actionDetail.field.field.AD_Column_ID,
                      defaultValue: "Remove",
                      fieldTypeForm: true
                    }
                  };
                  colAction.cellRendererSelector = function(params) {
                    return params.data !== undefined &&
                      params.data["Z_Fonction_ID"] !== "Animateur d'unité"
                      ? { component: "autocompleteRenderer" }
                      : null;
                  };
                }

                this.customDesignArray.push({
                  vcr: "vcrTableDeclarants",
                  type: CustomDesignItemType.GRID,
                  tableName: "Gestion des déclarants Éthias Table Declarants",
                  cssClass: "ui-g-12",
                  shouldSelectFirst: false,
                  editorColumns: editorColumns,
                  frameworkComponents: this.frameworkComponents,
                  isFitResize: true
                });

                break;

              case "Gestion des déclarants Éthias Table NonDeclarants":
                this.customDesignArray.push({
                  vcr: "vcrTableNonDeclarants",
                  type: CustomDesignItemType.GRID,
                  tableName:
                    "Gestion des déclarants Éthias Table NonDeclarants",
                  cssClass: "ui-g-12",
                  shouldSelectFirst: false,
                  frameworkComponents: this.frameworkComponents,
                  isFitResize: true,
                  rowSelection: "single"
                });

                break;
            }
        }
      });

      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: "SpecificWindowUiComponent",
        cssClass: "ui-g-12",
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };

      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
  }

  closePanel() {
    this.gridViews[0].overlayVisible = false;
  }

  onSearch(e) {
    this.gridViews[0].overlayVisible = e.overlayVisible;
    this.gridViews[0].label = e.label;
    this.gridViews[0].isLabelDisplay = e.isLabelDisplay;
    this.gridViews[0].scrollHeight = e.scrollHeight;
    this.gridViews[0].suggestions = e.suggestions;
    this.gridViews[0].field = e.field;
    this.gridViews[0].itemTemplate = e.itemTemplate;
    this.gridViews[0].noResults = e.noResults;
    this.gridViews[0].emptyMessage = e.emptyMessage;
    this.gridViews[0].setPosition(e.top, e.left, e.width);
    this.gridViews[0].linkAutocompleteRenderer = e.linkAutocomplete;
  }

  private lunchProcess() {
    this.dataStore.data.selections[0].selection = [];
    for (
      let i = 0;
      i <
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getDisplayedRowCount();
      i++
    ) {
      if (
        this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getDisplayedRowAtIndex(
          i
        )
      ) {
        this.dataStore.data.selections[0].selection.push(
          this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getDisplayedRowAtIndex(
            i
          ).data
        );
      }
    }
    const button = this.getDatacontainer("Execute");
    if (button) {
      button.isReadOnly = true;
    }
    this.executeProcess(this.processId);
  }

  onEndProcess() {
    this.updateLoading(false);
    const button = this.getDatacontainer("Execute");
    if (button) {
      button.isReadOnly = false;
    }
    this.refreshGrids(this.dataStore, false);
  }
}
