import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class PoService {
  private url: string;

  constructor(private http: ApiService, private config: AppConfig) {
    this.url = this.config.getBackendResource('po');
  }
  checkUrl() {
    if (!this.url) this.url = this.config.getBackendResource('po');
  }
  get<T>(tableName: string, id?: number): Observable<T> {
    this.checkUrl();
    return this.http.get<T>(`${this.url}${tableName}${id !== undefined ? '/' + id : ''}`);
  }

  save(tableName: string, data: any, id?: number): Observable<any> {
    this.checkUrl();
    if (id) {
      // UPDATE
      return this.http.put<any>(`${this.url}${tableName}/${id}`, data);
    } else {
      // INSERT
      return this.http.post<any>(`${this.url}${tableName}`, data);
    }
  }

  delete(tableName: string, id: number): Observable<any> {
    this.checkUrl();
    return this.http.delete<any>(`${this.url}${tableName}/${id}`);
  }
}
