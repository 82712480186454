//#region custo|LogicEvaluator|CountryAutocomplete|OperatorFilterType|SpecificWindowUiComponent|MapsAPILoader|CompiereDataGridFilterType|CompiereDataJSON2|DataStoreRequest
import { MapsAPILoader } from '@agm/core';
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataJSON2,
  DataStore,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { LocationService } from '@compiere-ws/services/compiere-location/location.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { CountryAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { debounce } from 'lodash';
//#endregion custo
@Component({
  selector: 'iu-input-location-ui',
  templateUrl: './input-location-ui.component.html',
  styleUrls: ['./input-location-ui.component.scss']
})
export class InputLocationUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  constructor(
    renderer: Renderer2,
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    protected po: PoService,
    private locationService: LocationService,
    private config: AppConfig,
    private resolver: ComponentFactoryResolver,
    // #region custo
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone // #endregion custo
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
    this.isAddressField = true;
    // #region custo
    this.IsGoogleAddress = Global.IsGoogleAddress;
    this.placeChanged = debounce(this.placeChanged.bind(this), 200);
    // #endregion custo
  }
  @Input()
  columnName: string;

  @Input()
  cssGrid: string;
  @ViewChild('vcrLocationPanel', { read: ViewContainerRef, static: true })
  vcrLocationPanel: ViewContainerRef;
  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  dataContainers: AbstractDataContainerCallout;

  displayLocation = false;

  enableSize;
  // #region custo
  IsGoogleAddress = false;
  countries: { items: CountryAutocomplete[]; isMandatory: boolean } = {
    items: [],
    isMandatory: true
  };
  selectionDone = false;
  displayValueBeforeChange = '';
  locationData = null;
  autocomplete:google.maps.places.Autocomplete;
  // #endregion custo
  ngOnInit() {
    super.ngOnInit();
    if (this.isStandalone && !this.data) {
      this.data = {};
    }
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();
    //#region custo
    if (this.IsGoogleAddress) {
      this.initGoogleAutoComplete();
    }
    //#endregion custo
  }
  //#region custo
  placeChanged(){
    const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
    if(place){
    if (place.formatted_address) {
      this.selectionDone = true;
      this.saveLocation(place);
    }
    if (place.geometry === undefined || place.geometry === null) {
      this.selectionDone = false;
      this.fieldValue = null;
      this.updateStore(null);
      super.dataChange(this.fieldValue);
    }}
  }
  setAutocomplete() {
    
    this.autocomplete = new google.maps.places.Autocomplete(this.inputRef.nativeElement);
    this.autocomplete.addListener('place_changed',  this.placeChanged.bind(this));
  }

  checkRestriction() {
    if (this.IsGoogleAddress) {
      this.selectionDone = false;
      this.displayValueBeforeChange = this.fieldValue ? this.fieldValue.displayValue : '';
      const context = this.getCurrentContext();
       
        this.setAutocomplete();
      
    }
  }
  initGoogleAutoComplete() {
    this.getCountries();
    this.mapsAPILoader.load().then(() => {
    });
  }
  //#endregion custo
  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      this.data.callouts.callouts.forEach((callout) => {
        this.inputRef.nativeElement.addEventListener(callout.calloutevent, () => {
          const calloutFn = new Function('Field', 'Fields', 'DBSelect', callout.script);
          calloutFn(this, this.dataContainers, this.uiCreatorService);
        });
      });
    }
  }

  displayLocationPanel() {
    if (this.DOMParentComponent && this.DOMParentComponent.elementRef && this.elementRef.nativeElement) {
      this.enableSize = this.DOMParentComponent.elementRef.nativeElement.clientHeight - this.elementRef.nativeElement.offsetTop;
    } else {
      this.enableSize = 1000;
    }
    this.displayLocation = true;
    // setTimeout(() => {
    //   this.createSpecificWindow();
    // }, 5);
  }

  blocInput() {
    return false;
  }

  //#region custo
  onLocationEmitter(response, fromGoogle = false) {
    if (response && response !== 'cancel' && response !== 'clear') {
      this.fieldValue = response;
      this.inputRef.nativeElement.value = response.displayValue;
      super.dataChange(this.fieldValue);
    } else if (response === 'clear') {
      this.fieldValue = null;
      this.inputRef.nativeElement.value = '';
      super.dataChange(this.fieldValue);
    } else if (response && response === 'cancel') {
      if (this.fieldValue) {
        this.locationData = null;
        this.inputRef.nativeElement.value = this.displayValueBeforeChange;
        this.fieldValue.displayValue = this.displayValueBeforeChange;
        super.dataChange(this.fieldValue);
      }
    }
    this.displayLocation = false;
  }
  //#endregion custo
  zoomAcross() {
    this.isZoom = true;
    let record_id = -1;
    if (this.fieldValue) {
      record_id = this.fieldValue.id;
    }
    this.subscriptions.push(
      this.uiCreatorService.zoomAcross(this.data.details.tableName, this.data.details.keyColumn, record_id).subscribe(
        (dataWs) => {
          if (dataWs && dataWs.length > 0) {
            this.zoomInfo = {
              windowId: dataWs[0].Window_ID,
              dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
              record_id: record_id,
              children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null
            };
            if (this.data.IsParam || this.container instanceof SpecificWindowUiComponent) {
              this.isZoom = false;
              Global.workspace.openTargetSearch({
                cat: { id: parseInt(dataWs[0].Window_ID, 10) },
                source: {
                  id: record_id !== -1 ? dataWs[0].Record_ID : 'newRecord'
                }
              });
            } else {
              this.subscriptions.push(
                this.uiCreatorService.getWindow(parseInt(dataWs[dataWs.length - 1]['Window_ID'], 10)).subscribe((tabs) => {
                  const item: DynamicComponent = {
                    container: this.container,
                    DOMParentComponent: this.container,
                    linkedComponents: [this],
                    component: 'EditViewUiComponent',
                    cssClass: 'iupics-blade-content',
                    isCssOnComponent: false,
                    tabId: tabs[0].tabId,
                    zoomInfo: this.zoomInfo
                  };
                  this.componentEmitter.emit({
                    type: IupicsTypeEvent.showEditView,
                    item: item
                  });
                })
              );
            }
          }
        },
        (err) => {
          this.isZoom = false;
        }
      )
    );
  }

  refreshZoomInfo() {
    let record_id = -1;
    if (this.fieldValue) {
      record_id = this.fieldValue.id;
    }
    this.zoomInfo.record_id = record_id;
    this.zoomInfo.dataUUID = this.data.details.keyColumn + ',' + record_id;
    if (!this.zoomInfo.children && this.zoomInfo.record_id !== -1) {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.selectZoomChange,
        item: {
          container: null,
          dataStoreKey: null,
          zoomInfo: this.zoomInfo
        }
      });
    }
  }
  onSiblingUpdate(event: any) {
    if (event && event.refreshZoom) {
      this.resetLocationFromZoom(event.id);
    } else if (event && event.type === IupicsTypeEvent.expandEvent) {
      this.isZoom = false;
    }
  }

  resetLocationFromZoom(id: any) {
    if (id === null) {
      this.dataChange(null);
    } else {
      this.subscriptions.push(
        this.locationService.getLocation(id).subscribe((response) => {
          const addressData = [
            response['Address1'],
            response['Address2'],
            response['Address3'],
            response['Address4'],
            response['Postal'],
            response['City'],
            response['RegionName']
          ];
          this.fieldValue = {
            id: id,
            displayValue: addressData.filter((item) => item !== undefined && item !== null && item !== '').join(',')
          };
          this.dataChange(this.fieldValue);
        })
      );
    }
  }
  //#region custo
  changeFieldValue(dataStored: DataStore, fromOtherChange: boolean = false, calloutStack: string[] = []) {
    super.changeFieldValue(dataStored, fromOtherChange, calloutStack);
    const columnName = this.data && this.data.columnName ? this.data.columnName : 'C_Location_ID';
    const id = this.fieldValue ? (this.fieldValue.id ? this.fieldValue.id : this.fieldValue) : null;
    const idStored =
      dataStored && dataStored.data && dataStored.data[columnName]
        ? dataStored.data[columnName].id
          ? dataStored.data[columnName].id
          : dataStored.data[columnName]
        : null;

    const displayValue = this.fieldValue ? (this.fieldValue.displayValue ? this.fieldValue.displayValue : null) : null;
    const displayValueStored =
      dataStored && dataStored.data && dataStored.data[columnName]
        ? dataStored.data[columnName].displayValue
          ? dataStored.data[columnName].displayValue
          : null
        : null;
    if (id && (id !== idStored || (displayValueStored && displayValue !== displayValueStored) || !displayValue)) {
      this.fieldValue = {
        id: id,
        displayValue: '...'
      };
      const sub = this.locationService.getLocation(id).subscribe(
        (response) => {
          if (response) {
            const addressData = [
              response['Address1'],
              response['Address2'],
              response['Address3'],
              response['Address4'],
              response['Postal'],
              response['RegionName'],
              response['City']
            ];
            const addressStr = addressData.filter((item) => item !== undefined && item !== null && item !== '').join(',');
            this.fieldValue = {
              id: response['C_Location_ID'],
              displayValue: addressStr
            };
            this.fieldValueModified.emit(this.fieldValue);
          }
          sub.unsubscribe();
        },
        (error) => {
          console.log('c_location_id not found: ' + id);
        }
      );
    }
  }

  OnInputChange($event) {
    if (!this.IsGoogleAddress) {
      if (this.fieldValue) {
        this.fieldValue.displayValue = $event.target.value;
      }
    } else if (this.fieldValue) {
      this.fieldValue.displayValue = $event;
    }
  }
  OnBlur($event) {
    if (this.IsGoogleAddress) {
      if ($event && $event.target && $event.target.value === '') {
        this.fieldValue = null;
        this.updateStore(null);
        super.dataChange(this.fieldValue);
      } else if (this.selectionDone === false) {
        if ($event && $event.target) {
          // create service
          const service = new google.maps.places.AutocompleteService();
          const displaySuggestions = (predictions, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              if (status === 'ZERO_RESULTS') {
                this.displayLocationPanel();
                return;
              }
            }
            if (this.fieldValue) {
              this.fieldValue.displayValue = this.displayValueBeforeChange;
            } else {
              $event.target.value = '';
            }
            return;
          };
          // perform request.
          const request = {
            input: $event.target.value
          };
          service.getPlacePredictions(request, displaySuggestions);
        }
      }
    }
  }
  dataChange(value: any) {
    if (value === null || value === '') {
      super.dataChange(value);
    }
  }
  saveLocation(place: google.maps.places.PlaceResult) {
    let address1 = null;
    let address2 = null;
    const address3 = null;
    const address4 = null;
    let city = null;
    const c_city_id = null;
    let postalCode = null;
    let region = null;
    let country = null;
    let country_id = -1;
    const longitude = place.geometry.location.lng();
    const latitude = place.geometry.location.lat();
    const placeId = place.place_id;
    const request = place.id;
    place.address_components.forEach((info) => {
      if (info.types.find((type) => type === 'route')) {
        address1 = info.long_name;
      }
      if (info.types.find((type) => type === 'street_number')) {
        address2 = info.long_name;
      }
      if (info.types.find((type) => type === 'locality')) {
        city = info.long_name;
      }
      if (info.types.find((type) => type === 'postal_code')) {
        postalCode = info.long_name;
      }
      if (info.types.find((type) => type === 'administrative_area_level_2')) {
        region = info.long_name;
      }
      if (info.types.find((type) => type === 'country')) {
        country = info.short_name;
        if (country) {
          const itemFound = this.countries.items.find((item) => item.value.toLowerCase() === country.toLowerCase());
          if (itemFound) {
            country_id = itemFound.id;
          }
        }
      }
    });
    const data = {
      AD_Client_ID:
        this.dataStored && this.dataStored.data && this.dataStored.data['AD_Client_ID']
          ? this.dataStored.data['AD_Client_ID']
          : this.connectorService.getIupicsUserContext()['#AD_Client_ID'],
      AD_Org_ID:
        this.dataStored && this.dataStored.data && this.dataStored.data['AD_Org_ID']
          ? this.dataStored.data['AD_Org_ID']
          : this.connectorService.getIupicsUserContext()['#AD_Org_ID'],
      IsActive:
        this.dataStored && this.dataStored.data && this.dataStored.data['IsActive'] ? this.dataStored.data['IsActive'] : 'Y',
      Address1: address1,
      Address2: address2,
      Address3: address3,
      Address4: address4,
      City: city,
      C_City_ID: null,
      Postal: postalCode,
      Postal_Add: null,
      C_Region_ID: null,
      RegionName: region,
      C_Country_ID: country_id ? country_id : null,
      Latitude: latitude,
      Longitude: longitude,
      GoogleID: placeId,
      GoogleAddress: request
    };
    const columnName = this.data && this.data.columnName ? this.data.columnName : 'C_Location_ID';
    data['Data_UUID'] =
      this.dataStored && this.dataStored.data
        ? this.dataStored.data[columnName]
          ? 'C_Location_ID,' + this.dataStored.data[columnName].id
          : null
        : this.fieldValue && this.fieldValue.id
        ? this.fieldValue.id
        : this.fieldValue;
    data['C_Location_ID'] =
      this.dataStored && this.dataStored.data
        ? this.dataStored.data[columnName]
          ? this.dataStored.data[columnName].id
          : null
        : this.fieldValue && this.fieldValue.id
        ? this.fieldValue.id
        : this.fieldValue;
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof Object) {
        data[key] = data[key].id;
      }
    });
    this.locationData = data;
    this.displayLocationPanel();
    // const dataWs: CompiereDataJSON2 = {
    //   data: [data],
    //   data_UUID: ['C_Location_ID'],
    //   displayData: {},
    //   secondaryColumnFields: [],
    //   lastRow: 0,
    //   tab_id: 154
    // };
    // const sub = this.store.saveLocation(dataWs).subscribe(compiereData => {
    //   this.onLocationEmitter(this.generateAdresseFromCompiereData(compiereData), true);
    //   sub.unsubscribe();
    // });
  }
  generateAdresseFromCompiereData(compiereData: CompiereDataJSON2) {
    const addressData = [
      compiereData.data[0]['Address1'],
      compiereData.data[0]['Address2'],
      compiereData.data[0]['Address3'],
      compiereData.data[0]['Address4'],
      compiereData.data[0]['City'],
      compiereData.data[0]['Postal'],
      compiereData.data[0]['RegionName']
    ];
    const addressStr = addressData.filter((item) => item !== undefined && item !== null && item !== '').join(',');
    return {
      id: compiereData.data[0]['C_Location_ID'],
      displayValue: addressStr
    };
  }
  private getCountries(callback?: Function) {
    if (!Global.LocationCountries) {
      const sub = this.locationService.getCountries().subscribe((countries) => {
        if (countries) {
          this.countries = {
            items: countries.map(
              (country) =>
                <CountryAutocomplete>{
                  id: parseInt(country.country_id, 10),
                  value: country.value,
                  displayValue: country.name
                }
            ),
            isMandatory: true
          };
        }
        Global.LocationCountries = this.countries;
        if (callback) {
          callback();
        }
        sub.unsubscribe();
      });
    } else {
      this.countries = Global.LocationCountries;
    }
  }
  //#endregion custo

  createSpecificWindow() {
    const formID = this.config.getConstant('LocationPanelComponent#FormID');
    this.subscriptions.push(
      this.uiCreatorService.getSpecificWindow(formID).subscribe((specificWindow) => {
        let component;
        if (specificWindow.angularClass && specificWindow.angularClass.length > 0 && specificWindow.angularClass !== 'default') {
          component = Global.iupics_specific_window.get(specificWindow.angularClass);
        }
        if (!component) {
          component = Global.iupics_specific_window.get('default');
        }
        const factory = this.resolver.resolveComponentFactory(component);
        this.vcrLocationPanel.clear();
        const componentRef = this.vcrLocationPanel.createComponent(factory);
        // this.specificWindowTitle = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).name = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).title = specificWindow.title;
        (<SpecificWindowUiComponent>componentRef.instance).description = specificWindow.description;
        (<SpecificWindowUiComponent>componentRef.instance).help = specificWindow.help;
        (<SpecificWindowUiComponent>componentRef.instance).componentRef = componentRef;
        (<SpecificWindowUiComponent>componentRef.instance).isModal = true;
        (<SpecificWindowUiComponent>componentRef.instance).formId = formID;
        (<SpecificWindowUiComponent>componentRef.instance).vcrwindow = this.vcrLocationPanel;
        (<SpecificWindowUiComponent>componentRef.instance).parentComponent = this;
        (<SpecificWindowUiComponent>componentRef.instance).index = this.vcrLocationPanel.length - 1;
        (<SpecificWindowUiComponent>componentRef.instance).sourceModal = null;
        (<SpecificWindowUiComponent>componentRef.instance).sourceComponentData = this && this.itemData ? this.itemData : this;
        (<SpecificWindowUiComponent>(
          componentRef.instance
        )).sourceComponent = this; /**dupliqué si pas d'itemdata mais trop de refactoring sur scout */
        (<SpecificWindowUiComponent>componentRef.instance).closeModalEmitter.subscribe(() => {
          this.displayLocation = !this.displayLocation;
        });
      })
    );
  }
}
