<span
  [style.display]="displayCss"
  [pTooltip]="tooltip"
  tooltipZIndex="2100"
  life="3"
  tooltipPosition="top"
  showDelay="500"
  class="{{ cssClass }}"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  (contextmenu)="onContextMenu($event)"
  data-cy="input-location"
  [attr.data-cy-columnName]="data ? data.columnName : columnName"
>
  <label *ngIf="this.isLabelDisplay">
    <span (click)="zoomAcross()" class="adressLabelZoom">{{
      label | textLimit: this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7
    }}</span></label
  >
  <!-- #region custo|keydown|focus|blur|ngModelOptions|ngModel|ngModelChange -->
  <form autocomplete="off" method="post" action="">
    <input
      #input
      [ngModel]="fieldValue ? (fieldValue.displayValue | replaceSeparator:  '   ':' ') : ''"
      (ngModelChange)="OnInputChange($event)"
      (focus)="checkRestriction()"
      (blur)="OnBlur($event)"
      [ngModelOptions]="{ standalone: true }"
      [attr.disabled]="isReadOnly ? 'disabled' : null"
      value=""
      type="text"
      (keydown)="IsGoogleAddress ? true : blocInput()"
      pInputText
      [ngClass]="[hasConflict ? 'iu-input-text-conflict' : '', mandatoryCss || '']"
      autocomplete="no"
    />
    <!-- #region custo|click|disabled -->
    <i
      data-cy="button-location"
      class="iconeLocation fa-map-marker"
      (click)="!isReadOnly && (displayLocation = !displayLocation)"
      aria-hidden="true"
      type="button"
      [attr.disabled]="isReadOnly ? 'disabled' : null"
    ></i>
    <!-- #endregion custo -->
  </form>

  <span
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="ui-inputgroup-addon ui-autocomplete-conflict-star"
    (mouseenter)="opConflict.show($event)"
  >
    /!\
  </span>
  <iu-value-preference-panel
    *ngIf="showValuePrefPanel"
    [fieldValue]="fieldValue"
    [dataStored]="this.dataStored"
    [sourceComponent]="this"
    [data]="this.data"
    (closeEmitter)="showValuePrefPanel = !showValuePrefPanel"
  ></iu-value-preference-panel>
</span>

<p-overlayPanel #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide($event)">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  | <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide($event)">{{ conflictedData }}</a>
</p-overlayPanel>

<!-- #region custo|locationData -->
<iu-location-panel
  *ngIf="displayLocation"
  [enableSize]="enableSize"
  [fieldValue]="fieldValue"
  [data]="data"
  [dataStored]="dataStored"
  [locationData]="locationData"
  (locationEmitter)="onLocationEmitter($event)"
  data-cy="location-panel"
  [attr.data-cy-columnName]="data ? data.columnName : columnName"
></iu-location-panel>
<!-- #endregion custo|locationData -->
