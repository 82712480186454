import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { InscriptionParticipantInfo } from '@compiere-ws/models/waiting-list-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { WaitingListService } from '@compiere-ws/services/waiting-list/waiting-list.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { zip } from 'rxjs';
import { InfoDialogType } from '../info-dialog/info-dialog.component';
import { ProcessParams } from '../process-ui/process-ui.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-transfert-participant',
  templateUrl: './transfert-participant.component.html',
  styleUrls: ['./transfert-participant.component.scss'],
})
export class TransfertParticipantComponent extends SpecificWindowUiComponent implements OnInit {
  isLoading = false;
  forceInscribeRoles = [];
  motif = '';
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private ppService: ProcessInProgressService,
    private po: PoService,
    private waitingListService: WaitingListService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      ppService,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {
    const request: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_MESSAGE',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: ['SCOUTS_FORCE_INSCRIBE_ROLES'],
            operators: [OperatorFilterType.EQUALS],
          },
        },
      },
    };
    const request2: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_MESSAGE',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: ['ZTransferMotifMessage'],
            operators: [OperatorFilterType.EQUALS],
          },
        },
      },
    };
    zip(this.store.getDataGrid(request), this.store.getDataGrid(request2)).subscribe((responses) => {
      if (responses[0] && responses[0].data.length > 0) {
        if (responses[0] && responses[0].data.length > 0) {
          const values = responses[0].data[0]['MSGTEXT'];
          this.forceInscribeRoles = values.split(',');
        }
        if (!this.isModal) {
          Global.workspace.linkedComponentToTabMap[this.activeTab.id] = this;
        }
        if (this.parentComponent instanceof EditViewUiComponent) {
          if (!this.dataStore) {
            this.dataStore = this.store.newSpecificWindowData(
              this.formId,
              (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
            );
          }
        }
        if (responses[1] && responses[1].data.length > 0) {
          let msg = responses[1].data[0]['MSGTEXT'];
          let projectDisplayValue =
            this.getFormStoreData('C_Project_ID') && this.getFormStoreData('C_Project_ID').displayValue
              ? this.getFormStoreData('C_Project_ID').displayValue
              : this.getFormStoreData('C_Project_ID');
          msg = msg.replace('{0}', projectDisplayValue);
          let dateAsString = moment(new Date()).format('L').slice(0, 10);
          msg = msg.replace('{1}', dateAsString);
          this.motif = msg;
        }
        const item: DynamicComponent = {
          container: this,
          DOMParentComponent: this,
          component: 'SpecificWindowUiComponent',
          cssClass: 'ui-g-12',
          isCssOnComponent: false,
          tabId: this.formId,
          gridPaginator: false,
        };
        this.windowFactory.newEventHandler({
          type: IupicsTypeEvent.showSpecificWindow,
          item: item,
        });
      }
    });
  }
  checkBeforeProcessing() {
    let success = true;
    if (this.mandatoryFields.length > 0) {
      this.mandatoryFields.forEach((column) => {
        if (this.dataStore.data[column] === null || this.dataStore.data[column] === undefined) {
          success = false;
          return;
        }
      });
    }
    if (success) {
      if (!this.getFormStoreData('Z_Role_Evenement_ID')) {
        success = false;
        throw new Error(this.translateService.instant('specificWindow.inscribe.roleNotSelected'));
      }
    }
    return success;
  }
  processProcessing(item: any) {
    const participantInfos: InscriptionParticipantInfo[] = [];
    participantInfos.push({
      adOrgId: this.getFormStoreData('AD_Org_ID') ? this.getFormStoreData('AD_Org_ID').id : -1,
      cBPartnerID: this.getFormStoreData('C_BPartner_ID') ? this.getFormStoreData('C_BPartner_ID').id : -1,
      zRoleEvenementId: this.getFormStoreData('Z_Role_Evenement_ID') ? this.getFormStoreData('Z_Role_Evenement_ID').id : -1,
    });
    this.po.get<POEvenement>('C_Project', this.getFormStoreData('C_Project_ID').id).subscribe((evenement) => {
      if (evenement && Object.keys(evenement).length > 0) {
        const condition = this.getCondition(evenement);
        if (condition === undefined) {
          this.forceInscribe(participantInfos, item);
        } else {
          if (this.forceInscribeRoles.includes(this.connectorService.getIupicsUserContext()['#AD_Role_ID'])) {
            Global.infoDialog.message = {
              summary: this.translateService.instant(condition.title),
              detail:
                this.translateService.instant(condition.text) +
                this.translateService.instant('specificWindow.inscribe.wantToContinue'),
            };
            Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
            Global.infoDialog.showInfoDialog();
            const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
              this.forceInscribe(participantInfos, item);
              confirm.unsubscribe();
              cancel.unsubscribe();
            });
            const cancel = Global.infoDialog.cancel.subscribe((e) => {
              confirm.unsubscribe();
              cancel.unsubscribe();
            });
          } else {
            Global.infoDialog.message = {
              summary: this.translateService.instant(condition.title),
              detail: this.translateService.instant(condition.text),
            };
            Global.infoDialog.dialogType = InfoDialogType.INFO;
            Global.infoDialog.showInfoDialog();
            const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
              confirm.unsubscribe();
            });
          }
        }
      }
    });
  }
  getCondition(evenement: POEvenement): ConditionMessage {
    if (evenement.Processed === 'Y') {
      return { title: 'specificWindow.inscribe.projectProcessed', text: 'specificWindow.inscribe.projectProcessedMessage' };
    }
    if (evenement.LockProject === 'D') {
      return { title: 'specificWindow.inscribe.projectLocked', text: 'specificWindow.inscribe.projectLockedMessage' };
    }
    if (evenement.DateFinish) {
      const timeVal = moment(evenement.DateFinish);
      const time = timeVal.format('YYYY-MM-DDTHH:mm:ss.SSS');
      const finalTime = time.substring(0, 26) + time.substring(27, time.length);
      const today = moment();
      const date = moment(finalTime);
      if (today.isAfter(date, 'day')) {
        return {
          title: 'specificWindow.inscribe.projectInscribeConfirm',
          text: 'specificWindow.inscribe.projectInscribeConfirmMessage',
        };
      }
    }
    if (evenement.Session_Status !== 'O') {
      return {
        title: 'specificWindow.inscribe.projectInscribeConfirm',
        text: 'specificWindow.inscribe.projectInscribeConfirmMessage',
      };
    }
  }
  forceInscribe(participantInfos: InscriptionParticipantInfo[], item: any) {
    this.subscriptions.push(
      this.waitingListService
        .getInscriptionConstraintList({
          cProjectId: this.getFormStoreData('C_Project_ID').id ? this.getFormStoreData('C_Project_ID').id + '' : '-1',
          participantInfos: participantInfos,
        })
        .subscribe(
          (response) => {
            let message = '';
            if (response) {
              const keyList = Object.keys(response);
              if (keyList.length > 0) {
                keyList.forEach((key) => {
                  const inscriptionConstraint = response[key];
                  if (
                    inscriptionConstraint.stopInscription ||
                    inscriptionConstraint.hasError ||
                    inscriptionConstraint.hasGeneralError
                  ) {
                    message += '<center><h2>' + inscriptionConstraint.cBPartnerName + '</h2></center>';
                    inscriptionConstraint.constraintChecks = inscriptionConstraint.constraintChecks
                      .filter((constraint) => constraint.isConfirm)
                      .sort(function (a, b) {
                        return a.order - b.order;
                      });
                    inscriptionConstraint.constraintChecks.forEach((constraint) => {
                      if (constraint.popupMessage && constraint.popupMessage.length > 0) {
                        message += constraint.popupMessage + '<br><hr>';
                      }
                    });
                  }
                });
              }
            }
            this.sendMessage(item, message);
          },
          (error) => {
            Global.infoDialog.message = {
              summary: this.translateService.instant('specificWindow.inscribe.constraintError'),
              detail: this.translateService.instant('specificWindow.inscribe.constraintErrorMessage'),
            };
            Global.infoDialog.dialogType = InfoDialogType.INFO;
            Global.infoDialog.showInfoDialog();
            const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
              confirm.unsubscribe();
            });
          }
        )
    );
  }
  sendMessage(item, message) {
    if (message.length > 0) {
      this.subscriptions.push(
        this.po.get<any>('AD_Role', this.connectorService.getIupicsUserContext()['#AD_Role_ID']).subscribe(
          (role) => {
            const forceInscr = role['Force_Inscr'] == 'Y';
            Global.infoDialog.message = {
              summary: forceInscr
                ? this.translateService.instant('specificWindow.inscribe.askForced')
                : this.translateService.instant('specificWindow.inscribe.cantForce'),
              detail: message,
            };
            if (forceInscr) {
              Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
              Global.infoDialog.showInfoDialog();
              const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
                this.transfertProcess(item);
                confirm.unsubscribe();
                cancel.unsubscribe();
              });
              const cancel = Global.infoDialog.cancel.subscribe((e) => {
                confirm.unsubscribe();
                cancel.unsubscribe();
              });
            } else {
              Global.infoDialog.dialogType = InfoDialogType.INFO;
              Global.infoDialog.showInfoDialog();
              const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
                confirm.unsubscribe();
              });
            }
          },
          (error) => console.log
        )
      );
    } else {
      this.transfertProcess(item);
    }
  }
  transfertProcess(item) {
    this.processService.getProcess(item.processId).subscribe((success) => {
      if (success) {
        let paramsMap: ProcessParams = {
          ad_process_id: null,
          className: null,
          record_id: null,
          tableName: null,
          tables: null,
          params: null,
          windowCtx: this.getCurrentContext(),
          ad_tab_id: null,
        };
        const classname = success.process.ClassName;
        const AD_Process_ID = success.process.AD_Process_ID;
        const tableName = this.isModal
          ? this.parentComponent.editTabs
            ? this.parentComponent.editTabs[0].data.TableName
            : null
          : null;
        paramsMap = {
          ad_process_id: AD_Process_ID,
          params: {},
          className: classname,
          record_id: this.dataStore.data['Data_UUID'].split(',')[1],
          tableName: tableName, // added in aim to test the process , it wrong
          tables: [],
          windowCtx: this.getCurrentContext(),
          ad_tab_id: null,
        };
        this.filters.forEach((filter) => {
          Object.keys(this.dataStore.data).forEach((key) => {
            if (filter === key) {
              if (this.dataStore.data[filter] !== undefined && this.dataStore.data[filter] !== null) {
                paramsMap.params[filter] = this.dataStore.data[filter].id;
              } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                paramsMap.params[filter] = null;
              }
            }
          });
        });
        if (this.dataStore.data && this.dataStore.data['Z_Participant_ID']) {
          paramsMap.params['Z_Participant_ID'] = this.dataStore.data['Z_Participant_ID'].id
            ? this.dataStore.data['Z_Participant_ID'].id
            : this.dataStore.data['Z_Participant_ID'];
          paramsMap.params['ZoneLibre4'] = this.dataStore.data['ZoneLibre4'];
        } else {
          throw new Error(this.translateService.instant('specificWindow.inscribe.constraintError'));
        }
        this.otherParams.forEach((filter) => {
          Object.keys(this.dataStore.data).forEach((key) => {
            if (filter === key) {
              if (
                this.dataStore.data[filter] !== undefined &&
                this.dataStore.data[filter] !== null &&
                this.dataStore.data[filter].id !== undefined
              ) {
                paramsMap.params[filter] = this.dataStore.data[filter].id;
              } else if (
                this.dataStore.data !== undefined &&
                this.dataStore.data[filter] !== null &&
                this.dataStore.data[filter] !== undefined
              ) {
                paramsMap.params[filter] = this.dataStore.data[filter];
              } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                paramsMap.params[filter] = null;
              }
            }
          });
        });
        const button = this.getDatacontainer('Processing');
        if (button) {
          button.isReadOnly = true;
        }
        //ajout des paramètres non affiché

        const obs = this.uiCreator.executeProcess(paramsMap);
        this.subscriptions.push(
          obs.subscribe((instance) => {
            if (this.parentComponent !== undefined) {
              (this.parentComponent as EditViewUiComponent).isProcessLaunch = true;
            }
            this.updateLoading(true);
            const pg = this.ppService.watchProcessInProgress().subscribe((pings) => {
              const me = this.connectorService.getIupicsUserAccount();
              const ping = pings.find((p) => {
                return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === AD_Process_ID&&p.Status === 'finish';
              });
              if (ping) {
                const button = this.getDatacontainer('Processing');
                if (button) {
                  button.isReadOnly = false;
                }
                this.updateLoading(false);
                pg.unsubscribe();
                this.onTriggerProcess();
              }
            });
          })
        );
      }
    });
  }
  onTriggerProcess() {
    if (this.closeModalEmitter.observers) {
      this.closeModalEmitter.emit();
    }
  }
  ngAfterViewInit() {
    // if (!this.dataStore) {
    //   this.dataStore = this.store.newSpecificWindowData(this.formId);
    // }
    // permettre aux champs liés à la valeur d'un autre de s'adapter
    this.setDataContainersValueWithChangedStore();
    if (!this.isModal) {
      Global.workspace.linkedComponentToTabMap[this.activeTab.id] = this;
    }
  }
  notifyFromDataChange(item?: any) {
    if (item.data.columnName === 'C_Project_ID' && this.getFormStoreData('C_Project_ID')) {
      let roleContainer = this.getDatacontainer('Z_Role_Evenement_ID');
      if (roleContainer) {
        const request: DataStoreRequest = {
          windowId: null,
          parent_constraint: '',
          compiereRequest: {
            startRow: 0,
            endRow: 0,
            tableName:
              // '(select z_role_evenement_id as value from z_role_evenement where c_projecttype_id =(select c_projecttype_id from c_project where c_project_id=' +
              // this.getFormStoreData('C_Project_ID').id +
              // ")  and ROWNUM<2 and isactive='Y' and ad_client_id = " +
              // (this.getFormStoreData('AD_Client_ID') ? this.getFormStoreData('AD_Client_ID').id : -1) +
              // ' order by sequence asc)'
              "(SELECT ( SELECT z_role_evenement_id FROM z_role_evenement WHERE ( lower(pt.name) = lower('ACTION') AND value = 'PARA' ) OR ( lower(pt.name) = lower('CAMPS') AND value = 'PARC' ) OR ( lower(pt.name) = lower('FORMATION') AND value = 'PARF' ) ) AS value FROM c_projecttype   pt INNER JOIN c_project       p ON pt.c_projecttype_id = p.c_projecttype_id WHERE p.c_project_id =" +
              this.getFormStoreData('C_Project_ID').id +
              ')',
          },
        };
        this.subscriptions.push(
          this.store.getDataGrid(request).subscribe((response) => {
            if (response.data.length > 0) {
              const value = response.data[0]['VALUE'];
              if (value) {
                roleContainer.updateStore(value);
              }
            }
          })
        );
      }
    } else if (item.data.isLaunchSearchGrid) {
      this.refreshGrids(this.dataStore, false, item.data);
    }
  }
  notifyFromDatacontainerInit(dataContainer) {
    if (dataContainer.data.columnName === 'ZoneLibre4') {
      this.setDatacontainerValue('ZoneLibre4', this.motif);
    }
  }
}
export interface POEvenement {
  LockProject: string;
  Z_Ouvert_B: string;
  Z_Ouvert_CF: string;
  Z_Ouvert_E: string;
  Z_Ouvert_L: string;
  Z_Ouvert_P: string;
  Z_Ouvert_R: string;
  Z_Ouvert_U: string;
  M_Warehouse_ID: string;
  DateContract: string;
  Processed: string;
  DateFinish: string;
  Session_Status: string;
}
export interface ConditionMessage {
  title: string;
  text: string;
}
