//from 3.0.0
import { registerLocaleData } from '@angular/common';
import localeEnglish from '@angular/common/locales/en';
import localeFrench from '@angular/common/locales/fr';
import localeDutch from '@angular/common/locales/nl';
import { Injectable } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { OAuth2TokenJSON } from '@compiere-ws/models/compiere-login-json';
import { CompiereLoginService } from '@compiere-ws/services/compiere-login/compiere-login.service';
import { AppConfig } from '@iupics-config/app.config';
import {
  CookieIupics,
  IupicsCookieService,
  LocalStorageIupics
} from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { RoleUI, UserAccount } from '@login-page/models/user-account.';
import { TranslateService } from '@ngx-translate/core';
import { UserPreference } from '@web-desktop/models/user-preference';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MessageManagerService } from '../message/message-manager.service';
@Injectable({
  providedIn: 'root'
})
export class SecurityManagerService {
  private iupicsDefaultLanguage: CompiereLanguage;
  private iupicsUserAccount: UserAccount;
  private iupicsUserContext: any;
  public oauth2Access: OAuth2TokenJSON;
  private intervalTimerId;

  constructor(
    private loginService: CompiereLoginService,
    private cookieService: IupicsCookieService,
    private messageManager: MessageManagerService,
    private appConfig: AppConfig,
    private translateService: TranslateService
  ) {}

  authenticate(login: string, password: string): Observable<any> {
    if (this.iupicsUserAccount && this.iupicsUserAccount.session_id) {
      return of(this.iupicsUserAccount);
    } else {
      return this.loginService.login(login, password).pipe(
        switchMap((oAuth2Token) => {
          this.oauth2Access = oAuth2Token;
          Global.configUrl = environment.domain + oAuth2Token.ConfigURL + environment.configFilePath;
          this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.urlConfig, oAuth2Token.ConfigURL);
          return from(this.appConfig.loadConfig(oAuth2Token.access_token)).pipe(
            switchMap((res) => {
              this.loginService.loadUrl();
              return this.loginService.getLoginInfo(this.oauth2Access.access_token).pipe(
                map((loginInfo) => {
                  if (loginInfo.session_id) {
                    this.iupicsUserAccount = {
                      id: parseInt(loginInfo.user_id, 0),
                      session_id: parseInt(loginInfo.session_id, 0),
                      ad_client: loginInfo.ad_client_id,
                      fullname: loginInfo.name,
                      email: loginInfo.email,
                      login: login,
                      avatarLetters: this.getAvatarTwoLetters(loginInfo.name),
                      avatarColor: this.randomAvatarColor(),
                      default_language: this.iupicsDefaultLanguage,
                      current_role: loginInfo.roles[0]
                    };
                    this.saveUserAccountCookie();
                    this.startSessionTimer();
                    return this.iupicsUserAccount;
                  }
                }),
                catchError((val) => of(`${val}`))
              );
            })
          );
        })
      );
    }
  }

  authenticateWithUserAccount(userAccount: UserAccount, password: string): Observable<any> {
    if (this.iupicsUserAccount && this.iupicsUserAccount.login === userAccount.login && this.oauth2Access) {
      return of(this.iupicsUserAccount);
    } else {
      return this.loginService.login(userAccount.login, password).pipe(
        switchMap((oAuth2Token) => {
          this.oauth2Access = oAuth2Token;
          Global.configUrl = environment.domain + oAuth2Token.ConfigURL + environment.configFilePath;
          this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.urlConfig, oAuth2Token.ConfigURL);
          return from(this.appConfig.loadConfig(oAuth2Token.access_token)).pipe(
            switchMap((res) => {
              this.loginService.loadUrl();
              return this.loginService.getLoginInfo(this.oauth2Access.access_token).pipe(
                map((loginInfo) => {
                  if (loginInfo.session_id) {
                    this.iupicsUserAccount = userAccount;
                    this.iupicsUserAccount.session_id = parseInt(loginInfo.session_id, 0);
                    this.iupicsUserAccount.ad_client = loginInfo.ad_client_id;
                    this.saveUserAccountCookie();
                    this.startSessionTimer();
                    return this.iupicsUserAccount;
                  }
                }),
                catchError((val) => of(`${val}`))
              );
            })
          );
        })
      );
    }
  }

  refreshLogin(): Observable<UserAccount> {
    return this.loginService.refreshLogin(this.oauth2Access.refresh_token).pipe(
      switchMap((oAuth2Token) => {
        this.oauth2Access = oAuth2Token;
        return this.loginService.getLoginInfo(this.oauth2Access.access_token).pipe(
          map((loginInfo) => {
            if (loginInfo.session_id) {
              this.iupicsUserAccount.session_id = parseInt(loginInfo.session_id, 0);
              this.iupicsUserAccount.ad_client = loginInfo.ad_client_id;
              this.saveUserAccountCookie();
              return this.iupicsUserAccount;
            }
          })
        );
      })
    );
  }

  isSessionValid(access_token: OAuth2TokenJSON, default_language?: CompiereLanguage): Observable<UserAccount> {
    if (!this.iupicsDefaultLanguage) {
      this.iupicsDefaultLanguage = default_language;
    }
    this.oauth2Access = access_token;
    return new Observable<UserAccount>((observer) => {
      this.loginService.sessionValid().subscribe(
        (status) => {
          Global.configUrl =
            environment.domain +
            this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.urlConfig) +
            environment.configFilePath;
            return from(this.appConfig.loadConfig(access_token.access_token)).subscribe((res) => {
            this.loginService.loadUrl();
            this.loginService.getCtx().subscribe(
              (ctxJSON) => {
                this.loginService.getLoginInfo(this.oauth2Access.access_token).subscribe((loginInfo) => {
                  const currentAD_Org_ID = ctxJSON['#AD_Org_ID'];
                  if (loginInfo['org_id'] !== null && loginInfo['org_id'] !== undefined) {
                    ctxJSON['#AD_Org_ID'] = parseInt(loginInfo['org_id'], 10);
                  }
                  this.iupicsUserContext = ctxJSON;
                  // date courante local setté
                  this.setIupicsUserContextDate(moment().toDate());
                  if (currentAD_Org_ID !== ctxJSON['#AD_Org_ID']) {
                    this.updateRemoteCtx();
                  }
                  this.iupicsUserAccount = {
                    id: parseInt(loginInfo.user_id, 0),
                    fullname: loginInfo.name,
                    login: loginInfo.value,
                    session_id: parseInt(loginInfo.session_id, 0),
                    email: loginInfo.email,
                    roles: loginInfo.roles,
                    avatarLetters: this.getAvatarTwoLetters(loginInfo.name),
                    avatarColor: this.randomAvatarColor(),
                    default_language: this.iupicsDefaultLanguage,
                    current_role: loginInfo.roles[0],
                    ad_client: loginInfo.ad_client_id
                  };
                  this.iupicsUserAccount = Object.assign(this.iupicsUserAccount, loginInfo);

                  this.iupicsUserAccount.roles.forEach((role) => {
                    if (parseInt(ctxJSON['#AD_Role_ID'], 0) === role.role_id) {
                      role.isSelected = true;
                      this.iupicsUserAccount.current_role = role;
                    } else {
                      role.isSelected = false;
                    }
                  });
                  observer.next(this.iupicsUserAccount);
                  observer.complete();
                  return { unsubscribe() {} };
                });
              },
              (err) => {
                this.oauth2Access = undefined;
                observer.error(err.error.message);
                observer.complete();
                return { unsubscribe() {} };
              }
            );
          });
        },
        (err) => {
          this.oauth2Access = undefined;
          this.cookieService.deleteLS(LocalStorageIupics.access_token);
          location.reload();
        }
      );
    });
  }

  changeRole(newRole: RoleUI): Observable<UserAccount> {
    return this.loginService.changeRole(newRole).pipe(
      switchMap((oAuth2Token) => {
        this.oauth2Access = oAuth2Token;
        return this.loginService.getLoginInfo(this.oauth2Access.access_token).pipe(
          map((loginInfo) => {
            if (loginInfo['org_id'] !== null && loginInfo['org_id'] !== undefined && this.iupicsUserContext) {
              if (this.iupicsUserContext['#AD_Org_ID'] !== parseInt(loginInfo['org_id'], 10)) {
                this.iupicsUserContext['#AD_Org_ID'] = parseInt(loginInfo['org_id'], 10);
                this.updateRemoteCtx();
              }
            }
            if (loginInfo.session_id) {
              this.iupicsUserAccount.session_id = parseInt(loginInfo.session_id, 0);
              this.iupicsUserAccount.ad_client = loginInfo.ad_client_id;
              this.iupicsUserAccount.current_role = newRole;
              this.saveUserAccountCookie();
              this.startSessionTimer();
              return this.iupicsUserAccount;
            }
          })
        );
      })
    );
  }

  changeLanguage(newLanguage: CompiereLanguage): Observable<UserAccount> {
    return this.loginService.changeLanguage(newLanguage).pipe(
      map((status) => {
        this.iupicsUserAccount.default_language = newLanguage;
        this.saveUserAccountCookie();
        return this.iupicsUserAccount;
      })
    );
  }

  disconnect(): Observable<boolean> {
    return this.loginService.logout().pipe(
      map((_) => {
        if (!this.setPreviousUser()) {
          this.iupicsUserAccount.session_id = undefined;
          this.oauth2Access = undefined;
          this.saveUserAccountCookie();
          return false;
        } else {
          return true;
        }
      })
    );
  }

  getCtx(): Observable<any> {
    return zip(this.loginService.getCtx(), this.loginService.getLoginInfo(this.oauth2Access.access_token)).pipe(
      map(([ctxJson, loginInfo]) => {
        const currentAD_Org_ID = ctxJson['#AD_Org_ID'];
        if (loginInfo['org_id'] !== null && loginInfo['org_id'] !== undefined) {
          ctxJson['#AD_Org_ID'] = parseInt(loginInfo['org_id'], 10);
        }
        this.iupicsUserContext = ctxJson;
        if (currentAD_Org_ID !== ctxJson['#AD_Org_ID']) {
          this.updateRemoteCtx();
        }
        this.iupicsUserAccount.roles = loginInfo.roles;
        this.iupicsUserAccount.roles.forEach((role) => {
          if (parseInt(ctxJson['#AD_Role_ID'], 0) === role.role_id) {
            role.isSelected = true;
            this.iupicsUserAccount.current_role = role;
          } else {
            role.isSelected = false;
          }
        });
        return this.iupicsUserAccount;
      })
    );
  }

  getAllUsers(): Observable<any> {
    return this.loginService.getAllUsers();
  }

  loginAs(username: string): Observable<any> {
    return this.loginService.loginAs(username).pipe(
      switchMap((oAuth2Token) => {
        return this.loginService.getLoginInfo(oAuth2Token.access_token).pipe(
          map((loginInfo) => {
            if (loginInfo.session_id) {
              this.backupCurrentUserToken(oAuth2Token);
              return this.iupicsUserAccount;
            }
          }),
          catchError((val) => of(`${val}`))
        );
      })
    );
  }

  getIupicsUserAccount(): UserAccount {
    return this.iupicsUserAccount;
  }

  getOAuth2AccessToken(): OAuth2TokenJSON {
    return this.oauth2Access;
  }

  getIupicsUserContext(): any {
    return this.iupicsUserContext;
  }

  getIupicsDefaultLanguage(): CompiereLanguage {
    return this.iupicsDefaultLanguage;
  }

  setIupicsDefaultLanguage(language: CompiereLanguage) {
    this.iupicsDefaultLanguage = language;
    switch (language.iso_code.replace('_', '-')) {
      case 'fr-FR': {
        registerLocaleData(localeFrench);
        break;
      }
      case 'nl-NL': {
        registerLocaleData(localeDutch);
        break;
      }
      case 'en-US': {
        registerLocaleData(localeEnglish);
        break;
      }
      default: {
        registerLocaleData(localeFrench);
        break;
      }
    }
  }

  saveUserAccountCookie(isLoginAs: boolean = false) {
    if (this.oauth2Access) {
      const expires = new Date();
      expires.setTime(expires.getTime() + this.oauth2Access.expires_in * 1000);
      this.cookieService.setAndEncryptCookie(CookieIupics.access_token_expires, String(expires.getTime()));
      this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.access_token, JSON.stringify(this.oauth2Access));
    } else {
      this.cookieService.deleteLS(LocalStorageIupics.access_token);
      this.cookieService.delete(CookieIupics.access_token_expires);
    }
    if (!isLoginAs) {
      this.cookieService.setAndEncryptLocalStorage(
        LocalStorageIupics.last_connected_user,
        JSON.stringify(this.iupicsUserAccount)
      );
    }
  }

  backupCurrentUserToken(oAuth2Token: OAuth2TokenJSON) {
    this.cookieService.setAndEncryptCookie(
      CookieIupics.access_token_previous_user,
      this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.access_token)
    );
    this.cookieService.setAndEncryptCookie(
      CookieIupics.access_token_expires_previous_user,
      this.cookieService.getDecryptedCookie(CookieIupics.access_token_expires)
    );
    this.oauth2Access = oAuth2Token;
    this.saveUserAccountCookie(true);
  }

  setPreviousUser(): boolean {
    const previousToken = this.cookieService.getDecryptedCookie(CookieIupics.access_token_previous_user);
    const previousTokenExpires = this.cookieService.getDecryptedCookie(CookieIupics.access_token_expires_previous_user);
    if (previousToken && previousTokenExpires) {
      this.cookieService.setAndEncryptLocalStorage(
        LocalStorageIupics.access_token,
        this.cookieService.getDecryptedCookie(CookieIupics.access_token_previous_user)
      );
      this.cookieService.setAndEncryptCookie(
        CookieIupics.access_token_expires,
        this.cookieService.getDecryptedCookie(CookieIupics.access_token_expires_previous_user)
      );
      this.cookieService.delete(CookieIupics.access_token_previous_user);
      this.cookieService.delete(CookieIupics.access_token_expires_previous_user);
      return true;
    }
    return false;
  }

  getAvatarTwoLetters(account_name: string): string {
    const spacesSplit = account_name.split(' ');
    if (spacesSplit.length > 1) {
      return spacesSplit[0].charAt(0) + spacesSplit[1].charAt(0);
    }
    const dashSplit = account_name.split('-');
    if (dashSplit.length > 1) {
      return dashSplit[0].charAt(0) + dashSplit[1].charAt(0);
    } else {
      return account_name.charAt(0) + account_name.charAt(1);
    }
  }

  randomAvatarColor(): string {
    return (
      'rgb(' +
      Math.floor(Math.random() * 256) +
      ',' +
      Math.floor(Math.random() * 256) +
      ',' +
      Math.floor(Math.random() * 256) +
      ')'
    );
  }

  startSessionTimer() {
    const timeout = parseInt(this.appConfig.getConstant('sessionTimeout'), 10);
    const timeToRefresh = timeout / 2;
    const timeToDisplayMessage = timeout / 30 < 10 ? 10 : timeout / 30; // 3600sec de session: 120sec avant d'afficher le message de refresh
    const activeTime = timeout / 6; // 3600sec de session: 600sec de temps minimum actif
    let expires = null;
    try {
      expires = new Date(JSON.parse(this.cookieService.getDecryptedCookie(CookieIupics.access_token_expires)));
    } catch (error) {
      expires = new Date();
      expires.setTime(expires.getTime() + this.oauth2Access.expires_in * 1000);
      this.cookieService.setAndEncryptCookie(CookieIupics.access_token_expires, String(expires.getTime()));
      this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.access_token, JSON.stringify(this.oauth2Access));
    }

    if (this.intervalTimerId) {
      clearInterval(this.intervalTimerId);
    }
    this.intervalTimerId = setInterval(() => {
      // Dans le cas où on se déconnecte
      if (!this.oauth2Access) {
        clearInterval(this.intervalTimerId);
      } else {
        this.oauth2Access.expires_in = Math.floor(expires.getTime() / 1000 - new Date().getTime() / 1000);
        // Reload de la page si access_token expiré
        if (this.oauth2Access.expires_in < 5) {
          // Logoff
          this.disconnect();
          this.cookieService.deleteLS(LocalStorageIupics.access_token);
          location.reload();
        } else {
          // Affichage d'un message de session bientot expiré si il reste moins de "x" secondes
          if (this.oauth2Access.expires_in <= timeToDisplayMessage) {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.warning'),
                this.translateService.instant('securityManager.expiredSession', { expires_in: this.oauth2Access.expires_in }),
                'message'
              )
            );
          }
          // Demande de nouveau access_token si l'access token expire dans la moitié du temps total de l'access token
          if (this.oauth2Access.expires_in <= timeToRefresh) {
            // On effectue une demande de nouveau token seulement si la dernière activité date de moins de "x" secondes
            const lastActivityTime = (new Date().getTime() - Global.lastActivity.getTime()) / 1000;
            if (lastActivityTime <= activeTime) {
              this.refreshLogin().subscribe(() => {
                expires = new Date(JSON.parse(this.cookieService.getDecryptedCookie(CookieIupics.access_token_expires)));
              });
            }
          }
        }
      }
    }, 1000);
  }
  setIupicsUserContextDate(date: Date) {
    this.iupicsUserContext['#Date'] = date.valueOf();
  }
  setIupicsUserContext(ctx: any) {
    this.iupicsUserContext = ctx;
  }
  updateRemoteCtx() {
    this.loginService.updateCtx(this.iupicsUserContext).subscribe((ctx) => (this.iupicsUserContext = ctx));
  }

  handleError() {}

  getPref(): Observable<any> {
    return this.loginService.getPref();
  }

  savePref(userPref: UserPreference): Observable<any> {
    return this.loginService.savePref(userPref);
  }

  loadConfig() {
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.checkLS(LocalStorageIupics.access_token)) {
        let userAccount: UserAccount;
        if (this.cookieService.checkLS(LocalStorageIupics.last_connected_user)) {
          userAccount = JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.last_connected_user));
        }

        const access_token: OAuth2TokenJSON = JSON.parse(
          this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.access_token)
        );

        try {
          const expires: Date = JSON.parse(this.cookieService.getDecryptedCookie(CookieIupics.access_token_expires));
          access_token.expires_in = parseInt(String(Math.abs(new Date().getTime() - new Date(expires).getTime()) / 1000), 0);
          this.isSessionValid(access_token, userAccount ? userAccount.default_language : undefined).subscribe((status) => {
            setTimeout(() => {
              this.startSessionTimer();
              resolve();
            }, 500);
          });
        } catch (e) {
          this.cookieService.clearLsCookies();
          location.reload();
        }
      } else {
        resolve();
      }
    });
  }

  resetCache(): Observable<any> {
    return this.loginService.resetCache();
  }

  getConfig(): AppConfig {
    return this.appConfig;
  }
}
