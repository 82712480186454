<div #specificContainer class="iu-specific-window iu-specific-window-container" [attr.data-cy-specific-window-name]="name">
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="ui-g-12">
      <div class="fields">
        <ng-template iuDynamicContainer></ng-template>
      </div>
    </div>
    <div class="iu-specific-btn-container" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
      <iu-button-ui
        *ngIf="gridViews && gridViews.length === 1 && hasInfoWindowGrid"
        class="iu-btn ui-g-12 ui-md-3 ui-lg-2"
        (click)="$event.preventDefault(); $event.stopPropagation(); zoomOnLineSelected()"
        label="{{ 'contextmenu.zoom' | translate }}"
        icon="fa fa-search-plus"
        data-cy="form-button-zoom"
      >
      </iu-button-ui>
      <ng-template #vcrButtons></ng-template>
    </div>
  </p-scrollPanel>
</div>
