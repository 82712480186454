import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereDataGridSortModelType } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { AutocompleteRendererComponent } from '../renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from '../renderer/button-renderer.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-gps-project',
  templateUrl: './gps-project.component.html',
  styleUrls: ['./gps-project.component.scss']
})
export class GpsProjectComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('filtersDistance', { read: ViewContainerRef, static: true })
  vcrFiltersDistance: ViewContainerRef;
  @ViewChild('filtersDistance2', { read: ViewContainerRef, static: true })
  vcrFiltersDistance2: ViewContainerRef;
  @ViewChild('filters', { read: ViewContainerRef, static: true })
  vcrFilters: ViewContainerRef;
  @ViewChild('tableParent', { read: ViewContainerRef, static: true })
  vcrTableParent: ViewContainerRef;
  @ViewChild('actions', { read: ViewContainerRef, static: true })
  vcrActions: ViewContainerRef;

  frameworkComponents: any;
  currentFilters: string[];
  tableNames: string[];

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService,
    translateService: TranslateService
  ) {
    super(windowFactory, resolver, uiCreator, store, processService, socketService, connectorService, pp, translateService);
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent
    };
    this.tableNames = ['Rechercher Camp Table Gps Camp'];
  }

  ngOnInit() {
    this.currentFilters = [];
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        switch (item.data.columnName) {
          case 'Geo_Address':
            this.customDesignArray.push({
              vcr: 'vcrFiltersDistance2',
              type: CustomDesignItemType.FIELD,
              columnName: 'Geo_Address',
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-lg-4 ui-md-4 ui-g-12'
            });
            break;

          case 'Geo_MaxDistance':
            this.customDesignArray.push({
              vcr: 'vcrFilters',
              type: CustomDesignItemType.FIELD,
              columnName: 'Geo_MaxDistance',
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-lg-4 ui-md-4 ui-g-12'
            });
            break;

          case 'Geo_Location_Lat':
            this.customDesignArray.push({
              vcr: 'vcrFiltersDistance',
              type: CustomDesignItemType.FIELD,
              columnName: 'Geo_Location_Lat',
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-lg-4 ui-md-4 ui-g-12'
            });
            break;

          case 'Geo_Location_Lng':
            this.customDesignArray.push({
              vcr: 'vcrFiltersDistance',
              type: CustomDesignItemType.FIELD,
              columnName: 'Geo_Location_Lng',
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-lg-4 ui-md-4 ui-g-12'
            });
            break;
          default:
            if (item.component === 'GridViewUiComponent') {
              this.customDesignArray.push({
                vcr: 'vcrTableParent',
                type: CustomDesignItemType.GRID,
                tableName: item.name,
                cssClass: 'ui-g-12',
                shouldSelectFirst: false,
                frameworkComponents: this.frameworkComponents,
                isFitResize: true,
                rowSelection: 'single',
                suppressRowClickSelection: false
              });

            }
            break;
        }
      });
      //this.setExcludedFiltersToGrid(this.tableNames[0], ['Geo_MaxDistance', 'Geo_Location_Lat', 'Geo_Location_Lng']);
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'ui-g-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };

      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
  }

  notifyFromDataChange(item?: any) {
    if ((item.data['columnName'] === 'Geo_Location_Lat' || item.data['columnName'] === 'Geo_Location_Lng') && this.dataStore.data['Geo_Location_Lat'] &&
      this.dataStore.data['Geo_Location_Lng']) {
      this.setExcludedFiltersToGrid(this.tableNames[0], ['Geo_Address']);
      this.currentFilters = ['Geo_Location_Lat', 'Geo_Location_Lng', 'Geo_MaxDistance'];
      this.resetLoc();
    } else {
      this.setExcludedFiltersToGrid(this.tableNames[0], []);
      if (
        (item.data['columnName'] === 'Geo_Address' ||
          (item.data['columnName'] === 'Geo_MaxDistance' &&
            this.dataStore.data['Geo_Address'] != null &&
            this.dataStore.data['Geo_Address'] !== ''))
      ) {
        if (this.dataStore.data['Geo_Address'] != null) {
          const geocode = new google.maps.Geocoder();
          geocode.geocode({ address: this.dataStore.data['Geo_Address'] }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.dataStore.data['Geo_Location_Lat'] = results[0].geometry.location.lat();
              this.dataStore.data['Geo_Location_Lng'] = results[0].geometry.location.lng();
              if (this.dataStore.dataChange) {
                const dataModified = {
                  Geo_Location_Lat: results[0].geometry.location.lat(),
                  Geo_Location_Lng: results[0].geometry.location.lng()
                };
                this.dataStore.dataChange.emit({
                  dataModified: dataModified,
                  bypassValidation: false,
                  isFromCallout: true
                });
              }
              this.refreshTable();
            } else {
              this.resetCoord();
            }
          });
        } else {
          this.resetCoord();
        }

      } else if (item.data['columnName'] === 'Geo_MaxDistance') {
        this.currentFilters = ['Geo_Location_Lat', 'Geo_Location_Lng', 'Geo_MaxDistance'];
        this.refreshTable();
        this.clearAllGridsSelection();
      }
    }

  }

  resetCoord() {
    this.dataStore.data['Geo_Location_Lat'] = 0;
    this.dataStore.data['Geo_Location_Lng'] = 0;
    if (this.dataStore.dataChange) {
      const dataModified = {
        Geo_Location_Lat: 0,
        Geo_Location_Lng: 0
      };
      this.dataStore.dataChange.emit({
        dataModified: dataModified,
        bypassValidation: false,
        isFromCallout: true
      });
    }
    this.clearAllGridsSelection();
    this.refreshGrids(this.dataStore, true);
  }
  resetLoc() {
    this.dataStore.data['Geo_Address'] = null;
    if (this.dataStore.dataChange) {
      const dataModified = {
        Geo_Address: null
      };
      this.dataStore.dataChange.emit({
        dataModified: dataModified,
        bypassValidation: false,
        isFromCallout: true
      });
    }
    this.clearAllGridsSelection();
    this.refreshGrids(this.dataStore, false);
  }
  notifyFromRowSelected(rowSelected: any) {
    if (rowSelected.length > 0) {
      Global.workspace.openTargetSearch({
        cat: { id: 130 },
        source: { id: 'C_Project_ID,' + rowSelected[0]['C_Project_ID'] }
      });
    }
  }

  refreshTable() {
    const tableName = this.tableNames[0];
    const showTable = this.gridViews.find((grid) => (<GridViewUiComponent>grid).data['tableName'] === tableName);
    if (showTable) {
      const filter = showTable.filter || { filterModel: {}, sortModel: [] };
      if (filter.sortModel && !filter.sortModel.find((sm) => sm.colId === 'Geo_Distance')) {
        filter.sortModel.push({
          colId: 'Geo_Distance',
          sort: CompiereDataGridSortModelType.ASC
        });
        showTable.updateFilterEmitter.emit(filter);
      }

      this.refreshGrids(this.dataStore, false, null, showTable.tabId);
    }
  }

  reset() {
    this.dataStore.data['Geo_Address'] = '';
    this.dataStore.data['Geo_Location_Lat'] = 0;
    this.dataStore.data['Geo_Location_Lng'] = 0;
    this.dataStore.data['Geo_MaxDistance'] = 50;
    this.dataContainers.forEach((datacontainer) => {
      datacontainer.setNewData(this.dataStore);
    });
    this.clearAllGridsSelection();
    this.refreshGrids(this.dataStore, true);
  }
}
