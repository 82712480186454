import { RowNode } from '@ag-grid-enterprise/all-modules';
import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridSortModelType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { truncate } from 'lodash';
import * as moment from 'moment';
import { ProcessParams } from '../process-ui/process-ui.component';
import { AutocompleteRendererComponent } from '../renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from '../renderer/button-renderer.component';
import { CalendarRendererComponent } from '../renderer/calendar-renderer.component';
import { NumberRendererComponent } from '../renderer/number-renderer.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-unit-management',
  templateUrl: './unit-management.component.html',
  styleUrls: ['./unit-management.component.scss']
})
export class UnitManagementComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('topleft', { read: ViewContainerRef, static: true })
  vcrTopLeft: ViewContainerRef;
  @ViewChild('topright', { read: ViewContainerRef, static: true })
  vcrTopRight: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  vcrBot: ViewContainerRef;

  sectionList = [];
  fonctionList = [];
  frameworkComponents: any;

  clotureFonction_Process_ID = 0;
  transfert_Process_ID = 0;
  newAnim_Process_ID = 0;
  Z_BP_Link_AFF_NewAnim = 0;
  partnerFormId = this.config.getConstant('SpecificWindowCreatePartner_FormID');
  modalClass = 'ui-g-10 ui-md-10 ui-lg-10';

  constructor(
    public elementRef: ElementRef,
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private config: AppConfig,
    private ppService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      ppService,
      translateService
    );
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent,
      numberRenderer: NumberRendererComponent,
      calendarRenderer: CalendarRendererComponent
    };
  }

  ngOnInit() {
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        if (item.component === 'GridViewUiComponent') {
          item.data.hasCheckbox = false;
          const editorColumns = [];

          const colAction = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'Action';
          });
          if (colAction) {
            const actionDetail = item.data.columnsDetails.get('Action');
            editorColumns.push('Action');
            colAction.editable = false;
            colAction.cellRenderer = 'autocompleteRenderer';
            colAction.cellRendererParams = {
              onSelect: this.onBtnClick1.bind(this),
              onSearch: this.onSearch.bind(this),
              closePanel: this.closePanel.bind(this),
              label: '',
              templates: {
                items: null,
                columnName: 'Action',
                urlList: actionDetail.field.urlList,
                urlSearch: actionDetail.field.urlSearch,
                isSearch: true,
                columnId: actionDetail.field.field.AD_Column_ID,
                fieldTypeForm: true
              }
            };
          }

          const colTargetFonction = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'TargetFonction';
          });
          if (colTargetFonction) {
            const targetFonctionDetail = item.data.columnsDetails.get('TargetFonction');
            editorColumns.push('TargetFonction');
            colTargetFonction.editable = false;
            colTargetFonction.cellRenderer = 'autocompleteRenderer';
            colTargetFonction.cellRendererParams = {
              onSelect: this.onBtnClick1.bind(this),
              onSearch: this.onSearch.bind(this),
              closePanel: this.closePanel.bind(this),
              label: '',
              templates: {
                items: null,
                columnName: 'TargetFonction',
                urlList: targetFonctionDetail.field.urlList,
                urlSearch: targetFonctionDetail.field.urlSearch,
                isSearch: true,
                columnId: targetFonctionDetail.field.field.AD_Column_ID,
                fieldTypeForm: true
              }
            };
          }

          const colVoixPour = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'Voix_pour';
          });
          if (colVoixPour) {
            editorColumns.push('Voix_pour');
            colVoixPour.editable = false;
            colVoixPour.cellRenderer = 'numberRenderer';
            colVoixPour.cellRendererParams = {
              onSelect: this.onBtnClick1.bind(this),
              label: '',
              templates: {
                min: 0,
                columnName: 'Voix_pour'
              }
            };
          }

          const colVoixContre = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'Voix_contre';
          });
          if (colVoixContre) {
            editorColumns.push('Voix_contre');
            colVoixContre.editable = false;
            colVoixContre.cellRenderer = 'numberRenderer';
            colVoixContre.cellRendererParams = {
              onSelect: this.onBtnClick1.bind(this),
              label: '',
              templates: {
                min: 0,
                columnName: 'Voix_contre'
              }
            };
          }

          const colVoixAbstention = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'Voix_abstention';
          });
          if (colVoixAbstention) {
            editorColumns.push('Voix_abstention');
            colVoixAbstention.editable = false;
            colVoixAbstention.cellRenderer = 'numberRenderer';
            colVoixAbstention.cellRendererParams = {
              onSelect: this.onBtnClick1.bind(this),
              label: '',
              templates: {
                min: 0,
                columnName: 'Voix_abstention'
              }
            };
          }

          const colDebutMandat = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'Debut_Date_Mandat';
          });
          if (colDebutMandat) {
            editorColumns.push('Debut_Date_Mandat');
            colDebutMandat.editable = false;
            colDebutMandat.cellRenderer = 'calendarRenderer';
            colDebutMandat.cellRendererParams = {
              onSelect: this.onBtnClick1.bind(this, truncate),
              onCalendar: this.onCalendar.bind(this),
              label: '',
              templates: {
                columnName: 'Debut_Date_Mandat'
              }
            };
          }

          const colFinMandat = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'Fin_Date_Mandat';
          });
          if (colFinMandat) {
            editorColumns.push('Fin_Date_Mandat');
            colFinMandat.editable = false;
            colFinMandat.cellRenderer = 'calendarRenderer';
            colFinMandat.cellRendererParams = {
              onSelect: this.onBtnClick1.bind(this),
              onCalendar: this.onCalendar.bind(this),
              label: '',
              templates: {
                columnName: 'Fin_Date_Mandat'
              }
            };
          }

          const colElu = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'Elu_ou_nomme';
          });
          if (colElu) {
            const eluDetail = item.data.columnsDetails.get('Elu_ou_nomme');
            editorColumns.push('Elu_ou_nomme');
            colElu.editable = false;
            colElu.cellRenderer = 'autocompleteRenderer';
            colElu.cellRendererParams = {
              onSelect: this.onBtnClick1.bind(this),
              onSearch: this.onSearch.bind(this),
              closePanel: this.closePanel.bind(this),
              label: '',
              templates: {
                items: null,
                columnName: 'Elu_ou_nomme',
                urlList: eluDetail.field.urlList,
                urlSearch: eluDetail.field.urlSearch,
                isSearch: true,
                columnId: eluDetail.field.field.AD_Column_ID,
                fieldTypeForm: true
              }
            };
          }

          const colNbrMandat = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'NbrMandat';
          });
          if (colNbrMandat) {
            const mandatDetail = item.data.columnsDetails.get('NbrMandat');
            editorColumns.push('NbrMandat');
            colNbrMandat.editable = false;
            colNbrMandat.cellRenderer = 'autocompleteRenderer';
            colNbrMandat.cellRendererParams = {
              onSelect: this.onBtnClick1.bind(this),
              onSearch: this.onSearch.bind(this),
              closePanel: this.closePanel.bind(this),
              label: '',
              templates: {
                items: null,
                columnName: 'NbrMandat',
                urlList: mandatDetail.field.urlList,
                urlSearch: mandatDetail.field.urlSearch,
                isSearch: true,
                columnId: mandatDetail.field.field.AD_Column_ID,
                fieldTypeForm: true
              }
            };
          }

          this.customDesignArray.push({
            vcr: 'vcrMiddle',
            type: CustomDesignItemType.GRID,
            tableName: "Gestion cadre d'unité - Table",
            cssClass: 'ui-g-12',
            shouldSelectFirst: false,
            editorColumns: editorColumns,
            frameworkComponents: this.frameworkComponents,
            isFitResize: true
          });
        } else if (item.data.columnName === 'AD_Org_ID') {
          const fieldSize = 'ui-g-12';
          this.customDesignArray.push({
            vcr: 'vcrTopLeft',
            type: CustomDesignItemType.FIELD,
            columnName: 'AD_Org_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'C_BPartnerMembre_ID') {
          item.data.label = this.translateService.instant('unitManagement.titleCBPartner');
          const fieldSize = 'ui-g-12 ui-md-6';
          this.customDesignArray.push({
            vcr: 'vcrTopRight',
            type: CustomDesignItemType.FIELD,
            columnName: 'C_BPartnerMembre_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'CreateNewAff') {
          const fieldSize = 'ui-g-12 ui-md-6 buttonUnitManagement';
          this.customDesignArray.push({
            vcr: 'vcrTopRight',
            type: CustomDesignItemType.FIELD,
            columnName: 'CreateNewAff',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'ExecuteModif') {
          this.transfert_Process_ID = item.processId;
          const fieldSize = 'ui-g-12 ui-md-offset-8 ui-md-4 ';
          this.customDesignArray.push({
            vcr: 'vcrBot',
            type: CustomDesignItemType.FIELD,
            columnName: 'ExecuteModif',
            cssClass: fieldSize
          });
        }
      });
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'ui-g-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };
      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });

    const processNewAnimRequest: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_Process',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: ['creationRapideTiers'],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };

    this.store.getDataGrid(processNewAnimRequest).subscribe((res) => {
      if (res && res.data && res.data.length > 0) {
        this.newAnim_Process_ID = res.data[0]['AD_PROCESS_ID'];
      }
    });
  }

  notifyFromDataChange(item?: any) {
    if (item.data['columnName'] === 'C_BPartnerMembre_ID' && this.dataStore && this.dataStore.data['C_BPartnerMembre_ID']) {
      const NewMembreRequest: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 1,
          tableName: 'Z_MEMBRE_FONCTION',
          filterModel: {
            C_BPARTNER_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data['C_BPartnerMembre_ID'].id],
              operators: [OperatorFilterType.EQUALS]
            },
            ISACTIVE: {
              filterType: CompiereDataGridFilterType.SET,
              values: ['Y'],
              operators: [OperatorFilterType.EQUALS]
            }
          },
          sortModel: [
            {
              colId: 'FIN_DATE',
              sort: CompiereDataGridSortModelType.DESC
            }
          ]
        }
      };

      this.store.getDataGrid(NewMembreRequest).subscribe((res) => {
        if (res && res.data && res.data.length > 0) {
          const newItem = {
            Name: res.data[0]['NAME'],
            FirstName: res.data[0]['FIRSTNAME'],
            DDN: res.data[0]['DDN'],
            FONCTION: res.data[0]['FONCTIONNAME'],
            Action: { id: 'T', displayValue: 'Transfert' },
            Data_UUID: res.data[0]['C_BPARTNER_ID'],
            Z_BP_Link_Affiliation_ID: res.data[0]['Z_BP_LINK_AFFILIATION_ID'],
            C_BPartner_ID: res.data[0]['C_BPARTNER_ID'],
            Elu_ou_nomme: { id: 'N', displayValue: 'Nommé' }
          };
          if (this.gridViews.length > 0) {
            const element = this.gridViews[0].GridTabInfinityScrollUiComponent.tempDataItems.find((elt) => {
              return elt['Data_UUID'] === res.data[0]['C_BPARTNER_ID'];
            });
            if (!element) {
              this.gridViews[0].GridTabInfinityScrollUiComponent.tempDataItems.push(newItem);
              this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.purgeServerSideCache();
              // this.refreshGrids(this.dataStore, false, item.data);
            }
          }
        }
      });
      this.dataStore.data['C_BPartnerMembre_ID'] = null;
      if (this.dataStore.dataChange) {
        const dataModified = {
          C_BPartner_ID: null
        };
        this.dataStore.dataChange.emit({
          dataModified: dataModified,
          bypassValidation: false,
          isFromCallout: true
        });
      }
    } else if (item.data['columnName'] === 'AD_Org_ID') {
      this.dataStore.data['C_BPartnerMembre_ID'] = null;
      if (this.gridViews.length > 0) {
        this.gridViews[0].GridTabInfinityScrollUiComponent.tempDataItems = [];
      }
      if (this.dataStore.dataChange) {
        const dataModified = {
          C_BPartner_ID: null
        };
        this.dataStore.dataChange.emit({
          dataModified: dataModified,
          bypassValidation: false,
          isFromCallout: true
        });
      }
    }
    if (item.data.isLaunchSearchGrid) {
      this.refreshGrids(this.dataStore, false, item.data);
    }
  }

  onBtnClick1(e, debut) {
    if (e.event && e.event.id === 'T') {
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.forEachNode((row: RowNode) => {
        if (row.data['C_BPartner_ID'] === e.rowData['C_BPartner_ID']) {
          row.data['Action'] = { id: 'T', displayValue: 'Transfert' };
          row.data['Elu_ou_nomme'] = { id: 'E', displayValue: 'Elu' };
          const d = new Date().getFullYear();
          const newDate = moment(new Date().setFullYear(d + 2))
            .format('YYYY-MM-DDTHH:mm:ss.SSS')
            .substring(0, 26);
          row.data[debut ? 'Debut_Date_Mandat' : 'Fin_Date_Mandat'] = newDate;
          this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.redrawRows({ rowNodes: [row] });
          return false;
        }
      });
    }
  }

  closePanel() {
    this.gridViews[0].overlayVisible = false;
  }
  onSearch(e) {
    this.gridViews[0].overlayVisible = e.overlayVisible;
    this.gridViews[0].label = e.label;
    this.gridViews[0].isLabelDisplay = e.isLabelDisplay;
    this.gridViews[0].scrollHeight = e.scrollHeight;
    this.gridViews[0].suggestions = e.suggestions;
    this.gridViews[0].field = e.field;
    this.gridViews[0].itemTemplate = e.itemTemplate;
    this.gridViews[0].noResults = e.noResults;
    this.gridViews[0].emptyMessage = e.emptyMessage;
    this.gridViews[0].setPosition(e.top, e.left, e.width);
    this.gridViews[0].linkAutocompleteRenderer = e.linkAutocomplete;
  }

  onCalendar(e) {
    this.gridViews[0].panelStyleClass = e.panelStyleClass;
    this.gridViews[0].panelStyle = e.panelStyle;
    this.gridViews[0].inline = e.inline;
    if (!this.gridViews[0].overlayCalendarVisible) {
      this.gridViews[0].addCloseListener();
    }

    this.gridViews[0].overlayCalendarVisible = e.overlayCalendarVisible;
    this.gridViews[0].months = e.months;
    this.gridViews[0].numberOfMonths = e.numberOfMonths;
    this.gridViews[0].monthNavigator = e.monthNavigator;
    this.gridViews[0].view = e.view;
    this.gridViews[0].locale = e.locale;
    this.gridViews[0].yearNavigator = e.yearNavigator;
    this.gridViews[0].yearOptions = e.yearOptions;
    this.gridViews[0].currentYear = e.currentYear;
    this.gridViews[0].weekDays = e.weekDays;
    this.gridViews[0].showOtherMonths = e.showOtherMonths;
    this.gridViews[0].dateTemplate = e.dateTemplate;
    this.gridViews[0].monthPickerValues = e.monthPickerValues;
    this.gridViews[0].linkCalendarRenderer = e.linkCalendarRenderer;
    this.gridViews[0].currentMonth = e.currentMonth;
    this.gridViews[0].setPosition(e.top, e.left + e.width - 240);
  }

  notifyFromRowSelected(newAnim: any) {
    // TODO NEED PING PONG PROCESS
  }

  processCreateNewAff() {
    this.updateModalDisplay(
      {
        key: 'displayFormUI',
        value: true,
        sourceComponent: { columnName: 'unitManagement' }
      },
      { key: 'formId', value: this.partnerFormId }
    );
    const obs = this.ppService.watchProcessInProgress().subscribe((pings) => {
      const me = this.connectorService.getIupicsUserAccount();
      const ctx = this.connectorService.getIupicsUserContext();
      const ping = pings.find((p) => {
        return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === this.newAnim_Process_ID&&p.Status === 'finish';
      });
      if (ping) {
        // this.socketService.closeDataChannel(this.channel_id);
        obs.unsubscribe();
        this.updateLoading(false);
        this.onEndProcess(ping);
      }
    });
  }

  updateModalDisplay(display: { key: string; value: boolean; sourceComponent?: any }, id?: { key: string; value: number }) {
    if (display.sourceComponent && display.sourceComponent.columnName) {
      this.modalClass = 'ui-g-10 ui-md-6 ui-lg-4';
    } else {
      this.modalClass = 'ui-g-10 ui-md-10 ui-lg-10';
    }
    this.customFormModalBodyCss =
      display.key && display.key === 'displayFormUI'
        ? { 'iu-modal-body': { overflow: 'auto', height: 'calc(100% - 25px)' } }
        : undefined;
    this[display.key] = display.value;
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(
          id.value,
          display.sourceComponent && display.sourceComponent.itemData ? display.sourceComponent.itemData : display.sourceComponent
        );
      }
    } else {
      this.customFormModalBodyCss = undefined;
    }
  }

  processExecuteModif() {
    const verif = this.checkBeforeProcessing();
    if (verif) {
      this.rows = [];
      this.processService.getProcess(this.transfert_Process_ID).subscribe((success) => {
        if (success) {
          let paramsMap: ProcessParams = {
            ad_process_id: null,
            className: null,
            record_id: null,
            tableName: null,
            tables: null,
            params: null,
            windowCtx: this.getCurrentContext(),
            ad_tab_id: null
          };
          const classname = success.process.ClassName;
          const processId = success.process.AD_Process_ID;

          const tableName = this.isModal ? this.parentComponent.editTabs[0].data.TableName : null;
          const rowData = [];
          this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.forEachNode((node) => {
            Object.keys(node.data).forEach((key) => {
              node.data[key] =
                node.data[key] && node.data[key].id !== null && node.data[key].id !== undefined
                  ? node.data[key].id
                  : node.data[key];
            });
            rowData.push(node.data);
          });
          let errMsg = false;

          const BreakException = {};
          try {
            rowData.forEach((line) => {
              if (
                line['Action'] &&
                ((line['Action'] === 'A' && line['TargetFonction'] !== null && line['TargetFonction'] !== undefined) ||
                  (line['Action'] === 'S' && line['Fin_Date_Mandat'] !== null) ||
                  (line['Action'] === 'S' && line['Debut_Date_Mandat'] !== null) ||
                  (line['Action'] === 'T' && line['TargetFonction'] !== null && line['TargetFonction'] !== undefined))
              ) {
                const row = {
                  record_ID: line['Z_BP_Link_Affiliation_ID'],
                  columns: line
                };
                this.rows.push(row);
              } else if (
                (line['Action'] === 'A' && line['TargetFonction'] === null) ||
                (line['Action'] === 'S' && line['Fin_Date_Mandat'] === null) ||
                (line['Action'] === 'S' && line['Debut_Date_Mandat'] === null) ||
                (line['Action'] === 'T' && line['TargetFonction'] === null)
              ) {
                errMsg = true;
                throw BreakException;
              }
            });
          } catch (e) {
            if (e !== BreakException) {
              throw e;
            }
          }
          if (this.rows.length > 0 && !errMsg) {
            this.tablesForm.push({
              ad_FormDetail_ID: this.dataStore.data.selections[0].AD_FormDetail_ID,
              rows: this.rows
            });
            paramsMap = {
              record_id: null,
              ad_process_id: processId,
              params: {},
              className: classname,
              tableName: tableName,
              tables: this.tablesForm,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };

            this.filters.forEach((filter) => {
              Object.keys(this.dataStore.data).forEach((key) => {
                if (filter === key) {
                  if (
                    this.dataStore.data[filter] !== undefined &&
                    this.dataStore.data[filter] !== null &&
                    this.dataStore.data[filter].id !== undefined
                  ) {
                    paramsMap.params[filter] = this.dataStore.data[filter].id;
                  } else if (
                    this.dataStore.data !== undefined &&
                    this.dataStore.data[filter] !== null &&
                    this.dataStore.data[filter] !== undefined
                  ) {
                    paramsMap.params[filter] = this.dataStore.data[filter];
                  } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                    paramsMap.params[filter] = null;
                  }
                }
              });
            });
            const button = this.getDatacontainer('ExecuteModif');
            if (button) {
              button.isReadOnly = true;
            }
            const obs = this.uiCreator.executeProcess(paramsMap);
            this.subscriptions.push(
              obs.subscribe((instance) => {
                this.updateLoading(true);
                const pg = this.ppService.watchProcessInProgress().subscribe((pings) => {
                  const me = this.connectorService.getIupicsUserAccount();
                  const ctx = this.connectorService.getIupicsUserContext();
                  const ping = pings.find((p) => {
                    return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === processId&&p.Status === 'finish';
                  });
                  if (ping) {
                    // this.socketService.closeDataChannel(this.channel_id);
                    this.gridViews[0].GridTabInfinityScrollUiComponent.tempDataItems = [];
                    const button = this.getDatacontainer('ExecuteModif');
                    if (button) {
                      button.isReadOnly = false;
                    }
                    pg.unsubscribe();
                    this.updateLoading(false);
                    this.onEndProcess(ping);
                  }
                });
              })
            );

            this.tablesForm.pop();
          } else if (errMsg) {
            this.messageManager.newMessage(new IupicsMessage('error', this.translateService.instant('unitManagement.errMsg')));
          } else {
            this.messageManager.newMessage(new IupicsMessage('error', this.translateService.instant('unitManagement.error')));
          }
        }
      });
    }
  }
}
