import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { AutocompleteRendererComponent } from '../renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from '../renderer/button-renderer.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-post-rejected',
  templateUrl: './post-rejected.component.html',
  styleUrls: ['./post-rejected.component.scss']
})
export class PostRejectedComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('bottom', { read: ViewContainerRef, static: true })
  vcrBottom: ViewContainerRef;
  @ViewChild('table', { read: ViewContainerRef, static: true })
  table: ViewContainerRef;

  frameworkComponents: any;
  public modules: Module[] = AllModules;
  tableColumnDefs: any[];
  tableRowData: any[];
  tableFormId: any;

  processId: number;
  maxRow: number;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    pp: ProcessInProgressService
  ) {
    super(windowFactory, resolver, uiCreator, store, processService, socketService, connectorService, pp, translateService);
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent
    };
  }

  ngOnInit() {
    this.tableRowData = [];
    this.uiCreator.getSpecificWindow(this.formId).subscribe(specificWindow => {
      specificWindow.items.forEach(item => {
        switch (item.data.columnName) {
          case 'C_BPartner_ID':
            this.customDesignArray.push({
              isLabelDisplay: false,
              vcr: 'vcrTop',
              type: CustomDesignItemType.FIELD,
              columnName: 'C_BPartner_ID',
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-g-4',
              events: {
                keyup: this.keyUp.bind(this)
              }
            });
            break;

          case 'Execute':
            this.customDesignArray.push({
              vcr: 'vcrTop',
              type: CustomDesignItemType.FIELD,
              columnName: 'Execute',
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-g-2 ui-g-offset-6',
              events: {
                click: this.fillTable.bind(this)
              }
            });
            break;

          case 'MarkBPartnersInRefusalPost':
            this.processId = item.processId;
            this.customDesignArray.push({
              vcr: 'vcrBottom',
              type: CustomDesignItemType.FIELD,
              columnName: 'MarkBPartnersInRefusalPost',
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-g-4 ui-g-offset-8',
              events: {
                click: this.lunchProcess.bind(this)
              }
            });
            break;

          default:
            item.data.columnsTableHeader.forEach(header => {
              header.field = header.field.toUpperCase();
            });
            this.tableColumnDefs = item.data.columnsTableHeader;
            this.tableFormId = item.data.AD_FormDetail_ID;
        }
      });

      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'ui-g-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };

      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });

      // max row data
      const request: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'AD_MESSAGE',
          filterModel: {
            VALUE: {
              filterType: CompiereDataGridFilterType.SET,
              values: ['SCOUTS_COUNT_REJECTED_POST'],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };
      this.store.getDataGrid(request).subscribe(response => {
        if (response.data.length > 0) {
          this.maxRow = response.data[0]['MSGTEXT'];
        }
      });
    });
  }

  private fillTable() {
    // const value = this.dataStore.data['C_BPartner_ID'];
    const value = this.dataContainers[0].fieldValue;
    if (value === null || !value.match('0*([1-9][0-9]{6}|[1-9][0-9]{7})')) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('postRejected.invalid_code_message'),
          'error'
        )
      );
    } else if (this.tableRowData.length === this.maxRow) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('postRejected.count_rejected_message'),
          'error'
        )
      );
    } else {
      const request: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'C_BPARTNER_LIST_V',
          filterModel: {
            C_BPartner_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [value],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };
      this.store.getDataGrid(request).subscribe(response => {
        if (response.data.length > 0 && this.checkIfItemNotExist(response.data[0])) {
          this.tableRowData = this.tableRowData.concat(response.data);

          const currentRow = this.tableRowData;

          currentRow.forEach(item => {
            item['Data_UUID'] = `C_BPARTNER_ID,${item['C_BPARTNER_ID']}`;
          });

          this.dataStore.data['selections'] = [
            {
              AD_FormDetail_ID: this.tableFormId,
              selection: currentRow
            }
          ];
        }
      });
    }
    this.setDatacontainerValue('C_BPartner_ID', '');
  }

  private keyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.dataContainers[0].fieldValue = this.dataContainers[0].fieldValue.slice(0, -1);
      this.fillTable();
    }
  }

  private checkIfItemNotExist(item: any): boolean {
    return this.tableRowData.filter(row => row['C_BPARTNER_ID'] === item['C_BPARTNER_ID']).length === 0;
  }

  private lunchProcess() {
    this.executeProcess(this.processId);
    this.tableRowData = [];
  }
}
