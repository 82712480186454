import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'iu-comment-ui',
  templateUrl: './comment-ui.component.html',
  styleUrls: ['./comment-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommentUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    renderer: Renderer2,
    protected po: PoService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
  }
  @Input()
  cssGrid: string;
  @Input()
  placeHolder: string;
  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;
  dataContainers: AbstractDataContainerCallout;

  @Output() fieldValueChange = new EventEmitter<any>();
  @Output() enterKey = new EventEmitter<any>();

  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
  }
}
