import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { SelectOrderComponent } from '@iupics-components/specific/window/select-order/select-order.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { cloneDeep } from 'lodash';
import { EditTabUiComponent } from '../../edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '../edit-view-ui.component';

export class EditViewUtils {
  /**
   *
   * @param {string}displayLogic
   * @param {Map<string, any>}dataMap
   * @returns {boolean} isDisplay
   */
  public static isTabDisplay(displayLogic: string, dataMap: Map<string, any>): boolean {
    if (displayLogic) {
      return LogicEvaluator.evaluateLogic(dataMap, displayLogic);
    }
    return true;
  }

  /**
   * Find the index of the current tabId in the container
   * @param {any}container
   * @param {number}tabId
   * @returns {number} index
   */
  public static findMyIndex(container: any, tabId: number): number {
    let find = false;
    let myIndex = -1;
    let i = 1;
    while (!find && container.DOMChildrenComponent.length > i) {
      if (container.DOMChildrenComponent[i].tabId === tabId) {
        myIndex = i;
        find = true;
      }
      i++;
    }
    return myIndex;
  }

  /**
   *
   * @param {string}value
   */
  public static copyToClipboard(value: string) {
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    // Move outside the screen to make it invisible
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    // Check if there is any content selected previously, Store selection if found, Mark as false to know no selection existed before
    const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
    // Select the <textarea> content
    el.select();
    // Copy - only works as a result of a user action (e.g. click events)
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }

  /**
   *
   * @param {EditTabUiComponent}tab
   * @param {DataStore}dataStore
   * @param {any}container
   * @param {number}tabId
   */
  public static updateEditViewData(tab: EditTabUiComponent, dataStore: DataStore, container: any, tabId: number) {
    if (tab.DOMChildrenComponent[0] instanceof SelectOrderComponent) {
      (<SelectOrderComponent>tab.DOMChildrenComponent[0]).init();
    } else {
      tab.updateAllLogic(dataStore);
      if (tab.isDisplay === 'inline') {
        if (
          container.DOMChildrenComponent[EditViewUtils.findMyIndex(container, tabId) + 1] &&
          tab.tabId ===
            container.DOMChildrenComponent[EditViewUtils.findMyIndex(container, tabId) + 1].DOMComponent.instance.tabId
        ) {
          (<GridViewUiComponent>(
            tab.DOMChildrenComponent[0].DOMComponent.instance
          )).GridTabInfinityScrollUiComponent.createServerDataSource(
            dataStore.data,
            EditViewUtils.findMyIndex(container, tabId) + 1
          );
        } else {
          if (
            (<GridViewUiComponent>tab.DOMChildrenComponent[0].DOMComponent.instance).GridTabInfinityScrollUiComponent !==
            undefined
          ) {
            (<GridViewUiComponent>(
              tab.DOMChildrenComponent[0].DOMComponent.instance
            )).GridTabInfinityScrollUiComponent.createServerDataSource(dataStore.data);
          }
        }
      }
    }
  }

  /**
   *
   * @param {EditTabUiComponent}tab
   * @param {DataStore}dataStore
   * @param {any}container
   * @param {number}tabId
   */
  public static checkDisplayTabCollapse(tab: EditTabUiComponent, dataMap: Map<string, any>, displayLogic) {
    if (tab.DOMChildrenComponent[0] instanceof SelectOrderComponent) {
      (<SelectOrderComponent>tab.DOMChildrenComponent[0]).init();
    } else {
      if (EditViewUtils.isTabDisplay(displayLogic, dataMap)) {
        tab.isDisplay = 'inline';
      } else {
        tab.isDisplay = 'none';
      }
    }
  }

  /**
   *
   * @param {any}container
   * @param {number}tabId
   * @param {EditTabUiComponent[]}editTabs
   */
  public static updateOtherEditView(container: any, tabId: number, editTabs: EditTabUiComponent[]) {
    if (container.DOMChildrenComponent[EditViewUtils.findMyIndex(container, tabId) + 1]) {
      const indexEditViewToEdit = EditViewUtils.findMyIndex(container, tabId) + 1;
      let find = false;
      let i = 1;
      while (!find && i < editTabs.length) {
        if (editTabs[i].tabId === container.DOMChildrenComponent[indexEditViewToEdit].DOMComponent.instance.tabId) {
          find = true;
        } else {
          i++;
        }
      }
      if (find && (editTabs[i].DOMComponent.instance.isDisplay === 'inline' || !editTabs[i].data.isCollapsable)) {
        container.DOMChildrenComponent[indexEditViewToEdit].DOMComponent.instance.noData = false;
      } else if (!container.DOMChildrenComponent[indexEditViewToEdit].DOMComponent.instance.isZoomEditView) {
        container.DOMChildrenComponent[indexEditViewToEdit].DOMComponent.instance.removeComponent();
      }
    }
  }

  /**
   *
   * @param {string}logic
   * @param {Map<string, any>}dataMap
   * @returns {boolean}
   */
  public static checkLogic(logic: string, dataMap: Map<string, any>): boolean {
    if (logic) {
      return LogicEvaluator.evaluateLogic(dataMap, logic);
    }
    return false;
  }
  /**
   *
   * @param edit EditView sur lequel on veut récupérer la clé du parent
   */
  public static getParentDatastoreKey(edit: EditViewUiComponent) {
    if (edit) {
      const gridElement = <GridViewUiComponent>edit.linkedComponents[0];
      return gridElement ? (gridElement.editViewParent ? gridElement.editViewParent.currentDataStoreKey : undefined) : undefined;
    } else {
      return undefined;
    }
  }

  /**
   *
   * @param editTab EditTab sur lequel on veut récupérer la clé du parent
   */
  public static getParentDatastoreKeyFromTab(editTab: EditTabUiComponent) {
    if (editTab) {
      const gridElement = <GridViewUiComponent>editTab.editViewParent.linkedComponents[0];
      return gridElement ? (gridElement.editViewParent ? gridElement.editViewParent.currentDataStoreKey : undefined) : undefined;
    } else {
      return undefined;
    }
  }

  /**
   *
   * @param grid GridView sur lequel on veut récupérer la clé du parent
   */
  public static getParentDatastoreKeyFromGrid(grid: GridViewUiComponent) {
    if (grid) {
      return grid.editViewParent ? grid.editViewParent.currentDataStoreKey : undefined;
    } else {
      return undefined;
    }
  }

  /**
   * Permet de merge les dataToAdd avec le currentData
   * @param childData data du store sur lequel on veut ajouter des data
   * @param parentData data du store que l'on veut ajouter
   * @param isDefaultValue va servir pour overrider la valeur du parent même si celui dans l'enfant est undefined (Exemple: Description sur C_Order et C_Orderline)
   */
  public static mergeCurrentDataDeepCopy(childData, parentData, overrideChild = true) {
    if (parentData) {
      Object.keys(parentData)
        .filter((key) =>
          overrideChild
            ? parentData[key] !== undefined &&
              parentData[key] !== null &&
              (!childData.hasOwnProperty(key) || childData[key] === undefined || childData[key] === null)
            : !childData.hasOwnProperty(key)
        )
        .forEach((key) => {
          try {
            childData[key] = cloneDeep(parentData[key]);
          } catch (error) {
            childData[key] = undefined;
          }
        });
    }
    // Suppression apiz_dataResult inutile dans le contexte
    if (childData && childData.hasOwnProperty('apiz_dataResult')) {
      delete childData['apiz_dataResult'];
    }
    if (childData && childData.hasOwnProperty('apiz_ctxChanged')) {
      delete childData['apiz_ctxChanged'];
    }
    return childData;
  }

  public static getCurrentContext(
    editView?: EditViewUiComponent,
    store?: DataStore,
    userContext?: any,
    overrideChild = true
  ): any {
    let parentStoreData = {};
    let currentParent = editView;
    if (currentParent && currentParent.editTabs && currentParent.editTabs[0].dataStored.data) {
      /*on initialise le contexte avec le store du premier parent */
      parentStoreData = EditViewUtils.mergeCurrentDataDeepCopy(
        parentStoreData,
        currentParent.editTabs[0].dataStored.data,
        overrideChild
      );
    }
    if (currentParent && currentParent.isZoomEditView) {
      while (currentParent && currentParent.isZoomEditView) {
        if (
          currentParent.linkedComponents &&
          currentParent.linkedComponents[0] &&
          currentParent.linkedComponents[0].editViewParent &&
          currentParent.linkedComponents[0].editViewParent.isZoomEditView &&
          currentParent.linkedComponents[0].editViewParent.editTabs[0].dataStored.data
        ) {
          /*on merge le store parent courant avec le contexte */
          parentStoreData = EditViewUtils.mergeCurrentDataDeepCopy(
            parentStoreData,
            currentParent.linkedComponents[0].editViewParent.editTabs[0].dataStored.data,
            overrideChild
          );
          currentParent = currentParent.linkedComponents[0].editViewParent;
        } else {
          currentParent = null;
        }
      }
    } else {
      while (currentParent) {
        if (
          currentParent.linkedComponents &&
          currentParent.linkedComponents[0] &&
          currentParent.linkedComponents[0].editViewParent &&
          currentParent.linkedComponents[0].editViewParent.editTabs[0].dataStored.data
        ) {
          if (currentParent?.isZoomEditView) {
            currentParent = null;
          } else {
            /*on merge le store parent courant avec le contexte */
            parentStoreData = EditViewUtils.mergeCurrentDataDeepCopy(
              parentStoreData,
              currentParent.linkedComponents[0].editViewParent.editTabs[0].dataStored.data,
              overrideChild
            );
            currentParent = currentParent.linkedComponents[0].editViewParent;
          }
        } else {
          currentParent = null;
        }
      }
    }

    let data = {};
    /*On ajoute le contexte utilisateur */
    if (userContext) {
      const keys = Object.keys(userContext);
      keys
        .filter((key) => {
          if (key.includes('|') || key.startsWith('$') || key.startsWith('#')) {
            return true;
          }
        })
        .forEach((key) => {
          data[key] = cloneDeep(userContext[key]);
        });
    }
    /*on merge le contexte avec le store du composant concerné et le contexte utilisateur */
    let output = {};
    data = EditViewUtils.mergeCurrentDataDeepCopy(data, store ? store.data : {}, overrideChild);
    if (data['AD_Org_ID'] !== undefined && data['AD_Org_ID'] !== null) {
      data['#AD_Org_ID'] = data['AD_Org_ID'] ? data['AD_Org_ID'].id : parseInt(data['AD_Org_ID']);
    }
    output = EditViewUtils.mergeCurrentDataDeepCopy(data, cloneDeep(parentStoreData), overrideChild);

    // Suppression apiz_dataResult inutile dans le contexte
    if (data && data.hasOwnProperty('apiz_dataResult')) {
      delete data['apiz_dataResult'];
    }
    if (data && data.hasOwnProperty('apiz_ctxChanged')) {
      delete data['apiz_ctxChanged'];
    }
    return output;
  }
}
