<div class="iu-specific-window" [ngClass]="{ 'iu-specific-window-container': !isModal }">
    <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' }" [ngClass]="{ 'iu-modal': isModal }">
    <div class="ui-g-12">
      <div class="ui-g-12">
        <span class="specific-title">
          <b>{{ 'postRejected.label' | translate }}</b>
        </span>
      </div>

      <div class="ui-g-12">
        <ng-template #top></ng-template>
      </div>

      <div class="ui-g-12">
        <span class="specific-title" style="font-size: 20px">
          <b>{{ 'postRejected.tableTitle' | translate }}</b>
        </span>
        <ag-grid-angular
          style="width: 100%; height: 200px;margin-left: 6px"
          class="ag-theme-balham"
          [rowData]="tableRowData"
          [columnDefs]="tableColumnDefs"
          [modules]="modules"
        >
        </ag-grid-angular>
        <span class="specific-title">
          <small>
            <b>{{ tableRowData.length }} {{ 'postRejected.tableLabel' | translate }}</b>
          </small>
        </span>
      </div>

      <div class="ui-g-12">
        <ng-template #bottom class="ui-right"></ng-template>
      </div>
    </div>
  </p-scrollPanel>
</div>
