// Angular Modules
/*#region custo|
    AgmCoreModule|
    ReactiveFormsModule|
    InputPlaceAutocompleteUiComponent|
    StaffManagementComponent|
    MassComplemInsuranceComponent|
    InscriptionPostEvenementComponent|
    BpartnerBalanceComponent|
    InscriptionPostEvenementComponent|
    PaymentSelectionWindowUiComponent|
    EventSearchComponent|
    BpartnerSearchComponent|
    UnitManagementComponent|
    GpsUnitComponent|
    GenererAttestationFiscaleComponent|
    EthiasManagementComponent|
    PostRejectedComponent|
    InscribeWaitingListComponent|
    PreinscriptionCampComponent|
    CreatePartnerComponent|
    InscribeUiComponent|
    RemiseBuchettesMembreComponent|
    PassageMasseComponent|
    SuppressionFonctionErroneeComponent|
    LettrageEcrituresComptablesComponent|
    ParticipantManagementComponent|
    PriceHistoryComponent|
    CreateAffiliationComponent|
    TransfertListeAttenteProcessComponent|
    CreateUpdateOwnerComponent|
    GetMapInfoCampComponent|
    ReadCreateStepComponent|
    RemiseBuchettesAnfComponent|
    TransfertParticipantComponent|GpsProjectComponent*/
import { AgGridModule } from '@ag-grid-community/angular';
import { LicenseManager } from '@ag-grid-enterprise/all-modules';
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CompiereWsModule } from '@compiere-ws/compiere-ws.module';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { PrimeAutocompleteComponent } from '@iupics-components/overrided/prime-autocomplete/prime-autocomplete.component';
import { PrimeBreadcrumbComponent } from '@iupics-components/overrided/prime-breadcrumb/prime-breadcrumb.component';
// Project Imports
import { PrimeCalendarComponent } from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import { PrimeFileuploadComponent } from '@iupics-components/overrided/prime-fileupload/prime-fileupload.component';
import { PrimeTerminalComponent } from '@iupics-components/overrided/prime-terminal/prime-terminal.component';
import { PrimengModule } from '@iupics-components/primeng.module';
import { BpartnerBalanceComponent } from '@iupics-components/specific/window/bpartner-balance/bpartner-balance.component';
import { CreateFromStatementWindowUiComponent } from '@iupics-components/specific/window/create-from-statement-window-ui/create-from-statement-window-ui.component';
import { InfoDialogComponent } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { PriceHistoryComponent } from '@iupics-components/specific/window/price-history/price-history.component';
import { ProcessUiComponent } from '@iupics-components/specific/window/process-ui/process-ui.component';
import { SelectOrderComponent } from '@iupics-components/specific/window/select-order/select-order.component';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { ButtonUiComponent } from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { CalendarUiComponent } from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import { InputFileUiComponent } from '@iupics-components/standard/fields/input-file-ui/input-file-ui.component';
import { InputImageUiComponent } from '@iupics-components/standard/fields/input-image-ui/input-image-ui.component';
import { InputLocationUiComponent } from '@iupics-components/standard/fields/input-location-ui/input-location-ui.component';
import { InputNumberUiComponent } from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import { InputProductAttributesUiComponent } from '@iupics-components/standard/fields/input-product-attributes-ui/input-product-attributes-ui.component';
import { InputSwitchUiComponent } from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { InputTextareaUiComponent } from '@iupics-components/standard/fields/input-textarea-ui/input-textarea-ui.component';
import { InputTimeUiComponent } from '@iupics-components/standard/fields/input-time-ui/input-time-ui.component';
import { GridTabInfinityScrollUiComponent } from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { TreeUiComponent } from '@iupics-components/standard/grid/tree-ui/tree-ui.component';
import { AccordionUiComponent } from '@iupics-components/standard/layouts/accordion-ui/accordion-ui.component';
import { BladeUiComponent } from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { GridUiComponent } from '@iupics-components/standard/layouts/grid-ui/grid-ui.component';
import { RowUiComponent } from '@iupics-components/standard/layouts/row-ui/row-ui.component';
import { MenuBarDetailUiComponent } from '@iupics-components/standard/menu/menu-bar-detail-ui/menu-bar-detail-ui.component';
import { MenuBarUiComponent } from '@iupics-components/standard/menu/menu-bar-ui/menu-bar-ui.component';
import { IupicsManagerModule } from '@iupics-manager/iupics-manager.module';
import { IupicsCookieService } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { IupicsUtilModule } from '@iupics-util/iupics-util.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { AngularSplitModule } from 'angular-split';
// Misc Imports
import { CookieService } from 'ngx-cookie-service';
import { ObjectUtils } from 'primeng/utils';
import { KeybindingDirective } from './directives/keybinding.directive';
import { MaterialModule } from './material.module';
import { BannerComponent } from './message/banner/banner.component';
import { NotificationComponent } from './message/notification/notification.component';
import { ProcessLogsUiComponent } from './message/process-logs-ui/process-logs-ui.component';
import { PrimeChipsComponent } from './overrided/prime-chips/prime-chips.component';
import {
  PrimeContextMenuComponent,
  PrimeContextMenuSubComponent
} from './overrided/prime-contextmenu/prime-contextmenu.component';
import { PrimeFieldsetComponent } from './overrided/prime-fieldset/prime-fieldset.component';
import { GetPercentInPxCssPipe } from './overrided/prime-fileupload/get-percent-in-px-css.pipe';
import { PrimeOverlayComponent } from './overrided/prime-overlay/prime-overlay.component';
import { PrimeStepsComponent } from './overrided/prime-steps/prime-steps.component';
import { ColumnGroupFilterPipe } from './pipes/column-group-filter/column-group-filter.pipe';
import { FilterListPipe } from './pipes/filter-list/filter-list.pipe';
import { OperatorFilterPipe } from './pipes/operator-filter/operator-filter.pipe';
import { ParseInstanceParamsPipe } from './pipes/parse-instance-params/parse-instance-params.pipe';
import { PluralizePipe } from './pipes/pluralize/pluralize.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml/safeHtml.pipe';
import { SpreadOperatorPipe } from './pipes/spread-operator/spread-operator.pipe';
import { UniversalFilterColFilterTypePipe } from './pipes/universal-filter-col-filter-type/universal-filter-col-filter-type.pipe';
import { AccountEditorWindowUiComponent } from './specific/window/account-editor-window-ui/account-editor-window-ui.component';
import { AccountingViewerUiComponent } from './specific/window/accounting-viewer-ui/accounting-viewer-ui.component';
import { AccountingViewerWindowUiComponent } from './specific/window/accounting-viewer-window-ui/accounting-viewer-window-ui.component';
import { BpartnerSearchComponent } from './specific/window/bpartner-search/bpartner-search.component';
import { NumberEditorComponent } from './specific/window/cell-editor/number-editor.component';
import { ChangelogWindowUIComponent } from './specific/window/changelog-window-ui/changelog-window-ui.component';
import { CreateAffiliationComponent } from './specific/window/create-affiliation/create-affiliation.component';
import { CreateFromInvoiceWindowUiComponent } from './specific/window/create-from-invoice-window-ui/create-from-invoice-window-ui.component';
import { CreateFromOpenInvoiceSTComponent } from './specific/window/create-from-open-invoice-st/create-from-open-invoice-st.component';
import { CreateFromOpenInvoiceComponent } from './specific/window/create-from-open-invoice/create-from-open-invoice.component';
import { CreateFromShipmentWindowUiComponent } from './specific/window/create-from-shipment-window-ui/create-from-shipment-window-ui.component';
import { CreatePartnerComponent } from './specific/window/create-partner/create-partner.component';
import { CreateUpdateOwnerComponent } from './specific/window/create-update-owner/create-update-owner.component';
import { CruiseSituationUiComponent } from './specific/window/cruise-situation-ui/cruise-situation-ui.component';
import { DirectPrintingDocumentsComponent } from './specific/window/direct-printing-documents/direct-printing-documents.component';
import { EmailEditorUiComponent } from './specific/window/email-editor-ui/email-editor-ui.component';
import { EthiasManagementComponent } from './specific/window/ethias-management/ethias-management.component';
import { EventSearchComponent } from './specific/window/event-search/event-search.component';
import { ExportDataUiComponent } from './specific/window/export-data-ui/export-data-ui.component';
import { FastCreateOrderComponent } from './specific/window/fast-create-order/fast-create-order.component';
import { GenererAttestationFiscaleComponent } from './specific/window/generer-attestation-fiscale/generer-attestation-fiscale.component';
import { GetMapInfoCampComponent } from './specific/window/get-map-info-camp/get-map-info-camp.component';
import { GpsUnitComponent } from './specific/window/gps-unit/gps-unit.component';
import { ImportFileLoaderWindowUiComponent } from './specific/window/import-file-loader-window-ui/import-file-loader-window-ui.component';
import { InscribeUiComponent } from './specific/window/inscribe-ui/inscribe-ui.component';
import { InscribeWaitingListComponent } from './specific/window/inscribe-waiting-list/inscribe-waiting-list.component';
import { InscriptionPostEvenementComponent } from './specific/window/inscription-post-evenement/inscription-post-evenement.component';
import { InvoiceMatchingWindowImplComponent } from './specific/window/invoice-matching-window-impl/invoice-matching-window-impl.component';
import { JoinFileUiComponent } from './specific/window/join-file-ui/join-file-ui.component';
import { LettrageEcrituresComptablesComponent } from './specific/window/lettrage-ecritures-comptables/lettrage-ecritures-comptables.component';
import { LocationPanelComponent } from './specific/window/location-panel/location-panel.component';
import { LoginasFormComponent } from './specific/window/loginas-form/loginas-form.component';
import { LoginasUiComponent } from './specific/window/loginas-ui/loginas-ui.component';
import { MassComplemInsuranceComponent } from './specific/window/mass-complem-insurance/mass-complem-insurance.component';
import { MergetoolComponent } from './specific/window/mergetool/mergetool.component';
import { OperationManagementUIComponent } from './specific/window/operation-management-ui/operation-management-ui.component';
import { ParticipantManagementComponent } from './specific/window/participant-management/participant-management.component';
import { PassageMasseComponent } from './specific/window/passage-masse/passage-masse.component';
import { PaymentAllocationWindowUiComponent } from './specific/window/payment-allocation-window-ui/payment-allocation-window-ui.component';
import { PaymentSelectionWindowUiComponent } from './specific/window/payment-selection-window-ui/payment-selection-window-ui.component';
import { PlanningWindowUiComponent } from './specific/window/planning-window-ui/planning-window-ui.component';
import { PosUiComponent } from './specific/window/pos-ui/pos-ui.component';
import { PostRejectedComponent } from './specific/window/post-rejected/post-rejected.component';
import { PreinscriptionCampComponent } from './specific/window/preinscription-camp/preinscription-camp.component';
import { ProcessInProgressUiComponent } from './specific/window/process-in-progress-ui/process-in-progress-ui.component';
import { ProcessInProgressWindowUiComponent } from './specific/window/process-in-progress-window-ui/process-in-progress-window-ui.component';
import { ProductAttributeComponent } from './specific/window/product-attribute/product-attribute.component';
import { ProductViewComponent } from './specific/window/product-view/product-view.component';
import { ReadCreateStepComponent } from './specific/window/read-create-step/read-create-step.component';
import { RemiseBuchettesAnfComponent } from './specific/window/remise-buchettes-anf/remise-buchettes-anf.component';
import { RemiseBuchettesMembreComponent } from './specific/window/remise-buchettes-membre/remise-buchettes-membre.component';
import { AutocompleteRendererComponent } from './specific/window/renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from './specific/window/renderer/button-renderer.component';
import { CalendarRendererComponent } from './specific/window/renderer/calendar-renderer.component';
import { NumberRendererComponent } from './specific/window/renderer/number-renderer.component';
import { StaffManagementComponent } from './specific/window/staff-management/staff-management.component';
import { SuppressionFonctionErroneeComponent } from './specific/window/suppression-fonction-erronee/suppression-fonction-erronee.component';
import { TransfertListeAttenteProcessComponent } from './specific/window/transfert-liste-attente-process/transfert-liste-attente-process.component';
import { TransfertParticipantComponent } from './specific/window/transfert-participant/transfert-participant.component';
import { TreeMaintenanceUIComponent } from './specific/window/tree-maintenance-ui/tree-maintenance-ui.component';
import { UnitManagementComponent } from './specific/window/unit-management/unit-management.component';
import { CommentUiComponent } from './standard/fields/comment-ui/comment-ui.component';
import { InputChipsUiComponent } from './standard/fields/input-chips-ui/input-chips-ui.component';
import { InputPlaceAutocompleteUiComponent } from './standard/fields/input-place-autocomplete-ui/input-place-autocomplete-ui.component';
import { SmartButtonUiComponent } from './standard/fields/smart-button-ui/smart-button-ui.component';
import { StepperUiComponent } from './standard/fields/stepper-ui/stepper-ui.component';
import { CalendarToolbarUiComponent } from './standard/grid/calendar/calendar-toolbar-ui/calendar-toolbar-ui.component';
import { CalendarViewUiComponent } from './standard/grid/calendar/calendar-view-ui/calendar-view-ui.component';
import { ChartUiComponent } from './standard/grid/chart-ui/chart-ui.component';
import { GanttComponent } from './standard/grid/gantt/gantt.component';
import { GridTabRowdataComponent } from './standard/grid/grid-tab-rowdata/grid-tab-rowdata.component';
import { KanbanBoardUiComponent } from './standard/grid/kanban/kanban-board-ui/kanban-board-ui.component';
import { KanbanCardUiComponent } from './standard/grid/kanban/kanban-card-ui/kanban-card-ui.component';
import { KanbanUiComponent } from './standard/grid/kanban/kanban-ui/kanban-ui.component';
import { PreferencesToolPanelComponent } from './standard/grid/preferences-tool-panel/preferences-tool-panel.component';
import { SelectAllHeaderComponent } from './standard/grid/select-all-header/select-all-header.component';
import { StatusBarCountComponent } from './standard/grid/status-bar-count/status-bar-count.component';
import { AdditionalInfoUiComponent } from './standard/layouts/additional-info-ui/additional-info-ui.component';
import { ModalUiComponent } from './standard/layouts/modal-ui/modal-ui.component';
import { MenuSmartbuttonsUiComponent } from './standard/menu/menu-smartbuttons-ui/menu-smartbuttons-ui.component';
import { UniversalFilterUiComponent } from './standard/menu/universal-filter-ui/universal-filter-ui.component';
import { PreviewDocComponent } from './standard/preview-doc/preview-doc.component';
import { ValuePreferencePanelComponent } from './standard/value-preference-panel/value-preference-panel.component';
import { OneManagementComponent } from './specific/window/one-management/one-management.component';
import { SubscriptionManagementComponent } from './specific/window/subscription-management/subscription-management.component';
import { SimpleFileUploadComponent } from './specific/window/simple-file-upload/simple-file-upload.component';
import { GpsProjectComponent } from './specific/window/gps-project/gps-project.component';
//#endregion custo
LicenseManager.setLicenseKey(
  'Audaxis_SA_Audaxis_Single_Application_4_Devs__28_November_2020_[v2]_MTYwNjUyMTYwMDAwMA==212359e37f18bfbeac07f8ba1e7a1d35'
);

@NgModule({
  imports: [
    CompiereWsModule,
    CommonModule,
    PrimengModule,
    BrowserAnimationsModule,
    BrowserModule,
    AgGridModule.withComponents([
      ButtonRendererComponent,
      AutocompleteRendererComponent,
      NumberRendererComponent,
      CalendarRendererComponent,
      NumberEditorComponent,
      SelectAllHeaderComponent,
      StatusBarCountComponent,
      PreferencesToolPanelComponent
    ]),
    TranslateModule,
    IupicsUtilModule,
    IupicsManagerModule,
    MaterialModule,
    AngularSplitModule,
    //#region custo
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAxktpVG6jXdEd-uUJzX8VwpUKnVorSfR4',
      libraries: ['places']
    })
    //#endregion custo
  ],
  exports: [
    PrimengModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    AutocompleteUiComponent,
    InputNumberUiComponent,
    InputTextUiComponent,
    InputSwitchUiComponent,
    TranslateModule,
    InfoDialogComponent,
    InputTimeUiComponent,
    InputImageUiComponent,
    InputFileUiComponent,
    InputLocationUiComponent,
    InputProductAttributesUiComponent,
    SpecificWindowUiComponent,
    MenuBarDetailUiComponent,
    MenuBarUiComponent,
    ButtonUiComponent,
    CalendarUiComponent,
    InputTextareaUiComponent,
    StepperUiComponent,
    AccordionUiComponent,
    EditTabUiComponent,
    GridTabInfinityScrollUiComponent,
    GridViewUiComponent,
    ProcessUiComponent,
    MergetoolComponent,
    KanbanUiComponent,
    KanbanBoardUiComponent,
    KanbanCardUiComponent,
    LocationPanelComponent,
    LocationPanelComponent,
    BannerComponent,
    NotificationComponent,
    BladeUiComponent,
    PrimeBreadcrumbComponent,
    CalendarViewUiComponent,
    CalendarToolbarUiComponent,
    ChartUiComponent,
    PrimeFieldsetComponent,
    SelectOrderComponent,
    IupicsUtilModule,
    IupicsManagerModule,
    AdditionalInfoUiComponent,
    MenuSmartbuttonsUiComponent,
    SmartButtonUiComponent,
    PrimeStepsComponent,
    EmailEditorUiComponent,
    InputChipsUiComponent,
    AccountingViewerUiComponent,
    AccountingViewerWindowUiComponent,
    BpartnerBalanceComponent,
    PriceHistoryComponent,
    KeybindingDirective,
    MaterialModule,
    JoinFileUiComponent,
    ModalUiComponent,
    PrimeChipsComponent,
    PrimeContextMenuSubComponent,
    PrimeContextMenuComponent,
    AccountEditorWindowUiComponent,
    InvoiceMatchingWindowImplComponent,
    CreateFromShipmentWindowUiComponent,
    CreateFromInvoiceWindowUiComponent,
    CreateFromStatementWindowUiComponent,
    PaymentAllocationWindowUiComponent,
    PaymentSelectionWindowUiComponent,
    LoginasUiComponent,
    LoginasFormComponent,
    FastCreateOrderComponent,
    DirectPrintingDocumentsComponent,
    PosUiComponent,
    ImportFileLoaderWindowUiComponent,
    GridTabRowdataComponent,
    ProcessInProgressUiComponent,
    ProcessInProgressWindowUiComponent,
    ProductAttributeComponent,
    CreateFromOpenInvoiceComponent,
    SelectAllHeaderComponent,
    StatusBarCountComponent,
    CommentUiComponent,
    PreferencesToolPanelComponent,
    ValuePreferencePanelComponent,
    PreviewDocComponent,
    FilterListPipe,
    //#region custo
    InputPlaceAutocompleteUiComponent,
    StaffManagementComponent,
    MassComplemInsuranceComponent,
    InscriptionPostEvenementComponent,
    BpartnerBalanceComponent,
    InscriptionPostEvenementComponent,
    PaymentSelectionWindowUiComponent,
    EventSearchComponent,
    BpartnerSearchComponent,
    UnitManagementComponent,
    GpsUnitComponent,
    GenererAttestationFiscaleComponent,
    EthiasManagementComponent,
    PostRejectedComponent,
    InscribeWaitingListComponent,
    PreinscriptionCampComponent,
    CreatePartnerComponent,
    InscribeUiComponent,
    RemiseBuchettesMembreComponent,
    PassageMasseComponent,
    SuppressionFonctionErroneeComponent,
    LettrageEcrituresComptablesComponent,
    ParticipantManagementComponent,
    PriceHistoryComponent,
    CreateAffiliationComponent,
    TransfertListeAttenteProcessComponent,
    CreateUpdateOwnerComponent,
    GetMapInfoCampComponent,
    ReadCreateStepComponent,
    RemiseBuchettesAnfComponent,
    TransfertParticipantComponent,
    CreateFromOpenInvoiceSTComponent,
    OneManagementComponent,
    SubscriptionManagementComponent,
    SimpleFileUploadComponent, GpsProjectComponent
    //#endregion custo
  ],
  declarations: [
    InputNumberUiComponent,
    ProcessUiComponent,
    BladeUiComponent,
    GridViewUiComponent,
    MenuBarUiComponent,
    GridTabInfinityScrollUiComponent,
    EditViewUiComponent,
    MenuBarDetailUiComponent,
    GridUiComponent,
    StepperUiComponent,
    AutocompleteUiComponent,
    ButtonUiComponent,
    CalendarUiComponent,
    InputSwitchUiComponent,
    InputTextUiComponent,
    SpecificWindowUiComponent,
    InputTextareaUiComponent,
    EditTabUiComponent,
    AccordionUiComponent,
    RowUiComponent,
    PrimeBreadcrumbComponent,
    PrimeAutocompleteComponent,
    PrimeCalendarComponent,
    PrimeContextMenuSubComponent,
    PrimeContextMenuComponent,
    InfoDialogComponent,
    InputTimeUiComponent,
    InputImageUiComponent,
    InputFileUiComponent,
    PrimeFileuploadComponent,
    InputLocationUiComponent,
    InputProductAttributesUiComponent,
    PrimeTerminalComponent,
    TreeUiComponent,
    MergetoolComponent,
    KanbanUiComponent,
    KanbanBoardUiComponent,
    KanbanCardUiComponent,
    LocationPanelComponent,
    BannerComponent,
    NotificationComponent,
    CalendarViewUiComponent,
    CalendarToolbarUiComponent,
    ChartUiComponent,
    PrimeFieldsetComponent,
    SelectOrderComponent,
    AdditionalInfoUiComponent,
    MenuSmartbuttonsUiComponent,
    SmartButtonUiComponent,
    PrimeStepsComponent,
    EmailEditorUiComponent,
    InputChipsUiComponent,
    ExportDataUiComponent,
    KeybindingDirective,
    AccountingViewerUiComponent,
    AccountingViewerWindowUiComponent,
    BpartnerBalanceComponent,
    PriceHistoryComponent,
    JoinFileUiComponent,
    ModalUiComponent,
    UniversalFilterUiComponent,
    PrimeChipsComponent,
    AccountEditorWindowUiComponent,
    OperatorFilterPipe,
    InvoiceMatchingWindowImplComponent,
    CreateFromShipmentWindowUiComponent,
    CreateFromInvoiceWindowUiComponent,
    CreateFromStatementWindowUiComponent,
    PaymentAllocationWindowUiComponent,
    PaymentSelectionWindowUiComponent,
    UniversalFilterColFilterTypePipe,
    LoginasUiComponent,
    LoginasFormComponent,
    ProcessLogsUiComponent,
    FastCreateOrderComponent,
    DirectPrintingDocumentsComponent,
    CruiseSituationUiComponent,
    PosUiComponent,
    ImportFileLoaderWindowUiComponent,
    GridTabRowdataComponent,
    ButtonRendererComponent,
    AutocompleteRendererComponent,
    NumberRendererComponent,
    CalendarRendererComponent,
    NumberEditorComponent,
    ProcessInProgressUiComponent,
    ProcessInProgressWindowUiComponent,
    ProductAttributeComponent,
    ParseInstanceParamsPipe,
    CreateFromOpenInvoiceComponent,
    SelectAllHeaderComponent,
    StatusBarCountComponent,
    CommentUiComponent,
    ColumnGroupFilterPipe,
    OperationManagementUIComponent,
    ProductViewComponent,
    ChangelogWindowUIComponent,
    SpreadOperatorPipe,
    PreferencesToolPanelComponent,
    GetPercentInPxCssPipe,
    //#region custo
    InputPlaceAutocompleteUiComponent,
    StaffManagementComponent,
    MassComplemInsuranceComponent,
    InscriptionPostEvenementComponent,
    BpartnerBalanceComponent,
    InscriptionPostEvenementComponent,
    PaymentSelectionWindowUiComponent,
    EventSearchComponent,
    BpartnerSearchComponent,
    UnitManagementComponent,
    GpsUnitComponent,
    GenererAttestationFiscaleComponent,
    EthiasManagementComponent,
    PostRejectedComponent,
    InscribeWaitingListComponent,
    PreinscriptionCampComponent,
    CreatePartnerComponent,
    InscribeUiComponent,
    RemiseBuchettesMembreComponent,
    PassageMasseComponent,
    SuppressionFonctionErroneeComponent,
    LettrageEcrituresComptablesComponent,
    ParticipantManagementComponent,
    PriceHistoryComponent,
    CreateAffiliationComponent,
    TransfertListeAttenteProcessComponent,
    CreateUpdateOwnerComponent,
    GetMapInfoCampComponent,
    ReadCreateStepComponent,
    RemiseBuchettesAnfComponent,
    TransfertParticipantComponent,
    PluralizePipe,
    SafeHtmlPipe,
    CreateFromOpenInvoiceSTComponent,
    OneManagementComponent,
    SubscriptionManagementComponent,
    SimpleFileUploadComponent,
    GpsProjectComponent,
    //#endregion custo
    TreeMaintenanceUIComponent,
    PrimeOverlayComponent,
    PlanningWindowUiComponent,
    ValuePreferencePanelComponent,
    GanttComponent,
    PreviewDocComponent,
    FilterListPipe
  ],
  providers: [ContextMenuService, CookieService, WindowFactoryService, ObjectUtils, IupicsCookieService, DataStore],
  entryComponents: [
    AccordionUiComponent,
    AccountEditorWindowUiComponent,
    AccountingViewerUiComponent,
    AccountingViewerWindowUiComponent,
    AdditionalInfoUiComponent,
    AutocompleteUiComponent,
    BladeUiComponent,
    BpartnerBalanceComponent,
    ButtonUiComponent,
    CalendarUiComponent,
    ChartUiComponent,
    CommentUiComponent,
    CreateFromInvoiceWindowUiComponent,
    CreateFromOpenInvoiceComponent,
    CreateFromShipmentWindowUiComponent,
    CreateFromStatementWindowUiComponent,
    CruiseSituationUiComponent,
    EditTabUiComponent,
    EditViewUiComponent,
    FastCreateOrderComponent,
    DirectPrintingDocumentsComponent,
    GridTabRowdataComponent,
    GridUiComponent,
    GridViewUiComponent,
    ImportFileLoaderWindowUiComponent,
    InputChipsUiComponent,
    InputFileUiComponent,
    InputImageUiComponent,
    InputLocationUiComponent,
    InputNumberUiComponent,
    InputProductAttributesUiComponent,
    InputSwitchUiComponent,
    InputTextareaUiComponent,
    InputTextUiComponent,
    InputTimeUiComponent,
    InvoiceMatchingWindowImplComponent,
    KanbanBoardUiComponent,
    KanbanCardUiComponent,
    KanbanUiComponent,
    MenuBarDetailUiComponent,
    OperationManagementUIComponent,
    PaymentAllocationWindowUiComponent,
    PaymentSelectionWindowUiComponent,
    PosUiComponent,
    PriceHistoryComponent,
    ProcessInProgressUiComponent,
    ProcessInProgressWindowUiComponent,
    ProcessUiComponent,
    ProductAttributeComponent,
    ProductViewComponent,
    RowUiComponent,
    SelectOrderComponent,
    SmartButtonUiComponent,
    SpecificWindowUiComponent,
    StepperUiComponent,
    TreeUiComponent,
    ChangelogWindowUIComponent,
    //#region custo
    InputPlaceAutocompleteUiComponent,
    StaffManagementComponent,
    MassComplemInsuranceComponent,
    InscriptionPostEvenementComponent,
    BpartnerBalanceComponent,
    InscriptionPostEvenementComponent,
    PaymentSelectionWindowUiComponent,
    EventSearchComponent,
    BpartnerSearchComponent,
    UnitManagementComponent,
    GpsUnitComponent,
    GenererAttestationFiscaleComponent,
    EthiasManagementComponent,
    PostRejectedComponent,
    InscribeWaitingListComponent,
    PreinscriptionCampComponent,
    CreatePartnerComponent,
    InscribeUiComponent,
    RemiseBuchettesMembreComponent,
    PassageMasseComponent,
    SuppressionFonctionErroneeComponent,
    LettrageEcrituresComptablesComponent,
    ParticipantManagementComponent,
    PriceHistoryComponent,
    CreateAffiliationComponent,
    TransfertListeAttenteProcessComponent,
    CreateUpdateOwnerComponent,
    GetMapInfoCampComponent,
    ReadCreateStepComponent,
    RemiseBuchettesAnfComponent,
    TransfertParticipantComponent,
    CreateFromOpenInvoiceSTComponent,
    OneManagementComponent,
    SubscriptionManagementComponent,
    SimpleFileUploadComponent, GpsProjectComponent,
    //#endregion custo
    TreeMaintenanceUIComponent,
    ValuePreferencePanelComponent,
    PlanningWindowUiComponent,
    PreviewDocComponent
  ]
})
export class IupicsComponentsModule { }
