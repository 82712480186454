import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompiereDataJSON2, DataStore } from '@compiere-ws/models/compiere-data-json';
import { LocationService } from '@compiere-ws/services/compiere-location/location.service';
import { CountryAutocomplete, LocationAutocomplete, RegionAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';

@Component({
  selector: 'iu-location-panel',
  templateUrl: './location-panel.component.html',
  styleUrls: ['./location-panel.component.scss']
})
export class LocationPanelComponent implements OnInit, AfterViewInit {
  @Input()
  fieldValue: any;
  @Input()
  data: any;
  //#region custo
  @Input()
  locationData: any;
  //#endregion custo
  @Input()
  dataStored: DataStore;
  @Input()
  enableSize: number;
  locationPanelPosition = 'bottomPosition';
  @Output()
  locationEmitter = new EventEmitter();

  countries: { items: CountryAutocomplete[]; isMandatory: boolean } = {
    items: [],
    isMandatory: true
  };
  regions: { items: RegionAutocomplete[] } = { items: [] };
  locations: { items: LocationAutocomplete[] } = { items: [] };
  locationsFiltered: { items: LocationAutocomplete[] } = { items: [] };

  country: any = { id: -1, displayValue: '' };
  region: any = { id: -1, displayValue: '' };
  location: any = { id: -1, displayValue: '', postalCode: undefined };
  postalCode = '';
  textLocation = '';
  address1 = '';
  address2 = '';
  address3 = '';
  address4 = '';
  hasLocation = false;
  //#region custo
  regionTxt = '';
  cityTxt = '';
  latitude = null;
  longitude = null;
  placeId = null;
  request = '';
  //#endregion custo

  constructor(
    private locationService: LocationService,
    private uiCreator: UICreatorService,
    private store: DataStoreService,
    private connectorService: SecurityManagerService,
    private config: AppConfig
  ) {}

  ngOnInit() {
    if (this.enableSize - 300 < 0) {
      this.locationPanelPosition = 'topPosition';
    }
  }
  //#region custo
  ngAfterViewInit() {
    if (this.locationData) {
      this.setInfo(this.locationData);
    } else {
      const id = this.fieldValue ? (this.fieldValue.id ? this.fieldValue.id : this.fieldValue) : null;
      if (id) {
        const sub = this.locationService.getLocation(id).subscribe(
          (response) => {
            if (response) {
              this.setInfo(response);
            }
            sub.unsubscribe();
          },
          (error) => {
            this.initCountry();
          }
        );
      } else {
        this.initCountry();
      }
    }
  }
  //#endregion custo
  initCountry() {
    this.getCountries(() => {
      const countryDefaultID = this.config.getConstant('LocationPanel#DefaultCountryID');
      if (countryDefaultID > 0) {
        this.country = this.countries.items.find((value) => String(value.id) === String(countryDefaultID));
        this.setCountry(this.country);
      }
    });
  }
  //#region custo
  setInfo(data: any) {
    this.locationData = null;
    this.data.nbrAddressSlot = 4;
    this.address1 = data['Address1'];
    this.address2 = data['Address2'];
    this.address3 = data['Address3'];
    this.address4 = data['Address4'];
    this.regionTxt = data['RegionName'];
    this.cityTxt = data['City'];
    this.postalCode = data['Postal'];
    this.latitude = data['Latitude'];
    this.longitude = data['Longitude'];
    this.placeId = data['GoogleID'];
    this.request = data['GoogleAddress'];
    if (data['C_Country_ID']) {
      this.getCountries(() => {
        this.country = this.countries.items.find((value) => String(value.id) === String(data['C_Country_ID']));
        this.getRegions(data['C_Country_ID'], () => {
          let region = null;
          if (data['C_Region_ID']) {
            region = this.regions.items.find((value) => String(value.id) === String(data['C_Region_ID']));
            this.region = region ? region : { id: -1, displayValue: '' };
          }
          if (!region && data['RegionName']) {
            region = this.regions.items.find(
              (value) => String(value.displayValue).toLowerCase() === String(data['RegionName']).toLowerCase()
            );
            this.region = region ? region : { id: -1, displayValue: '' };
          }
        });
        this.getLocations(data['C_Country_ID'], () => {
          let location = null;
          if (data['C_City_ID']) {
            location = this.locations.items.find((value) => String(value.id) === String(data['C_City_ID']));
            this.location = location ? location : { id: -1, displayValue: '' };
          }
          if (!location && data['City']) {
            location = this.locations.items.find(
              (value) => String(value.displayValue).toLowerCase() === String(data['City']).toLowerCase()
            );
            this.location = location ? location : { id: -1, displayValue: '' };
          }
        });
      });
    }
  }
  //endregion custo
  checkFieldValue() {
    if (this.fieldValue) {
      const arrayField = (<string>this.fieldValue).split(',');
      let i = 0;
      if (this.data.nbrAddressSlot > 0 && arrayField[i++] !== ' ') {
        this.address1 = arrayField[--i];
        i++;
      }
      if (this.data.nbrAddressSlot > 1 && arrayField[i++] !== ' ') {
        this.address2 = arrayField[--i];
        i++;
      }
      if (this.data.nbrAddressSlot > 2 && arrayField[i++] !== ' ') {
        this.address3 = arrayField[--i];
        i++;
      }
      if (this.data.nbrAddressSlot > 3 && arrayField[i++] !== ' ') {
        this.address4 = arrayField[--i];
        i++;
      }
      if (arrayField[i++] !== ' ') {
        this.postalCode = arrayField[--i];
        i++;
      }
      if (arrayField[i++] !== ' ') {
        this.location = parseInt(arrayField[--i], 10);
        i++;
      }
      if (arrayField[i++] !== ' ') {
        this.country = parseInt(arrayField[--i], 10);
        i++;
      }
    }
  }

  saveLocation(event: Event) {
    event.stopPropagation();
    //#region custo
    if (this.regionTxt) {
      this.region = this.regions.items.find(
        (value) => String(value.displayValue).toLowerCase() === String(this.regionTxt).toLowerCase()
      );
    }
    this.region = this.region ? this.region : { id: -1, displayValue: '' };
    if (this.cityTxt) {
      this.location = this.locations.items.find((value) => String(value.id) === String(this.cityTxt));
    }
    this.location = this.location ? this.location : { id: -1, displayValue: '' };
    const columnName = this.data && this.data.columnName ? this.data.columnName : 'C_Location_ID';
    const fieldValueId = this.fieldValue && this.fieldValue.id ? this.fieldValue.id : this.fieldValue;
    //#endregion custo
    const data = {
      Data_UUID:
        this.dataStored && this.dataStored.data && this.dataStored.data[columnName] && this.dataStored.data[columnName].id
          ? 'C_Location_ID,' + this.dataStored.data[columnName].id
          : 'C_Location_ID,' + fieldValueId,
      C_Location_ID:
        this.dataStored && this.dataStored.data && this.dataStored.data[columnName] && this.dataStored.data[columnName].id
          ? this.dataStored.data[columnName].id
          : fieldValueId,
      AD_Client_ID:
        this.dataStored && this.dataStored.data && this.dataStored.data['AD_Client_ID']
          ? this.dataStored.data['AD_Client_ID']
          : this.connectorService.getIupicsUserContext()['#AD_Client_ID'],
      AD_Org_ID:
        this.dataStored && this.dataStored.data && this.dataStored.data['AD_Org_ID']
          ? this.dataStored.data['AD_Org_ID']
          : this.connectorService.getIupicsUserContext()['#AD_Org_ID'],
      IsActive:
        this.dataStored && this.dataStored.data && this.dataStored.data['IsActive'] ? this.dataStored.data['IsActive'] : 'Y',
      Address1: this.data.nbrAddressSlot > 0 && this.address1 ? this.address1 : null,
      Address2: this.data.nbrAddressSlot > 0 && this.address2 ? this.address2 : null,
      Address3: this.data.nbrAddressSlot > 0 && this.address3 ? this.address3 : null,
      Address4: this.data.nbrAddressSlot > 0 && this.address4 ? this.address4 : null,
      City: this.cityTxt ? this.cityTxt : null,
      C_City_ID: this.location.id !== -1 ? this.location.id : null,
      Postal: this.postalCode ? this.postalCode : null,
      Postal_Add: null,
      C_Region_ID: this.region.id !== -1 ? this.region.id : null,
      RegionName: this.region.id !== -1 ? this.region.displayValue : this.regionTxt,
      C_Country_ID: this.country.id ? this.country.id : null,
      Latitude: this.latitude,
      Longitude: this.longitude,
      GoogleID: this.placeId,
      GoogleAddress: this.request
    };
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof Object) {
        data[key] = data[key].id;
      }
    });
    const dataWs: CompiereDataJSON2 = {
      data: [data],
      data_UUID: ['C_Location_ID'],
      displayData: {},
      secondaryColumnFields: [],
      lastRow: 0,
      tab_id: 154
    };

    const sub = this.store.saveLocation(dataWs).subscribe((compiereData) => {
      this.locationEmitter.emit(this.generateAdresseFromCompiereData(compiereData));
      sub.unsubscribe();
    });
  }

  cancel(event: Event) {
    event.stopPropagation();
    this.locationEmitter.emit('cancel');
  }

  generateAdresseFromCompiereData(compiereData: CompiereDataJSON2) {
    const addressData = [
      compiereData.data[0]['Address1'],
      compiereData.data[0]['Address2'],
      compiereData.data[0]['Address3'],
      compiereData.data[0]['Address4'],
      compiereData.data[0]['City'],
      compiereData.data[0]['Postal']
    ];
    const addressStr = addressData.filter((item) => item !== undefined && item !== null && item !== '').join(',');
    return {
      id: compiereData.data[0]['C_Location_ID'],
      displayValue: addressStr
    };
  }

  clearLocation(e) {
    this.address1 = null;
    this.address2 = null;
    this.address3 = null;
    this.address4 = null;
    this.country = null;
    this.location = null;
    this.region = null;
    this.fieldValue = null;
    event.stopPropagation();
    this.locationEmitter.emit('clear');
  }

  setCountry(country: CountryAutocomplete) {
    this.country = country;
    this.region = { id: -1, displayValue: '' };
    this.location = { id: -1, displayValue: '' };
    if (country) {
      this.getRegions(country.id);
      this.getLocations(country.id);
    }
  }

  setRegion(region: RegionAutocomplete) {
    this.region = region;
  }

  setLocation(location: LocationAutocomplete) {
    this.location = location;
    if (location) {
      this.postalCode = location.postalCode;
    }
  }

  changePostal(postal) {
    if (this.hasLocation) {
      this.locationsFiltered = Object.assign({}, this.locations);
      this.locationsFiltered.items = this.locationsFiltered.items.filter((loc) => loc.postalCode === postal);
    }
  }

  openMap() {
    let searchStr = '';
    if (this.address1) {
      searchStr += encodeURI(this.address1 + ' ');
    }
    if (this.address2) {
      searchStr += encodeURI(this.address2 + ' ');
    }
    if (this.postalCode) {
      searchStr += encodeURI(this.postalCode + ' ');
    }
    if (this.cityTxt) {
      searchStr += encodeURI(this.cityTxt + ' ');
    } else if (this.location && this.location.displayValue) {
      searchStr += encodeURI(this.location.displayValue + ' ');
    }
    if (this.country && this.country.displayValue) {
      searchStr += encodeURI(this.country.displayValue + ' ');
    }
    if (searchStr) {
      window.open('https://www.google.com/maps?q=' + searchStr, '_blank');
    }
  }

  /*
   * ********************************************************
   * ********************************************************
   * ********************************************************
   * ******************* PRIVATE METHODS ********************
   * ********************************************************
   * ********************************************************
   * ********************************************************
   */

  private getCountries(callback?: Function) {
    const sub = this.locationService.getCountries().subscribe((countries) => {
      if (countries) {
        this.countries = {
          items: countries.map(
            (country) =>
              <CountryAutocomplete>{
                id: parseInt(country.country_id, 10),
                displayValue: country.name
              }
          ),
          isMandatory: true
        };
      }
      if (callback) {
        callback();
      }
      sub.unsubscribe();
    });
  }

  private getRegions(countryId: number, callback?: Function) {
    if (countryId !== undefined) {
      const sub = this.locationService.getRegions(countryId).subscribe((regionsForCountry) => {
        if (regionsForCountry.regions && regionsForCountry.regions.length > 0) {
          this.regions = {
            items: regionsForCountry.regions.map(
              (region) =>
                <RegionAutocomplete>{
                  id: parseInt(region.region_id, 10),
                  displayValue: region.name
                }
            )
          };
        } else {
          this.regions.items = [];
        }
        if (callback) {
          callback();
        }
        sub.unsubscribe();
      });
    }
  }

  private getLocations(countryId: number, callback?: Function) {
    if (countryId !== undefined) {
      const sub = this.locationService.getCities(countryId).subscribe((cities) => {
        if (cities && cities.length > 0) {
          this.hasLocation = true;
          this.locations = {
            items: cities.map(
              (city) =>
                <LocationAutocomplete>{
                  id: parseInt(city.city_id, 10),
                  displayValue: city.name,
                  postalCode: city.postal
                }
            )
          };
          this.locationsFiltered = Object.assign({}, this.locations);
        } else {
          this.hasLocation = false;
        }
        if (callback) {
          callback();
        }
        sub.unsubscribe();
      });
    }
  }
}
