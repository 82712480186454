import { Injectable } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { SpecificWindowCompiereWS } from '@compiere-ws/models/specific-window-json';
import { WidgetCompiereJson, WidgetFavoriteCompiereJson } from '@compiere-ws/models/widget-center-json';
import { CompiereTab, TabsEntityCompiereJSON, WindowCompiereWS } from '@compiere-ws/models/window-json';
import { CompiereCalloutService } from '@compiere-ws/services/compiere-callout/compiere-callout.service';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { CompiereMenuService } from '@compiere-ws/services/compiere-menu/compiere-menu.service';
import { CompiereMenuFavoritesService } from '@compiere-ws/services/compiere-menufavorites/compiere-menufavorites.service';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { PrinterService } from '@compiere-ws/services/printer-service/printer-service.service';
import { WidgetCenterService } from '@compiere-ws/services/widget-center/widget-center.service';
import { WindowsService } from '@compiere-ws/services/windows/windows.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { IupicsField, IupicsSpecificWindow, IupicsTab } from '@iupics-manager/models/iupics-data';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { IupicsColumnInfo } from '@iupics-manager/models/iupics_column_info';
import { TranslateService } from '@ngx-translate/core';
import { DashboardMenuItem, DashboardRemoveMenuItem } from '@web-desktop/models/dashboard-menu-item';
import { IupicsMenuType, MenuCategoryUI, MenuFavoritesCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { cloneDeep } from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageManagerService } from '../message/message-manager.service';
import { SecurityManagerService } from '../security-manager/security-manager.service';
import { MenuCreatorUtils } from './utils/ui-creator-menu.utils';
import { SpecificWindowsCreatorUtils } from './utils/ui-creator-specific-windows.utils';
import { UICreatorUtils } from './utils/ui-creator.utils';

@Injectable()
export class UICreatorService {
  fieldGroup: IupicsField;

  private iupicsMenus: MenuItemUI[] = [];
  private iupicsMenuFavorites: MenuItemUI[];
  private iupicsWidgetFavorites: WidgetFavoriteCompiereJson[];
  private iupicsMenuCategories: MenuCategoryUI[];
  private iupicsMenuFavoritesCategories: MenuFavoritesCategoryUI[];
  private iupicsWidgetFavoritesCategories: MenuFavoritesCategoryUI[];
  private iupics_column_info: Map<number, IupicsColumnInfo[]> = new Map();

  private iupicsWindowDefaultTab: Map<number, number> = new Map();
  private iupicsTabs: Map<number, IupicsTab[]> = new Map();
  private iupicsWindowNames: Map<number, string> = new Map();
  private iupicsSpecificWindows: Map<number, IupicsSpecificWindow> = new Map();
  private iupicsSpecificWindowsErrors: Map<number, any> = new Map();

  constructor(
    private menuService: CompiereMenuService,
    private menuFavoritesService: CompiereMenuFavoritesService,
    private widgetCenterService: WidgetCenterService,
    private windowService: WindowsService,
    private translator: TranslateService,
    private calloutService: CompiereCalloutService,
    private processSevice: CompiereProcessService,
    private compiereDataService: CompiereDataService,
    private dataStore: DataStoreService,
    private messageManager: MessageManagerService,
    private printerService: PrinterService,
    private connectorService: SecurityManagerService
  ) {
    UICreatorUtils.setDataStore(this.dataStore);
    UICreatorUtils.setUICreatorService(this);
    UICreatorUtils.setConnectorService(this.connectorService);
    SpecificWindowsCreatorUtils.setDataStore(this.dataStore);
    SpecificWindowsCreatorUtils.setConnectorService(this.connectorService);
  }

  resetCachingData() {
    this.iupicsWindowDefaultTab = new Map();
    this.iupicsTabs = new Map();
    this.iupicsMenus = [];
    this.iupicsMenuFavorites = undefined;
    this.iupicsWidgetFavorites = undefined;
    this.iupicsMenuCategories = [];
    this.iupicsWidgetFavoritesCategories = [];
    this.iupicsMenuFavoritesCategories = [];
    this.iupicsSpecificWindows = new Map();
  }

  getIupicsMenusCategories() {
    return this.iupicsMenuCategories;
  }

  getIupicsMenuFavoritesCategories() {
    return this.iupicsMenuFavoritesCategories;
  }
  getIupicsWidgetFavoritesCategories() {
    return this.iupicsWidgetFavoritesCategories;
  }

  getIupicsMenus(): Observable<MenuItemUI[]> {
    if (this.iupicsMenus.length) {
      return of(this.iupicsMenus);
    } else {
      return this.menuService.getMenus().pipe(
        map((compiereMenus) => {
          this.iupicsMenuCategories = [];
          this.iupicsMenus = MenuCreatorUtils.transformMenus(this.iupicsMenuFavoritesCategories, compiereMenus);

          this.iupicsMenuCategories = this.iupicsMenus
            .map((m) => m.menu_category)
            .filter((c) => c !== undefined)
            .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [] as MenuCategoryUI[]);

          this.iupicsMenuCategories.push(
            {
              id: -1,
              name: this.translator.instant('menu.category_all'),
              isSelected: true,
              icon: 'fas fa-folder',
              isDisplay: true
            },
            {
              id: 0,
              name: this.translator.instant('menu.category_mostAccurate'),
              isSelected: false,
              isDisplay: true,
              icon: ''
            },
            {
              id: 1,
              name: this.translator.instant('menu.category_undefined'),
              isSelected: false,
              icon: 'fas fa-not-equal',
              isDisplay: true
            }
          );

          return this.iupicsMenus;
        })
      );
    }
  }

  getIupicsMenuFromWindow(window_id: number, menu_type: IupicsMenuType) {
    return this.getIupicsMenus().pipe(
      map((menus) => menus.find((menu) => menu.action_id === window_id && menu.menu_type === menu_type))
    );
  }

  getWidgetList(): Observable<WidgetCompiereJson[]> {
    return this.widgetCenterService.getWidgetList();
  }

  getIupicsMenuFavorites(forceRefresh = false): Observable<MenuItemUI[]> {
    if (!forceRefresh && this.iupicsMenuFavorites) {
      return of(this.iupicsMenuFavorites);
    } else {
      return this.menuFavoritesService.getMenuFavorites().pipe(
        map((compiereMenuFavorites) => {
          this.iupicsMenuFavoritesCategories = [];
          this.iupicsMenuFavorites = MenuCreatorUtils.transformMenus(
            this.iupicsMenuFavoritesCategories,
            compiereMenuFavorites,
            true
          );
          return this.iupicsMenuFavorites;
        })
      );
    }
  }

  getIupicsWidgetFavorites(forced = false): Observable<WidgetFavoriteCompiereJson[]> {
    if (this.iupicsWidgetFavorites && !forced) {
      return of(this.iupicsWidgetFavorites);
    } else {
      return this.menuFavoritesService.getWidgetFavorites().pipe(
        map((compiereWidgetFavorites) => {
          this.iupicsWidgetFavoritesCategories = [];
          this.iupicsWidgetFavorites = MenuCreatorUtils.transformWidgets(
            this.iupicsWidgetFavoritesCategories,
            compiereWidgetFavorites,
            true
          );
          return this.iupicsWidgetFavorites;
        })
      );
    }
  }

  setIupicsWidgetFavorites(menuItem: DashboardMenuItem[]): Observable<WidgetFavoriteCompiereJson[]> {
    return this.menuFavoritesService.setWidgetFavorites(menuItem);
  }

  deleteIupicsWidgetFavorites(menuItem: DashboardRemoveMenuItem): Observable<CompiereMenu> {
    return this.menuFavoritesService.deleteWidgetFavorites(menuItem);
  }

  setIupicsFavorites(menuItem: DashboardMenuItem[]): Observable<CompiereMenu> {
    return this.menuFavoritesService.setMenuFavorites(menuItem);
  }

  deleteIupicsFavorites(menuItem: DashboardRemoveMenuItem): Observable<CompiereMenu> {
    return this.menuFavoritesService.deleteMenuFavorites(menuItem);
  }

  getProductAttribute(attributeInstance_id: number, product_ID: number, tab_id: number): Observable<any> {
    return this.windowService.getProductAttribute(attributeInstance_id, product_ID, tab_id);
  }

  saveProductAttribute(
    attributeInstance_id: number,
    product_ID: number,
    tab_id: number,
    fieldId: number,
    params: any
  ): Observable<any> {
    return this.windowService.saveProductAttribute(attributeInstance_id, product_ID, tab_id, fieldId, params);
  }
  saveProductAttributeLot(product_ID: number): Observable<any> {
    return this.windowService.saveProductAttributeLot(product_ID);
  }

  getWindowName(windowId: number) {
    return this.iupicsWindowNames.get(windowId);
  }
  getWindow(windowId: number): Observable<IupicsTab[]> {
    if (this.iupicsWindowDefaultTab.has(windowId)) {
      return of(this.iupicsTabs.get(this.iupicsWindowDefaultTab.get(windowId)));
    } else {
      return this.windowService.getWindow(windowId).pipe(
        map((compiereWindow) => {
          if (compiereWindow.tabs[0]) {
            console.log('nb Fields: ' + compiereWindow.tabs[0].fields.length);
          } else {
            console.log('No Tabs on this Window: ' + compiereWindow.window.Name);
          }
          this.iupicsWindowNames.set(windowId, compiereWindow.window.Name);
          this.transformColumnInfos(compiereWindow);
          this.transformWindow(compiereWindow);
          return this.iupicsTabs.get(this.iupicsWindowDefaultTab.get(windowId));
        })
      );
    }
  }
  getSpecificWindow(ad_form_id: number): Observable<IupicsSpecificWindow> {
    if (this.iupicsSpecificWindows.has(ad_form_id)) {
      const errors = this.iupicsSpecificWindowsErrors.get(ad_form_id);
      if (errors && Object.keys(errors).length > 0) {
        this.messageManager.newMessage(
          new IupicsMessage(this.translator.instant('specificWindow.itemsError'), errors.keys, 'error', errors.values)
        );
      }
      return of(this.iupicsSpecificWindows.get(ad_form_id));
    } else {
      return this.windowService.getSpecificWindow(ad_form_id).pipe(
        map((specificWindow) => {
          this.iupicsWindowNames.set(ad_form_id, specificWindow.Name);
          this.transformColumnInfosSpecificWindow(specificWindow);
          const errors = SpecificWindowsCreatorUtils.transformSpecificWindow(this.iupicsSpecificWindows, specificWindow);
          this.iupicsSpecificWindowsErrors.set(ad_form_id, errors);
          return this.iupicsSpecificWindows.get(ad_form_id);
        })
      );
    }
  }

  getDBSelect(query: string, params: any[], tablenames: string[]): Observable<any[]> {
    return this.calloutService.getData(query, params, tablenames);
  }

  getFields(tabId: number): Observable<IupicsField[]> {
    return this.windowService.getFields(tabId).pipe(
      map((compiereField) => {
        const fields: IupicsField[] = [];
        const dataStructure = {};
        compiereField.forEach((el) => {
          const fieldTransformed = UICreatorUtils.transformField(el);
          fields.push(fieldTransformed);
          dataStructure[el.field.ColumnName] = null;
        });
        this.dataStore.addWindowDataStructure(tabId, dataStructure);
        return fields;
      })
    );
  }

  executeProcess(params: any): Observable<any> {
    return this.processSevice.executeProcess(params);
  }

  /**
   *
   * @param tabId number
   * @returns Observable<IupicsTab[]>: l'ensemble des tabs pour iupics
   */
  getTab(tabId: number): Observable<IupicsTab[]> {
    if (this.iupicsTabs.has(tabId)) {
      return of(this.iupicsTabs.get(tabId));
    } else {
      console.error('No tab id found : ' + tabId);
    }
  }

  getActualTab(tabId: number): Observable<IupicsTab> {
    if (this.iupicsTabs.has(tabId)) {
      return of(this.iupicsTabs.get(tabId).find((tab) => tab.tabId === tabId));
    } else {
      console.error(`No tab found for ID: ${tabId}`);
    }
  }

  /**
   * Parcours l'ensemble des tabs d'une fenêtre.\
   * Pour chacun des tabs **(tabLevel = n)** elle va prendre le(s) suivant tant que **tabLevel === n+1**.
   * - Boucle n°1: les tabs
   * - Boucle n°2: les tabs de tabLevel = n+1
   * @param {WindowCompiereWS}window
   */
  transformWindow(window: WindowCompiereWS): void {
    for (let i = 0; i < window.tabs.length; i++) {
      let isFind = true;
      if (window.window.CtxArea) {
        window.window.CtxArea = UICreatorUtils.formatCtxArea(window.window.CtxArea);
      }
      const currentLevel = window.tabs[i].tab.TabLevel;
      this.iupicsTabs.set(window.tabs[i].tab.AD_Tab_ID, [UICreatorUtils.transformTab(window.tabs[i], window.window)]);
      if (currentLevel === 0) {
        this.iupicsWindowDefaultTab.set(window.window.AD_Window_ID, window.tabs[i].tab.AD_Tab_ID);
      }
      for (let j = i + 1; j < window.tabs.length && isFind; j++) {
        if (window.tabs[j].tab.TabLevel === currentLevel + 1) {
          let sortOrder: number;
          let sortYesNo: number;
          let tabId: number;
          let tabTri: number;

          if (window.tabs[j].tab.IsSortTab === true) {
            sortOrder = window.tabs[j].tab.AD_ColumnSortOrder_ID;
            sortYesNo = window.tabs[j].tab.AD_ColumnSortYesNo_ID;
            tabId = window.tabs[j].tab.AD_Table_ID;
            tabTri = window.tabs[j].tab.AD_Tab_ID;
            this.iupicsTabs
              .get(window.tabs[i].tab.AD_Tab_ID)
              .push(this.creatorComponentOS(window.window.AD_Window_ID, window.tabs[j], sortOrder, sortYesNo, tabId, tabTri));
          } else {
            this.iupicsTabs.get(window.tabs[i].tab.AD_Tab_ID).push(UICreatorUtils.transformTab(window.tabs[j], window.window));
          }
        } else if (window.tabs[j].tab.TabLevel === currentLevel) {
          isFind = false;
        }
      }
    }
  }

  creatorComponentOS(
    windowID: number,
    tab: TabsEntityCompiereJSON,
    sortOrder: number,
    sortYesNo: number,
    tabId: number,
    adTabId: number
  ): IupicsTab {
    const tabTransformed: IupicsTab = {
      tabId: tab.tab.AD_Tab_ID,
      tabLevel: tab.tab.TabLevel,
      isSingleRow: tab.tab.IsSingleRow,
      editView: {
        children: [],
        component: 'EditTabUiComponent',
        data: {
          positionEditTab: tab.tab.PositionEdit,
          colspan: tab.tab.ColspanEdit,
          isCollapsable: true,
          label: tab.tab.Name,
          isSingleRow: tab.tab.IsSingleRow,
          tabLevel: tab.tab.TabLevel,
          DisplayLogic: tab.tab.DisplayLogic
        }
      },
      gridView: {
        children: [],
        component: 'SelectOrderComponent',
        gridPaginator: false,
        data: {
          positionEditTab: tab.tab.TabLevel === 0 ? tab.tab.PositionEdit : tab.tab.PositionGrid,

          colspan: tab.tab.TabLevel === 0 ? tab.tab.ColspanEdit : tab.tab.ColspanGrid,
          isCollapsable: true,
          label: tab.tab.Name,
          columnsTableHeader: [],
          DisplayLogic: tab.tab.DisplayLogic,
          items: [],
          AD_ColumnSortOrder_ID: sortOrder,
          AD_ColumnSortYesNo_ID: sortYesNo,
          RecordID: 0,
          ADTableID: tabId,
          ADTabID: adTabId,
          AD_window_ID: windowID
        }
      }
    };
    return tabTransformed;
  }

  getCompiereTab(tab_id): Observable<CompiereTab> {
    return this.windowService.getTab(tab_id);
  }

  zoomAcross(tableName: string, columnKey: string, record_id: number, isSOTrx?: boolean, window_id?: number): Observable<any> {
    let tmpColumnKey = columnKey;
    if (tmpColumnKey && tmpColumnKey.split('.').length > 1) {
      tmpColumnKey = tmpColumnKey.split('.')[1];
    }
    return this.windowService.getZoomAcross(tableName, tmpColumnKey, record_id, isSOTrx, window_id);
  }

  getZoomTarget(tab_id: number, columnKey: string, record_id: number) {
    return this.windowService.getZoomTarget(tab_id, columnKey, record_id);
  }

  transformColumnInfos(compiereWindow: WindowCompiereWS) {
    compiereWindow.tabs.forEach((tabEntity) => {
      const columnInfo: IupicsColumnInfo[] = [];
      tabEntity.fields.forEach((fieldEntity) => {
        columnInfo.push({
          fieldEntity: fieldEntity,
          filterType: UICreatorUtils.getFilterTypeFromReference(fieldEntity.field.AD_Reference_ID)
        });
      });
      this.iupics_column_info.set(tabEntity.tab.AD_Tab_ID, columnInfo);
      this.dataStore.setColumnInfo(tabEntity.tab.AD_Tab_ID, columnInfo);
    });
  }
  transformColumnInfosSpecificWindow(specificWindow: SpecificWindowCompiereWS) {
    specificWindow.detail.items
      .filter((item) => item.detail && item.detail.Type === 'T')
      .forEach((tableItem) => {
        const columnInfo: IupicsColumnInfo[] = [];
        tableItem.detail.items.forEach((column) => {
          let fieldEntity = cloneDeep(column) as any;
          delete fieldEntity.field;
          fieldEntity = { ...fieldEntity, ...column.field };
          columnInfo.push({
            fieldEntity: fieldEntity,
            filterType: UICreatorUtils.getFilterTypeFromReference(column.field.field.AD_Reference_ID)
          });
        });
        this.iupics_column_info.set(tableItem.detail.AD_FormDetail_ID, columnInfo);
        this.dataStore.setColumnInfo(tableItem.detail.AD_FormDetail_ID, columnInfo);
      });
  }

  getColumnInfos(tabId: number): Observable<IupicsColumnInfo[]> {
    if (this.iupics_column_info.has(tabId)) {
      return of(this.iupics_column_info.get(tabId));
    } else {
      return throwError(`No column infos for AD_Tab_ID : ${tabId}`);
    }
  }
  getFieldsData(url: string): Observable<any> {
    url = url.replace(/\/\//g, '/');
    return this.compiereDataService.getFieldsData(url);
  }

  getCupsPrinters(): Observable<any> {
    return this.printerService.getCupsPrinters();
  }
}
