import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Chips } from 'primeng/chips';

export const CHIPS_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PrimeChipsComponent),
  multi: true
};

@Component({
  selector: 'iu-prime-chips',
  templateUrl: './prime-chips.component.html',
  styleUrls: ['./prime-chips.component.scss'],
  providers: [CHIPS_VALUE_ACCESSOR]
})
export class PrimeChipsComponent extends Chips implements ControlValueAccessor {
  @ViewChild('wrapper', { read: ElementRef, static: true })
  wrapper: ElementRef<HTMLDivElement>;
  @ViewChild('mainDiv', { read: ElementRef, static: true })
  mainDiv: ElementRef<HTMLDivElement>;
  @ViewChild('inputtext', { read: ElementRef, static: true })
  inputViewChild: ElementRef<HTMLInputElement>;

  @Input()
  columnName: string;
  @Input() inputType = 'text';
  @Input() addOnEnter = true;
  @Input() addOnSpace = false;
  @Input() editOnBackspace = false;
  @Input() matchPattern: RegExp = /.*/;
  @Input() shouldBeSmall = false;
  @Input() preventInput = false;
  @Input() widthChipsContainer: number;
  @Input() inContainer = false;

  @Output() addError = new EventEmitter<any>();

  get maxWidthChipsContainer() {
    if (this.shouldBeSmall) {
      return 125;
    }
    if (this.widthChipsContainer !== undefined && this.widthChipsContainer !== null) {
      return this.widthChipsContainer;
    }
    if (!this.inContainer) {
      if (document.documentElement.clientWidth <= 480) {
        return document.documentElement.clientWidth - 305;
      } else {
        return document.documentElement.clientWidth - 450;
      }
    } else {
      return (
        this.mainDiv?.nativeElement.getBoundingClientRect().width -
        this.inputViewChild?.nativeElement.getBoundingClientRect().width -
        10
      );
    }
  }

  private globalClickListener: Function;

  @Input() buildItemOnSave: Function = (item: string) => {
    return item;
  };

  @Input() conditionToAdd: Function = (...args: any): boolean => {
    return true;
  };

  constructor(public el: ElementRef, private renderer: Renderer2) {
    super(el);
  }

  addItem(event: Event, item: string): boolean {
    if (!this.matchPattern.test(item)) {
      event.preventDefault();
      return false;
    }
    this.value = this.value || [];
    if (item && item.trim().length && this.conditionToAdd()) {
      if (this.allowDuplicate || this.value.indexOf(item) === -1) {
        this.value = [...this.value, this.buildItemOnSave(item)];
        this.onModelChange(this.value);
        this.onAdd.emit({
          originalEvent: event,
          value: item
        });
        return true;
      }
    } else {
      this.addError.emit();
      return false;
    }
    this.updateMaxedOut();
  }

  handleWheel(event: WheelEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (event.deltaY > 0) {
      if (
        this.wrapper.nativeElement.scrollWidth - this.wrapper.nativeElement.scrollLeft - this.wrapper.nativeElement.clientWidth >
        0
      ) {
        this.wrapper.nativeElement.scrollTo({ left: this.wrapper.nativeElement.scrollLeft + 25 });
      }
    } else {
      if (this.wrapper.nativeElement.scrollLeft !== 0) {
        this.wrapper.nativeElement.scrollTo({ left: this.wrapper.nativeElement.scrollLeft - 25 });
      }
    }
  }

  onInputFocus(event: FocusEvent) {
    this.renderer.addClass(this.inputViewChild.nativeElement, 'focus');
    if (this.globalClickListener === undefined) {
      this.globalClickListener = this.renderer.listen(document, 'click', (e: any) => {
        const path = e.path ? e.path : e.composedPath();
        if (path.indexOf(this.el.nativeElement) < 0) {
          this.renderer.removeClass(this.inputViewChild.nativeElement, 'focus');
          this.globalClickListener();
          this.globalClickListener = undefined;
        }
      });
    }
    super.onInputFocus(event);
  }

  onInputBlur(event: FocusEvent) {
    this.focus = false;
    if (this.addOnBlur && this.inputViewChild.nativeElement.value) {
      if (this.addItem(event, this.inputViewChild.nativeElement.value)) {
        this.inputViewChild.nativeElement.value = '';
      }
    }
    this.onModelTouched();
    this.onBlur.emit(event);
  }

  onInputChipsBlur() {
    this.renderer.removeClass(this.inputViewChild.nativeElement, 'focus');
  }
  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === 'Backspace' || event.key === 'Tab') {
      this.wrapper.nativeElement.scrollTo({ left: this.wrapper.nativeElement.scrollWidth });
    }
  }
  onKeydown(event: KeyboardEvent): void {
    switch (event.key) {
      // Backspace
      case 'Backspace':
        if (this.inputViewChild.nativeElement.value.length === 0 && this.value && this.value.length > 0) {
          this.value = [...this.value];
          const removedItem = this.value.pop();
          this.onModelChange(this.value);
          this.onRemove.emit({
            originalEvent: event,
            value: removedItem
          });
          if (this.editOnBackspace) {
            this.inputViewChild.nativeElement.value = removedItem;
            event.preventDefault();
          }
        }
        break;

      // Enter
      case 'Enter':
        if (this.addOnEnter && this.inputViewChild.nativeElement.value !== '') {
          if (!this.addItem(event, this.inputViewChild.nativeElement.value)) {
            return;
          }
          this.inputViewChild.nativeElement.value = '';
          event.preventDefault();
        } else {
          this.inputViewChild.nativeElement.blur();
        }
        break;

      // Tab
      case 'Tab':
        if (this.addOnTab && this.inputViewChild.nativeElement.value !== '') {
          if (!this.addItem(event, this.inputViewChild.nativeElement.value)) {
            return;
          }
          this.inputViewChild.nativeElement.value = '';

          event.preventDefault();
        }
        break;

      // Space
      case ' ':
        if (this.addOnSpace && this.inputViewChild.nativeElement.value !== '') {
          if (!this.addItem(event, this.inputViewChild.nativeElement.value)) {
            return;
          }
          this.inputViewChild.nativeElement.value = '';
          event.preventDefault();
        }
        break;

      default:
        if (this.max && this.value && this.max === this.value.length) {
          event.preventDefault();
        }
        break;
    }
  }
}
