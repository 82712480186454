<iu-input-file-ui
  customUpload="true"
  [auto]="false"
  [isAttachment]="true"
  [multiple]="true"
  [data]="{ IsParam: false, columnName: 'join-file' }"
  [searchInformations]="baseSearch"
  [additionalUploadInformations]="additionalInformations"
  (nbUploadedFilesChangeEmitter)="updateNbUploadedFiles($event)"
  [adTable_ID]="adTable_ID"
  [record_id]="record_id"
>
</iu-input-file-ui>
