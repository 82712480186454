<h3 *ngIf="displayFileUploaded" class="file_upload file_list_header">
  <span [style.font-size]="overridedCSS?.labelFontSize">{{ uploadedFileList_title | translate }}</span>
  <input
    #filterInput
    type="text"
    class="file_upload_input_search"
    (keyup)="updateFilterOptions($event, filterInput.value, 'files')"
    placeholder="{{ uploadedFileList_search | translate }}"
    value=""
    width="10vw"
  />
</h3>
<div *ngIf="displayFileUploaded" class="file_list">
  <div *ngIf="uploadedFiles.length === 0; else uploadedFilesList" [style.font-size]="overridedCSS?.inputFontSize">
    <p-progressSpinner *ngIf="isLoaderActive; else noFileMessage"></p-progressSpinner>
    <ng-template #noFileMessage> {{ uploadedFileList_noFiles | translate }} </ng-template>
  </div>
  <ng-template #uploadedFilesList>
    <table class="uploadedFiles">
      <tr *ngFor="let file of uploadedFiles | filterList: filterListOptions; let i = index">
        <td><img [src]="getLogoFileSrc(file)" [width]="previewWidth" /></td>
        <td [style.font-size]="overridedCSS?.inputFontSize">{{ file.name }}</td>
        <td *ngIf="canDownload"><p-button icon="fa fa-download" (onClick)="downloadFile($event, file, i)"></p-button></td>
        <td *ngIf="canPreview && hasPreview(file)">
          <p-button icon="icon-ico-print" (onClick)="onPreview($event, file, i)"></p-button>
        </td>
        <td *ngIf="canDelete && (file.isDeletable === undefined || file.isDeletable)">
          <p-button
            icon="fa fa-trash"
            (onClick)="isReadOnly ? null : deleteFile($event, file, i)"
            data-cy="deleteFile"
          ></p-button>
        </td>
      </tr>
    </table>
  </ng-template>
</div>
<h3
  *ngIf="displayFileUploaded && linkedFiles.length > 0"
  class="file_upload file_list_header"
  [style.font-size]="overridedCSS?.labelFontSize"
>
  <span [style.font-size]="overridedCSS?.labelFontSize">{{ linkedFileList_title | translate }}</span>

  <input
    #filterLinkedInput
    type="text"
    class="file_upload_input_search"
    (keyup)="updateFilterOptions($event, filterLinkedInput.value, 'linkedFiles')"
    value=""
    placeholder="{{ uploadedFileList_search | translate }}"
    width="10vw"
  />
</h3>
<div *ngIf="displayFileUploaded && linkedFiles.length > 0" class="file_list linked_file" data-cy="fileupload-linkedfile-list">
  <div *ngIf="linkedFiles.length === 0; else linkedFilesList" [style.font-size]="overridedCSS?.inputFontSize">
    <p-progressSpinner *ngIf="isLoaderActive; else noFileMessage"></p-progressSpinner>
    <ng-template #noFileMessage> {{ uploadedFileList_noLinkedFiles | translate }} </ng-template>
  </div>
  <ng-template #linkedFilesList>
    <table class="linkedFiles">
      <tr
        *ngFor="let file of linkedFiles | filterList: filterLinkedListOptions; let i = index"
        [attr.data-cy-index]="i"
        data-cy="fileupload-linkedfile"
      >
        <td><img [src]="getLogoFileSrc(file)" [width]="previewWidth" /></td>
        <td [style.font-size]="overridedCSS?.inputFontSize">{{ file.name }}</td>
        <td *ngIf="canDownload">
          <p-button icon="fa fa-download" (onClick)="downloadFile($event, file, i)" data-cy="downloadFile"></p-button>
        </td>
        <td *ngIf="canPreview && hasPreview(file)">
          <p-button icon="icon-ico-print" (onClick)="onPreview($event, file, i)" data-cy="previewFile"></p-button>
        </td>
        <td *ngIf="canDelete && (file.isDeletable === undefined || file.isDeletable)">
          <p-button
            icon="fa fa-trash"
            (onClick)="isReadOnly ? null : deleteFile($event, file, i)"
            data-cy="deleteFile"
          ></p-button>
        </td>
      </tr>
    </table>
  </ng-template>
</div>

<div *ngIf="isReadOnly || canUpload">
  <h3 style="margin-top: 0px;">{{ uploadingFileList_title | translate }}</h3>
  <div [ngClass]="['ui-fileupload ui-widget', styleClass || '']" [ngStyle]="style">
    <div
      #content
      [ngClass]="['ui-fileupload-content ui-widget-content ui-corner-bottom']"
      (dragenter)="onDragEnter($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      (click)="addFile($event)"
      data-cy="fileupload-click-or-drop-zone"
    >
      <p-messages [value]="msgs" [enableService]="false"></p-messages>
      <input
        #advancedfileinput
        type="file"
        style="display: none;"
        (change)="onFileSelect($event)"
        [multiple]="multiple"
        [disabled]="isReadOnly"
        (focus)="onFocus()"
        (blur)="onBlur()"
        data-cy="fileupload-input-file"
      />
      <div *ngIf="!hasFiles(); else notEmpty" class="ui-fileupload-label">
        <i class="fas fa-cloud-upload-alt fa-4x" aria-hidden="true"></i>
        <p>{{ uploadingFileList_drag | translate }}</p>
      </div>
      <ng-template #notEmpty>
        <div class="ui-fileupload-files">
          <table class="ui-fileupload-table">
            <tr *ngFor="let file of files; let i = index">
              <td *ngIf="uploadingFiles.indexOf(file) !== -1">
                <p-progressSpinner></p-progressSpinner>
              </td>
              <td><img [src]="getLogoFileSrc(file)" [width]="previewWidth" /></td>
              <td>
                <div
                  [ngStyle]="{
                    width: content | getPercentInPxCss: { of: 'clientWidth', many: 40 }
                  }"
                  [style.font-size]="overridedCSS?.inputFontSize"
                  class="prime-fileupload-filename"
                >
                  {{ file.name }}
                </div>
              </td>
              <td>
                <button
                  type="button"
                  icon="fa fa-trash"
                  pButton
                  (click)="remove($event, i)"
                  *ngIf="uploadingFiles.indexOf(file) === -1"
                ></button>
              </td>
            </tr>
          </table>
        </div>
      </ng-template>
    </div>
  </div>
  <div style="margin-top: 10px;" *ngIf="!auto">
    <p-button
      *ngIf="displayButtons"
      (click)="onClickUploadFiles()"
      [disabled]="uploadingFiles.length > 0"
      label="{{ uploadBtn_confirm | translate }}"
      data-cy="fileupload-btn-confirm"
    ></p-button>
    <p-button
      *ngIf="displayButtons"
      (click)="clear()"
      [disabled]="uploadingFiles.length > 0"
      label="{{ uploadBtn_cancel | translate }}"
      data-cy="fileupload-btn-cancel"
    ></p-button>
  </div>
</div>
<iu-preview-doc [fromDocServer]="fromDocServer"></iu-preview-doc>
