<p-toolbar styleClass="wd-menu-top-show" (contextmenu)="onContextMenu($event)" (press)="onContextMenu($event)">
  <div class="ui-toolbar-group-left">
    <!-- #region custo|class="workspace"-->
    <button
      [ngClass]="[isDashboardVisible ? 'active' : '', 'workspace', 'ui-button']"
      (click)="swapToDashBoard()"
      iuKeybinding
      class="workspace"
      [iuKeybinding-active]="true"
      iuKeybinding-code="menu-top.dashboard"
      data-cy="dashboard-btn"
    >
      {{ dashboardLabel }}
    </button>
    <!-- #endregion custo-->
  </div>
  <div
    #tabsBar
    class="ui-toolbar-group-center"
    (drop)="drop($event)"
    (dragover)="allowDrop($event)"
    iuKeybinding
    iuKeybinding-code="custom"
    [iuKeybinding-active]="true"
    [iuKeybinding-behavior-function]="chooseTab"
    [iuKeybinding-behavior-context]="this"
  >
    <div
      *ngIf="!hasEnoughWidth && !isMobile"
      class="scroller scroller-left icon-ico-back"
      (dragover)="scrollLeft($event)"
      (mousedown)="scrollLeft($event)"
      (mouseup)="stopScroll($event)"
      (dragleave)="stopScroll($event)"
      (drop)="stopScroll($event)"
    ></div>
    <div #wrapper (wheel)="handleWheel($event)" class="wrapper">
      <!-- #region custo|class="tabsBar"-->
      <ul
        id="tabsBar"
        class="tabsBar"
        [ngClass]="[isMobileWidth ? 'tabsBarMobile' : '', 'tabsBar']"
        iuKeybinding
        iuKeybinding-code="menu-top.close-tab"
        [iuKeybinding-active]="true"
        [iuKeybinding-badge-active]="false"
        [iuKeybinding-behavior-function]="handleKeybind"
        [iuKeybinding-behavior-context]="this"
      >
        <!-- #endregion custo -->
        <ng-template #tabContainer> </ng-template>
      </ul>
    </div>
    <div
      *ngIf="!hasEnoughWidth && !isMobileWidth"
      class="scroller scroller-right icon-ico-next"
      (dragover)="scrollRight($event)"
      (mousedown)="scrollRight($event)"
      (mouseup)="stopScroll($event)"
      (dragleave)="stopScroll($event)"
      (drop)="stopScroll($event)"
    ></div>
  </div>
  <div class="ui-toolbar-group-right">
    <p-button
      tooltipPosition="bottom"
      *ngIf="currentUserAccount && currentUserAccount.current_role && (currentUserAccount.current_role.name !== 'Animé')"
      (click)="showSearchContainer = !showSearchContainer; $event.stopPropagation()"
      life="3"
      pTooltip="{{ 'menuTop.search' | translate }}"
      icon="icon-ico-search-bolder"
      iuKeybinding
      [iuKeybinding-active]="true"
      iuKeybinding-code="menu-top.usearch"
      [iuKeybinding-shift]="true"
      data-cy="search-container-btn"
    ></p-button>
    <!-- #region custo|class="notif"-->
    <button
      *ngIf="currentUserAccount && currentUserAccount.current_role && currentUserAccount.current_role.role_id === 1000033"
      (click)="goToMyGuides($event)"
      data-cy="my-guides-btn"
      class="myGuides ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left"
    >
      <span class="gcb-trefle-couleurs ui-clickable ui-button-icon-left ng-star-inserted"
        ></span
      ><span class="ui-button-text ui-clickable" aria-hidden="false">{{ 'menuTop.myGuides' | translate }}</span>
    </button>
    <p-button
      tooltipPosition="bottom"
      life="3"
      pTooltip="{{ 'menuTop.notif' | translate }}"
      class="notif"
      [ngClass]="[nbNotifications > 0 ? 'has-notif' : 'no-notif', 'notif']"
      label="{{ nbNotifications }}"
      (click)="toggleNotificationCenter($event)"
      iuKeybinding
      [iuKeybinding-active]="true"
      iuKeybinding-code="menu-top.notifications"
      [iuKeybinding-shift]="true"
      data-cy="notification-center-btn"
      ><span class="pin"></span
    ></p-button>
    <!-- #endregion custo-->
    <p-button
      tooltipPosition="bottom"
      life="3"
      pTooltip="{{ 'menuTop.menus' | translate }}"
      icon="icon-ico-menu-bars"
      (click)="toggleMenu($event); isMenuDisplay ? menu.display($event) : ''"
      iuKeybinding
      [iuKeybinding-active]="true"
      iuKeybinding-code="menu-top.menu-ui"
      data-cy="main-menu-btn"
    ></p-button>
    <!-- #region custo|label-->
    <p-button
      class="userProfileButton"
      tooltipPosition="bottom"
      life="3"
      [pTooltip]="'menuTop.profile' | translate"
      label="{{ connectorService.getIupicsUserAccount().fullname | textLimit: 25 }} - {{
        connectorService.getIupicsUserAccount().current_role.name
      }} "
      icon=" {{ overlayPanel.overlayVisible ? 'icon-ico-up' : 'icon-ico-open' }}"
      (click)="toggleProfile($event)"
      iconPos="right"
      iuKeybinding
      [iuKeybinding-active]="true"
      iuKeybinding-code="menu-top.profile"
      data-cy="user-profile-btn"
    ></p-button>
    <!-- #region custo-->
  </div>
</p-toolbar>

<p-overlayPanel #overlayPanel class="userProfile">
  <iu-user-profile
    #userProfile
    [nbTabs]="components.length"
    [overlayPanel]="overlayPanel"
    (logoutEmitter)="logout($event)"
    (changeRoleEmitter)="changeRole($event)"
  ></iu-user-profile>
</p-overlayPanel>

<p-sidebar
  [(visible)]="showSearchContainer"
  [baseZIndex]="6000"
  position="top"
  styleClass="ui-sidebar-md search-container"
  data-cy="search-container"
>
  <iu-usearch-container
    *ngIf="showSearchContainer"
    (openTargetSearch)="this.openTargetSearch.emit($event); showSearchContainer = false"
  ></iu-usearch-container>
</p-sidebar>

<p-sidebar
  [(visible)]="isNotificationCenterDisplay"
  [baseZIndex]="6000"
  position="right"
  [styleClass]="(isMobile || isMobileWidth ? 'iu-fullscreen-sidebar' : 'ui-sidebar-md') + ' notification-center-sidebar'"
  data-cy="notification-center"
>
  <wd-notification-center-ui
    (toggleNotifCenter)="toggleNotificationCenter($event)"
    (updateNbNotification)="updateNbNotification($event)"
  ></wd-notification-center-ui>
</p-sidebar>

<p-sidebar
  [(visible)]="isMenuDisplay"
  [baseZIndex]="7000"
  position="right"
  [styleClass]="isMobile || isMobileWidth ? 'iu-fullscreen-sidebar' : 'iu-big-sidebar'"
  data-cy="main-menu"
>
  <wd-menu-ui
    #menu
    (createTabEmitter)="createTab($event)"
    (closeEmitter)="isMenuDisplay = $event"
    [opened]="isMenuDisplay"
  ></wd-menu-ui>
</p-sidebar>
