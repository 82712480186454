<div class="iu-login-portal">
  <div class="header-login">
    <!-- #region custo|src|alt|width-->
    <img
      *ngIf="!isDefaultLogo; else defaultLogo"
      [src]="logoSrc"
      alt="Scribe, Les Guides"
      width="140px"
      srcset="assets/themes/iupics/img/Logo-Guides.png 2x"
    />
    <ng-template #defaultLogo>
      <a [href]="officialApizWebSiteUrl" target="_blank" style="width: 140px">
        <img class="version-logo" [src]="logoSrc" alt="Apiz" />
      </a>
    </ng-template>
    <!-- #region custo|src|alt|width-->
  </div>
  <div class="iu-login-content">
    <div class="iu-login-content-form">
      <div *ngIf="accounts" class="ui-row">
        <!-- #region custo|clickOnAccount -->
        <iu-account-selector
          #accountsRegistered
          [accounts]="accounts"
          (clickAnotherAccount)="cleanAccounts()"
          (clickOnAccount)="checkCondDisplayed()"
        ></iu-account-selector>
        <!-- #endregion custo -->
      </div>
      <div *ngIf="!accounts" class="ui-row">
        <span class="group-field">
          <label class="inputLoginPageFloatLabel" for="float-input">{{ 'homepage.login' | translate }}</label>
          <!-- #region custo|class|blur -->
          <input
            #inputLogin
            class="inputLoginPage"
            [ngClass]="[getErrorMessage() ? 'inputError' : '', 'inputLoginPage']"
            type="text"
            pInputText
            [(ngModel)]="login"
            (keyup.enter)="confirmLogin($event)"
            autofocus
            (blur)="checkCondDisplayed()"
            data-cy="input-login"
          />
          <!-- #endregion custo -->
          <i class="iconField icon-ico-tiers"></i>
        </span>
      </div>
      <div class="ui-row">
        <span class="group-field">
          <label class="inputLoginPageFloatLabel" for="float-input">{{ 'homepage.password' | translate }}</label>
          <input
            #inputPassword
            [ngClass]="[getErrorMessage() ? 'inputError' : '', 'inputLoginPage']"
            type="password"
            pInputText
            [(ngModel)]="password"
            (keyup.enter)="confirmLogin($event)"
            autofocus
            data-cy="input-password"
          />
          <i class="iconField icon-ico-locked"></i>
        </span>
      </div>
      <div *ngIf="getErrorMessage() && getErrorMessage().length > 0" class="ui-row">
        <span *ngIf="getErrorMessage() === 'Error'; else authDown" class="invalidCredential">
          {{ 'homepage.invalidCredential' | translate }}
        </span>
        <ng-template #authDown>
          <span class="invalidCredential" [innerHTML]="'homepage.authDown' | translate"> </span>
        </ng-template>
      </div>
      <!-- #region custo -->
      <span *ngIf="globalEnv && globalEnv.conditionsGenerales && globalEnv.conditionsGenerales.length > 0" class="ui-g-12">
        <input #inputConditions type="checkbox" name="condGen" value="false" />
        <span #inputCondContainer class="condContainer"
          >{{ 'homepage.conditionGeneral.switchLabel1' | translate }}
          <a class="condLink" (click)="showModalCond()">
            {{ 'homepage.conditionGeneral.switchLabel2' | translate }} <span class="icon-ico-zoom-2"> </span> </a
        ></span>
      </span>
      <!-- #endregion custo -->
      <!-- <div *ngIf="!accounts" class="ui-row">
        <span class="group-field">
          <p-checkbox
            class="inputLoginPage"
            name="rememberme"
            label="{{ 'homepage.rememberme' | translate }}"
            binary="true"
            [(ngModel)]="isRememberUser"
            (keyup.enter)="confirmLogin($event)"
          ></p-checkbox>
        </span>
      </div> -->
      <div class="ui-row">
        <span class="group-field buttonRow" style="float: right">
          <!-- <a class="iu-button iu-button-password"> {{ 'homepage.forgotPassword' | translate }} </a> -->
          <a class="iu-button iu-button-login" (click)="confirmLogin($event)" data-cy="input-button-login">
            {{ 'homepage.signin' | translate }}
          </a>
        </span>
      </div>
      <!-- #region custo -->
      <div class="ui-row">
        <span class="getPassWordBack">
          {{ 'homepage.getPassword' | translate }}<a (click)="showModalGetPassword()">cliquez ici</a>
        </span>
      </div>
      <div *ngIf="browserNotSupported" class="ui-row">
        <span class="browserNotSupported">
          <i class="fa-lg fas fa-exclamation-triangle"></i> Nous vous conseillons d'utiliser Chrome ou Firefox.
        </span>
      </div>
      <!-- #endregion custo -->
    </div>
  </div>
  <div [ngClass]="['version', isCusto ? 'custo' : '']">
    <a *ngIf="isCusto && !isDefaultLogo" [href]="officialApizWebSiteUrl" target="_blank">
      <img class="version-logo" src="assets/themes/iupics/img/logo-apiz.svg" alt="Apiz" />
    </a>
    <span class="versionName" data-cy="app-version-number">© APIZ Advanced ERP Solutions — {{ version }}</span>
  </div>
  <!-- #region custo -->
  <!-- request condGene modal -->
  <div *ngIf="displayModalCond">
    <iu-modal-ui
      (closeModalEmitter)="displayModalCond = false"
      [hasCloseBtn]="true"
      [angularStyle]="{ 'iu-modal-header': { height: 0 }, 'iu-modal-body': { padding: 0, 'min-height': '48vh' } }"
    >
      <div class="conditionGeneral" [innerHTML]="globalEnv.conditionsGenerales"></div
    ></iu-modal-ui>
  </div>
  <div *ngIf="displayModalAccessForbidden">
    <iu-modal-ui
      (closeModalEmitter)="displayModalAccessForbidden = false"
      [hasCloseBtn]="true"
      [angularStyle]="{
        'iu-modal': { width: 'fit-content' },
        'iu-modal-header': { height: 0 },
        'iu-modal-footer': { 'padding-top': '20px' },
        'iu-modal-body': { padding: '20px' }
      }"
    >
      <div class="accessForbidden">{{ 'homepage.modalAccessForbidden.message' | translate }}</div></iu-modal-ui
    >
  </div>
  <!-- request condGene modal end -->
  <!-- request new pwd modal -->
  <div *ngIf="displayModalGetPassword">
    <iu-modal-ui
      (closeModalEmitter)="displayModalGetPassword = false"
      [hasCloseBtn]="true"
      [angularStyle]="{ 'iu-modal-body': { padding: 0, 'min-height': '48vh' } }"
    >
      <fieldset style="border-bottom: none; border-left: none; border-right: none">
        <legend style="border: black solid 1px; padding: 5px">{{ 'homepage.modalGetPassword.title' | translate }}</legend>
        <iu-input-text-ui
          #inputName
          [data]="{ isMandatory: 'Y' }"
          label="{{ 'homepage.modalGetPassword.nameLabel' | translate }}"
          cssGrid="ui-g-12 ui-md-6 ui-lg-6"
          (fieldValueChange)="validateInput(inputName)"
        ></iu-input-text-ui>
        <iu-input-text-ui
          #inputSurName
          [data]="{ isMandatory: 'Y' }"
          label="{{ 'homepage.modalGetPassword.surNameLabel' | translate }}"
          cssGrid="ui-g-12 ui-md-6 ui-lg-6"
          (fieldValueChange)="validateInput(inputSurName)"
        ></iu-input-text-ui>
        <iu-calendar-ui
          #inputCalendar
          [data]="{ isMandatory: 'Y' }"
          class="defaultDateCalendar ui-g-12 ui-md-6 ui-lg-6 ui"
          label="{{ 'homepage.modalGetPassword.birthDateLabel' | translate }}"
          [isStandalone]="true"
          cssGrid="ui-g-12 ui-md-6 ui-lg-6"
          (fieldValueChange)="validateInput(inputCalendar)"
        ></iu-calendar-ui>
        <iu-input-text-ui
          #inputEmail
          [data]="{ isMandatory: 'Y' }"
          label="{{ 'homepage.modalGetPassword.emailLabel' | translate }}"
          cssGrid="ui-g-12 ui-md-6 ui-lg-6"
          (fieldValueChange)="validateInput(inputEmail, true)"
        ></iu-input-text-ui>

        <div class="getPasswordBtnContainer">
          <span *ngIf="missingFieldMessage.length > 0" class="missingFieldMessage">
            {{ 'homepage.modalGetPassword.mandatoryFieldMessage' | translate }}{{ missingFieldMessage }}
          </span>
          <iu-button-ui
            class="ui-button primary ui-g-12 ui-md-3 ui-lg-1"
            (click)="sendRequest()"
            label="{{ 'homepage.modalGetPassword.send' | translate }}"
          >
          </iu-button-ui>
        </div>
        <span>
          {{ 'homepage.modalGetPassword.contactHelpDesk' | translate }} <a href="mailto:scribe@guides.be">scribe@guides.be</a>
        </span>
      </fieldset>
      <fieldset style="border-bottom: none; border-left: none; border-right: none">
        <legend style="border: black solid 1px; padding: 5px">
          {{ 'homepage.modalGetPassword.warningPanel.title' | translate }}
        </legend>
        <span>
          {{ 'homepage.modalGetPassword.warningPanel.message' | translate }}
        </span>
        <ul>
          <li>
            {{ 'homepage.modalGetPassword.warningPanel.nameLabel' | translate }}
          </li>
          <li>
            {{ 'homepage.modalGetPassword.warningPanel.surNameLabel' | translate }}
          </li>
          <li>
            {{ 'homepage.modalGetPassword.warningPanel.birthDateLabel' | translate }}
          </li>
          <li>
            {{ 'homepage.modalGetPassword.warningPanel.emailLabel' | translate }}
          </li>
        </ul>
        <div>
          {{ 'homepage.modalGetPassword.warningPanel.info' | translate }}
        </div>
        <br/>
        <span>
          {{ 'homepage.modalGetPassword.warningPanel.contactUs' | translate }}  <a href="mailto:scribe@guides.be">scribe@guides.be</a>
        </span>
      </fieldset>
    </iu-modal-ui>
  </div>
  <div
    *ngIf="successMessage"
    class="clearMessageBanner"
    [ngClass]="{
      'iu-banner': true,
      'iu-success': successMessage.type === 'success',
      'iu-error': successMessage.type === 'error'
    }"
  >
    <i class="icon-ico-close" style="cursor: pointer" (click)="closeSuccessMessage($event)"></i>
    <div class="iu-banner-message">
      <i
        class="{{
          successMessage.type === 'success'
            ? 'iu-banner-icon fa-lg fas fa-check'
            : successMessage.type === 'message'
            ? 'iu-banner-icon fa-lg far fa-sticky-note'
            : 'iu-banner-icon fa-lg fas fa-exclamation-triangle'
        }}"
      ></i>
      <span class="iu-banner-message-text"> {{ successMessage.message }} </span>
    </div>
  </div>
  <!-- request new pwd modal end -->
  <!-- #endregion custo -->
</div>
<!-- </div> -->
