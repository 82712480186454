import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { AppConfig } from '@iupics-config/app.config';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'iu-join-file-ui',
  templateUrl: './join-file-ui.component.html',
  styleUrls: ['./join-file-ui.component.scss']
})
export class JoinFileUiComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  adTable_ID: number;
  record_id: number;
  @Input()
  dsKey: DataStoreKey;
  @Input()
  nbUploadedFiles = 0;
  @Input()
  data: any;
  @Input()
  query: any;

  @Output()
  nbUploadedFilesChange = new EventEmitter<number>();
  @Output()
  cancelEmitter = new EventEmitter<any>();

  baseSearch: any;
  additionalInformations: any;

  constructor(private config: AppConfig, private smService: SecurityManagerService, private docServerService: DocServerService) {}

  public ngOnInit() {
    const recordId = this.dsKey.recordId.split(',');
    this.record_id = recordId.length > 1 ? parseInt(recordId[1], 10) : -1;
    const filterFromKey = this.docServerService.createfilterFromKey(this.dsKey);
    let samples: any[] = [
      {
        ...filterFromKey,
        'META|table_id': this.adTable_ID,
        'META|record_id': recordId.length > 1 ? parseInt(recordId[1], 10) : -1
      }
    ];
    if (this.query) {
      samples = this.query;
    } else {
      //On check les colonnes indiquées dans la config comme nécessitant la récup d'attachment
      if (this.data) {
        const docFilters = this.data.DocFilters;
        if (docFilters) {
          samples = samples.concat(this.docServerService.createDocFiltersInfo(docFilters, this.dsKey));
        }
      }
    }

    this.baseSearch = cloneDeep(samples);
    const key = recordId.length > 1 ? recordId[0] : undefined;
    if (key) {
      const userContext = this.smService.getIupicsUserContext();
      let sample = cloneDeep(samples[0]);
      //add taggedColumns
      let taggedData = {};
      if (this.data) {
        const taggedColumns = this.data.TaggedColumns;
        if (taggedColumns) {
          taggedData = this.docServerService.createTaggedData(taggedColumns, this.dsKey);
        }
      }
      sample = { ...sample, ...taggedData };
      sample['META|AD_Client_ID'] = userContext['#AD_Client_ID'];
      this.additionalInformations = sample;
      const value = recordId.length > 1 ? parseInt(recordId[1], 10) : undefined;
      if (value) {
        this.additionalInformations['META|' + key] = value;
      }
    }
  }

  public ngAfterViewInit() {}

  public cancel(event: Event) {
    event.stopPropagation();
    this.cancelEmitter.emit();
  }

  public updateNbUploadedFiles(nb: number) {
    this.nbUploadedFiles = nb;
    this.nbUploadedFilesChange.emit(this.nbUploadedFiles);
  }

  public ngOnDestroy() {}
}
