<div class="iu-specific-window" [ngClass]="{ 'iu-specific-window-container': !isModal }">
    <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' }" [ngClass]="{ 'iu-modal': isModal }">
    <div class="ui-g-12">
      <div class="fields">
        <div class="top-container-CreateFromShipment">
          <p class="help">{{ 'staffManagement.title' | translate }}</p>
          <div class="ui-g-12"><ng-template #top></ng-template></div>

          <div class="ui-g-12">
            <p class="help alignRight">{{ 'staffManagement.messageAction' | translate }}</p>
            <ng-template #middle></ng-template>
          </div>
          <div class="ui-g-12">
            <fieldset style="border-bottom: none;border-left: none;border-right: none;">
              <legend style=" border: black solid 1px; padding: 5px;">{{ 'staffManagement.newAnim' | translate }}</legend>
              <ng-template #bot></ng-template>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="iu-specific-btn-container">
        <a
          *ngIf="isModal"
          class="iu-btn btn-cancel"
          (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
        >
          <i class="fa fa-ban"></i> {{ 'process.cancel' | translate }}
        </a>
      </div>
    </div>
  </p-scrollPanel>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>

<div class="specificModal" [ngClass]="{ hidden: !displayFormUI }">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
