import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridResponseJSON,
  DataStore,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { ParentChildRelation, TreeCompiereJSON } from '@compiere-ws/models/compiere-tree-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { GridTabInfinityScrollUiComponent } from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { IupicsTableDataHeader } from '@iupics-manager/models/iupics-data';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { TreeNode } from 'primeng/api/treenode';
import { map, switchMap, tap } from 'rxjs/operators';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import {
  buildValidation,
  findInTree,
  formatTree,
  getColumnsRequest,
  getDataFromTable,
  getIconForItem,
  getInTree,
  getNbLevel,
  getTableIDRequest,
  getTableNameRequest,
  isInTree,
  moveToNewIndex,
  pushInStack,
  uniformData
} from './tree-maintenance.util';

@Component({
  selector: 'iu-tree-maintenance-ui',
  templateUrl: './tree-maintenance-ui.component.html',
  styleUrls: ['./tree-maintenance-ui.component.scss']
})
export class TreeMaintenanceUIComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit, OnDestroy {
  /* View Children */
  @ViewChild('dynContainer') dynContainer: ElementRef<HTMLDivElement>;
  @ViewChild('leftBtnContainer') leftBtnContainer: ElementRef<HTMLDivElement>;
  @ViewChild('searchTreeInputComponent', { static: false }) searchTreeInputComponent: InputTextUiComponent;
  @ViewChild('gridTab', { static: false }) gridTab: GridTabInfinityScrollUiComponent;
  @ViewChild('filterSearch', { static: false }) filterSearch: ElementRef<HTMLDivElement>;

  /* Tree stuff */
  treeID: IAutocomplete;
  treeType: IAutocomplete;
  treeData: TreeCompiereJSON;
  treeNodes: TreeNode[];
  private _selectedTreeNodes: TreeNode[] = [];

  get selectedTreeNodes(): TreeNode[] {
    return this._selectedTreeNodes;
  }

  set selectedTreeNodes(selection: TreeNode[]) {
    this._selectedTreeNodes = selection
      .sort((a, b) => a.parent.children.indexOf(a) - b.parent?.children.indexOf(b))
      .sort((a, b) => a.parent?.data.id - b.parent?.data.id);
    this.isMovementDisabled =
      this._selectedTreeNodes
        .map((n) => getNbLevel(n))
        .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [] as number[]).length > 1;
  }

  isTreeLoading = false;
  treeSearchValue: string;

  /* Table stuff */
  private tableID: IAutocomplete;
  colTableHeaders: IupicsTableDataHeader[] = [];
  gridSearchValue: string;
  selectedLines: any[] = [];
  rowClassRules = {
    'tree-maintenance-row-is-summary': (params: any) => (params.data ? params.data.IsSummary === 'Y' : false)
  };

  /* Configuration stuff */
  isLetterByLetterSearch = true;
  isDeleteAll = false;
  isAddAll = false;
  isMovementDisabled = false;

  /* Private stuff */
  private removed_ids: number[] = [];
  private added_ids: number[] = [];
  private columnsToDisplay: { [tableName: string]: string[] } = {
    AD_Menu: ['$COL_ID', 'Name', 'Description', 'IsSummary', 'Action'],
    M_Product: ['$COL_ID', 'Name', 'Description', 'IsSummary', 'AD_PrintColor_ID'],
    C_BPartner: ['$COL_ID', 'Name', 'Description', 'IsSummary', 'AD_PrintColor_ID'],
    AD_Org: ['$COL_ID', 'Name', 'Description', 'IsSummary', 'AD_PrintColor_ID'],
    C_Campaign: ['$COL_ID', 'Name', 'Description', 'IsSummary', 'AD_PrintColor_ID'],
    _other_: ['$COL_ID', 'Name', 'Description', 'IsSummary']
  };
  private searchInTreeListener: () => void = () => {};

  /* CSS stuff */
  get splitHeight(): string {
    return this.dynContainer ? `calc(100% - ${this.dynContainer.nativeElement.clientHeight}px)` : undefined;
  }

  get splitTreeWidth(): string {
    return this.leftBtnContainer ? `calc(100% - ${this.leftBtnContainer.nativeElement.scrollWidth}px)` : undefined;
  }

  get treeHeight(): string {
    return this.searchTreeInputComponent
      ? `calc(100% - ${this.searchTreeInputComponent.inputRef.nativeElement.parentElement.scrollHeight}px)`
      : '100%';
  }

  get gridHeight(): string {
    return this.filterSearch ? `calc(100% - ${this.filterSearch.nativeElement.scrollHeight}px)` : '100%';
  }

  /* Constructor */
  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService,
    private messageManager: MessageManagerService,
    private renderer: Renderer2
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }
  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    ['AD_Tree_ID'].forEach((key) => {
      const field = this.dataContainers.find((item) => item.data.columnName === key);
      if (field) {
        field.fieldValueModified.subscribe(this[`handle${key}`].bind(this));
      }
    });
  }

  //#region data management
  private handleAD_Tree_ID(ds: DataStore) {
    setTimeout(() => {
      this.treeID = ds.data.AD_Tree_ID;
      this.isTreeLoading = true;
      this.selectedTreeNodes = [];
      this.selectedLines = [];
      this.treeSearchValue = undefined;
      this.gridSearchValue = undefined;
      this.store
        .getDataGrid(
          getTableIDRequest(this.treeID.id, this.connectorService.getIupicsDefaultLanguage().iso_code, this.translateService)
        )
        .toPromise()
        .then((response) => {
          this.tableID = response.data[0]['AD_Table_ID'];
          this.treeType = response.data[0]['TreeType'];
          this.getTree();
          this.getDatagrid();
        });
    }, 250);
  }

  private getTree() {
    const treeSub = this.store
      .getDataTree(this.treeID.id)
      .pipe(
        tap((_) => {
          this.searchInTreeListener();
          this.searchInTreeListener = this.renderer.listen(
            this.searchTreeInputComponent.inputRef.nativeElement,
            'keyup',
            ($e: Event) => {
              $e.stopPropagation();
              if (this.isLetterByLetterSearch) {
                this.treeSearchValue = this.searchTreeInputComponent.fieldValue;
                this.treeNodes = this.searchInTree(
                  this.treeSearchValue,
                  cloneDeep(this.treeData.treeNodes.filter((n) => n.data.id !== -2))
                );
              }
            }
          );
        }),
        map((tree) => {
          tree.treeNodes[0] = formatTree(tree.treeNodes[0]);
          return tree;
        })
      )
      .subscribe(
        (tree) => {
          if (tree) {
            this.treeData = tree;
            this.treeData.treeNodes.push({
              children: [],
              label: 'deleted nodes',
              expanded: false,
              data: {
                color: null,
                description: '',
                id: -2,
                imageIndicator: '',
                isSummary: 'Y',
                name: 'deleted nodes'
              },
              selectable: false,
              icon: null
            });
            this.treeNodes = cloneDeep(this.treeData.treeNodes.filter((n) => n.data.id !== -2));
          }
          this.isTreeLoading = false;

          treeSub.unsubscribe();
        },
        (err: HttpErrorResponse) => {
          if (err.status === 422) {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.error'),
                this.translateService.instant('tree-maintenance.no-tree-for-table', {
                  tableID: this.tableID
                }),
                'error',
                err.error
              )
            );
          }
          this.treeData = undefined;
          this.isTreeLoading = false;
          treeSub.unsubscribe();
        }
      );
  }

  private getDatagrid() {
    const gridSub = this.store
      .getDataGrid(
        getTableNameRequest(this.tableID.id, this.connectorService.getIupicsDefaultLanguage().iso_code, this.translateService)
      )
      .pipe(
        tap((_response) => (this.tableID.displayValue = _response.data[0]['TableName'])),
        switchMap((_) =>
          this.store.getDataGrid(getColumnsRequest(this.tableID.id, this.connectorService.getIupicsDefaultLanguage().iso_code))
        )
      )
      .subscribe((_response) => {
        this.colTableHeaders = this.buildColumnHeaders(_response);
        setTimeout(() => {
          this.overrideGetRows();
          gridSub.unsubscribe();
        }, 5);
      });
  }

  public refresh(event?: Event): any {
    event?.stopPropagation();

    this.removed_ids = [];
    this.added_ids = [];

    this.refreshTree();
    this.refreshGrid();
  }

  private refreshTree() {
    // refresh tree
    this.treeData = undefined;
    this.treeNodes = [];
    this.isTreeLoading = true;
    this.getTree();
  }

  private refreshGrid() {
    // refresh grid
    this.gridTab.agGrid.api.deselectAll();
    this.getDatagrid();
  }

  public saveTree(event: Event) {
    event.stopPropagation();
    const dataToSave: ParentChildRelation[] = [];
    this.prepareDataToSave(this.treeData.treeNodes, dataToSave, 0);

    const tree = cloneDeep(this.treeData);
    tree.treeNodes = [];
    tree.parentChildRelations = dataToSave.filter(
      (i) =>
        i.node_ID >= 0 &&
        tree.parentChildRelations.findIndex(
          (pcr) => pcr.node_ID === i.node_ID && pcr.parent_ID === i.parent_ID && pcr.seqNo === i.seqNo
        ) < 0
    );
    const sub = this.store.saveDataTree(tree).subscribe((_) => {
      this.refresh();
      sub.unsubscribe();
    });
  }

  private prepareDataToSave(nodes: TreeNode[], dataToSave: ParentChildRelation[], parent_ID: number) {
    let seqNo = 0;
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      seqNo += 10;
      dataToSave.push({ node_ID: node.data.id, parent_ID: parent_ID, seqNo: seqNo });

      if (node.children && node.children.length > 0) {
        this.prepareDataToSave(node.children, dataToSave, node.data.id);
      }
    }
  }
  //#endregion

  //#region grid management
  private overrideGetRows() {
    if (this.gridTab.hasOwnProperty('oldGetRows')) {
      this.gridTab.getRows = this.gridTab['oldGetRows'];
      delete this.gridTab['oldGetRows'];
    }
    this.gridTab['oldGetRows'] = this.gridTab.getRows;
    this.gridTab.getRows = (
      dataStoreRequest: DataStoreRequest,
      params: any,
      isNewRecord: boolean,
      indexEditViewToEdit: number,
      recordChanged: boolean,
      callBack?: any
    ) => {
      const tableName = Object.keys(this.columnsToDisplay).includes(this.tableID.displayValue)
        ? this.tableID.displayValue
        : '_other_';

      Object.assign(
        dataStoreRequest.compiereRequest,
        getDataFromTable(
          this.tableID.displayValue,
          this.colTableHeaders
            .filter((columnHeader) => this.columnsToDisplay[tableName].includes(columnHeader.field))
            .map((columnHeader) => ({
              id: columnHeader.field,
              field: columnHeader.field,
              displayName: columnHeader.headerName
            })),
          buildValidation(this.tableID, this.treeType, this.treeID, this.removed_ids, this.added_ids),
          this.connectorService.getIupicsDefaultLanguage().iso_code
        ).compiereRequest
      );

      if (this.gridSearchValue) {
        dataStoreRequest.compiereRequest.filterModel['Name'] = {
          filterType: CompiereDataGridFilterType.TEXT,
          operators: [OperatorFilterType.CONTAINS],
          values: [this.gridSearchValue]
        };
      }

      this.gridTab['oldGetRows'](dataStoreRequest, params, isNewRecord, indexEditViewToEdit, recordChanged, callBack);
    };
    this.gridTab.refresh();
    this.gridTab.tableHeight = '100%';
  }

  private buildColumnHeaders(response: CompiereDataGridResponseJSON): IupicsTableDataHeader[] {
    const columnHeaders: IupicsTableDataHeader[] = [];
    const tableName = Object.keys(this.columnsToDisplay).includes(this.tableID.displayValue)
      ? this.tableID.displayValue
      : '_other_';

    const datas = response.data.filter(
      (column) => column['IsKey'] === 'Y' || this.columnsToDisplay[tableName].includes(column['ColumnName'])
    );

    for (let i = 0; i < datas.length; i++) {
      const row = datas[i];

      const columnHeader: IupicsTableDataHeader = {
        field: row['ColumnName'],
        headerName: row['Name'],
        filter: 'agTextColumnFilter',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        editable: false,
        hide: false,
        enableCellChangeFlash: false,
        filterParams: {
          newRowsAction: 'keep',
          applyButton: true,
          clearButton: true,
          filterOptions: ['contains', 'notContains', 'startsWith']
        }
      };
      columnHeaders.push(columnHeader);
    }

    return columnHeaders;
  }

  public searchInGrid(value: string) {
    this.gridSearchValue = value;
    this.gridTab.agGrid.api.deselectAll();
    this.gridTab.refresh();
  }

  public onSelectOnGrid({ data }: { data: any[] }) {
    this.selectedLines = data;
  }
  //#endregion

  //#region tree management
  private searchInTree(value: string, treeNodes: TreeNode[]) {
    for (let i = 0; i < treeNodes.length; i++) {
      const treeNode = treeNodes[i];
      if (!treeNode.label.toLowerCase().trim().includes(value.toLowerCase().trim())) {
        if (treeNode.data.isSummary) {
          treeNode.children = this.searchInTree(value, treeNode.children);
          if (!treeNode.children.length) {
            treeNodes[i] = undefined;
          }
        } else {
          treeNodes[i] = undefined;
        }
      }
    }
    return treeNodes.filter((i) => i);
  }

  public searchFromFieldValue(value: string) {
    this.treeSearchValue = value;
    if (value) {
      this.treeNodes = this.searchInTree(value, cloneDeep(this.treeData.treeNodes.filter((n) => n.data.id !== -2)));
    } else {
      this.treeNodes = cloneDeep(this.treeData.treeNodes.filter((n) => n.data.id !== -2));
    }
  }

  public addInTree(event: Event) {
    event.stopPropagation();
    if (this.selectedLines?.length) {
      let selectedTreeNode = this.treeData.treeNodes.find((n) => n.data.id === -1);
      if (this.selectedTreeNodes?.length === 1 && this.selectedTreeNodes[0].data.IsSummary === 'Y') {
        selectedTreeNode = this.selectedTreeNodes[0];
      }
      selectedTreeNode.children = selectedTreeNode.children || [];
      const alreadyInTree: string[] = [];
      for (let i = 0; i < this.selectedLines.length; i++) {
        const line = uniformData(this.selectedLines[i]);
        if (
          isInTree(
            this.treeData.treeNodes.filter((n) => n.data.id === -2),
            line
          )
        ) {
          this.moveInTree({
            originalEvent: document.createEvent('Event'),
            dropNode: selectedTreeNode,
            dragNode: this.treeData.treeNodes.filter((n) => n.data.id === -2)[0].children.find((n) => n.data.id === line.id)
          });
          if (this.removed_ids.includes(line.id)) {
            this.removed_ids = this.removed_ids.filter((id) => id !== line.id);
          } else if (!this.added_ids.includes(line.id)) {
            this.added_ids.push(line.id);
          }
        } else if (!isInTree(this.treeData.treeNodes, line)) {
          selectedTreeNode.children.push({
            label: line.name,
            data: line,
            expanded: false,
            expandedIcon: line.isSummary ? 'fas fa-folder-open' : '',
            collapsedIcon: line.isSummary ? 'fas fa-folder' : '',
            selectable: true,
            children: line.isSummary ? [] : null,
            icon: !line.isSummary ? getIconForItem(line) : ''
          } as TreeNode);
          if (this.removed_ids.includes(line.id)) {
            this.removed_ids = this.removed_ids.filter((id) => id !== line.id);
          } else if (!this.added_ids.includes(line.id)) {
            this.added_ids.push(line.id);
          }
        } else {
          alreadyInTree.push(line.name);
        }
      }
      if (alreadyInTree?.length) {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translateService.instant('generic.info'),
            this.translateService.instant('tree-maintenance.already-in-tree', {
              nodes: alreadyInTree.join(', ')
            }),
            'message'
          )
        );
      }
      this.refreshGrid();
      this.searchFromFieldValue(this.treeSearchValue);
    }
  }

  public dropNode({
    originalEvent,
    dragNode,
    dropNode
  }: {
    originalEvent: DragEvent;
    dragNode: TreeNode;
    dropNode: TreeNode;
  }): void {
    originalEvent?.stopPropagation();
    const oldParent = findInTree(this.treeData.treeNodes, dragNode)?.parent;
    if (oldParent) {
      const oldNode = oldParent.children.splice(
        oldParent.children.findIndex((n) => n.data.id === dragNode.data.id),
        1
      )[0];
      let newParent: TreeNode;
      if (
        originalEvent?.target &&
        (originalEvent.target as HTMLElement).tagName.toLowerCase() === 'li' &&
        (originalEvent.target as HTMLElement).classList.contains('ui-treenode-droppoint')
      ) {
        // si on met sur la barre bleue
        newParent = findInTree(this.treeData.treeNodes, dropNode.parent);
        if (!newParent) {
          return;
        }
        const dropIndex = newParent.children.findIndex((n) => n.data.id === dropNode.data.id);
        newParent.children.splice(dropIndex, 0, oldNode);
      } else if (originalEvent?.target && (originalEvent.target as HTMLElement).tagName.toLowerCase() === 'div') {
        // on drop sur un element
        if (!dropNode.data.isSummary) {
          return;
        }
        newParent = findInTree(this.treeData.treeNodes, dropNode);
        if (!newParent) {
          return;
        }
        newParent.children.push(oldNode);
      }
      oldNode.parent = newParent;
    }
  }

  private moveInTree(
    { originalEvent, dragNode, dropNode }: { originalEvent: Event; dragNode: TreeNode; dropNode: TreeNode },
    position: 'top' | 'bottom' | 'up' | 'down' | number = 'bottom'
  ): void {
    originalEvent?.stopPropagation();
    let dragStack = [],
      dropStack = [];
    if (dragNode?.parent) {
      dragStack = pushInStack(dragNode.parent);
    }
    dropStack = pushInStack(dropNode);
    if (dragStack.length) {
      const _dragNodeParent = getInTree(this.treeData.treeNodes, dragStack);
      _dragNodeParent.children.splice(
        _dragNodeParent.children.findIndex((n) => n.data.id === dragNode.data.id),
        1
      );
    }
    if (dropStack.length) {
      const _dropNode = getInTree(this.treeData.treeNodes, dropStack);
      switch (position) {
        case 'bottom':
          _dropNode.children.push(dragNode);
          break;
        case 'top':
          _dropNode.children = [dragNode, ..._dropNode.children];
          break;
        case 'down':
          const down_index = _dropNode.children.findIndex((n) => n.data.id === dragNode.data.id);
          _dropNode.children = moveToNewIndex(_dropNode.children, down_index, down_index + 1);
          break;
        case 'up':
          const up_index = _dropNode.children.findIndex((n) => n.data.id === dragNode.data.id);
          _dropNode.children = moveToNewIndex(_dropNode.children, up_index, up_index - 1);
          break;
        default:
          _dropNode.children.push(dragNode);
          const def_index = _dropNode.children.length - 1;
          _dropNode.children = moveToNewIndex(_dropNode.children, def_index, position);
      }
      dragNode.parent = dropNode;
    }
  }

  public deleteFromTree(event: Event): void {
    event.stopPropagation();
    if (this.selectedTreeNodes?.length) {
      this._deleteFromTree(this.selectedTreeNodes);
      this.refreshGrid();
      this.searchFromFieldValue(this.treeSearchValue);
    }
  }

  private _deleteFromTree(nodes: TreeNode[]): void {
    const fakeDropNode = this.treeData.treeNodes.find((n) => n.data.id === -2);
    for (let i = 0; i < nodes.length; i++) {
      const fakeDragNode = nodes[i];
      if (fakeDragNode.children?.length) {
        this._deleteFromTree(fakeDragNode.children);
        fakeDragNode.children = [];
      }
      this.moveInTree({ originalEvent: document.createEvent('Event'), dragNode: fakeDragNode, dropNode: fakeDropNode });
      fakeDragNode.parent = fakeDropNode;
      if (this.added_ids.includes(fakeDragNode.data.id)) {
        this.added_ids = this.added_ids.filter((id) => id !== fakeDragNode.data.id);
      } else if (!this.removed_ids.includes(fakeDragNode.data.id)) {
        this.removed_ids.push(fakeDragNode.data.id);
      }
    }
  }

  public stepUp(event: Event, marker?: string): void {
    event?.stopPropagation();
    if (!this.selectedTreeNodes?.length) {
      return;
    }
    if (marker === '*') {
      for (let i = this.selectedTreeNodes.length - 1; i >= 0; i--) {
        const node = this.selectedTreeNodes[i];
        this.moveInTree({ originalEvent: event, dragNode: node, dropNode: this.treeNodes[0] }, 'top');
      }
      this.searchFromFieldValue(this.treeSearchValue);
      this.selectedTreeNodes = this.reselect(this.treeNodes[0]);
    } else {
      for (let i = 0; i < this.selectedTreeNodes.length; i++) {
        const node = this.selectedTreeNodes[i];
        const { parent } = node;
        const old_index = parent.children.indexOf(node);
        if (old_index > 0 && !this.selectedTreeNodes.includes(parent.children[old_index - 1])) {
          node.parent = undefined;
          // déplacement dans l'arbre visuel
          parent.children = moveToNewIndex(parent.children, old_index, old_index - 1);
          // déplacement dans le vrai arbre
          this.moveInTree({ originalEvent: event, dragNode: node, dropNode: parent }, 'up');
          node.parent = parent;
        }
      }
    }
  }

  public stepDown(event: Event, marker?: string): void {
    event?.stopPropagation();
    if (!this.selectedTreeNodes?.length) {
      return;
    }
    if (marker === '*') {
      for (let i = 0; i < this.selectedTreeNodes.length; i++) {
        const node = this.selectedTreeNodes[i];
        this.moveInTree({ originalEvent: event, dragNode: node, dropNode: this.treeNodes[0] }, 'bottom');
      }
      this.searchFromFieldValue(this.treeSearchValue);
      this.selectedTreeNodes = this.reselect(this.treeNodes[0]);
    } else {
      for (let i = this.selectedTreeNodes.length - 1; i >= 0; i--) {
        const node = this.selectedTreeNodes[i];
        const { parent } = node;
        const old_index = parent.children.indexOf(node);
        if (old_index < parent.children.length - 1 && !this.selectedTreeNodes.includes(parent.children[old_index + 1])) {
          node.parent = undefined;
          // déplacement dans l'arbre visuel
          parent.children = moveToNewIndex(parent.children, old_index, old_index + 1);
          // déplacement dans le vrai arbre
          this.moveInTree({ originalEvent: event, dragNode: node, dropNode: parent }, 'down');
          node.parent = parent;
        }
      }
    }
  }

  public stepIn(event: Event): void {
    event?.stopPropagation();
    let changed = false;
    for (let i = this.selectedTreeNodes.length - 1; i >= 0; i--) {
      const node = this.selectedTreeNodes[i];
      const { parent } = node;
      const index = parent.children.indexOf(node);
      const next = parent.children[index + 1];
      if (next && next.data.isSummary) {
        if (!next.expanded) {
          this.expandNode({ originalEvent: event, node: next });
        }
        this.moveInTree({ originalEvent: event, dragNode: node, dropNode: next }, 'top');
        changed = true;
      }

      let isFollowed = true;
      while (i > 0 && isFollowed) {
        const _next = this.selectedTreeNodes[i - 1];
        if (_next.parent === parent && parent.children.indexOf(_next) === index - 1) {
          this.moveInTree({ originalEvent: event, dragNode: _next, dropNode: next }, 'top');
          i--;
        } else {
          isFollowed = false;
        }
      }
    }
    if (changed) {
      this.searchFromFieldValue(this.treeSearchValue);
      this.selectedTreeNodes = this.reselect(this.treeNodes[0]);
    }
  }

  public stepOut(event: Event): void {
    event?.stopPropagation();
    let changed = false;
    for (let i = this.selectedTreeNodes.length - 1; i >= 0; i--) {
      const node = this.selectedTreeNodes[i];
      const { parent } = node;
      if (parent.parent) {
        this.moveInTree(
          { originalEvent: event, dragNode: node, dropNode: parent.parent },
          parent.parent.children.indexOf(parent)
        );
        changed = true;
      }
    }
    if (changed) {
      this.searchFromFieldValue(this.treeSearchValue);
      this.selectedTreeNodes = this.reselect(this.treeNodes[0]);
    }
  }

  private reselect(_in: TreeNode): TreeNode[] {
    const _selectedTreeNodes = [];
    if (this.selectedTreeNodes?.length) {
      for (let i = 0; i < _in.children.length; i++) {
        const node = _in.children[i];
        const _node = this.selectedTreeNodes.find((n) => n.data.id === node.data.id);
        if (_node) {
          _selectedTreeNodes.push(node);
        } else if (node.children?.length) {
          _selectedTreeNodes.push(...this.reselect(node));
        }
      }
    }
    return _selectedTreeNodes;
  }

  public expandNode({ originalEvent, node }: { originalEvent: Event; node: TreeNode }): void {
    originalEvent?.stopPropagation();
    const path = pushInStack(node);
    const data_node = getInTree(this.treeData.treeNodes, path);
    data_node.expanded = true;
  }

  public collapseNode({ originalEvent, node }: { originalEvent: Event; node: TreeNode }): void {
    originalEvent?.stopPropagation();
    const stack = pushInStack(node);
    const data_node = getInTree(this.treeData.treeNodes, stack);
    data_node.expanded = false;
  }
  //#endregion

  public setConfig(config: string, value: any) {
    if (typeof this[config] === 'boolean') {
      this[config] = value === 'Y';
    } else {
      this[config] = value;
    }
  }
}
