<fieldset
  [attr.id]="id"
  [ngClass]="[
    'ui-fieldset ui-widget ui-widget-content ui-corner-all',
    toggleable ? 'ui-fieldset-toggleable' : '',
    styleClass ? styleClass : ''
  ]"
  [ngStyle]="style"
  [attr.data-cy-tablename]="tableName ? tableName : legend"
  [attr.aria-expanded]="!collapsed"
>
  <legend *ngIf="isLegendVisible" [ngClass]="['ui-fieldset-legend ui-corner-all ui-state-default ui-unselectable-text']">
    <ng-container>
      <!--#region custo-->
      <a
        tabindex="0"
        *ngIf="!externURL"
        (click)="handleLegendEvent($event)"
        (keydown.enter)="handleLegendEvent($event)"
        [attr.aria-controls]="id + '-content'"
        [attr.aria-expanded]="!collapsed"
        data-cy="fieldset"
      >
        <ng-container *ngTemplateOutlet="legendContent"></ng-container>
      </a>
      <a
        tabindex="0"
        *ngIf="externURL"
        [href]="externURL"
        target="_blank"
        [attr.aria-controls]="id + '-content'"
        [attr.aria-expanded]="!collapsed"
      >
        <ng-container *ngTemplateOutlet="legendContent"></ng-container>
      </a>
      <!--#endregion custo-->
    </ng-container>
    <ng-template #legendContent>
      <span class="ui-fieldset-legend-text" [style.font-size]="overridedCSS?.legendFontSize">{{ legend }}</span>
      <span
        *ngIf="toggleable && !externURL && (!isSingleRow || isReadOnly)"
        [ngClass]="['ui-fieldset-toggler pi', collapsed ? 'pi-plus' : 'pi-minus']"
      ></span>
      <!--#region custo-->
      <span class="ui-fieldset-toggler pi pi-ext" *ngIf="externURL"></span>
      <!--#endregion custo-->
      <span
        class="icon-ico-next-info"
        tooltipPosition="top"
        *ngIf="isSingleRow && !isReadOnly"
        showDelay="250"
        life="3"
        data-cy="openNewBlade"
        pTooltip="{{ 'menuBarDetails.openNewBlade' | translate }}"
      ></span>
      <ng-content select="p-header"></ng-content>
    </ng-template>
  </legend>
  <div
    [attr.id]="id + '-content'"
    [@fieldsetContent]="
      collapsed
        ? { value: 'hidden', params: { transitionParams: transitionOptions } }
        : { value: 'visible', params: { transitionParams: transitionOptions } }
    "
    [ngClass]="[collapsed || animating ? 'ui-fieldset-content-wrapper-overflown' : '', 'ui-fieldset-content-wrapper']"
    [attr.aria-hidden]="collapsed"
    (@fieldsetContent.done)="onToggleDone($event)"
    role="region"
  >
    <div class="ui-fieldset-content"><ng-content></ng-content></div>
  </div>
</fieldset>
