<!-- #region custo|disabled -->
<p-accordionTab
  [ngStyle]="{ display: displayCss }"
  [header]="label"
  [selected]="selected"
  [ngClass]="[isMandatory ? 'mandatory' : '', 'ui-g-12']"
  [attr.data-cy-accordion-name]="label"
>
<!-- #endregion custo -->
  <ng-template iuDynamicContainer></ng-template>
</p-accordionTab>
