<span
  [style.backgroundColor]="backgroundColorCss"
  [style.display]="displayCss"
  class="{{ cssClass }} iu-input-text-ui"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
>
  <label *ngIf="this.isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0">{{
    label | textLimit: this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7
  }}</label>
  <input
    #input
    placeholder="{{ placeHolder }}"
    [(ngModel)]="fieldValue"
    [pTooltip]="tooltip"
    life="3"
    tooltipPosition="top"
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($event)"
    [attr.disabled]="isReadOnly ? 'disabled' : null"
    showDelay="500"
    [(class)]="mandatoryCss"
    value=""
    [type]="data ? data.fieldType : 'text'"
    (change)="dataChange(input.value)"
    [ngClass]="{ 'iu-field-conflict-text': hasConflict }"
    pInputText
    tooltipZIndex="2100"
  />
  <i class="ui-inputgroup-addon rtconflict fas fa-map-marker-alt iconClass"></i>
</span>
