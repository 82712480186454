<span
  #container
  life="3"
  pTooltip="{{ description }}"
  tooltipPosition="top"
  tooltipZIndex="2100"
  showDelay="500"
  [ngClass]="[
    'ui-autocomplete ui-widget',
    dropdown ? 'ui-autocomplete-dd' : '',
    multiple ? 'ui-autocomplete-multiple' : '',
    styleClass || ''
  ]"
  [ngStyle]="style"
>
  <!-- #region custo -->
  <label
    *ngIf="isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0"
    [style.font-size]="overridedCSS?.labelFontSize"
    ><span
      (click)="onLabelClickEmitter.emit()"
      [ngClass]="{
        autoCompleteLabelZoom: this.data.detailZoom !== null && this.data.detailZoom !== null
      }"
      >{{ label | textLimit: this.el.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}</span
    ></label
  >
  <!-- #endregion custo -->
  <input
    *ngIf="!multiple"
    [ngClass]="[
      'ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input',
      mandatoryCss || '',
      inputStyleClass || ''
    ]"
    #in
    [attr.type]="type"
    [attr.id]="inputId"
    [ngStyle]="inputStyle"
    [attr.required]="required"
    [value]="inputFieldValue"
    (click)="onInputClick($event)"
    (input)="onInput($event)"
    (keydown)="onKeydown($event)"
    (keyup)="onKeyup($event)"
    (focus)="!readonly ? handleDropdownClick($event) : in.blur()"
    [tabindex]="readonly ? '-1' : null"
    (blur)="onInputBlur($event)"
    (change)="onInputChange($event)"
    [attr.placeholder]="placeholder"
    [attr.size]="size"
    [attr.maxlength]="maxlength"
    [attr.tabindex]="tabindex"
    [readonly]="readonly"
    [disabled]="disabled"
    autocomplete="no"
    [style.font-size]="overridedCSS?.inputFontSize"
    data-cy="input-autocomplete"
    [style.height]="overridedCSS?.inputHeight"
    [attr.data-cy-columnName]="data.columnName ? data.columnName : columnName"
  />

  <ul
    *ngIf="multiple"
    #multiContainer
    [ngClass]="[
      disabled ? 'ui-disabled' : '',
      focus ? 'ui-state-focus' : '',
      'ui-autocomplete-multiple-container ui-widget ui-inputtext ui-state-default ui-corner-all ui-autocomplete-input'
    ]"
    (click)="multiIn.focus()"
    [ngStyle]="{ display: 'flex', float: 'left', overflow: 'hidden', 'margin-right': '-1px' }"
    data-cy="overlay-multiple-autocomplete"
  >
    <div #wrapper (wheel)="handleWheel($event)" (scroll)="$event.stopPropagation()">
      <ng-container *ngIf="value?.length">
        <li #token *ngFor="let val of value" class="ui-autocomplete-token ui-highlight ui-corner-all">
          <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: val }"></ng-container>
          <span *ngIf="!selectedItemTemplate" class="ui-autocomplete-token-label">{{ val.displayValue }}</span>
          <span class="ui-autocomplete-token-icon fa fa-fw fa-close" (click)="removeItem(token)" *ngIf="!disabled"></span>
        </li>
      </ng-container>
    </div>
    <li class="ui-autocomplete-input-token">
      <input
        #multiIn
        [attr.type]="type"
        [attr.id]="inputId"
        [attr.placeholder]="value && value.length ? null : placeholder"
        [attr.tabindex]="tabindex"
        (input)="onInput($event)"
        (click)="onInputClick($event)"
        (keydown)="onKeydown($event)"
        [readonly]="readonly"
        (keyup)="onKeyup($event)"
        (focus)="!readonly ? onInputFocus($event) : in.blur()"
        [tabindex]="readonly ? '-1' : null"
        (blur)="onInputBlur($event)"
        (change)="onInputChange($event)"
        autocomplete="off"
        [ngStyle]="inputStyle"
        class="{{ inputStyleClass }}"
        data-cy="input-autocomplete"
        [attr.data-cy-columnName]="data.columnName ? data.columnName : columnName"
      />
    </li>
  </ul>
  <i *ngIf="loading" class="ui-autocomplete-loader icon-ico-line-loader"></i>
  <i
    [style.height]="overridedCSS?.inputHeight"
    [ngClass]="['icon-ico-search-bolder', iconeMandatoryCss || '']"
    aria-hidden="true"
    #ddBtn
    type="button"
    data-cy="input-autocomplete-lookup"
    [attr.data-cy-lookuui-columnName]="data.columnName ? data.columnName : columnName"
    (click)="
      checkSpecificView()
        ? !readonly && !disabled
          ? showFormPanel()
          : null
        : !readonly && !disabled
        ? data.urlSearch
          ? !isRendererView
            ? openSearchPanel()
            : handleDropdownClick($event)
          : handleDropdownClick($event)
        : null
    "
    *ngIf="dropdown"
  ></i>
  <div
    #panel
    *ngIf="overlayVisible"
    [style]="'overflow: auto; background: #fff;z-index: 9999;'"
    [ngClass]="['ui-autocomplete-panel ui-component ui-widget-content ui-corner-all ui-shadow']"
    [style.max-height]="scrollHeight"
    [ngStyle]="panelStyle"
    [class]="panelStyleClass"
    [@overlayAnimation]="{
      value: 'visible',
      params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
    }"
    (@overlayAnimation.start)="onOverlayAnimationStart($event)"
  >
    <ul
      class="ui-autocomplete-items ui-autocomplete-list ui-widget-content ui-widget ui-corner-all ui-helper-reset"
      data-cy="overlay-autocomplete"
    >
      <li
        *ngFor="let option of suggestions; let idx = index"
        [ngClass]="[
          'ui-autocomplete-list-item ui-corner-all',
          option.id === -1 ? 'moreResults' : '',
          highlightOption == option ? 'ui-highlight' : ''
        ]"
        (mouseenter)="highlightOption = option"
        (mouseleave)="highlightOption = null"
        (click)="selectItem(option); $event.stopPropagation()"
      >
        <span [style.font-size]="overridedCSS?.inputFontSize" *ngIf="!itemTemplate && option.id !== -1">{{
          field ? ObjectUtils.resolveFieldData(option, field) : option.displayValue
        }}</span>
        <span [style.font-size]="overridedCSS?.inputFontSize" class="moreResultsSpan" *ngIf="!itemTemplate && option.id === -1">{{
          field ? ObjectUtils.resolveFieldData(option, field) : option.displayValue
        }}</span>
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option, index: idx }"></ng-container>
      </li>
      <li *ngIf="noResults && emptyMessage" class="ui-autocomplete-list-item ui-corner-all">
        {{ emptyMessage }}
      </li>
    </ul>
  </div>
</span>
