import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DataStore, DataStoreKey, DataStoreName } from '@compiere-ws/models/compiere-data-json';
import { CompiereNotification } from '@compiere-ws/models/compiere-notification-json';
import { CallInProgressService } from '@compiere-ws/services/call-in-progress/call-in-progress.service';
import { CompiereExportDataService } from '@compiere-ws/services/compiere-export-data/compiere-export-data.service';
import { PrintReportService } from '@compiere-ws/services/compiere-print-report/print-report.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { ModalUiComponent } from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { PrintReportManager } from '@iupics-manager/managers/print-report/print-report-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'iu-export-data-ui',
  templateUrl: './export-data-ui.component.html',
  styleUrls: ['./export-data-ui.component.scss']
})
export class ExportDataUiComponent implements OnInit, OnDestroy {
  @ViewChild('printFormatsAutocomplete')
  printFormatsAutocomplete: AutocompleteUiComponent;
  @ViewChild('fileTypesAutocomplete')
  fileTypesAutocomplete: AutocompleteUiComponent;

  // @Input()
  // exportDataOptions: any;
  @Input()
  ids: any[];
  @Input()
  tableId: number;
  @Input()
  tabId: number;
  @Input()
  dsKey: DataStoreKey;
  @Input()
  whereClause: string;
  @Input()
  sourceModal: ModalUiComponent;
  @Output()
  cancelExportDataEmitter = new EventEmitter<any>();

  // isMobile = false;
  style = {};
  subscriptions: any[] = [];
  dataStored: DataStore;
  fileTypes: any = {};
  printFormats: any = {};
  fileType: any;
  printFormat: any;
  recordIds: string[] = [];
  //#region custo
  defaultValue = { id: 'PDF', displayValue: 'Fichier Acrobat PDF' };
  //#endregion custo
  // width: number;
  // height: number;

  exportDataOptions$: Observable<any>;
  isLoading = false;
  constructor(
    private translateService: TranslateService,
    private exportDataService: CompiereExportDataService,
    private printReportService: PrintReportService,
    private messageManager: MessageManagerService,
    private printReportManager: PrintReportManager,
    private connectorService: SecurityManagerService,
    private translate: TranslateService,
    private socketService: SocketService,
    private callInProgressService: CallInProgressService,
    private store: DataStoreService
  ) {}

  ngOnInit() {
    // this.width = Global.getDeviceWidth();
    // this.height = Global.getDeviceHeight();
    this.exportDataOptions$ = this.exportDataService.getExportTableDataFormats(this.tableId, 3).pipe(
      map((exportDataOptions) => {
        const obj: any = {};
        if (exportDataOptions.length > 1) {
          obj['printFormats'] = {
            items: exportDataOptions[0].listBoxVO.options.map((printFormat: any) => ({
              id: printFormat.id,
              displayValue: printFormat.name,
              model: printFormat
            })),
            IsParam: false
          };
          obj['fileTypes'] = {
            items: exportDataOptions[1].listBoxVO.options
              //#region custo
              .filter((item) => item.id === 'PDF' || item.id === 'XLS')
              //#endregion custo
              .map((fileType: any) => ({
                id: fileType.id,
                displayValue: fileType.name,
                model: fileType
              })),
            IsParam: false
          };
          obj['recordIds'] = this.ids;
        }
        return obj;
      })
    );
    if (this.ids !== undefined && this.ids.length > 0) {
    }
    //#region custo
    this.fileType = { id: 'PDF', displayValue: 'Fichier Acrobat PDF' };
    //#endregion custo
  }

  /**
   * Export des données sélectionnées sous un format choisi
   * @param {Event}event
   */
  public exportData(event: Event, recordIds: any[]) {
    event.stopPropagation();
    const ids = [];
    let ctx = {};
    if (this.dsKey) {
      const record_id =
        this.dsKey.recordId.split(',').length > 1
          ? this.dsKey.recordId.split(',').length > 1
            ? this.dsKey.recordId.split(',')[1]
            : null
          : null;
      ids.push(parseInt(record_id, 10));
      ctx = this.store.getStore(this.dsKey, DataStoreName.CURRENT).data;
    }
    if (recordIds) {
      recordIds.forEach((recordId) => {
        const recordIdSplit = recordId.split(',');
        ids.push(recordIdSplit[1]);
      });
      ctx = this.connectorService.getIupicsUserContext();
    }
    if (this.callInProgressService.isSubscriptionDone(this.tabId + '', 'export-data.exportData')) {
      if (this.sourceModal) {
        this.sourceModal.isLoading = true;
      } else {
        this.isLoading = true;
      }
      this.callInProgressService.setSubscription(
        this.tabId + '',
        'export-data.exportData',
        this.printReportManager
          .printReportWithChoice(
            ids,
            this.connectorService.getIupicsUserAccount().session_id,
            this.tabId,
            this.printFormat ? this.printFormat.id : -1,
            this.fileType.id,
            this.whereClause,
            ctx
          )
          .subscribe(
            (response) => {
              if (response) {
                if (this.sourceModal) {
                  this.sourceModal.isLoading = false;
                } else {
                  this.isLoading = false;
                }
                this.messageManager.newMessage(
                  new IupicsMessage(
                    this.translate.instant('exportData.messageTitle'),
                    this.translate.instant('exportData.exportDataStarted'),
                    'success'
                  )
                );

                (async () => {
                  const channel = this.socketService.openDataChannel<CompiereNotification>(
                    SocketService.ROLE_NOTIFICATION_CHANNEL
                  );
                  for await (const notification of channel) {
                    // if (
                    //   notification &&
                    //   notification.source &&
                    //   notification.source.description &&
                    //   notification.source.description.includes(response)
                    // ) {
                    //   if (notification.source && notification.source.fileLinks) {
                    //     setTimeout(() => {
                    //       this.subscriptions.push(
                    //         this.printReportService.downloadReport(notification.source.fileLinks).subscribe(resp => {
                    //           Global.downloadFile(resp);
                    //           this.cancelExportDataEmitter.emit();
                    //           if (this.sourceModal) {
                    //             this.sourceModal.isLoading = false;
                    //           } else {
                    //             this.isLoading = false;
                    //           }
                    //           channel.unsubscribe();
                    //         })
                    //       );
                    //     }, 1000);
                    //   }
                    // }
                  }
                })();
              } else {
                this.messageManager.newMessage(
                  new IupicsMessage(
                    this.translate.instant('exportData.messageTitle'),
                    this.translate.instant('exportData.exportDataFail'),
                    'error'
                  )
                );
              }
              this.callInProgressService.completeSubscription(this.tabId + '', 'export-data.exportData');
            },
            (err) => {
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translate.instant('exportData.messageTitle'),
                  this.translate.instant('exportData.exportDataFail'),
                  'error'
                )
              );
            },
            () => {
              this.callInProgressService.completeSubscription(this.tabId + '', 'export-data.exportData');
              this.isLoading = false;
            }
          )
      );
    }
  }
  selectFileType(fileType: any) {
    this.fileType = fileType;
  }
  selectPrintFormat(printFormat: any) {
    this.printFormat = printFormat;
  }
  /**
   * Change la taille de la modal en fonction du screen
   * @param {Event}event
   */
  // @HostListener('window:resize', ['$event'])
  // onResize(event: Event) {
  //   event.stopPropagation();
  //   this.width = Global.getDeviceWidth();
  //   this.height = Global.getDeviceHeight();
  // }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
