<div
  #widgetElement
  class="box firstbox ui-g-12 iupics-hover-item"
  [ngStyle]="{ 'border-top-color': !widget.color ? 'white' : widget.color, height: '100%' }"
  draggable="true"
  (drag)="$event.preventDefault()"
  (dragstart)="$event.preventDefault(); showMenu = false; showColor = false"
  (dragend)="$event.preventDefault()"
  (dragenter)="$event.preventDefault()"
  (dragleave)="$event.preventDefault()"
  (dragover)="$event.preventDefault()"
  (drop)="$event.preventDefault()"
  [attr.data-cy-widget-name]="widget?.title"
>
  <div class="box ui-g-12 ui-g">
    <div class="box ui-g-11">
      <h4>{{ widget?.title }}</h4>
    </div>
    <div draggable="true" (dragstart)="$event.stopPropagation(); $event.preventDefault()" class="box ui-g-1 edit-menu">
      <ul [ngClass]="['edit-item', showMenu ? 'blocked' : '']">
        <li [ngClass]="[showMenu ? 'opened' : '']">
          <a
            href="#"
            class="icon-ico-menu-bullets"
            (click)="$event.preventDefault(); showColor = false"
            iuToggleButton
            [iuToggleButtonSwitch]="true"
            [(iuToggleButtonVar)]="showMenu"
            ><span> </span
          ></a>
          <ul>
            <ng-container *ngIf="showMenu">
              <ng-container *ngIf="!showColor">
                <li>
                  <a data-cy="change-color-widget" href="#" (click)="$event.preventDefault(); showColor = true">{{
                    'widgetUi.changeColor' | translate
                  }}</a>
                </li>
                <li>
                  <a data-cy="delete-widget" href="#" (click)="$event.preventDefault(); onDelete(index)">{{
                    'widgetUi.remove' | translate
                  }}</a>
                </li>
              </ng-container>
              <li *ngIf="showColor" class="new-menu">
                <div style="text-align: center;" class="widgetColor">
                  <div class="color-container">
                    <div class="dot" *ngFor="let color of defaultColor">
                      <span
                        [ngClass]="['dot-color', isSelectedColor(color) ? 'selected' : '']"
                        [ngStyle]="{ 'background-color': color }"
                        (click)="changeDefaultColor(color)"
                      ></span>
                    </div>
                  </div>
                  <div *ngIf="!showMoreColor" (click)="showMoreColor = true">
                    <img
                      width="25"
                      height="25"
                      src="https://raw.githubusercontent.com/SahAssar/bolt-bootstrap-colorpicker/master/web/colorpicker-icon.png"
                      alt=""
                      style="cursor: pointer;"
                    />
                  </div>
                  <div class="colorpicker" *ngIf="showMoreColor || (!oneDefaultColorIsSelected && widget.color != undefined)">
                    <p-colorPicker [inline]="true" [(ngModel)]="colorpicked" (onChange)="onColorPicker($event)"></p-colorPicker>
                  </div>
                  <button class="changeColor" (click)="cancelColorChange($event)">{{ 'widgetUi.cancel' | translate }}</button>
                  <button class="changeColor" (click)="onChangeColor($event, index)">
                    {{ 'widgetUi.editColor' | translate }}
                  </button>
                </div>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="box ui-g-12 ui-g content">
    <div class="box ui-g-12 centeredContainer">
      <iu-chart-widget
        *ngIf="widget.viewType === 'chart' && widget.angularClass === undefined && isLoaded"
        [@enterAnimation]
        [widget]="widget"
      ></iu-chart-widget>
      <iu-list-widget
        *ngIf="widget.viewType === 'list' && widget.angularClass === undefined && isLoaded"
        [@enterAnimation]
        [widget]="widget"
      ></iu-list-widget>
      <iu-grid-widget
        *ngIf="widget.viewType === 'grid' && widget.angularClass === undefined && isLoaded"
        [@enterAnimation]
        [widget]="widget"
        (openTabEmitter)="openTab($event)"
      ></iu-grid-widget>
      <ng-container #widgetVCR [@enterAnimation]></ng-container>
    </div>
  </div>
</div>
