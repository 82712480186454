import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereField } from '@compiere-ws/models/window-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { CalendarUiComponent } from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsField } from '@iupics-manager/models/iupics-data';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-product-attribute',
  templateUrl: './product-attribute.component.html',
  styleUrls: ['./product-attribute.component.scss']
})
export class ProductAttributeComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @Input()
  attributeInstance_id = 0;
  @Input()
  cssGrid: string;
  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  private baseAttributeSetID: number;
  ctxFromWs = {};
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    socketService: SocketService,
    private poService: PoService,
    private workflowService: CompiereWorkflowService,
    private messageManager: MessageManagerService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }

  ngOnInit() {
    const product_id =
      this.sourceComponent.dataStored.data['M_Product_ID'] instanceof Object
        ? this.sourceComponent.dataStored.data['M_Product_ID'].id
        : this.sourceComponent.dataStored.data['M_Product_ID'];
    let attributeInstanceId = this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID']
      ? this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID'] instanceof Object
        ? this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID'].id
        : this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID']
      : this.attributeInstance_id;
    const currentAttributeSetID = this.sourceComponent.dataStored.data['M_AttributeSet_ID']
      ? this.sourceComponent.dataStored.data['M_AttributeSet_ID'] instanceof Object
        ? this.sourceComponent.dataStored.data['M_AttributeSet_ID'].id
        : this.sourceComponent.dataStored.data['M_AttributeSet_ID']
      : undefined;
    if (this.baseAttributeSetID !== currentAttributeSetID) {
      attributeInstanceId = this.attributeInstance_id;
    }
    this.init(attributeInstanceId, product_id);
  }
  notifyFromDatacontainerInit(datacontainer: AbstractDataContainer) {
    datacontainer.updateStore(this.ctxFromWs[datacontainer.data.columnName]);
    if (datacontainer.data.columnName === 'M_Lot_ID') {
      (datacontainer as AutocompleteUiComponent).autoComplete.onSelect.subscribe(data => {
        const lot = this.dataContainers.filter(el => el.data.columnName === 'Lot')[0] as InputTextUiComponent;
        if (data === null || data.id === null) {
          lot.isReadOnly = false;
          lot.updateStore('');
        } else {
          lot.isReadOnly = true;
          lot.fieldValue = data.displayValue;
          lot.updateStore(data.displayValue);
        }
      });
    }
    if (datacontainer.data.columnName === 'LotNew') {
      datacontainer.elementRef.nativeElement.addEventListener('click', () => {
        this.newLot();
      });
    }
  }
  init(attributeInstanceId: number, product_id: number) {
    const request_form: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName:
          ' ( select a.name as columnName,CASE WHEN ai.M_AttributeValue_ID is not null THEN CAST(ai.M_AttributeValue_ID AS NVARCHAR2(100)) ELSE ai.value END AS value,asi.lot,asi.serno,asi.m_lot_id,asi.guaranteedate FROM m_attributesetinstance asi inner join m_attributeinstance ai on ai.m_attributesetinstance_id=asi.m_attributesetinstance_id inner join m_attribute a on a.m_attribute_id=ai.m_attribute_id WHERE ai.m_attributesetinstance_id= ' +
          attributeInstanceId +
          ' )',
        filterModel: {}
      }
    };
    this.subscriptions.push(
      this.store.getDataGrid(request_form, true).subscribe(formWS => {
        if (formWS && formWS.data[0]) {
          this.ctxFromWs['Lot'] = formWS.data[0]['LOT'];
          this.ctxFromWs['M_Lot_ID'] = formWS.data[0]['M_LOT_ID'];
          this.ctxFromWs['SerNo'] = formWS.data[0]['SERNO'];
          formWS.data.forEach(data => {
            this.ctxFromWs[data['COLUMNNAME']] = data['VALUE'];
          });
          this.ctxFromWs['GuaranteeDate'] = formWS.data[0]['GUARANTEEDATE'];
        }
        const observ = this.uiCreator.getProductAttribute(attributeInstanceId, product_id, this.sourceComponent.data.tabId);
        this.subscriptions.push(
          observ.subscribe(fieldsWS => {
            const fields: IupicsField[] = [];
            fieldsWS.forEach(field => {
              if (field.ColumnName !== 'NewEdit' && field.ColumnName !== 'SelectExisting' && field.ColumnName !== 'Description') {
                // Remove this when WS is OK
                const compiereField: CompiereField = {
                  field: field,
                  details: null,
                  data: null,
                  urlList: null,
                  urlCallouts: null,
                  urlSearch: null
                };
                // compiereField.field.AD_Reference_ID = 10; // Remove this when WS is OK
                if (field.listBoxVO && field.listBoxVO.options) {
                  // compiereField.field.AD_Reference_ID = 30; // Remove this when WS is OK
                  compiereField.data = [];
                  field.listBoxVO.options.forEach(listValue => {
                    compiereField.data.push({ id: listValue.id, displayValue: listValue.name });
                  });
                }
                const fieldTransformed = UICreatorUtils.transformField(compiereField);
                if (fieldTransformed) {
                  fields.push(fieldTransformed);
                }
              }
            });
            this.buildProductPanel(fields);
          })
        );
      })
    );
  }
  buildProductPanel(fields: any, isCssOnComponent = true) {
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    // if (specific.title !== undefined && specific.title !== null) {
    //   this.title = specific.title;
    // }
    this.fields = fields;
    const transformedFields = this.transformFields(fields);
    transformedFields.forEach(item => {
      /*on ajoute un defaultValue pour qu'on set les valeurs venants du store */
      this.addComponent(item, isCssOnComponent);
    });
  }

  transformFields(items: any[]): any {
    const itemsOrdered = [];
    items.forEach(item => {
      // if (item.data.columnName === 'LotNew') {
      //   item.cssClass = 'ui-g-6 ui-md-4 ui-lg-2';
      // } else {
      item.cssClass = 'ui-g-12'; // ui-md-6 ui-lg-4
      // }
      if (item.data.columnName === 'Lot') {
        item.data.ReadOnlyLogic = '@M_Lot_ID@>0';
      }
      itemsOrdered.push(item);
    });
    return itemsOrdered;
  }

  validAttributes(isNew = false) {
    // permet d'annuler le refresh de l'editViewParent à la fermeture de la fenetre
    if (<EditViewUiComponent>this.parentComponent) {
      this.parentComponent.isProcessLaunch = false;
    }
    const jsonToSave = {};
    const errors = [];
    if (this.dataContainers.length > 0) {
      this.dataContainers.forEach(el => {
        if (el instanceof CalendarUiComponent) {
          if (el.calendar.inputFieldValue !== null && el.calendar.inputFieldValue !== undefined && el.fieldValue !== '') {
            jsonToSave[el.data.columnName] =
              '' +
              moment(el.calendar.inputFieldValue, 'DD/MM/YYYY')
                .toDate()
                .getTime();
          } else {
            if (el.data.isMandatory) {
              errors.push(el.data.label);
            }
          }
        } else {
          if (el.fieldValue !== null && el.fieldValue !== undefined && el.fieldValue !== '') {
            jsonToSave[el.data.columnName] = el.fieldValue instanceof Object ? el.fieldValue.id : el.fieldValue + '';
          } else {
            if (el.data.isMandatory) {
              errors.push(el.data.label);
            }
          }
        }
      });
      if (errors.length === 0) {
        const product_id =
          this.sourceComponent.dataStored.data['M_Product_ID'] instanceof Object
            ? this.sourceComponent.dataStored.data['M_Product_ID'].id
            : this.sourceComponent.dataStored.data['M_Product_ID'];
        const attributeInstanceId = this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID']
          ? this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID'] instanceof Object
            ? this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID'].id
            : this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID']
          : this.attributeInstance_id;
        const observ = this.uiCreator.saveProductAttribute(
          attributeInstanceId,
          product_id,
          this.sourceComponent.data.tabId,
          this.sourceComponent.data.fieldId,
          jsonToSave
        );
        this.subscriptions.push(
          observ.subscribe(result => {
            if (result) {
              if (this.parentComponent instanceof EditViewUiComponent) {
                (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data[this.sourceComponent.data.columnName] =
                  result[0];
                (<EditViewUiComponent>this.parentComponent).editTabs[0].updateData(
                  (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
                );
              }
            }
            this.closeModalEmitter.emit();
          })
        );
      } else {
        this.messageManager.newMessage(
          new IupicsMessage(this.translateService.instant('specificWindow.fillMandatory'), errors.join(', '), 'error')
        );
      }
    }
  }
  newLot() {
    const product_id =
      this.sourceComponent.dataStored.data['M_Product_ID'] instanceof Object
        ? this.sourceComponent.dataStored.data['M_Product_ID'].id
        : this.sourceComponent.dataStored.data['M_Product_ID'];
    this.uiCreator.saveProductAttributeLot(product_id).subscribe(response => {
      if (response) {
        this.getDatacontainer('M_Lot_ID').data.items.push({ id: response['M_Lot_ID'], displayValue: response['Name'] });
        this.dataStore.data['M_Lot_ID'] = { id: response['M_Lot_ID'], displayValue: response['Name'] };
        this.getDatacontainer('M_Lot_ID').updateStore(this.dataStore.data['M_Lot_ID']);
        const lot = this.getDatacontainer('Lot') as InputTextUiComponent;
        lot.isReadOnly = true;
        lot.updateStore(response['Name']);
      }
    });
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.sourceModal) {
      this.sourceModal.modalClass = 'ui-g-10 ui-md-8 ui-lg-4';
    }
  }
}
