<div class="box ui-g-12 iupics-hover-item" [attr.data-cy-favorite-name]="favorite.name">
  <div
    [ngStyle]="{ 'border-left-color': !favorite.color ? 'white' : favorite.color }"
    class="box ui-g-11 shortcut favoriteBorder"
    (click)="openTab($event)"
    draggable="true"
    (drag)="$event.preventDefault()"
    (dragstart)="$event.preventDefault(); showMenu = false; showColor = false"
    (dragend)="$event.preventDefault()"
    (dragenter)="$event.preventDefault()"
    (dragleave)="$event.preventDefault()"
    (dragover)="$event.preventDefault()"
    (drop)="$event.preventDefault()"
  >
    <a (click)="$event.preventDefault()"> <span class="favorite-icon {{ favorite.iconClass }}"></span> {{ favorite.name }}</a>
    <span class="colorTheme">
      <svg
        width="8px"
        height="8px"
        viewBox="0 0 8 8"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g id="-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="desk__workspaces__module-dropdown" transform="translate(-928.000000, -117.000000)" [attr.fill]="favorite.color">
            <g id="Group-20" transform="translate(489.000000, 100.000000)">
              <circle id="Oval-4" cx="443" cy="21" r="4"></circle>
            </g>
          </g>
        </g>
      </svg>
    </span>
  </div>
  <div class="box ui-g-1 edit-menu">
    <ul [ngClass]="['edit-item', showMenu ? 'blocked' : '']">
      <li [ngClass]="[showMenu ? 'opened' : '']">
        <a
          href="#"
          class="icon-ico-menu-bullets"
          (click)="$event.preventDefault(); showColor = false"
          iuToggleButton
          [iuToggleButtonSwitch]="true"
          [(iuToggleButtonVar)]="showMenu"
          ><span> </span
        ></a>
        <ul>
          <ng-container *ngIf="showMenu">
            <ng-container *ngIf="!showColor">
              <li>
                <a data-cy="change-color-favorite" href="#" (click)="$event.preventDefault(); showColor = true">{{
                  'favoriteUi.changeColor' | translate
                }}</a>
              </li>
              <li>
                <a data-cy="delete-favorite" href="#" (click)="$event.preventDefault(); onDelete()">{{
                  'favoriteUi.remove' | translate
                }}</a>
              </li>
            </ng-container>
            <li *ngIf="showColor" class="new-menu">
              <div style="text-align: center;" class="widgetColor">
                <div class="color-container">
                  <div class="dot" *ngFor="let color of defaultColor">
                    <span
                      [ngClass]="['dot-color', color === this.colorpicked ? 'selected' : '']"
                      [ngStyle]="{ 'background-color': color }"
                      (click)="changeDefaultColor(color)"
                    ></span>
                  </div>
                </div>
                <div *ngIf="!showMoreColor" (click)="showMoreColor = true">
                  <img width="25" height="25" src="assets/img/colorpicker-icon.png" alt="" style="cursor: pointer;" />
                </div>
                <div class="colorpicker" *ngIf="showMoreColor">
                  <p-colorPicker [inline]="true" [(ngModel)]="colorpicked" (onChange)="onColorPicker($event)"></p-colorPicker>
                </div>
                <button class="changeColor" (click)="cancelColorChange($event)">{{ 'favoriteUi.cancel' | translate }}</button>
                <button class="changeColor" (click)="onChangeColor($event)">{{ 'favoriteUi.editColor' | translate }}</button>
              </div>
            </li>
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
</div>
