import { Injectable } from '@angular/core';
import { CompiereNotification } from '@compiere-ws/models/compiere-notification-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class CompiereNotificationService {
  private url: string;

  constructor(private http: ApiService, private config: AppConfig) {}

  getNotifications(): Observable<CompiereNotification[]> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.get<CompiereNotification[]>(this.url);
  }
  handleNotification(notification: CompiereNotification): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.put<CompiereNotification>(this.url + '/' + notification.request_id, {});
  }

  handleAllNotification(): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.put<CompiereNotification>(this.url, {});
  }

  closeNotification(notification: CompiereNotification): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.delete<CompiereNotification>(this.url + '/' + notification.request_id);
  }
  closeAllNotification(): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.delete<CompiereNotification>(this.url);
  }
}
