import { Component, ComponentFactoryResolver, OnInit } from "@angular/core";
import { ProcessPingInfo } from "@compiere-ws/models/process-ping-info";
import { CompiereProcessService } from "@compiere-ws/services/compiere-process/compiere-process.service";
import { PoService } from "@compiere-ws/services/po/po.service";
import { ProcessInProgressService } from "@compiere-ws/services/process-in-progress/process-in-progress.service";
import { SocketService } from "@compiere-ws/services/socket/socket.service";
import { DataStoreService } from "@iupics-manager/managers/data-store/data-store.service";
import { SecurityManagerService } from "@iupics-manager/managers/security-manager/security-manager.service";
import { UICreatorService } from "@iupics-manager/managers/ui-creator/ui-creator.service";
import { WindowFactoryService } from "@iupics-manager/managers/ui-creator/window-factory/window-factory.service";
import { AbstractDataContainer } from "@iupics-manager/models/abstract-datacontainer";
import { Global } from "@iupics-manager/models/global-var";
import { TranslateService } from "@ngx-translate/core";
import { SpecificWindowUiComponent } from "../specific-window-ui/specific-window-ui.component";

@Component({
  selector: "iu-transfert-liste-attente-process",
  templateUrl: "./transfert-liste-attente-process.component.html",
  styleUrls: ["./transfert-liste-attente-process.component.scss"]
})
export class TransfertListeAttenteProcessComponent
  extends SpecificWindowUiComponent
  implements OnInit {
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private ppService: ProcessInProgressService,
    private po: PoService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      ppService,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {
    super.ngOnInit();
  }
  notifyFromDatacontainerInit(dataContainer: AbstractDataContainer) {
    if (
      dataContainer.data.columnName === "Processing" &&
      dataContainer.itemData &&
      dataContainer.itemData.processId
    ) {
      this.executeProcess(dataContainer.itemData.processId, {
        record_id: this.getFormStoreData("C_BPartner_ID")
      });
    }
  }
  onEndProcess(ping: ProcessPingInfo = null) {
    if (ping && ping.AD_PInstance_ID) {
      this.subscriptions.push(
        this.po
          .get<POPInstance>("AD_PInstance", ping.AD_PInstance_ID.id)
          .subscribe(pInstance => {
            let id = 0;
            if (pInstance.ErrorMsg) {
              const index = pInstance.ErrorMsg.indexOf("id:");
              id = parseInt(pInstance.ErrorMsg.substring(index + 3))
                ? parseInt(pInstance.ErrorMsg.substring(index + 3))
                : 0;
            }
            if (id !== 0) {
              this.uiCreator
                .zoomAcross("Z_Waiting_List", "Z_Waiting_List_ID", id)
                .subscribe(dataWs => {
                  if (dataWs && dataWs.length > 0) {
                    this.zoomInfo = {
                      // tableName: this.data.detailZoom.tableName,
                      windowId: dataWs[0].Window_ID,
                      dataUUID: dataWs[dataWs.length - 1]["Record_ID"],
                      record_id: id,
                      children:
                        dataWs.length > 1
                          ? dataWs.splice(0, dataWs.length - 1)
                          : null
                    };

                    Global.workspace.openTargetSearch({
                      cat: { id: parseInt(dataWs[0].Window_ID, 10) },
                      source: {
                        id: dataWs[0].Record_ID
                      }
                    });
                  }
                });
            } else {
              throw new Error(
                this.translateService.instant(
                  "specificWindow.transfertListeAttenteProcess.errorMessage"
                )
              );
            }
          })
      );
    }
    if (this.isModal) {
      this.closeModalEmitter.emit();
    }
  }
}
export interface POPInstance {
  AD_PInstance_ID: number;
  ErrorMsg: string;
}
