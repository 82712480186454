import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {
  CompiereDataGridFilterType,
  DataStore,
  DataStoreRequest
} from "@compiere-ws/models/compiere-data-json";
import { CompiereProcessService } from "@compiere-ws/services/compiere-process/compiere-process.service";
import { ProcessInProgressService } from "@compiere-ws/services/process-in-progress/process-in-progress.service";
import { SocketService } from "@compiere-ws/services/socket/socket.service";
import { CustomDesignItemType } from "@iupics-components/models/custom-design";
import { OperatorFilterType } from "@iupics-components/models/universal-filter";
import { GridViewUiComponent } from "@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component";
import { DataStoreService } from "@iupics-manager/managers/data-store/data-store.service";
import { MessageManagerService } from "@iupics-manager/managers/message/message-manager.service";
import { SecurityManagerService } from "@iupics-manager/managers/security-manager/security-manager.service";
import { UICreatorService } from "@iupics-manager/managers/ui-creator/ui-creator.service";
import { WindowFactoryService } from "@iupics-manager/managers/ui-creator/window-factory/window-factory.service";
import { DynamicComponent } from "@iupics-manager/models/dynamic-component";
import { Global } from "@iupics-manager/models/global-var";
import { IupicsTypeEvent } from "@iupics-manager/models/iupics-event";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { AutocompleteRendererComponent } from "../renderer/autocomplete-renderer.component";
import { ButtonRendererComponent } from "../renderer/button-renderer.component";
import { SpecificWindowUiComponent } from "../specific-window-ui/specific-window-ui.component";

@Component({
  selector: "iu-bpartner-balance",
  templateUrl: "./bpartner-balance.component.html",
  styleUrls: ["./bpartner-balance.component.scss"]
})
export class BpartnerBalanceComponent extends SpecificWindowUiComponent
  implements OnInit {
  frameworkComponents: any;
  dataStore: DataStore;
  lengthOfRowSelected: any;
  additionalParams = {};

  @ViewChild("topleft", { read: ViewContainerRef, static: true })
  vcrTopLeft: ViewContainerRef;
  @ViewChild("topright", { read: ViewContainerRef, static: true })
  vcrTopRight: ViewContainerRef;
  @ViewChild("middle", { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild("bot", { read: ViewContainerRef, static: true })
  vcrBot: ViewContainerRef;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    processService: CompiereProcessService,
    protected store: DataStoreService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private uiCreatorService: UICreatorService,
    pp: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      pp,
      translateService
    );
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent
    };
  }

  ngOnInit(): void {
    this.uiCreator.getSpecificWindow(this.formId).subscribe(specificWindow => {
      specificWindow.items.forEach(item => {
        if (item.data.columnName === "C_BPartner_ID") {
          const fieldSize = "ui-g-6";
          this.customDesignArray.push({
            vcr: "vcrTopLeft",
            type: CustomDesignItemType.FIELD,
            columnName: "C_BPartner_ID",
            cssClass: fieldSize
          });
        } else if (item.data.columnName === "DateFrom") {
          const fieldSize = "ui-g-6";
          this.customDesignArray.push({
            vcr: "vcrTopLeft",
            type: CustomDesignItemType.FIELD,
            columnName: "DateFrom",
            cssClass: fieldSize
          });
        } else if (item.data.columnName === "Balance") {
          const fieldSize = "ui-g-12";
          this.customDesignArray.push({
            vcr: "vcrTopRight",
            type: CustomDesignItemType.FIELD,
            columnName: "Balance",
            cssClass: fieldSize
          });
        } else if (item.data.columnName === "Type_piece") {
          const fieldSize = "ui-g-6";
          this.customDesignArray.push({
            vcr: "vcrTopLeft",
            type: CustomDesignItemType.FIELD,
            columnName: "Type_piece",
            cssClass: fieldSize
          });
        } else if (item.data.columnName === "DateTo") {
          const fieldSize = "ui-g-6";
          this.customDesignArray.push({
            vcr: "vcrTopLeft",
            type: CustomDesignItemType.FIELD,
            columnName: "DateTo",
            cssClass: fieldSize
          });
        } else if (item.data.columnName === "DueTotal") {
          const fieldSize = "ui-g-12";
          this.customDesignArray.push({
            vcr: "vcrTopRight",
            type: CustomDesignItemType.FIELD,
            columnName: "DueTotal",
            cssClass: fieldSize
          });
        } else if (item.data.columnName === "OnlyOpen") {
          const fieldSize = "ui-g-6 ";
          this.customDesignArray.push({
            vcr: "vcrTopLeft",
            type: CustomDesignItemType.FIELD,
            columnName: "OnlyOpen",
            cssClass: fieldSize
          });
        } else if (item.data.columnName === "NotDueTotal") {
          const fieldSize = "ui-g-12 ";
          this.customDesignArray.push({
            vcr: "vcrTopRight",
            type: CustomDesignItemType.FIELD,
            columnName: "NotDueTotal",
            cssClass: fieldSize
          });
        } else if (item.data.columnName === "Relevé Tiers - Table") {
          this.customDesignArray.push({
            vcr: "vcrMiddle",
            type: CustomDesignItemType.GRID,
            tableName: "Relevé Tiers - Table",
            cssClass: "ui-g-12",
            shouldSelectFirst: false,
            frameworkComponents: this.frameworkComponents
          });
        } else if (item.data.columnName === "zoom") {
          const fieldSize = "ui-g-6 ui-md-4 ui-lg-3";
          this.customDesignArray.push({
            vcr: "vcrBot",
            type: CustomDesignItemType.FIELD,
            columnName: "zoom",
            cssClass: fieldSize
          });
        } else if (item.data.columnName === "printData") {
          const fieldSize = "ui-g-6 ui-md-4 ui-lg-3";
          this.customDesignArray.push({
            vcr: "vcrBot",
            type: CustomDesignItemType.FIELD,
            columnName: "printData",
            cssClass: fieldSize
          });
        }
      });
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: "SpecificWindowUiComponent",
        cssClass: "ui-g-12",
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };
      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // Global.workspace = this;
  }

  notifyFromGridRefresh(
    gridView: GridViewUiComponent,
    dataStoreRequest: DataStoreRequest
  ) {
    dataStoreRequest.compiereRequest.valueCols = [
      {
        id: "OpenAmt",
        aggFunc: "sum",
        displayName: "Débit",
        field: "OpenAmt"
      }
    ];
    dataStoreRequest.compiereRequest.sortModel = [];
    this.store.getDataGrid(dataStoreRequest).subscribe(response => {
      // console.log(response.data[0]['OpenAmt'], response.data);
      this.dataStore.data["Balance"] = response.data[0]["OpenAmt"];
      const Balance = this.dataContainers.find(
        datacontainer => datacontainer.data.columnName === "Balance"
      );
      Balance.dataChange(this.dataStore.data["Balance"]);
    });

    dataStoreRequest.compiereRequest.filterModel = {
      DueDate: {
        filterType: CompiereDataGridFilterType.DATE,
        values: [
          moment(new Date())
            .format("YYYY-MM-DDTHH:mm:ss.SSS")
            .substring(0, 26) +
            "" +
            moment(new Date())
              .format("YYYY-MM-DDTHH:mm:ss.SSS")
              .substring(
                27,
                moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS").length
              )
        ],
        operators: [OperatorFilterType.LESS_EQUALS]
      }
    };

    this.store.getDataGrid(dataStoreRequest).subscribe(response => {
      this.dataStore.data["DueTotal"] = response.data[0]["OpenAmt"];
      const DueTotal = this.dataContainers.find(
        datacontainer => datacontainer.data.columnName === "DueTotal"
      );
      DueTotal.dataChange(this.dataStore.data["DueTotal"]);
    });

    this.dataStore.data["NotDueTotal"] =
      this.dataStore.data["Balance"] - this.dataStore.data["DueTotal"];
    const NotDueTotal = this.dataContainers.find(
      datacontainer => datacontainer.data.columnName === "NotDueTotal"
    );
    NotDueTotal.dataChange(this.dataStore.data["NotDueTotal"]);

    super.notifyFromGridRefresh(gridView, dataStoreRequest);
  }

  processzoom() {
    const selections = this.dataStore.data["selections"].find(
      grid =>
        grid["AD_FormDetail_ID"] ===
        (<GridViewUiComponent>this.gridViews[0]).data["AD_FormDetail_ID"]
    );
    if (selections && selections.selection.length > 0) {
      selections.selection.forEach(selection => {
        // console.log('selection : ' + selection);

        const type = selection["Type"];
        const tableName = type === "F" ? "C_Invoice" : "C_Payment";
        const columnKey = type === "F" ? "C_Invoice_ID" : "C_Payment_ID";
        const record_id =
          type === "F" ? selection["DOC_ID"] - 10000000 : selection["DOC_ID"];

        // console.log('Zoom -> ' + type + ' - ' + tableName + ' - ' + columnKey + ' - ' + record_id);

        this.subscriptions.push(
          this.uiCreatorService
            .zoomAcross(tableName, columnKey, record_id)
            .subscribe(dataWs => {
              if (dataWs && dataWs.length > 0) {
                this.zoomInfo = {
                  // tableName: this.data.detailZoom.tableName,
                  windowId: dataWs[0].Window_ID,
                  dataUUID: dataWs[dataWs.length - 1]["Record_ID"],
                  record_id: record_id,
                  children:
                    dataWs.length > 1
                      ? dataWs.splice(0, dataWs.length - 1)
                      : null
                };
                this.subscriptions.push(
                  this.uiCreatorService
                    .getWindow(
                      parseInt(dataWs[dataWs.length - 1]["Window_ID"], 10)
                    )
                    .subscribe(tabs => {
                      const item: DynamicComponent = {
                        container: this.container,
                        DOMParentComponent: this.container,
                        linkedComponents: [this],
                        component: "EditViewUiComponent",
                        cssClass: "iupics-blade-content",
                        isCssOnComponent: false,
                        tabId: tabs[0].tabId,
                        zoomInfo: this.zoomInfo
                      };
                      this.componentEmitter.emit({
                        type: IupicsTypeEvent.showEditView,
                        item: item
                      });
                    })
                );

                Global.workspace.openTargetSearch({
                  cat: { id: parseInt(dataWs[0].Window_ID, 10) },
                  source: {
                    id: record_id !== -1 ? dataWs[0].Record_ID : "newRecord"
                  } // dataWs[0].Record_ID
                });
              }
            })
        );
      });
    }
  }
  processprintData(item) {
    const ctx = this.getWindowContext();
    if (this.dataStore.data["AD_Org_ID"]) {
      this.additionalParams["AD_Org_ID"] = this.dataStore.data["AD_Org_ID"];
    } else {
      this.additionalParams["AD_Org_ID"] = ctx["#AD_Org_ID"];
    }
    if (this.dataStore.data["AD_Client_ID"]) {
      this.additionalParams["AD_Client_ID"] = this.dataStore.data[
        "AD_Client_ID"
      ];
    } else {
      this.additionalParams["AD_Client_ID"] = ctx["#AD_Client_ID"];
    }
    this.additionalParams["BPartner_ID"] =
      "" + this.dataStore.data["C_BPartner_ID"].id;
    this.additionalParams["OnlyOpen"] = this.dataStore.data["OnlyOpen"]
      ? this.dataStore.data["OnlyOpen"]
      : "N";
    this.additionalParams["DueOrdred"] = this.dataStore.data["DueOrdred"]
      ? this.dataStore.data["DueOrdred"]
      : "N";
    this.additionalParams["TypePieces"] =
      "" + this.dataStore.data["Type_piece"].id;
    this.additionalParams["Currency"] = "102";
    this.additionalParams["DateFrom"] = this.dataStore.data["DateFrom"];
    this.additionalParams["DateTo"] = this.dataStore.data["DateTo"];
    this.executeProcess(item.processId, this.additionalParams);
  }
}
