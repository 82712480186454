import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  CompiereDataGridFilterType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { AutocompleteRendererComponent } from '@iupics-components/specific/window/renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from '@iupics-components/specific/window/renderer/button-renderer.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import { debounce } from 'lodash';
@Component({
  selector: 'iu-generer-attestation-fiscale',
  templateUrl: './generer-attestation-fiscale.component.html',
  styleUrls: ['./generer-attestation-fiscale.component.scss']
})
export class GenererAttestationFiscaleComponent
  extends SpecificWindowUiComponent
  implements OnInit {
  @ViewChild('filters', { read: ViewContainerRef, static: true })
  vcrFilters: ViewContainerRef;
  @ViewChild('actions', { read: ViewContainerRef, static: true })
  vcrActions: ViewContainerRef;
  frameworkComponents: any;

  processId: any;
  additionalParams = {};

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService,
    translateService: TranslateService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      pp,
      translateService
    );
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent
    };
  }

  ngOnInit() {
    this.uiCreator.getSpecificWindow(this.formId).subscribe(specificWindow => {
      specificWindow.items.forEach(item => {
        switch (item.data.columnName) {
          case 'AD_Org_ID':
            this.customDesignArray.push({
              vcr: 'vcrFilters',
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-g-4'
            });
            break;
          case 'C_BPartner_ID':
            this.customDesignArray.push({
              vcr: 'vcrFilters',
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-g-4'
            });
            break;
          case 'AgeLimite':
            this.customDesignArray.push({
              vcr: 'vcrFilters',
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-g-4'
            });
            break;
          case 'valider':
            this.processId = item.processId;
            this.customDesignArray.push({
              vcr: 'vcrActions',
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: Global.isMobileWidth() ? 'ui-g-12' : 'ui-g-4',
              events: {
                click: this.lunchProcess.bind(this)
              }
            });
            break;
        }
      });

      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'ui-g-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };

      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
    this.lunchProcess = debounce(this.lunchProcess , 3000);
  }

  lunchProcess() {
    const filterModel = {};

    if (this.dataStore.data['AD_Org_ID'] != null) {
      filterModel['AD_Org_ID'] = {
        filterType: CompiereDataGridFilterType.SET,
        values: [this.dataStore.data['AD_Org_ID']['id']],
        operators: [OperatorFilterType.EQUALS]
      };
    }

    if (this.dataStore.data['C_BPartner_ID'] != null) {
      filterModel['C_BPartner_ID'] = {
        filterType: CompiereDataGridFilterType.SET,
        values: [this.dataStore.data['C_BPartner_ID']['id']],
        operators: [OperatorFilterType.EQUALS]
      };
    }

    const request: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'PARTICIPANTCERTIFICATE_V',
        filterModel: filterModel
      }
    };

    this.store.getDataGrid(request).subscribe(response => {
      let oldCProjectId = 0;
      let messageToShow = '';
      this.additionalParams['AgeLimite'] =
        '' + this.dataStore.data['AgeLimite'];
      response.data.forEach(item => {
        if (
          item['C_PROJECT_ID'] !== oldCProjectId &&
          item['PROCESSED'] === 'N'
        ) {
          messageToShow +=
            'L\'évenement ' + item['VALUE'] + ' n\'est pas cloturé <br>';
          oldCProjectId = item['C_PROJECT_ID'];
        }
      });
      oldCProjectId = 0;
      let nbr = 0;
      response.data.forEach(item => {
        if (
          item['ISPAID'] === 'N' &&
          this.calculateDays(
            item['DATE_NAISS'],
            item['DATECONTRACT'],
            item['DATEFINISH'],
            item
          ) > 0 ) {
            if(item['C_PROJECT_ID'] !== oldCProjectId){
          if (nbr > 0) {
            messageToShow += nbr + ' Participant(s) qui n\'ont pas payé <br>';
            nbr = 0;
          }
          messageToShow += item['VALUE'] + ' : ';
          oldCProjectId = item['C_PROJECT_ID'];
          nbr = 1;
        } else if (item['C_PROJECT_ID'] === oldCProjectId) {
            nbr++;
        }
        }
      });
      if (nbr > 0) {
        messageToShow += nbr + ' Participant(s) qui n\'ont pas payé <br>';
        nbr = 0;
      }
      if (messageToShow !== '') {
        Global.infoDialog.message = {
          summary:
            'Voulez-vous continuer malgrés le(s) problème(s) suivant(s)?',
          detail: messageToShow
        };
        Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
        Global.infoDialog.showInfoDialog();
        const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
          const button = this.getDatacontainer('valider');
          this.executeProcess(this.processId, this.additionalParams); //#131952
          if (button) {
            button.isReadOnly = true;
          }
          
        });
        const cancel = Global.infoDialog.cancel.subscribe(e => {
          confirm.unsubscribe();
          cancel.unsubscribe();
        });
      } else {
        const button = this.getDatacontainer('valider');
        this.executeProcess(this.processId, this.additionalParams); //#131952
        if (button) {
          button.isReadOnly = true;
        }
      }
    });
  }

  calculateDays(
    dateOfBirth: string,
    dateContract: string,
    dateFinish: string,
    item: any
  ): number {
    let daysInderAgeAttest = 0;
    let res = 0;

    if (dateOfBirth == null || dateContract == null || dateFinish == null) {
      return res;
    }

    const currentDate = new Date(dateOfBirth.substr(0, 10));
    const dateOfBirthTime = new Date(
      currentDate.getFullYear() +
        parseInt(this.dataStore.data['AgeLimite'], 10),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const dateContractTime = new Date(dateContract.substr(0, 10));
    const dateFinishTime = new Date(dateFinish.substr(0, 10));

    if (dateOfBirthTime > dateFinishTime) {
      daysInderAgeAttest = parseInt(
        String(
          (dateFinishTime.getTime() - dateContractTime.getTime()) /
            (24 * 60 * 60 * 1000) +
            1
        ),
        10
      );
    } else if (dateContractTime > dateOfBirthTime) {
      daysInderAgeAttest = 0;
    } else {
      daysInderAgeAttest = parseInt(
        String(
          (dateOfBirthTime.getTime() - dateContractTime.getTime()) /
            (24 * 60 * 60 * 1000)
        ),
        10
      );
    }

    for (let i = 1; i <= daysInderAgeAttest && i <= 30; i++) {
      if (item['J' + i] === 'Y') {
        res++;
      }
    }
    return res;
  }

  notifyFromDataChange(item?: any) {
    if (item.data['columnName'] === 'AD_Org_ID') {
      const champ = this.dataContainers.find(
        datacontainer => datacontainer.data.columnName === 'C_BPartner_ID'
      );
      champ.dataChange(null);
    }
    super.notifyFromDataChange(item);
  }

  onEndProcess() {
    const button = this.getDatacontainer('valider');
    if (button) {
      button.isReadOnly = false;
    }
    this.refreshGrids(this.dataStore, false);
  }
}
