import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar-renderer',
  template: `
    <iu-calendar-ui
      [fieldValue]="this.fieldValue"
      [isLabelDisplay]="false"
      [cssClass]="'defaultDateCalendar ui-g-12 calendarRenderer'"
      [isStandalone]="true"
      [isGridRenderer]="true"
      (fieldValueModified)="onFieldModified($event)"
      (checkGridRendererEmitter)="onCalendar($event)"
    ></iu-calendar-ui>
  `
})
export class CalendarRendererComponent implements ICellRendererAngularComp {
  params;
  label: string;
  templates: any;
  fieldValue = null;
  activeGridView: GridViewUiComponent;

  agInit(params: any): void {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;
    this.activeGridView = this.params.activeGridView;

    if (params.value) {
      this.fieldValue = moment(params.value).toDate();
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  onFieldModified($event) {
    let newValue;
    if ($event) {
      newValue = moment($event).format('YYYY-MM-DDTHH:mm:ss.SSS');
      newValue = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
    }
    this.params.node.data[this.templates.columnName] = newValue;
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        columnName: this.params.colDef.field
        // ...something
      };
      this.params.onClick(params);
    }
  }

  onCalendar(event) {
    this.activeGridView.panelStyleClass = event.panelStyleClass;
    this.activeGridView.panelStyle = event.panelStyle;
    this.activeGridView.inline = event.inline;
    if (!this.activeGridView.overlayCalendarVisible) {
      this.activeGridView.addCloseListener();
    }

    this.activeGridView.overlayCalendarVisible = event.overlayCalendarVisible;
    this.activeGridView.months = event.months;
    this.activeGridView.numberOfMonths = event.numberOfMonths;
    this.activeGridView.monthNavigator = event.monthNavigator;
    this.activeGridView.view = event.view;
    this.activeGridView.locale = event.locale;
    this.activeGridView.yearNavigator = event.yearNavigator;
    this.activeGridView.yearOptions = event.yearOptions;
    this.activeGridView.currentYear = event.currentYear;
    this.activeGridView.weekDays = event.weekDays;
    this.activeGridView.showOtherMonths = event.showOtherMonths;
    this.activeGridView.dateTemplate = event.dateTemplate;
    this.activeGridView.monthPickerValues = event.monthPickerValues;
    this.activeGridView.linkCalendarRenderer = event.linkCalendarRenderer;
    this.activeGridView.currentMonth = event.currentMonth;
    this.activeGridView.setPosition(event.top, event.left + event.width - 240);
    this.params.onCalendar(event);
  }
}
