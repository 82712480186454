<!-- #region custo -->
<div *ngIf="isCondGeneOk && authorizedRoleOK">
  <!-- #endregion custo -->
  <wd-workspace-ui
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($event)"
    (validateLogoutEmitter)="validateLogoutEmitter.emit()"
    [urlParams]="urlParams"
    iuKeybinding
    [iuKeybinding-active]="true"
    [iuKeybinding-badge-function]="showKeyBindLegend"
  ></wd-workspace-ui>
  <!-- #region custo -->
</div>

<!-- #endregion custo -->

<iu-prime-contextMenu
  baseZIndex="10000"
  [global]="false"
  [model]="menuItems"
  #context
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($event)"
>
</iu-prime-contextMenu>
<p-toast>
  <ng-template let-message pTemplate="message">
    <div *ngIf="message.data && message.data.type === 'Notification'" class="wd-notification-center-item toast">
      <i
        [ngClass]="[
          !message.data.notification
            ? 'iu-toast-icon success fa-lg fas fa-exclamation-circle'
            : !message.data.notification.isError
            ? 'iu-toast-icon success fa-lg fas fa-check-circle'
            : 'iu-toast-icon error fa-lg fas fa-times-circle'
        ]"
      ></i>
      <iu-notification style="flex: 3" [isStandalone]="true" [item]="message.data.notification"></iu-notification>
    </div>
    <ng-container *ngIf="message.data && message.data.type !== 'Notification'">
      <span
        [ngClass]="[
          message.severity == 'info' ? 'pi-info-circle' : '',
          message.severity == 'warn' ? 'pi-exclamation-triangle' : '',
          message.severity == 'error' ? 'pi-times' : '',
          message.severity == 'success' ? 'pi-check' : '',
          'ui-toast-icon pi'
        ]"
      >
      </span>
      <div class="ui-toast-message-text-content">
        <div class="ui-toast-summary">{{ message.summary }}</div>
        <div class="ui-toast-detail" [innerHTML]="message.detail"></div></div
    ></ng-container>
  </ng-template>
</p-toast>
<iu-info-dialog></iu-info-dialog>
<iu-modal-ui
  *ngIf="isLoginModalDisplayed()"
  [title]="'loginAs.title'"
  [hasCloseBtn]="false"
  (closeModalEmitter)="hideLoginModal()"
  [angularStyle]="{ 'iu-modal': { overflow: 'visible' } }"
>
  <iu-loginas-ui></iu-loginas-ui>
</iu-modal-ui>
