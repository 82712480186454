export class RecentItem {
  mode: string;
  level: number;
  name: string;
  description: string;
  pathDir: string;
  parentId: number;
  nodeId: number;
  componentId: number;
  entityID: number;
  entityKey: string;
  nodeType: number;
  summary: boolean;
  hasChildren: boolean;
  isFavorite: boolean;
  cssColor: string;
  icon: string;
  seqNo: number;
  isEditable: boolean;
  query: string;
  fieldValuePairs: any;
  isAdhoc: boolean;
  isExternal: boolean;
  isDashboard: boolean;

  constructor(name: string, tabId: number, windowId: number, recordId: string | number) {
    this.mode = 'UNSPECIFIED';
    this.level = 0;
    this.name = name;
    this.description = null;
    this.pathDir = null;
    this.parentId = 0;
    this.nodeId = 0;
    this.componentId = tabId;
    this.entityID = windowId;
    this.entityKey = recordId + '';
    this.nodeType = 1;
    this.summary = false;
    this.hasChildren = false;
    this.isFavorite = false;
    this.cssColor = null;
    this.icon = null;
    this.seqNo = 0;
    this.isEditable = false;
    this.query = null;
    this.fieldValuePairs = null;
    this.isAdhoc = false;
    this.isExternal = false;
    this.isDashboard = false;
  }
}
