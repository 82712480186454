<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window']"
  [attr.data-cy-specific-window-name]="name"
>
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div
      [ngClass]="[
        this.dataStore &&
        this.dataStore.data &&
        this.dataStore.data['Posted'] &&
        (this.dataStore.data['Posted'].id === 'E' || this.dataStore.data['Posted'].id === 'N')
          ? 'containerHidden'
          : '',
        'fields'
      ]"
    >
      <div class="top-container-AccountingViewer">
        <div class="ui-g-12"><ng-template #left></ng-template></div>
      </div>
      <div class="bot-container-AccountingViewer">
        <div class="ui-g-12"><ng-template #bot> </ng-template></div>
        <ng-template iuDynamicContainer></ng-template>
      </div>
    </div>
    <div
      class="postingErrorMessage"
      *ngIf="
        this.dataStore && this.dataStore.data
          ? this.dataStore.data['Posted']
            ? this.dataStore.data['Posted'].id === 'E' || this.dataStore.data['Posted'].id === 'N'
              ? true
              : false
            : false
          : false
      "
    >
      {{ 'specificWindow.accountingViewer.postingErrorMessage' | translate }}
    </div>
  </p-scrollPanel>
  <div class="iu-specific-btn-container" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
    <ng-template #vcrButtons></ng-template>
    <iu-button-ui
      *ngIf="isModal"
      class="iu-btn btn-cancel ui-g-12 ui-md-3 ui-lg-1"
      (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
      label="{{ 'process.cancel' | translate }}"
      icon="fa fa-ban"
    >
    </iu-button-ui>
  </div>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false,sourceComponent:this })"
    [angularStyle]="customFormModalBodyCss"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
