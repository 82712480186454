import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';

@Component({
  selector: 'iu-preview-doc',
  templateUrl: './preview-doc.component.html',
  styleUrls: ['./preview-doc.component.scss']
})
export class PreviewDocComponent implements OnInit, AfterViewInit {
  @Input()
  modal = true;
  @Input()
  urlFile;
  @Input()
  fileName;
  @Input()
  isPreviewDisplayed = false;
  isImagePreview = false;
  isDocPreview = false;
  url: SafeUrl;
  extension: string;
  @Input()
  z_index = 0;
  @Input()
  modalStyle = null;
  @Input()
  imgStyle = null;
  mediaType = 'application/pdf';
  showError = false;
  @Input()
  fromDocServer = true;
  constructor(private sanitizer: DomSanitizer, private docServerService: DocServerService) {}

  ngOnInit(): void {
    let builedStyle = {
      'iu-modal-body': { 'align-items': 'center', height: document.documentElement.clientHeight / 1.5 + 'px' }
    };
    if (this.z_index > 0) {
      builedStyle['iu-modal-background'] = { 'z-index': this.z_index };
    }
    this.modalStyle = builedStyle;
  }
  ngAfterViewInit(): void {
    if (this.fileName && this.urlFile) {
      this.onPreview(this.fileName, this.urlFile);
    }
  }
  onPreview(fileName, url) {
    this.isPreviewDisplayed = false;
    this.isDocPreview = false;
    this.isImagePreview = false;
    this.fileName = fileName;
    this.extension = fileName ? (fileName.split('.').length > 1 ? fileName.split('.')[1] : null) : null;
    if (this.extension) {
      const urlPreview = this.fromDocServer ? this.docServerService.getUrlPreview(url) : url;
      if (/\png$|\jpg$|\jpeg$/.test(this.extension.toLowerCase() as string)) {
        this.isPreviewDisplayed = true;
        this.isImagePreview = true;
        this.url = this.sanitizer.bypassSecurityTrustUrl(urlPreview as string);
      } else if (this.getMediaType(this.fileName)) {
        this.mediaType = this.getMediaType(this.fileName);
        this.isPreviewDisplayed = true;
        this.isDocPreview = true;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(urlPreview as string);
      } else {
        this.fileName = null;
        this.url = null;
      }
    } else {
      this.isPreviewDisplayed = true;
      this.showError = true;
    }
  }
  getMediaType(fileName: string) {
    const name = fileName.toLowerCase();
    if (name.endsWith('.jpeg') || name.endsWith('.jpg')) {
      return 'image/jpeg';
    } else if (name.endsWith('.png')) {
      return 'image/png';
    } else if (name.endsWith('.doc')) {
      return 'application/msword';
    } else if (name.endsWith('.docx')) {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (name.endsWith('.odt')) {
      return 'application/vnd.oasis.opendocument.text';
    } else if (name.endsWith('.csv')) {
      return 'text/csv';
    } else if (name.endsWith('.txt')) {
      return 'text/plain';
    } else if (name.endsWith('.xls')) {
      return 'application/vnd.ms-excel';
    } else if (name.endsWith('.xlsx')) {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (name.endsWith('.pdf')) {
      return 'application/pdf';
    } else if (name.endsWith('.html') || name.endsWith('.htm')) {
      return 'text/html';
    } else if (name.endsWith('.ico')) {
      return 'image/vnd.microsoft.icon';
    } else if (name.endsWith('.svg')) {
      return 'image/svg+xml';
    } else if (name.endsWith('.bin')) {
      return 'application/octet-stream';
    } else {
      return null;
    }
  }
}
