import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  ActivatedRoute,
  // #region custo
  Router
} from '@angular/router';
import {
  CompiereDataGridFilterType,
  CompiereDataGridRequestJSON,
  CompiereDataGridResponseJSON,
  // #region custo
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { ApiService } from '@compiere-ws/services/api/api.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { PrimeContextMenuComponent } from '@iupics-components/overrided/prime-contextmenu/prime-contextmenu.component';
import { InfoDialogComponent } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { UserAccount } from '@login-page/models/user-account.';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { of, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Component({
  selector: 'wd-desktop-ui',
  templateUrl: './desktop-ui.component.html',
  styleUrls: ['./desktop-ui.component.scss']
})
export class DesktopComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PrimeContextMenuComponent, { static: true })
  cm: PrimeContextMenuComponent;
  @ViewChild(InfoDialogComponent, { static: true })
  infoDialog: InfoDialogComponent;
  menuItems: MenuItem[];
  subscriptions: any[] = [];
  // #region custo
  isCondGeneOk = false;
  authorizedRoleOK = false;
  //#endregion custo
  urlParams = {
    id: null,
    type: null,
    viewType: null,
    recordId: null,
    viewData: null,
    processParamsMap: null,
    othersRecordId: null,
    dataGridRequest: null,
    ctx: null
  };
  @Output()
  validateLogoutEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private cmService: ContextMenuService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
    private themeService: ThemeService,
    private progressService: ProcessInProgressService,
    // #region custo
    private config: AppConfig,
    private http: ApiService,
    private connectorService: SecurityManagerService,
    private router: Router,
    private store: DataStoreService,
    private po: PoService // #endregion custo
  ) {}

  ngOnInit() {
    // #region custo
    let url = this.config.getBackendResource('conditionsGenOk');
    const userAccount = this.connectorService.getIupicsUserAccount();
    if (userAccount && userAccount.id) {
      url = url.replace('$ID', userAccount.id.toString());
      this.checkConditionGen(url, userAccount);
      this.initSmartButtonAuthorizedRoleList();
    }
    //#endregion custo
  }
  ngAfterViewInit() {
    this.themeService.setActiveTheme();
    Global.infoDialog = this.infoDialog;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  subscription() {
    this.subscriptions.push(
      this.cmService.emitter.subscribe((res) => {
        this.menuItems = res.menuItems;
        this.cm.show(res.originalEvent);
      })
    );
  }

  onContextMenu(event: MouseEvent) {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    event.preventDefault();
  }
  /**
   * @description check if any params from url are specified and set them to the component
   */
  handleParamsFromUrl() {
    this.route.paramMap.subscribe((data) => {
      if (data.get('windowId')) {
        this.urlParams.id = +data.get('windowId');
        this.urlParams.recordId = data.get('recordId') ? data.get('recordId') : null;
        this.urlParams.type = 'Window';
        // check if params exist
        this.route.queryParams.subscribe((queryParam) => {
          if (queryParam['viewType']) {
            switch (queryParam['viewType'].toLowerCase()) {
              case ViewType.GRID:
                this.urlParams.viewType = ViewType.GRID;
                break;
              case ViewType.CALENDAR:
                this.urlParams.viewType = ViewType.CALENDAR;
                break;
              case ViewType.KANBAN:
                this.urlParams.viewType = ViewType.KANBAN;
                break;
              case ViewType.CHART:
                this.urlParams.viewType = ViewType.CHART;
                break;
              case ViewType.TREE:
                this.urlParams.viewType = ViewType.TREE;
                break;
              default:
                break;
            }
          }
          let i = 0;
          const dataGridRequest: CompiereDataGridRequestJSON = {
            filterModel: {},
            sortModel: [],
            rowGroupCols: [],
            valueCols: []
          };
          while (queryParam['column_' + i]) {
            if (
              queryParam['column_' + i] &&
              queryParam['filterOperator_' + i] &&
              queryParam['filterValue_' + i] &&
              queryParam['filterType_' + i]
            ) {
              dataGridRequest.filterModel[queryParam['column_' + i]] = {
                operators: queryParam['filterOperator_' + i].split('|'),
                values:
                  ((queryParam['filterType_' + i] === CompiereDataGridFilterType.SET ||
                    queryParam['filterType_' + i] === CompiereDataGridFilterType.NUMBER) &&
                    queryParam['filterValue_' + i].indexOf(',')) > 0
                    ? queryParam['filterValue_' + i].split('|').map((v) =>
                        v.split(',').map((toObject) => {
                          return toObject;
                        })
                      )
                    : queryParam['filterType_' + i] === CompiereDataGridFilterType.SET ||
                      queryParam['filterType_' + i] === CompiereDataGridFilterType.NUMBER
                    ? queryParam['filterValue_' + i].split('|').map((toObject) => {
                        return [toObject];
                      })
                    : queryParam['filterValue_' + i].split('|').map((toObject) => {
                        if (queryParam['filterType_' + i] === CompiereDataGridFilterType.DATE) {
                          return moment(toObject).toDate();
                        } else {
                          return toObject;
                        }
                      }),
                filterType: queryParam['filterType_' + i]
              };
            }
            i++;
          }
          i = 0;
          while (queryParam['sortColId_' + i]) {
            if (queryParam['sortType_' + i]) {
              dataGridRequest.sortModel.push({
                sort: queryParam['sortType_' + i],
                colId: queryParam['sortColId_' + i]
              });
            }
            i++;
          }
          i = 0;
          while (queryParam['groupId_' + i]) {
            if (
              queryParam['groupAggFunc_' + i] !== null &&
              queryParam['groupAggFunc_' + i] !== undefined &&
              queryParam['groupDisplayName_' + i] &&
              queryParam['groupField_' + i]
            ) {
              dataGridRequest.rowGroupCols.push({
                aggFunc: queryParam['groupAggFunc_' + i],
                displayName: queryParam['groupDisplayName_' + i],
                field: queryParam['groupField_' + i],
                id: queryParam['groupId_' + i]
              });
            }
            i++;
          }
          i = 0;
          while (queryParam['valueId_' + i]) {
            if (
              queryParam['valueAggFunc_' + i] !== null &&
              queryParam['valueAggFunc_' + i] !== undefined &&
              queryParam['valueDisplayName_' + i] &&
              queryParam['valueField_' + i]
            ) {
              dataGridRequest.valueCols.push({
                aggFunc: queryParam['valueAggFunc_' + i],
                displayName: queryParam['valueDisplayName_' + i],
                field: queryParam['valueField_' + i],
                id: queryParam['valueId_' + i]
              });
            }
            i++;
          }

          this.urlParams.dataGridRequest = dataGridRequest;
          if (queryParam['path']) {
            const othersRecordId: string = queryParam['path'];
            const othersRecordIdSplitted = othersRecordId.split('/');
            othersRecordIdSplitted.splice(0, 1);
            if (othersRecordIdSplitted && othersRecordIdSplitted.length > 1) {
              this.urlParams.othersRecordId = [];
              for (let j = 0; j < othersRecordIdSplitted.length; j += 2) {
                if (othersRecordIdSplitted[j + 1]) {
                  this.urlParams.othersRecordId.push({
                    tabId: othersRecordIdSplitted[j],
                    recordId: othersRecordIdSplitted[j + 1]
                  });
                }
              }
            }
          }
        });
      }
      if (data.get('processId')) {
        this.urlParams.id = +data.get('processId');
        this.urlParams.type = 'Process' || 'Report';
        // get every params from url
        this.route.queryParams.subscribe((queryParam) => {
          if (Object.keys(queryParam).length) {
            this.urlParams.processParamsMap = new Map<string, any>();
            Object.keys(queryParam).forEach((paramKey) => {
              this.urlParams.processParamsMap.set(paramKey, queryParam[paramKey]);
            });
          }
        });
      }
      if (data.get('formId')) {
        this.urlParams.id = +data.get('formId');
        this.urlParams.type = 'Form';
      }
      if (data.get('reportId')) {
        this.urlParams.id = +data.get('reportId');
        this.urlParams.type = 'Report';
        // get every params from url
        this.route.queryParams.subscribe((queryParam) => {
          if (Object.keys(queryParam).length) {
            this.urlParams.processParamsMap = new Map<string, any>();
            Object.keys(queryParam).forEach((paramKey) => {
              this.urlParams.processParamsMap.set(paramKey, queryParam[paramKey]);
            });
          }
        });
      }
    });
  }

  showKeyBindLegend() {
    const spanElement = this.renderer.createElement('span') as HTMLSpanElement;
    this.renderer.addClass(spanElement, 'iupics-badge-legend');

    spanElement.innerHTML = `
      <span class="iupics-badge-not-shift">Primary Keybind: ALT+[KEY_SHORTCUT]</span>
      <span class="iupics-badge-not-shift">Tab navigation: ALT + [ & | é | " | \' | ( | § | è | ! | ç ]</span>
      <span class="iupics-badge-shift">Secondary Keybind: ALT+SHIFT+[KEY_SHORTCUT]</span>
    `;

    this.renderer.insertBefore(this.el.nativeElement, spanElement, this.el.nativeElement.firstChild);
    return spanElement;
  }

  isLoginModalDisplayed() {
    return Global.isLoginAsModalVisible;
  }

  hideLoginModal() {
    Global.isLoginAsModalVisible = false;
  }
  //#region custo
  initAdMessageRequest() {
    //récupération de tous les ad_messages nécessaires pour les scouts
    let request: DataStoreRequest = null;
    const adMessages = this.config.getConstant('adMessages');
    if (adMessages && adMessages.length > 0) {
      const messages = adMessages.split(',');
      if (messages.length > 0) {
        request = {
          windowId: null,
          parent_constraint: '',
          compiereRequest: {
            startRow: 0,
            endRow: 0,
            tableName: 'AD_MESSAGE',
            filterModel: {
              VALUE: {
                filterType: CompiereDataGridFilterType.SET,
                values: [messages],
                operators: [OperatorFilterType.EQUALS]
              }
            }
          }
        };
      }
    }
    return request;
  }
  init() {
    const request: DataStoreRequest = this.initAdMessageRequest();
    let obs = of(null);
    if (request !== null) {
      obs = this.store.getDataGrid(request);
    }
    obs.subscribe((response: CompiereDataGridResponseJSON) => {
      if (response && response.data && response.data.length > 0) {
        response.data.forEach((row) => {
          Global.ad_message_text.set(row['VALUE'], row['MSGTEXT']);
        });
      }
      Global.camp_ete_first_tab_id = this.config.getConstant('Camp_Ete_First_Tab_ID');
      this.isCondGeneOk = true;
      Global.conditionsGenerales = null;
      Global.conditionsGeneralesUserIdentifier = null;
      this.subscription();
      this.handleParamsFromUrl();
    });
  }
  checkConditionGen(url: string, userAccount: UserAccount) {
    this.subscriptions.push(
      this.http.get<{ okCadreUnite: boolean; isCondOk: boolean; condition: string }>(url).subscribe(
        (response) => {
          Global.okCadreUnite = response.okCadreUnite;
          if (response.okCadreUnite) {
            if (response.isCondOk) {
              this.init();
            } else {
              if (response.condition) {
                Global.conditionsGenerales = response.condition;
              }
              if (Global.conditionsGeneralesAccepted) {
                this.subscriptions.push(
                  this.http.put<{ isCondOk: boolean; condition: string }>(url, {}).subscribe(
                    (response2) => {
                      if (response2.isCondOk) {
                        this.init();
                      } else {
                      }
                    },
                    (error2) => {
                      this.redirectToLoginPage(userAccount);
                    }
                  )
                );
                Global.conditionsGeneralesAccepted = false;
              } else {
                this.redirectToLoginPage(userAccount);
              }
            }
          } else {
            this.redirectToLoginPage(userAccount);
          }
        },
        (error) => {
          this.redirectToLoginPage(userAccount);
        }
      )
    );
  }
  redirectToLoginPage(userAccount: UserAccount) {
    this.connectorService.disconnect().subscribe((response) => {
      Global.conditionsGeneralesUserIdentifier = userAccount ? userAccount.login : null;
      this.router.navigate(['/login']);
    });
  }
  initSmartButtonAuthorizedRoleList() {
    const request: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_MESSAGE',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: ['AUTHORIZED_ROLES_SMARTB'],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };
    zip(
      this.store.getDataGrid(request).pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => of(undefined))
      ),
      this.po.get<any>('AD_Org', this.connectorService.getIupicsUserContext()['#AD_Org_ID']).pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => of(undefined))
      )
    ).subscribe((responses) => {
      if (responses[0] && responses[0].data.length > 0) {
        Global.authorizedRoleListSmartButtons = responses[0].data.length > 0 ? responses[0].data[0]['MSGTEXT'].split(',') : [];
        this.authorizedRoleOK = true;
      }
      if (responses[1] && responses[1]['IsGoogleAddress']) {
        Global.IsGoogleAddress = responses[1]['IsGoogleAddress'] === 'Y';
      } else {
        Global.IsGoogleAddress = true;
      }
    });
  }
  //#endregion custo
}
