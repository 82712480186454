<div class="iu-specific-window iu-specific-window-container">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' }">
    <ng-template #top></ng-template>
    <div class="ui-g-12">
      <agm-map
        [latitude]="lat"
        [longitude]="lng"
        [zoom]="zoom"
        [disableDefaultUI]="false"
        [zoomControl]="true"
        (mapClick)="mapClicked($event)"
      >
        <agm-marker
          (markerClick)="clickedMarker(marker.label, index)"
          [latitude]="marker.lat"
          [longitude]="marker.lng"
          [label]="marker.label"
          [markerDraggable]="marker.draggable"
          (dragEnd)="mapClicked($event)"
        >
        </agm-marker>

        <!-- [circleDraggable]="true" -->
        <!-- [editable]="true" -->
        <agm-circle
          [latitude]="lat"
          [longitude]="lng"
          [radius]="5000"
          [fillColor]="'red'"
        >
        </agm-circle>
      </agm-map>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-6"></div>
      <iu-button-ui
        *ngIf="isModal"
        class="iu-btn btn-cancel ui-g-3 ui-md-3 ui-lg-3"
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          closeModalEmitter.emit()
        "
        label="{{ 'process.cancel' | translate }}"
        icon="fa fa-ban"
      >
      </iu-button-ui>
      <iu-button-ui
        *ngIf="isModal"
        class="iu-btn btn-save ui-g-3 ui-md-3 ui-lg-3"
        (click)="
          save();
          $event.preventDefault();
          $event.stopPropagation();
          closeModalEmitter.emit()
        "
        label="{{ 'generic.save' | translate }}"
        icon="fas fa-save"
      >
      </iu-button-ui>
      <!-- <ng-template #bottom></ng-template> -->
    </div>
  </p-scrollPanel>
</div>
