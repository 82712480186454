import { Injectable } from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { environment } from 'environments/environment';
import { IAppConfig } from './app-config.model';

@Injectable()
export class AppConfig {
  private static envVariable: {};
  private static config: IAppConfig;

  constructor() {}

  loadEnvVariable() {
    const configFile = `assets/env.properties`;
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', configFile);

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const response = xhr.responseText;
          AppConfig.envVariable = this.parsePropertyFileToJSON(response);
          if (AppConfig.envVariable && AppConfig.envVariable['IUPICS']) {
            Object.keys(AppConfig.envVariable['IUPICS']).forEach((envKey) => {
              environment[envKey] = AppConfig.envVariable['IUPICS'][envKey];
            });
          }
          resolve(AppConfig.envVariable);
        }
      });
      xhr.send(null);
    });
  }

  loadConfig(access_token) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', Global.configUrl);
      xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const result = JSON.parse(xhr.responseText);
          AppConfig.config = this.parseYMLtoJSON(result.propertySources[0].source);
          resolve(AppConfig.config);
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
          reject();
        }
      });
      xhr.send(null);
    });
  }

  getBackendResource(key?: string): string {
    try {
      AppConfig.config.environment.backend.url =
        AppConfig.envVariable['IUPICS']['environment']['backend']['url'] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['url'] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['url']
          : AppConfig.config.environment.backend.url;
    } catch (e) {}

    try {
      AppConfig.config.environment.backend.paths[key] =
        AppConfig.envVariable['IUPICS']['environment']['backend']['paths'][key] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['paths'][key] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['paths'][key]
          : AppConfig.config.environment.backend.paths[key];
    } catch (e) {}

    if (AppConfig.config) {
      return environment.domain + AppConfig.config.environment.backend.url + AppConfig.config.environment.backend.paths[key];
    } else {
      return null;
    }
  }

  getUniversalSearchURL(): string {
    try {
      AppConfig.config.environment.backend.url =
        AppConfig.envVariable['IUPICS']['environment']['backend']['url'] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['url'] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['url']
          : AppConfig.config.environment.backend.url;
    } catch (e) {}

    return AppConfig.config.environment.universalSearch.url;
  }

  getProcessRunnerResource(key?: string): string {
    try {
      AppConfig.config.environment.backend.processrunner.hostname =
        AppConfig.envVariable['IUPICS']['environment']['backend']['processrunner']['hostname'] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['processrunner']['hostname'] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['processrunner']['hostname']
          : AppConfig.config.environment.backend.processrunner.hostname;
    } catch (e) {}

    try {
      AppConfig.config.environment.backend.processrunner[key] =
        AppConfig.envVariable['IUPICS']['environment']['backend']['processrunner'][key] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['processrunner'][key] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['processrunner'][key]
          : AppConfig.config.environment.backend.processrunner[key];
    } catch (e) {}

    if (!key) {
      return environment.domain + AppConfig.config.environment.backend.processrunner.hostname;
    } else {
      return (
        environment.domain +
        AppConfig.config.environment.backend.processrunner.hostname +
        AppConfig.config.environment.backend.processrunner[key]
      );
    }
  }

  getAuthorizationResource(key?: string): string {
    try {
      AppConfig.config.environment.backend.oauth.url =
        AppConfig.envVariable['IUPICS']['environment']['backend']['oauth']['url'] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['oauth']['url'] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['oauth']['url']
          : AppConfig.config.environment.backend.oauth.url;
    } catch (e) {}

    try {
      AppConfig.config.environment.backend.oauth[key] =
        AppConfig.envVariable['IUPICS']['environment']['backend']['oauth'][key] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['oauth'][key] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['oauth'][key]
          : AppConfig.config.environment.backend.oauth[key];
    } catch (e) {}

    return environment.domain + AppConfig.config.environment.backend.oauth.url + AppConfig.config.environment.backend.oauth[key];
  }

  getBackendUrl(): any {
    try {
      AppConfig.config.environment.backend.url =
        AppConfig.envVariable['IUPICS']['environment']['backend']['url'] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['url'] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['url']
          : AppConfig.config.environment.backend.url;
    } catch (e) {}

    return environment.domain + AppConfig.config.environment.backend.url;
  }

  getSocketclusterResource(key: string): any {
    try {
      AppConfig.config.environment.backend.socketcluster[key] =
        AppConfig.envVariable['IUPICS']['environment']['backend']['socketcluster'][key] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['socketcluster'][key] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['socketcluster'][key]
          : AppConfig.config.environment.backend.socketcluster[key];
    } catch (e) {}

    return AppConfig.config.environment.backend.socketcluster[key];
  }
  getDocServerResource(): any {
    try {
      AppConfig.config.environment.backend.docserver =
        AppConfig.envVariable['IUPICS']['environment']['backend']['docserver'] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['docserver'] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['docserver']
          : AppConfig.config.environment.backend.docserver;
    } catch (e) {}

    return AppConfig.config ? environment.domain + AppConfig.config.environment.backend.docserver : null;
  }

  getDocServerServiceName(): any {
    try {
      AppConfig.config.environment.backend.docserver =
        AppConfig.envVariable['IUPICS']['environment']['backend']['docserver'] !== undefined &&
        AppConfig.envVariable['IUPICS']['environment']['backend']['docserver'] !== null
          ? AppConfig.envVariable['IUPICS']['environment']['backend']['docserver']
          : AppConfig.config.environment.backend.docserver;
    } catch (e) {}

    return AppConfig.config.environment.backend.docserver;
  }

  getConstant(key: string): any {
    try {
      AppConfig.config.constant[key] =
        AppConfig.envVariable['IUPICS']['constant'][key] !== undefined &&
        AppConfig.envVariable['IUPICS']['constant'][key] !== null
          ? AppConfig.envVariable['IUPICS']['constant'][key]
          : AppConfig.config.constant[key];
    } catch (e) {}

    return AppConfig.config ? AppConfig.config.constant[key] : null;
  }

  getOAuthClientId(): any {
    return environment.clientId;
  }

  getOAuthClientSecret(): any {
    return environment.clientSecret;
  }

  getThemeList(): any {
    return AppConfig.config.themes;
  }

  private parseYMLtoJSON(ymlObject: any): IAppConfig {
    const config = {};
    Object.keys(ymlObject).forEach((key) => {
      let node = config;
      const keys = key.split('.');
      for (let i = 0; i < keys.length; i++) {
        if (!node[keys[i]]) {
          if (i === keys.length - 1) {
            if (keys[i].endsWith(']')) {
              const keyArray = keys[i].substr(0, keys[i].lastIndexOf('['));
              if (node[keyArray]) {
                node[keyArray].push(ymlObject[key]);
              } else {
                node[keyArray] = [];
                node[keyArray].push(ymlObject[key]);
              }
            } else {
              node[keys[i]] = ymlObject[key];
            }
          } else {
            node[keys[i]] = {};
          }
        }
        node = node[keys[i]];
      }
    });
    return <IAppConfig>config;
  }

  private parsePropertyFileToJSON(str) {
    const result = {};
    str.split('\n').forEach((line: string) => {
      if (!line.startsWith('#')) {
        const datas = line.split('=');
        const keys = datas[0].split('_');
        let obj = result;
        for (let i = 0; i < keys.length; i++) {
          if (obj instanceof Object) {
            if (!obj[keys[i].trim()] && obj) {
              obj[keys[i].trim()] = {};
            }
            if (i + 1 === keys.length) {
              try {
                obj[keys[i].trim()] = JSON.parse(datas[1]);
              } catch (e) {
                obj[keys[i].trim()] = datas[1];
              }
            }
            obj = obj[keys[i].trim()];
          }
        }
      }
    });
    return result;
  }
}
