<div [ngClass]="[isFlexDesign ? 'board-alone' : 'board']">
  <div class="board-inner">
    <header class="board-header" [ngStyle]="{ display: kanbanGroup.title !== undefined ? 'block' : 'none' }">
      <h3 class="board-title js-board-handle">
        <span title="{{ kanbanGroup.title }}" class="board-title-text has-tooltip block-truncated">
          {{ kanbanGroup.title }}
        </span>
        <div title="{{ kanbanGroup.datas.length }}" class="issue-count-badge text-secondary">
          <span class="issue-count-badge-count"> {{ kanbanGroup.datas.length }} </span>
        </div>
        <button
          aria-label="New issue"
          title=""
          class="issue-count-badge-add-button btn btn-sm btn-default ml-1 has-tooltip js-no-trigger-collapse"
        >
          +
        </button>
      </h3>
    </header>
    <div [ngClass]="[!this.kanbanGroup.title ? 'all' : '', kanbanGroup.isMoreData ? 'withButton' : '', 'board-list-component']">
      <ul #boardList data-board="64" [ngClass]="[isFlexDesign ? 'board-list-flex' : 'board-list']" (drop)="changeGroup()">
        <li
          #cardboard
          *ngFor="let dataKanban of kanbanGroup.datas"
          draggable="true"
          (dragstart)="dragstart($event, cardboard, dataKanban)"
          (dragover)="dragOver($event)"
          (dragend)="dragend()"
          (click)="onClick(dataKanban)"
          [ngClass]="[isFlexDesign ? 'board-card-flex' : 'board-card', 'ui-g-12 ui-md-6 ui-lg-3']"
        >
          <iu-kanban-card-ui [datasKanban]="dataKanban" [tableName]="tableName"></iu-kanban-card-ui>
        </li>
      </ul>
      <div
        *ngIf="!isFlexDesign"
        style="flex: 1 1 auto;"
        (dragover)="dragOverEndList($event)"
        (dragend)="dragend()"
        (drop)="changeGroup()"
      ></div>
    </div>
    <div class="moreItems">
      <button *ngIf="kanbanGroup.isMoreData" (click)="moreData()" class="ui-button ui-state-default ui-button-secondary">
        Voir plus
      </button>
    </div>
  </div>
</div>
