import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { OverridedCSS } from '@iupics-manager/models/overrided-css';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { of, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import { getOPComponents, getOperationDetail, getOPRessources, Operation } from './operation-management-utils';

@Component({
  selector: 'iu-operation-management-ui',
  templateUrl: './operation-management-ui.component.html',
  styleUrls: ['./operation-management-ui.component.scss']
})
export class OperationManagementUIComponent extends SpecificWindowUiComponent implements OnInit, OnDestroy {
  @ViewChild('top', { read: ViewContainerRef, static: true }) vcrTop: ViewContainerRef;

  fakeOperation: Operation = {
    components: [
      { name: 'vis', baseQty: 10, usedQty: 10, unit: 'unité(s)' },
      { name: 'boulon', baseQty: 5, usedQty: 5, unit: 'unité(s)' },
      { name: 'bois', baseQty: 24, usedQty: 24, unit: 'unité(s)' }
    ],
    ressources: [
      { name: 'Montage', baseTime: 2, usedTime: 2 },
      { name: 'Vernisage', baseTime: 1, usedTime: 1 }
    ],
    attachedFile: [
      {
        docId: 'img-test-1234',
        id: 'img-test-1234',
        name: 'img-test.png',
        src: 'assets/img-test.png',
        isDeletable: false
      },
      {
        docId: 'pdf-test-1234',
        id: 'pdf-test-1234',
        name: 'pdf-test.pdf',
        src: 'assets/pdf-test.pdf',
        isDeletable: false
      }
    ],
    description: "description de l'opération",
    deltaTime: '',
    note: ''
  };

  selectedOperation: Operation;

  fakeProducts = [
    {
      name: 'Article C1',
      baseQty: 2,
      realQty: 2
    },
    {
      name: 'Article C2',
      baseQty: 5,
      realQty: 5
    }
  ];

  selectedProducts: { name: string; baseQty: number; realQty: number }[];

  bigLabel: OverridedCSS = {
    labelFontSize: '16pt'
  };

  bigLegend: OverridedCSS = {
    legendFontSize: '18pt'
  };

  bigInput: OverridedCSS = {
    inputFontSize: '12pt',
    inputHeight: '30px'
  };

  hasDeltaTime = false;
  isLastOperation = false;
  isAddingOperationComponent = false;

  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );

    this.customDesignArray.push(
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_User_ID',
        label: this.translateService.instant('operation-management.user'),
        overridedCSS: { ...this.bigLabel, ...this.bigInput },
        cssClass: 'ui-g-6'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Z_Production_ID',
        overridedCSS: { ...this.bigLabel, ...this.bigInput },
        cssClass: 'ui-g-6'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Z_Production_Operation_ID',
        overridedCSS: { ...this.bigLabel, ...this.bigInput },
        cssClass: 'ui-g-offset-6 ui-g-6'
      }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.showSpecificWindow();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  notifyFromDatacontainerInit(dataContainer: AbstractDataContainer) {
    switch (dataContainer.data.columnName) {
      case 'Z_Production_ID':
        this.subscriptions.push(
          dataContainer.fieldValueModified.subscribe((value: DataStore) => {
            this.selectedOperation = undefined;
          })
        );
        break;
      case 'Z_Production_Operation_ID':
        this.subscriptions.push(
          dataContainer.fieldValueModified.subscribe((value: DataStore) => {
            const Z_Production_Operation_ID: IAutocomplete = value.data['Z_Production_Operation_ID'];
            if (Z_Production_Operation_ID) {
              zip(
                this.store.getDataGrid(
                  getOperationDetail(Z_Production_Operation_ID.id, this.connectorService.getIupicsDefaultLanguage().iso_code)
                ),
                this.store.getDataGrid(
                  getOPComponents(Z_Production_Operation_ID.id, this.connectorService.getIupicsDefaultLanguage().iso_code)
                ),
                // of(this.fakeOperation.ressources)
                this.store.getDataGrid(
                  getOPRessources(Z_Production_Operation_ID.id, this.connectorService.getIupicsDefaultLanguage().iso_code)
                )
              )
                .pipe(
                  switchMap(([operation, components, ressources]) => {
                    console.log(operation, components, ressources);

                    const _selectedOperation: Operation = {
                      attachedFile: cloneDeep(this.fakeOperation.attachedFile),
                      components: components.data.map((data) => {
                        return {
                          baseQty: data['QtyToConsume'],
                          name: data['M_Product_ID']?.displayValue,
                          usedQty: data['QtyToConsume'],
                          unit: data['C_UOM_ID'].displayValue
                        };
                      }),
                      deltaTime: this.fakeOperation.deltaTime,
                      description: operation.data[0]['Description'],
                      note: this.fakeOperation.note,
                      ressources: cloneDeep(this.fakeOperation.ressources)
                    };
                    this.isLastOperation = operation.data[0]['IsLastOperation'] === 'Y';
                    this.selectedOperation = _selectedOperation;
                    if (this.isLastOperation) {
                      return of(this.fakeProducts);
                    }
                    return of(undefined);
                  })
                )
                .subscribe((products) => {
                  if (products !== undefined) {
                    this.selectedProducts = products;
                  }
                });
            } else {
              this.selectedOperation = undefined;
            }
          })
        );
        break;
      default:
        break;
    }
  }

  changeUsedQtyOperation(nb: number, index: number) {
    if (!(this.selectedOperation.components[index].usedQty === 0 && nb < 0)) {
      this.selectedOperation.components[index].usedQty += nb;
    }
  }

  updateUsedQtyOperation(value: number, index: number) {
    if (value < 0) {
      value = 0;
    }
    this.selectedOperation.components[index].usedQty = value;
  }

  changeUsedQtyProduct(nb: number, index: number) {
    if (!(this.selectedProducts[index].realQty === 0 && nb < 0)) {
      this.selectedProducts[index].realQty += nb;
    }
  }

  updateUsedQtyProduct(value: number, index: number) {
    if (value < 0) {
      value = 0;
    }
    this.selectedProducts[index].realQty = value;
  }

  changeUsedTime(nb: number, index: number) {
    if (!(this.selectedOperation.ressources[index].usedTime === 0 && nb < 0)) {
      this.selectedOperation.ressources[index].usedTime += nb;
    }
    this.checkDeltaTime();
  }

  updateUsedTime(value: number, index: number) {
    if (value < 0) {
      value = 0;
    }
    this.selectedOperation.ressources[index].usedTime = value;
    this.checkDeltaTime();
  }

  private checkDeltaTime(): void {
    let hasDeltaTime = false;
    for (let i = 0; i < this.selectedOperation.ressources.length; i++) {
      const res = this.selectedOperation.ressources[i];
      if (res.baseTime !== res.usedTime) {
        hasDeltaTime = true;
        break;
      }
    }

    if (this.hasDeltaTime !== hasDeltaTime) {
      this.hasDeltaTime = hasDeltaTime;
      if (!hasDeltaTime) {
        this.selectedOperation.deltaTime = '';
      }
    }
  }

  updateDeltaTime(value: string) {
    this.selectedOperation.deltaTime = value;
  }

  // todo renommer la méthode pour qu'elle corresponde à ce qu'elle fait
  saveOperation(event: MouseEvent) {
    // todo: ajouter la logique de sauvegarde de l'opération
    console.log(event);
  }

  // todo renommer la méthode pour qu'elle corresponde à ce qu'elle fait
  validateOperation(event: MouseEvent) {
    // todo: ajouter la logique de validation de l'opération
    // this.isLastOperation = true;
    // this.selectedProducts = cloneDeep(this.fakeProducts);
    console.log(event);
  }

  // todo renommer la méthode pour qu'elle corresponde à ce qu'elle fait
  saveProducts(event: MouseEvent) {
    // todo: ajouter la logique de sauvegardes des produits finis
    console.log(event);
  }

  // todo renommer la méthode pour qu'elle corresponde à ce qu'elle fait
  endOperation(event: MouseEvent) {
    // todo: ajouter la logique de fin de l'opération
    console.log(event);
  }

  toggleComponentInput(event: MouseEvent) {
    this.isAddingOperationComponent = true;
  }

  // todo renommer la méthode pour qu'elle corresponde à ce qu'elle fait
  addOperationComponent(name: string, baseQty: number, unit: string, event: MouseEvent) {
    // todo: ajouter la logique d'ajout du composant
    console.log(name, baseQty, event);
    this.selectedOperation.components.push({
      name,
      baseQty,
      usedQty: baseQty,
      unit
    });
    this.isAddingOperationComponent = false;
  }
}
