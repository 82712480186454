<div class="mainDiv">
  <div class="content page-404">
    <!-- #region custo|src|alt -->
    <img src="assets/themes/iupics/img/Logo-Guides.png" alt="SCRIBe" width="150px" srcset="assets/themes/iupics/img/Logo-Guides.png 2x" />
    <!-- #endregion custo -->
    <div></div>
    <div></div>
    <div></div>
    <div>
      <h1 class="message">{{ message | translate }}</h1>
    </div>
    <div>
      <button routerLink="/dashboard" class="ui-button primary">Retour au dashboard</button>
    </div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
