<div #mainDiv id="chips" [ngClass]="['ui-chips ui-widget', styleClass || '']" [ngStyle]="style" (click)="onClick()">
  <ul
    [ngClass]="[
      'ui-inputtext ui-state-default ui-corner-all',
      focus ? 'ui-state-focus' : '',
      disabled ? 'ui-state-disabled' : ''
    ]"
    style="display: flex; overflow-x: hidden"
  >
    <div
      #wrapper
      [ngStyle]="{
        overflow: 'hidden',
        'max-width.px': maxWidthChipsContainer,
        'white-space': 'nowrap',
        display: '-webkit-inline-box'
      }"
      (wheel)="handleWheel($event)"
    >
      <li
        #token
        *ngFor="let item of value; let i = index"
        class="ui-chips-token ui-state-highlight ui-corner-all"
        (click)="onItemClick($event, item)"
      >
        <span *ngIf="!disabled" class="ui-chips-token-icon pi pi-fw pi-times" (click)="removeItem($event, i)"></span>
        <span *ngIf="!itemTemplate" class="ui-chips-token-label">{{ field ? resolveFieldData(item, field) : item }}</span>
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
      </li>
    </div>
    <li class="ui-chips-input-token" style="max-width: 50%">
      <input
        #inputtext
        type="text"
        [attr.id]="inputId"
        [attr.placeholder]="value && value.length ? null : placeholder"
        [attr.tabindex]="tabindex"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeydown($event)"
        (focus)="onInputFocus($event)"
        (blur)="onInputBlur($event)"
        [disabled]="disabled || preventInput"
        [ngStyle]="inputStyle"
        [ngClass]="['prime-chips-input', value?.length ? 'prime-chips-input-small' : '', inputStyleClass || '']"
        autocomplete="no"
        data-cy="input-chips"
        [attr.data-cy-columnName]="columnName"
      />
    </li>
  </ul>
</div>
