//#region custo|WaitingListService
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CompiereCalloutService } from '@compiere-ws/services/compiere-callout/compiere-callout.service';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { CompiereLoginService } from '@compiere-ws/services/compiere-login/compiere-login.service';
import { CompiereMenuService } from '@compiere-ws/services/compiere-menu/compiere-menu.service';
import { CompiereMenuFavoritesService } from '@compiere-ws/services/compiere-menufavorites/compiere-menufavorites.service';
import { CompiereNotificationService } from '@compiere-ws/services/compiere-notification/compiere-notification.service';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { PushNotificationsService } from '@compiere-ws/services/push-notifications/push-notifications.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { WidgetCenterService } from '@compiere-ws/services/widget-center/widget-center.service';
import { WindowsService } from '@compiere-ws/services/windows/windows.service';
import { ElasticService } from '@elastics-ws/services/elastic.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { CallInProgressService } from './services/call-in-progress/call-in-progress.service';
import { EmailService } from './services/compiere-email/email.service';
import { CompiereExportDataService } from './services/compiere-export-data/compiere-export-data.service';
import { LocationService } from './services/compiere-location/location.service';
import { PrintReportService } from './services/compiere-print-report/print-report.service';
import { CompiereRecentItemService } from './services/compiere-recent-item/compiere-recent-item.service';
import { DocServerService } from './services/doc-server/doc-server.service';
import { PoService } from './services/po/po.service';
import { PrinterService } from './services/printer-service/printer-service.service';
import { ProcessInProgressService } from './services/process-in-progress/process-in-progress.service';
import { UniversalFilterService } from './services/universal-filter/universal-filter.service';
import { WaitingListService } from './services/waiting-list/waiting-list.service';

//#endregion custo
@NgModule({
  imports: [CommonModule, HttpClientModule],
  exports: [HttpClientModule],
  providers: [
    WindowsService,
    CompiereMenuService,
    CompiereMenuFavoritesService,
    CompiereLoginService,
    UICreatorService,
    ElasticService,
    CompiereDataService,
    WidgetCenterService,
    CompiereProcessService,
    CompiereCalloutService,
    SocketService,
    PushNotificationsService,
    CompiereNotificationService,
    CompiereWorkflowService,
    PrintReportService,
    LocationService,
    DocServerService,
    EmailService,
    CompiereExportDataService,
    CompiereRecentItemService,
    UniversalFilterService,
    PoService,
    ProcessInProgressService,
    CallInProgressService,
    PrinterService,
    //#region custo
    WaitingListService
    //#endregion custo
  ]
})
export class CompiereWsModule {}
