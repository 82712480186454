<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window fast']"
  [attr.data-cy-specific-window-name]="name"
>
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div id="page-wrap">
      <h1>{{ 'specificWindow.quickOrder.title' | translate }} ( {{ docStatus }} )</h1>
      <div id="customer">
        <div class="row">
          <table class="metaleft ui-md-4 ui-sm-12" id="meta">
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.customer' | translate }}</td>
              <td><ng-template #vcrBPartner></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.customerLocation' | translate }}</td>
              <td>
                <ng-template #vcrBPartnerLocation></ng-template>
              </td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.deliveryViaRule' | translate }}</td>
              <td><ng-template #vcrDeliveryViaRule></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.deliveryRule' | translate }}</td>
              <td><ng-template #vcrDeliveryRule></ng-template></td>
            </tr>
          </table>

          <table class="metaright ui-md-4 ui-sm-12" id="meta">
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.order' | translate }}</td>
              <td><ng-template #vcrOrder></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.poReference' | translate }}</td>
              <td><ng-template #vcrPOReference></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.dateOrdered' | translate }}</td>
              <td><ng-template #vcrDateOrdered></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.shipper' | translate }}</td>
              <td><ng-template #vcrMShipper></ng-template></td>
            </tr>
          </table>
        </div>
        <a class="settings" (click)="isSidebarOpen = !isSidebarOpen"><i class="fas fa-cog"></i></a>
      </div>
      <table id="items">
        <thead>
          <tr>
            <th>{{ 'specificWindow.quickOrder.product' | translate }}</th>
            <th>{{ 'specificWindow.quickOrder.description' | translate }}</th>
            <!-- <th class="alignRight">{{ 'specificWindow.quickOrder.priceList' | translate }}</th> -->
            <th class="alignRight">{{ 'specificWindow.quickOrder.unitPrice' | translate }}</th>
            <th>{{ 'specificWindow.quickOrder.quantity' | translate }}</th>
            <th>{{ 'specificWindow.quickOrder.discount' | translate }}</th>
            <th class="alignRight">{{ 'specificWindow.quickOrder.totalLine' | translate }}</th>
            <th class="alignRight"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of dataLines.length + 1 | fakeArray; let i = index">
            <tr class="item-row">
              <td class="item-name">
                <ng-template #vcrProduct></ng-template>
              </td>
              <td class="description"><ng-template #vcrDescription></ng-template></td>
              <!-- <td class="fastorder-price">
                <ng-template #vcrPriceList></ng-template>
              </td> -->
              <td class="fastorder-price">
                <ng-template #vcrPriceList></ng-template>
                <ng-template #vcrPriceActual></ng-template>
                <ng-template #vcrPriceEntered></ng-template>
              </td>
              <td class="fastorder-qty"><ng-template #vcrQty></ng-template></td>
              <td class="fastorder-discount"><ng-template #vcrDiscount></ng-template></td>
              <td class="fastorder-linetotal"><ng-template #vcrLineTotalAmt></ng-template></td>
              <td class="deleteButton">
                {{ item }}
                <button
                  *ngIf="i < $any(dataLines).length"
                  [ngClass]="[
                    dataStore &&
                    dataStore.data['DocStatus'] &&
                    (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                      ? 'disabledButton'
                      : ''
                  ]"
                  tooltipPosition="top"
                  showDelay="250"
                  life="3"
                  pTooltip="{{ 'generic.delete' | translate }}"
                  pButton
                  icon="icon-ico-delete"
                  (click)="removeLine(dataLines[i], i)"
                ></button>
              </td>
            </tr>
          </ng-container>
          <tr id="hiderow">
            <td colspan="7">
              <a
                id="addrow"
                [ngClass]="[
                  dataStore &&
                  (!dataStore.data['C_Order_ID'] ||
                    (dataStore.data['DocStatus'] &&
                      (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')))
                    ? 'disabledButton'
                    : '',
                  'button secondary'
                ]"
                title="Add a row"
                (click)="newLine()"
                (focus)="newLine()"
                ><span class="icon-ico-add"></span>{{ 'specificWindow.quickOrder.addrow' | translate }}</a
              >
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" class="blank"></td>
            <td class="total-line balance">{{ 'specificWindow.quickOrder.orderTotal' | translate }}</td>
            <td colspan="2" class="total-value balance fastorder-grandtotal"><ng-template #vcrGrandTotal></ng-template></td>
          </tr>
          <tr>
            <td class="buttonExecute" colspan="7">
              <a
                *ngIf="
                  dataStore &&
                  dataStore.data['DocStatus'] &&
                  (dataStore.data['DocStatus'] !== 'DR' ||
                    (dataStore.data['DocStatus'].id && dataStore.data['DocStatus'].id !== 'DR'))
                "
                class="iu-btn btn-execute"
                (click)="$event.preventDefault(); $event.stopPropagation(); resetOrder()"
              >
                <i class="fa fa-plus"></i>
                {{ 'specificWindow.quickOrder.newOrder' | translate }}
              </a>
              <a
                [ngClass]="[
                  dataStore &&
                  dataStore.data['DocStatus'] &&
                  (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                    ? 'disabledButton'
                    : '',
                  'iu-btn btn-execute'
                ]"
                (click)="$event.preventDefault(); $event.stopPropagation(); processOrder()"
              >
                <i
                  [ngClass]="[
                    dataStore &&
                    dataStore.data['DocStatus'] &&
                    (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                      ? 'fa fa-ban'
                      : 'fa fa-check'
                  ]"
                ></i>
                {{ 'specificWindow.processing' | translate }}
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div style="height: 70px;"></div>
  </p-scrollPanel>
  <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="6000" position="right" styleClass="ui-sidebar-md">
    <h1 class="iu-notification-center-title">
      {{ 'specificWindow.quickOrder.settings' | translate }}
    </h1>
    <ng-template #vcrSettings></ng-template>
  </p-sidebar>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
