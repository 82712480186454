import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { ApiService } from '@compiere-ws/services/api/api.service';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AutocompleteRendererComponent } from '../renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from '../renderer/button-renderer.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-one-management',
  templateUrl: './one-management.component.html',
  styleUrls: ['./one-management.component.scss']
})
export class OneManagementComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('filter', { read: ViewContainerRef, static: true })
  vcrFilter: ViewContainerRef;
  @ViewChild('synchronizePO', { read: ViewContainerRef, static: true })
  vcrSynchronizePO: ViewContainerRef;
  @ViewChild('declareActivity', { read: ViewContainerRef, static: true })
  vcrDeclareActivity: ViewContainerRef;
  @ViewChild('requestSubsidy', { read: ViewContainerRef, static: true })
  vcrRequestSubsidy: ViewContainerRef;

  tabs: OneTab[] = [];
  selectedTab: OneTab = null;
  url;
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService,
    translateService: TranslateService,
    private http: ApiService,
    private config: AppConfig,
    private messageManager: MessageManagerService
  ) {
    super(windowFactory, resolver, uiCreator, store, processService, socketService, connectorService, pp, translateService);
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent
    };
    this.url = this.config.getBackendResource('oneApi');
  }
  checkUrl() {
    if (!this.url) this.url = this.config.getBackendResource('oneApi');
  }
  ngOnInit() {
    this.tabs = [
      {
        id: 'synchronizePO',
        title: this.translateService.instant('OneManagement.synchronizePO'),
        content: null,
        action: this.synchronizePO.bind(this)
      },
      {
        id: 'declareActivity',
        title: this.translateService.instant('OneManagement.declareActivity'),
        content: null,
        action: this.declareActivity.bind(this)
      },
      {
        id: 'requestSubsidy',
        title: this.translateService.instant('OneManagement.requestSubsidy'),
        content: null,
        action: this.requestSubsidy.bind(this)
      }
    ];
    const request: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_MESSAGE',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: [
              [
                'GUIDES_ONE_SYNCHRONIZE_PO_DESCRIPTION',
                'GUIDES_ONE_DECLARE_ACTIVITY_DESCRIPTION',
                'GUIDES_ONE_REQUEST_SUBSIDY_DESCRIPTION'
              ]
            ],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };
    this.subscriptions.push(
      this.store.getDataGrid(request, true).subscribe(
        (response) => {
          if (response.data.length > 0) {
            let message = response.data.find((d) => (d['VALUE'] === 'GUIDES_ONE_SYNCHRONIZE_PO_DESCRIPTION'));
            if (message) {
              this.tabs[0].content = message['MSGTEXT'];
            }
            message = response.data.find((d) => (d['VALUE'] === 'GUIDES_ONE_DECLARE_ACTIVITY_DESCRIPTION'));
            if (message) {
              this.tabs[1].content = message['MSGTEXT'];
            }
            message = response.data.find((d) => (d['VALUE'] === 'GUIDES_ONE_REQUEST_SUBSIDY_DESCRIPTION'));
            if (message) {
              this.tabs[2].content = message['MSGTEXT'];
            }
          }
        },
        () => {}
      )
    );
    this.selectTab(null, this.tabs[0]);
  }

  selectTab(event, tab: OneTab) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    this.selectedTab = tab;
  }
  synchronizePO() {
    this.checkUrl();
    this.isLoading = true;
    this.subscriptions.push(
      this.http.post<any>(`${this.url}synchronizePO`, null).subscribe(
        (response) => {
          this.isLoading = false;
          if (response) {
            this.messageManager.newMessage(
              new IupicsMessage(this.translateService.instant('OneManagement.callSuccess'), '', 'success')
            );
          } else {
            throw new Error(this.translateService.instant('OneManagement.callError'));
          }
        },
        () => {
          this.isLoading = false;
          throw new Error(this.translateService.instant('OneManagement.callError'));
        }
      )
    );
  }
  declareActivity() {
    this.checkUrl();
    this.isLoading = true;
    this.subscriptions.push(
      this.http.post<any>(`${this.url}declareActivity`, null).subscribe(
        (response) => {
          this.isLoading = false;
          if (response) {
            this.messageManager.newMessage(
              new IupicsMessage(this.translateService.instant('OneManagement.callSuccess'), '', 'success')
            );
          } else {
            throw new Error(this.translateService.instant('OneManagement.callError'));
          }
        },
        () => {
          this.isLoading = false;
          throw new Error(this.translateService.instant('OneManagement.callError'));
        }
      )
    );
  }
  requestSubsidy() {
    this.checkUrl();
    this.isLoading = true;
    this.subscriptions.push(
      this.http.post<any>(`${this.url}requestSubsidy`, null).subscribe(
        (response) => {
          this.isLoading = false;
          if (response) {
            this.messageManager.newMessage(
              new IupicsMessage(this.translateService.instant('OneManagement.callSuccess'), '', 'success')
            );
          } else {
            throw new Error(this.translateService.instant('OneManagement.callError'));
          }
        },
        () => {
          this.isLoading = false;
          throw new Error(this.translateService.instant('OneManagement.callError'));
        }
      )
    );
  }
}

interface OneTab {
  id: string;
  title: string;
  content: string;
  action: Function;
}
