<div class="iu-calendar-toolbar-ui">
  <div class="iu-calendar-toolbar-ui-left">
    <ng-container *ngIf="!isMobile; else mobileView">
      <p-button
        life="3"
        pTooltip="{{ 'calendar.month' | translate }}"
        tooltipPosition="top"
        showDelay="1000"
        label="{{ 'calendar.month' | translate }}"
        (click)="changeView.emit('dayGridMonth')"
      ></p-button>
      <p-button
        life="3"
        pTooltip="{{ 'calendar.week' | translate }}"
        tooltipPosition="top"
        showDelay="1000"
        label="{{ 'calendar.week' | translate }}"
        (click)="changeView.emit('dayGridWeek')"
      ></p-button>
      <p-button
        life="3"
        pTooltip="{{ 'calendar.day' | translate }}"
        tooltipPosition="top"
        showDelay="1000"
        label="{{ 'calendar.day' | translate }}"
        (click)="changeView.emit('dayGridDay')"
      ></p-button>
    </ng-container>

    <ng-template #mobileView>
      <p-button
        life="3"
        pTooltip="{{ 'calendar.' + cycle.current.replace('dayGrid', '') | lowercase | translate }}"
        tooltipPosition="top"
        showDelay="1000"
        label="{{ 'calendar.' + cycle.current.replace('dayGrid', '') | lowercase | translate }}"
        (click)="switchMobileView($event, cycle.current)"
      ></p-button>
    </ng-template>

    <p-button
      life="3"
      pTooltip="prev"
      tooltipPosition="top"
      showDelay="1000"
      icon="fa fa-angle-left"
      (click)="click($event, 'prev')"
    ></p-button>
  </div>
  <div class="iu-calendar-toolbar-ui-right">
    <p-button
      life="3"
      pTooltip="next"
      tooltipPosition="top"
      showDelay="1000"
      icon="fa fa-angle-right"
      (click)="click($event, 'next')"
    ></p-button>
    <p-button
      life="3"
      pTooltip="{{ 'calendar.today' | translate }}"
      tooltipPosition="top"
      showDelay="1000"
      label="{{ 'calendar.today' | translate }}"
      (click)="click($event, 'today')"
    ></p-button>
  </div>
  <div class="iu-calendar-toolbar-ui-center">
    <div class="iu-calendar-toolbar-ui-title">{{ title }}</div>
  </div>
  <div class="iu-calendar-toolbar-ui-clear"></div>
</div>
