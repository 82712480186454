import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'iu-input-place-autocomplete-ui',
  templateUrl: './input-place-autocomplete-ui.component.html',
  styleUrls: ['./input-place-autocomplete-ui.component.scss']
})
export class InputPlaceAutocompleteUiComponent extends AbstractDataContainer implements OnInit {
  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    renderer: Renderer2,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    po: PoService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
  }

  @Input()
  cssGrid: string;
  @Input()
  placeHolder: string;
  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;
  dataContainers: AbstractDataContainerCallout;

  @Output()
  fieldValueChange = new EventEmitter<any>();

  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();

    this.mapsAPILoader.load().then(() => {
      const request: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'AD_Message',
          filterModel: {
            Value: {
              filterType: CompiereDataGridFilterType.TEXT,
              values: ['PLACE_AUTOCOMPLETE_COUNTRIES_RESTRICTION'],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };

      this.store.getDataGrid(request).subscribe(response => {
        const ListCountries = response.data.length > 0 ? response.data[0]['MSGTEXT'].split(',') : [];
        const autocomplete = new google.maps.places.Autocomplete(this.inputRef.nativeElement, { types: ['geocode'] });
        autocomplete.setComponentRestrictions({ country: ListCountries });
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.formatted_address) {
              super.dataChange(place.formatted_address);
            }
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
          });
        });
      });
    });
  }

  dataChange(value: any) {
    if (value === null || value === '') {
      super.dataChange(value);
    }
  }
}
