import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-lettrage-ecritures-comptables',
  templateUrl: './lettrage-ecritures-comptables.component.html',
  styleUrls: ['./lettrage-ecritures-comptables.component.scss']
})
export class LettrageEcrituresComptablesComponent extends SpecificWindowUiComponent implements OnInit {
  LengthOfRowSelected = 0;

  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('middlebtn', { read: ViewContainerRef, static: true })
  vcrMiddlebtn: ViewContainerRef;
  @ViewChild('middleDate', { read: ViewContainerRef, static: true })
  vcrMiddleDate: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  vcrBot: ViewContainerRef;
  @ViewChild('optional', { read: ViewContainerRef, static: true })
  vcrOptional: ViewContainerRef;

  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected pp: ProcessInProgressService,
    protected translateService: TranslateService,
    protected messageManager: MessageManagerService
  ) {
    super(windowFactory, resolver, uiCreator, store, processService, socketService, connectorService, pp, translateService);
  }

  ngOnInit() {
    // super.ngOnInit();

    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        if (item.component === 'GridViewUiComponent') {
          item.data.hasCheckbox = true;
          const editorColumns = [];

          this.customDesignArray.push({
            vcr: 'vcrBot',
            type: CustomDesignItemType.GRID,
            tableName: 'tableFactAcct',
            cssClass: 'ui-g-12',
            shouldSelectFirst: false,
            editorColumns: editorColumns
          });
        } else if (item.data.columnName === 'statusLine') {
          const fieldSize = 'ui-g-12  ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrBot',
            type: CustomDesignItemType.FIELD,
            columnName: 'statusLine',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'C_AcctSchema_ID') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'C_AcctSchema_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'SelectDocument') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'SelectDocument',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'AD_Table_ID') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'AD_Table_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'PostingType') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'PostingType',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'Record_ID') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'Record_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'Description') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'Description',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'fMatchingNo') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'fMatchingNo',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'DateFrom') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'DateFrom',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'DateAcctto') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'DateAcctto',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'AD_Org_ID') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'AD_Org_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'Account_ID') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'Account_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'User09_ID') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'User09_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'MatchingDate') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrMiddleDate',
            type: CustomDesignItemType.FIELD,
            columnName: 'MatchingDate',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'Lettrage') {
          const fieldSize = 'ui-g-12 ui-md-6 ui-lg-4';
          this.customDesignArray.push({
            vcr: 'vcrMiddlebtn',
            type: CustomDesignItemType.FIELD,
            columnName: 'Lettrage',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'Delettrage') {
          const fieldSize = 'ui-g-12 ui-md-6 ui-lg-4';
          this.customDesignArray.push({
            vcr: 'vcrMiddlebtn',
            type: CustomDesignItemType.FIELD,
            columnName: 'Delettrage',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'Verifierlettrage') {
          const fieldSize = 'ui-g-12 ui-md-12 ui-lg-4';
          this.customDesignArray.push({
            vcr: 'vcrMiddlebtn',
            type: CustomDesignItemType.FIELD,
            columnName: 'Verifierlettrage',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'DisplayMatch') {
          const fieldSize = 'ui-g-12 ui-md-4';
          this.customDesignArray.push({
            vcr: 'vcrMiddlebtn',
            type: CustomDesignItemType.FIELD,
            columnName: 'DisplayMatch',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'C_Project_ID') {
          const fieldSize = 'ui-g-12';
          this.customDesignArray.push({
            vcr: 'vcrOptional',
            type: CustomDesignItemType.FIELD,
            columnName: 'C_Project_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'C_BPartner_ID') {
          const fieldSize = 'ui-g-12';
          this.customDesignArray.push({
            vcr: 'vcrOptional',
            type: CustomDesignItemType.FIELD,
            columnName: 'C_BPartner_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'User1_ID') {
          const fieldSize = 'ui-g-12';
          this.customDesignArray.push({
            vcr: 'vcrOptional',
            type: CustomDesignItemType.FIELD,
            columnName: 'User1_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'M_Product_ID') {
          const fieldSize = 'ui-g-12';
          this.customDesignArray.push({
            vcr: 'vcrOptional',
            type: CustomDesignItemType.FIELD,
            columnName: 'M_Product_ID',
            cssClass: fieldSize
          });
        } else if (item.data.columnName === 'AmtAcctDr') {
          const fieldSize = 'ui-g-12';
          this.customDesignArray.push({
            vcr: 'vcrOptional',
            type: CustomDesignItemType.FIELD,
            columnName: 'AmtAcctDr',
            cssClass: fieldSize
          });
        }
      });
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'ui-g-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };
      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });

    this.dataContainers.forEach((datacontainer) => {
      if (datacontainer.data.columnName === 'Lettrage') {
        datacontainer.isReadOnly = true;

        datacontainer.data.IsReadOnly = true;
      }
      if (datacontainer.data.columnName === 'Delettrage') {
        datacontainer.isReadOnly = true;
        datacontainer.data.IsReadOnly = true;
      }
    });
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.colResize();
  }
  processLettrage(item) {
    this.dataStore.data['Lettrage'] = 'Y';
    this.dataStore.data['Delettrage'] = 'N';
    this.dataStore.data['Verifierlettrage'] = 'N';
    let button = this.getDatacontainer('Delettrage');
    if (button) {
      button.isReadOnly = true;
    }
    button = this.getDatacontainer('Verifierlettrage');
    if (button) {
      button.isReadOnly = true;
    }
    this.executeProcess(item.processId);
  }
  processDelettrage(item) {
    if (
      this.dataStore.data['fMatchingNo'] != null &&
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().length > 1
    ) {
      this.dataStore.data['Lettrage'] = 'N';
      this.dataStore.data['Delettrage'] = 'Y';
      this.dataStore.data['Verifierlettrage'] = 'N';
      let button = this.getDatacontainer('Delettrage');
      if (button) {
        button.isReadOnly = true;
      }
      button = this.getDatacontainer('Verifierlettrage');
      if (button) {
        button.isReadOnly = true;
      }
      this.executeProcess(item.processId);
    } else {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          'Verify that No Lettrage is set and there are rows selected',
          'error'
        )
      );
    }
  }
  processVerifierlettrage(item) {
    this.dataStore.data['Lettrage'] = 'N';
    this.dataStore.data['Delettrage'] = 'N';
    this.dataStore.data['Verifierlettrage'] = 'Y';
    this.executeProcess(item.processId);
  }

  /**
   * à implémenter si on veut etre notifié des sélections
   * @param gridView grid sur laquelle s'est fait la sélection
   */
  notifyFromRowSelected(gridView: GridViewUiComponent) {
    this.LengthOfRowSelected = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().length;
    let message = '';
    let sumDrCr = 0;
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().forEach((element) => {
      sumDrCr = sumDrCr + element['AmtAcctDr'] - element['AmtAcctCr'];
    });

    const buttonLettrage = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'Lettrage');
    const buttonDelettrage = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'Delettrage');

    if (sumDrCr === 0 && this.LengthOfRowSelected !== 0) {
      // disable lettrage
      buttonLettrage.isReadOnly = false;
    } else {
      buttonLettrage.isReadOnly = true;
    }

    if (
      this.dataStore.data['DisplayMatch'].id === 'M' &&
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getInfiniteRowCount() > 1 &&
      this.dataStore.data['fMatchingNo'] !== null &&
      this.dataStore.data['fMatchingNo'].length > 0
    ) {
      buttonDelettrage.isReadOnly = false;
    } else {
      buttonDelettrage.isReadOnly = true;
    }

    if (this.LengthOfRowSelected === 0) {
      message = 'Pas de lignes sélectionnées';
    } else {
      message = 'Total = ' + sumDrCr.toFixed(2);
    }
    const champs = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'statusLine');
    champs.dataChange(message);
  }

  onEndProcess() {
    let button = this.getDatacontainer('Delettrage');
    if (button) {
      button.isReadOnly = false;
    }
    button = this.getDatacontainer('Verifierlettrage');
    if (button) {
      button.isReadOnly = false;
    }
    this.refreshGrids(this.dataStore, false);
    this.clearAllGridsSelection();
    if (this.dataStore.data['fMatchingNo'] != null) {
      const champs = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'fMatchingNo');
      champs.dataChange(null);
    }
  }
  notifyFromGridRefresh() {
    this.colResize();
  }
  colResize() {
    this.gridViews.forEach((grid) => {
      grid.GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnWidth('Description', 700, true);
    });
  }
}
