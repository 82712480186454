import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridSortModelType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { InputFileUiComponent } from '@iupics-components/standard/fields/input-file-ui/input-file-ui.component';
import { GridTabRowdataComponent } from '@iupics-components/standard/grid/grid-tab-rowdata/grid-tab-rowdata.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-import-file-loader-window-ui',
  templateUrl: './import-file-loader-window-ui.component.html',
  styleUrls: ['./import-file-loader-window-ui.component.scss']
})
export class ImportFileLoaderWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('vcrParameters', { read: ViewContainerRef, static: true })
  vcrParameters: ViewContainerRef;

  @ViewChild('vcrProcess', { read: ViewContainerRef, static: true })
  vcrProcess: ViewContainerRef;

  @ViewChild('grid', { read: GridTabRowdataComponent, static: true })
  gridPreview: GridTabRowdataComponent;

  fileContent: string;

  impFormat: {};
  impFormatRow: {}[];

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    private poService: PoService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrParameters',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_ImpFormat_ID',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrParameters',
        type: CustomDesignItemType.FIELD,
        columnName: 'Filepath',
        cssClass: 'ui-g-12',
        isLabelDisplay: false
      },
      {
        vcr: 'vcrProcess',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'ui-g-12'
      }
    );
  }
  ngOnInit() {
    super.ngOnInit();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'ui-g-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }

  notifyFromDataChange(item: any) {
    if (item.data['columnName'] === 'Filepath') {
      const fileContainer = <InputFileUiComponent>(
        this.dataContainers.find(container => container instanceof InputFileUiComponent)
      );
      this.readFile(fileContainer.getLocalFiles()[0]);
    } else if (item.data['columnName'] === 'AD_ImpFormat_ID' && this.dataStore.data[item.data['columnName']] instanceof Object) {
      const impFormatRequest: DataStoreRequest = {
        windowId: this.formId,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'AD_ImpFormat',
          filterModel: {
            AD_IMPFORMAT_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data[item.data['columnName']].id],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };

      const impFormatRowRequest: DataStoreRequest = {
        windowId: this.formId,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'AD_ImpFormat_Row',
          filterModel: {
            AD_IMPFORMAT_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data[item.data['columnName']].id],
              operators: [OperatorFilterType.EQUALS]
            }
          },
          sortModel: [
            {
              colId: 'SEQNO',
              sort: CompiereDataGridSortModelType.ASC
            }
          ]
        }
      };

      zip(this.store.getDataGrid(impFormatRequest), this.store.getDataGrid(impFormatRowRequest)).subscribe(([header, rows]) => {
        this.impFormat = header.data[0];
        this.impFormatRow = rows.data;
        this.gridPreview.columnsTableHeader = [];
        rows.data.forEach(field => {
          this.gridPreview.columnsTableHeader.push({
            field: field['NAME'],
            headerName: field['NAME'],
            filter: 'agTextColumnFilter',
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            editable: false,
            enableCellChangeFlash: false,
            filterParams: {
              newRowsAction: 'keep',
              applyButton: true,
              clearButton: true,
              filterOptions: ['contains', 'notContains', 'startsWith']
            }
          });
        });
      });
    }
  }

  readFile(file: File) {
    const reader = new FileReader();
    const fuckingJS = this;
    reader.onload = function() {
      fuckingJS.fileContent = <string>reader.result;
      fuckingJS.parseFile();
    };
    reader.readAsText(file);
  }

  parseFile() {
    let separator = '';
    switch (this.impFormat['FORMATTYPE']) {
      case 'C':
        separator = ',';
        break;
      case 'F':
        separator = '';
        break;
      case 'S':
        separator = ';';
        break;
      case 'T':
        separator = '\t';
        break;
    }
    const data = [];
    this.fileContent.split('\n').forEach(row => {
      const rowData = {};
      let i = 0;
      if (separator) {
        // SEPARATOR
        const fieldsData = row.split(separator);
        if (fieldsData.length > 0) {
          this.impFormatRow.forEach(columnDef => {
            rowData[this.impFormatRow[i++]['NAME']] = fieldsData[columnDef['STARTNO'] - 1];
          });
        }
      } else {
        // POSITION FIXE
        this.impFormatRow.forEach(columnDef => {
          if (columnDef['STARTNO'] > 0 && columnDef['ENDNO'] <= row.length) {
            rowData[this.impFormatRow[i++]['NAME']] = row.substring(columnDef['STARTNO'] - 1, columnDef['ENDNO']);
          }
        });
      }
      data.push(rowData);
    });
    this.gridPreview.rowsData = data;
  }
}
