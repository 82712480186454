import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';

@Component({
  selector: 'app-autocomplete-renderer',
  template: `
    <iu-autocomplete-ui
      label="{{ label }}"
      [cssClass]="'ui-g-12 autocompleteRenderer'"
      [isLabelDisplay]="false"
      limitSuggestions="25"
      (autoCompleteEmitter)="onSelect($event)"
      (checkGridRendererEmitter)="onSearch($event)"
      (blurEmitter)="closePanel($event)"
      [data]="templates"
      [fieldValue]="this.fieldValue"
      [isRendererView]="true"
    ></iu-autocomplete-ui>
  `
})
export class AutocompleteRendererComponent implements ICellRendererAngularComp {
  params;
  label: string;
  templates: any;
  fieldValue = null;
  activeGridView: GridViewUiComponent;

  agInit(params: any): void {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;
    this.activeGridView = this.params.activeGridView;

    if (params.value) {
      if (params.value.id !== null && params.value.id !== undefined) {
        this.fieldValue = {
          id: params.value.id,
          displayValue: params.value.displayValue
        };
      } else {
        this.fieldValue = {
          id: params.value,
          displayValue: params.valueFormatted
        };
      }
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  onSelect($event) {
    this.params.node.data[this.templates.columnName] = $event ? $event.id : $event;
    if (this.params.onSelect instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        columnName: this.params.colDef.field
        // ...something
      };
      this.params.onSelect(params);
    }
  }

  onSearch(event) {
    // console.log(event);
    this.activeGridView.overlayVisible = event.overlayVisible;
    this.activeGridView.label = event.label;
    this.activeGridView.isLabelDisplay = event.isLabelDisplay;
    this.activeGridView.scrollHeight = event.scrollHeight;
    this.activeGridView.suggestions = event.suggestions;
    this.activeGridView.field = event.field;
    this.activeGridView.itemTemplate = event.itemTemplate;
    this.activeGridView.noResults = event.noResults;
    this.activeGridView.emptyMessage = event.emptyMessage;
    this.activeGridView.setPosition(event.top, event.left, event.width);
    this.activeGridView.linkAutocompleteRenderer = event.linkAutocomplete;
    this.params.onSearch(event);
  }

  closePanel(event) {
    // console.log(event);
    this.activeGridView.overlayVisible = false;
    this.params.closePanel();
  }
}
