<span
  #spanRef
  [style.display]="displayCss"
  class="{{ cssClass }}"
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($event)"
  [style.backgroundColor]="backgroundColorCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
>
  <!-- #region custo|onLabelClickEmitter -->
  <iu-prime-autocomplete
    (onLabelClickEmitter)="zoomAcross()"
    (completeMethod)="search($event)"
    (mousedown)="onMouseDown($event)"
    [isLabelDisplay]="this.isLabelDisplay"
    (onFocus)="search($event)"
    (click)="onInputClick($event)"
    (onBlur)="blurEmitter.emit($event)"
    (onSelect)="dataChange(autoComplete.value)"
    (onUnselect)="dataChange(autoComplete.value)"
    (selectItemEmitter)="calloutEventChange()"
    (showFormPanelEmitter)="showFormPanel($event)"
    (showSearchPanelEmitter)="showSearchPanel()"
    [(suggestions)]="suggestions"
    [data]="data"
    [columnName]="columnName"
    [dataStored]="dataStored"
    [description]="tooltip"
    [disabled]="isReadOnly"
    [dropdown]="true"
    [iconClass]="iconClass"
    [label]="label"
    [multiple]="multiple"
    [ngClass]="[hasConflict ? 'iu-field-conflict' : '']"
    [scrollHeight]="scrollHeight"
    [suggestionsFilter]="suggestionsFilter"
    [tabID]="data?.tabId"
    [isRendererView]="isRendererView"
    [isInsideOverflow]="isInsideOverflow"
    [overridedCSS]="overridedCSS"
    #autoComplete
    ><!-- #endregion custo| -->
  </iu-prime-autocomplete>
  <i
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="ui-inputgroup-addon rtconflict fas fa-exclamation-triangle iconClass iu-field-conflict-icon"
    (click)="showConflictPanel($event)"
  ></i>
  <iu-value-preference-panel
    *ngIf="showValuePrefPanel"
    [fieldValue]="fieldValue"
    [dataStored]="this.dataStored"
    [sourceComponent]="this"
    [data]="this.data"
    (closeEmitter)="showValuePrefPanel = !showValuePrefPanel"
  ></iu-value-preference-panel>
</span>

<p-overlayPanel #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide($event)">{{
    (dataStored?.data)[data.columnName]?.displayValue
  }}</a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide($event)">{{
    conflictedData?.displayValue
  }}</a>
</p-overlayPanel>
