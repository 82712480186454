import { Component, ComponentFactoryResolver, OnInit } from "@angular/core";
import { CompiereProcessService } from "@compiere-ws/services/compiere-process/compiere-process.service";
import { ProcessInProgressService } from "@compiere-ws/services/process-in-progress/process-in-progress.service";
import { SocketService } from "@compiere-ws/services/socket/socket.service";
import { AutocompleteUiComponent } from "@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component";
import { ButtonUiComponent } from "@iupics-components/standard/fields/button-ui/button-ui.component";
import { GridViewUiComponent } from "@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component";
import { DataStoreService } from "@iupics-manager/managers/data-store/data-store.service";
import { MessageManagerService } from "@iupics-manager/managers/message/message-manager.service";
import { SecurityManagerService } from "@iupics-manager/managers/security-manager/security-manager.service";
import { UICreatorService } from "@iupics-manager/managers/ui-creator/ui-creator.service";
import { WindowFactoryService } from "@iupics-manager/managers/ui-creator/window-factory/window-factory.service";
import { DynamicComponent } from "@iupics-manager/models/dynamic-component";
import { Global } from "@iupics-manager/models/global-var";
import { IupicsTypeEvent } from "@iupics-manager/models/iupics-event";
import { TranslateService } from "@ngx-translate/core";
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { ProcessUiComponent } from "../process-ui/process-ui.component";
import { SpecificWindowUiComponent } from "../specific-window-ui/specific-window-ui.component";

@Component({
  selector: "iu-bpartner-search",
  templateUrl: "./bpartner-search.component.html",
  styleUrls: ["./bpartner-search.component.scss"]
})
export class BpartnerSearchComponent extends SpecificWindowUiComponent
  implements OnInit {
  SumSelectedRows = 0;
  processUiComponent: ProcessUiComponent;
  ButtonUiComponent: ButtonUiComponent;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    public uiCreatorService: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreatorService,
      store,
      processService,
      socketService,
      connectorService,
      pp,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {
    super.ngOnInit();
  }
  selectBPartner(p_C_BPartner_ID: number) {
    const sourceFieldName = this.sourceComponent.data.columnName;
    if (p_C_BPartner_ID && sourceFieldName) {
      const event = {
        data: { Data_UUID: sourceFieldName + "," + p_C_BPartner_ID }
      };
      (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(
        event
      );
    }
  }
  processConfirm(){
    const selections = this.dataStore.data['selections'].find(
      grid =>
        grid['AD_FormDetail_ID'] ===
        (<GridViewUiComponent>this.gridViews[0]).data['AD_FormDetail_ID']
    );
    if (selections && selections.selection.length > 0) {
    this.selectBPartner(        selections.selection[0]['C_BPartner_ID']);
    if(this.isModal){
    this.closeModalEmitter.emit();}}
  }
  processzoom() {
    const selections = this.dataStore.data['selections'].find(
      grid =>
        grid['AD_FormDetail_ID'] ===
        (<GridViewUiComponent>this.gridViews[0]).data['AD_FormDetail_ID']
    );
    if (selections && selections.selection.length > 0) {
      selections.selection.forEach(selection => {
        this.subscriptions.push(
          this.uiCreatorService
            .zoomAcross(
              'C_BPartner',
              'C_BPartner_ID',
              selection['C_BPartner_ID']
            )
            .subscribe(dataWs => {
              if (dataWs && dataWs.length > 0) {
this.subscriptions.push(this.uiCreatorService.getIupicsMenus().subscribe(
  menus=>{
    let menu= menus.find(menu => (menu.action_id === parseInt(dataWs[0].Window_ID, 10)) && menu.menu_type === IupicsMenuType.WINDOW);

    if(!menu){
      menu= menus.find(menu => [123
        ,1000225
        ,1000111].includes(menu.action_id) && menu.menu_type === IupicsMenuType.WINDOW);
    }
    if(menu){
      dataWs[0].Window_ID=menu.action_id;
    }
    this.zoomInfo = {
      // tableName: this.data.detailZoom.tableName,
      windowId: dataWs[0].Window_ID,
      dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
      record_id: selection['C_BPartner_ID'],
      children:
        dataWs.length > 1
          ? dataWs.splice(0, dataWs.length - 1)
          : null
    };
    this.subscriptions.push(
      this.uiCreatorService
        .getWindow(
          parseInt(dataWs[dataWs.length - 1]['Window_ID'], 10)
        )
        .subscribe(tabs => {
          const item: DynamicComponent = {
            container: this.container,
            DOMParentComponent: this.container,
            linkedComponents: [this],
            component: 'EditViewUiComponent',
            cssClass: 'iupics-blade-content',
            isCssOnComponent: false,
            tabId: tabs[0].tabId,
            zoomInfo: this.zoomInfo
          };
          this.componentEmitter.emit({
            type: IupicsTypeEvent.showEditView,
            item: item
          });
        })
    );
    Global.workspace.urlParams.dataGridRequest = null;
    Global.workspace.openTargetSearch({
      cat: { id: parseInt(dataWs[0].Window_ID, 10) },
      source: {
        id:
          selection['C_BPartner_ID'] !== -1
            ? dataWs[0].Record_ID
            : 'newRecord'
      } // dataWs[0].Record_ID
    });
  }
));
  
              }
            })
        );
      });
    }
  }
  notifyFromGridAfterViewInit(gridView: GridViewUiComponent) {
    gridView.GridTabInfinityScrollUiComponent.pinnedColKeys = this.pinnedColKeys;
    this.refreshGrids(this.dataStore, true, null, gridView.tabId);
    if(this.isModal){
      gridView.rowSelection = "single";
    }
  }
}
