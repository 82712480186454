import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceSeparator'
})
export class ReplaceSeparatorPipe implements PipeTransform {
  transform(value: string, textToReplace?: string, textToInsert?: string): any {
    if (value && textToReplace) {
      let replacementText = '';
      if (textToInsert) {
        replacementText = textToInsert;
      }
      const splittedValues = value.split(textToReplace);
      let tmpValue = '';
      splittedValues.forEach(splittedValue => {
        if (splittedValue.trim().length > 0) {
          if (tmpValue.length > 0) {
            tmpValue += textToInsert;
          }
          tmpValue += splittedValue;
        }
      });
      return tmpValue;
    } else {
      return value;
    }
  }
}
