<div class="iu-specific-window iu-specific-window-container">
    <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' }">
    <div class="ui-g-12">
      <div class="ui-g-12">
        <ng-template #filterNumber></ng-template>
      </div>
      <div class="ui-g-12">
        <ng-template #filterDate></ng-template>
      </div>
      <div>
        <p class="help ui-g-12">{{ 'suppFonctionErr.label_txt' | translate }}</p>
      </div>
      <div class="ui-g-12">
        <ng-template #table></ng-template>
      </div>
      <div class="ui-g-12">
        <ng-template #actions></ng-template>
      </div>
    </div>
  </p-scrollPanel>
</div>
