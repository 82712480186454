<div class="iu-specific-window iu-specific-window-container" style="min-height: 25vh">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <a *ngIf="vcrOptional.length > 0" class="settings" (click)="isSidebarOpen = !isSidebarOpen"><i class="fas fa-cog"></i></a>
  <div class="ui-g-12">
    <div class="firstContainer">
      <div class="ui-g-12 ui-md-6 ui-lg-6">
        <label class="specific-title"
          ><p class="help">
            {{ headerTitle | translate }}
          </p></label
        >
      </div>
      <div  class="ui-g-12 ui-md-6 ui-lg-4 ui-lg-offset-2">
        <div class="projectInfoContainer">
          <div class="projectInfoLine ui-g">
            <span>
              Nombre de places disponibles pour les participants : {{ this.nbDispo != null && this.nbDispo != undefined ? this.nbDispo : 'NC' }}</span
            >
          </div>
          <div class="projectInfoLine">
            <span>
              Nombre de places pour l'Unité :
              {{ this.nbDispoUnit != null && this.nbDispoUnit != undefined ? this.nbDispoUnit : 'NC' }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="fields" style="padding-bottom: 220px" [ngClass]="{'buttonCss': isButtonCss}">
      <ng-template iuDynamicContainer></ng-template>
      <div [hidden]="true">
        <ng-template #vcrTmp></ng-template>
      </div>

      <div [hidden]="isSinglePartner">
        <ng-template #firstGrid></ng-template>
        <div class="inscrireButtonContainer">
          <button
            class="ui-button primary"
            [ngClass]="{
              disabledButton: registerEnabled === false
            }"
            (click)="registerEnabled && register()"
          >
            <i class="fa fa-check"></i>{{ 'specificWindow.inscribe.inscribe' | translate }}
          </button>
        </div>
        <ng-template #secondGrid></ng-template>
      </div>
    </div>
  </div>
  <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="6000" position="right" styleClass="ui-sidebar-md optionalFilterPanel">
    <h1 class="iu-notification-center-title">Optional filters</h1>
    <div class="optionalFields">
      <ng-template #optional></ng-template>
    </div>
  </p-sidebar>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="closeModalSearch()"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div class="specificModal" [ngClass]="{ hidden: !displayFormUI }">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
<div class="specificCustoModal" [ngClass]="{ hidden: !displayCustoModal }">
  <iu-modal-ui
    [angularStyle]="{
      'iu-modal': { overflow: 'inherit' },
      'iu-modal-background': { 'z-index': 1000 }
    }"
    class="modal-overflow-inherit"
    title="{{ 'specificWindow.inscribe.subtitle2' | translate }}"
    [isModalDisplay]="displayCustoModal"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayCustoModal', value: false })"
    [isLoading]="isLoading"
  >
    <label class="specific-title"
      ><p class="help">
        {{ 'specificWindow.inscribe.roleSelectionTitle' | translate }}
      </p></label
    >
    <div *ngFor="let fonction of fonctionLines.length + 1 | fakeArray; let i = index">
      <span class="line" [ngStyle]="{ display: i < fonctionLines.length + 1 ? ' ' : 'none' }">
        <span *ngIf="i < fonctionLines.length">{{ fonctionLines[i].nb }} {{ fonctionLines[i].name }} </span
        ><span class="fill"></span><ng-template #vcrRole></ng-template
      ></span>
      <!-- {{ fonctionLines[i].name | pluralize }} à utiliser quand tu a ajouté le pipe que j'ai ajouté dans le custo je ne sais pas comment l'ajouter dans le iupicsUtilsmodule -->
    </div>
    <div class="botButtonContainer">
      <a
        class="iu-btn btn-cancel"
        [ngClass]="{
          disabledButton: registerEnabled === false
        }"
        (click)="registerEnabled && updateModalDisplay({ key: 'displayCustoModal', value: false })"
      >
        <i class="fa fa-ban"></i>{{ 'generic.cancel' | translate }}
      </a>
      <ng-template #vcrButtonInscrire></ng-template>
    </div>
  </iu-modal-ui>
  <p-dialog [modal]="true "[dismissableMask]="true" [showHeader]="false" [closeOnEscape]="true" [(visible)]="showCarrickContact" [style]="{ width: '50vw' }">
    <ng-template pTemplate="header"> </ng-template>
    <p class="help" [innerHTML]="contactCarrickMessage"></p>
    <ng-template pTemplate="footer">
      <iu-button-ui
        label="{{ 'generic.ok' | translate }}"
        class="iu-btn ui-g-12 ui-md-3 ui-lg-2"
        (click)="$event.preventDefault(); $event.stopPropagation(); showCarrickContact = false"
      >
      </iu-button-ui>
    </ng-template>
  </p-dialog>
</div>
