<div class="iu-specific-window iu-specific-window-container divSpecific ">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <!-- <p-scrollPanel [style]="{ width: '100%' }"> -->
   <a hidden='true' class="settings" (click)="isSidebarOpen = !isSidebarOpen"><i class="fas fa-cog"></i></a>
  <div class="ui-g-12">
    <form [formGroup]="formGroup">
      <div formArrayName="array" *ngFor="let e of etapes; let i = index">
        <div class="ui-g-12">
          <div class="ui-g-1">
            <label>{{ 'readCreateStep.noStepLabel' | translate }} {{ i + 1 }} : </label>
          </div>
          <div class="ui-g-12 ui-md-3 ui-lg-3">
            <iu-input-location-ui
              [container]="this"
              [isStandalone]="true"
              label="{{ 'readCreateStep.locationLabel' | translate }}"
              [isLabelDisplay]="true"
              [data]="data"
              [fieldValue]="e.ville_depart"
              (fieldValueModified)="set_ville_Depart($event, i)"
            >
            </iu-input-location-ui>
          </div>
          <div class="ui-g-12 ui-md-3 ui-lg-3">
            <iu-calendar-ui
              [isStandalone]="true"
              label="{{ 'readCreateStep.dateStartLabel' | translate }}"
              [fieldValue]="e.date_depart"
              [isLabelDisplay]="true"
              (fieldValueModified)="changeDefaultDate($event, i, true)"
              class="dateStart-{{ i }}"
            >
            </iu-calendar-ui>
          </div>
          <div class="ui-g-12 ui-md-3 ui-lg-3">
            <iu-calendar-ui
              [isStandalone]="true"
              [fieldValue]="e.date_arrive"
              label="{{ 'readCreateStep.dateFinishLabel' | translate }}"
              [isLabelDisplay]="true"
              (fieldValueModified)="changeDefaultDate($event, i, false)"
              class="dateFinish-{{ i }}"
            >
            </iu-calendar-ui>
          </div>
          <div class="ui-g-12 ui-md-2 ui-lg-2">
            <button
              tooltipPosition="top"
              showDelay="250"
              life="3"
              pTooltip="{{ 'generic.delete' | translate }}"
              pButton
              type="button"
              icon="icon-ico-delete"
              class="readCreateStepDeleteButton ui-menu-bar-button hover"
              style="margin-top: 20px; margin-left: 20px;"
              (click)="deleteStep(i)"
            ></button>
          </div>
        </div>
      </div>
    </form>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <iu-button-ui
        *ngIf="isModal"
        class="iu-btn btn-default"
        (click)="addNewEtape($event)"
        label="{{ 'readCreateStep.new' | translate }}"
        icon="fa fa-plus"
      >
      </iu-button-ui>
    </div>
    <div class="iu-specific-btn-container">
      <ng-template #bottom></ng-template>
    </div>
  </div>
  <!-- </p-scrollPanel> -->
  <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="6000" position="right" styleClass="ui-sidebar-md optionalFilterPanel">
    <h1 class="iu-notification-center-title">
      {{ 'specificWindow.optionalFilters' | translate }}
    </h1>
    <div class="optionalFields">
      <div class="ui-g-12">
        <iu-calendar-ui
          [isStandalone]="true"
          [fieldValue]="getFormStoreData('DateDebutFictive')"
          label="{{ 'readCreateStep.dateStartLabel' | translate }}"
          [isLabelDisplay]="true"
          [isReadOnly]="true"
        >
        </iu-calendar-ui>
      </div>
      <div class="ui-g-12">
        <iu-calendar-ui
          [isStandalone]="true"
          [fieldValue]="getFormStoreData('DateFinFictive')"
          label="{{ 'readCreateStep.dateFinishLabel' | translate }}"
          [isLabelDisplay]="true"
          [isReadOnly]="true"
        >
        </iu-calendar-ui>
      </div>
    </div>
  </p-sidebar>
</div>
