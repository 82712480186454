<div class="iu-specific-window iu-specific-window-container">
  <p-progressBar
    *ngIf="isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <label class="specific-title"
    ><b>{{ title }}</b></label
  >
  <div class="fields">
    <div class="ui-g-12"><ng-template #top></ng-template></div>
    <div *ngFor="let c of contacts; let i = index">
      <p class="ui-g-12" *ngIf="i === 0">
        Sélectionner le contact secondaire à copier
      </p>
      <div class="ui-g-12">
        <iu-input-switch-ui
          #autoCommitField
          label="{{ c.Name }}"
          [fieldValue]="c.isSelected"
          [isStandalone]="true"
          cssGrid="ui-g-12"
          (fieldValueModified)="changeContactSelected($event, i)"
          style="text-align: left;"
        ></iu-input-switch-ui>
      </div>
    </div>
    <div class="ui-g-12"><ng-template #bot></ng-template></div>
    <div class="ui-g-12" style="margin-top: -10px;">
      <div class="ui-g-12">
        <button
          #btn
          pButton
          class="button-field"
          type="button"
          [label]="'generic.cancel' | translate"
          (click)="onClickCancel()"
        ></button>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="closeModalSearch()"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div class="specificModal" [ngClass]="{ hidden: !displayFormUI }">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>

<div *ngIf="displayDoublonPanel">
  <iu-modal-ui [title]="'createBPartner.doubleTitle'" (closeModalEmitter)="displayDoublonPanel = false">
    <p class="help" [innerHTML]="'createBPartner.subDoubleTitle' | translate | safeHtml"></p>
    <div class="ui-g-4">
      <button type="button" (click)="openSelected()" [disabled]="selectDisabled" class="ui-button secondary">
        {{ labelSelect | translate }}
      </button>
    </div>
    <div class="ui-g-8"></div>
    <div *ngIf="isCadreUnite" class="ui-g-4">
      <button type="button" (click)="closeFonction()" [disabled]="selectDisabled" class="ui-button secondary">
        {{ labelClose | translate }}
      </button>
    </div>
    <div *ngIf="isCadreUnite" class="ui-g-8"></div>
    <div class="ui-g-4">
      <button type="button" (click)="createNew()" class="ui-button secondary">
        {{ labelCreate | translate }}
      </button>
    </div>
    <div class="ui-g-8"></div>

    <div id="iu_menu_category_list" class="ui-g-12 double-ui-categories">
      <table>
        <ng-container *ngFor="let double of listDoublon">
          <span
            id="iu_menu_category_item_{{ double.name }}"
            class="doublonItem"
            [ngClass]="{
              menuCategoryItemActive: double.isSelected,
              menuCategoryItemSelected: double.name === selectedCategory?.name && typeofSelected === 'category'
            }"
            (click)="changeSelected(double)"
          >
            <div class="ui-g-12">
              <tr>
                <td>
                  <i class="icon-ico-next" aria-hidden="true"></i>
                </td>
                <td>
                  <a>{{ double.name }}</a>
                </td>
                <td>
                  <a>{{ double.name2 }}</a>
                </td>
                <td>
                  <a>{{ double.ddn }}</a>
                </td>
              </tr>
            </div>
          </span>
        </ng-container>
      </table>
    </div>
  </iu-modal-ui>
</div>
