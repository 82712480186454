<!-- #region custo -->
<div [ngClass]="[locationPanelPosition, 'locationPanel ui-g-12']">
  <div class="locationPanel-content">
    <div class="locationPanel-field-fullwidth">
      <iu-input-text-ui
        #inputAdr1
        label="{{ 'locationpanel.address1' | translate }}"
        [isStandalone]="true"
        [(fieldValue)]="address1"
        [columnName]="'Address1'"
      ></iu-input-text-ui>
    </div>
    <div class="locationPanel-field-fullwidth">
      <iu-input-text-ui
        #inputAdr2
        label="{{ 'locationpanel.address2' | translate }}"
        [isStandalone]="true"
        [(fieldValue)]="address2"
        [columnName]="'Address2'"
      ></iu-input-text-ui>
    </div>
    <div class="locationPanel-field-fullwidth">
      <iu-input-text-ui
        #inputAdr3
        label="{{ 'locationpanel.address3' | translate }}"
        [isStandalone]="true"
        [(fieldValue)]="address3"
        [columnName]="'Address3'"
      ></iu-input-text-ui>
    </div>
    <div class="locationPanel-field-fullwidth">
      <iu-input-text-ui
        #inputAdr4
        label="{{ 'locationpanel.address4' | translate }}"
        [isStandalone]="true"
        [(fieldValue)]="address4"
        [columnName]="'Address4'"
      ></iu-input-text-ui>
    </div>
    <div class="locationPanel-field-semiwidth">
      <iu-input-text-ui
        #inputPC
        label="{{ 'locationpanel.postal' | translate }}"
        [(fieldValue)]="postalCode"
        (fieldValueChange)="changePostal($event)"
        [isStandalone]="true"
        [columnName]="'Postal'"
      ></iu-input-text-ui>
    </div>
    <div class="locationPanel-field-semiwidth">
      <iu-input-text-ui
        #inputTextLoc
        label="{{ 'locationpanel.city' | translate }}"
        [isStandalone]="true"
        [(fieldValue)]="cityTxt"
        [columnName]="'City'"
      ></iu-input-text-ui>
    </div>
    <div class="locationPanel-field-semiwidth">
      <iu-input-text-ui
        #inputTextRegion
        label="{{ 'locationpanel.region' | translate }}"
        [isStandalone]="true"
        [(fieldValue)]="regionTxt"
      ></iu-input-text-ui>
    </div>
    <div class="locationPanel-field-semiwidth">
      <iu-autocomplete-ui
        #inputCountry
        label="{{ 'locationpanel.country' | translate }}"
        [isStandalone]="true"
        [data]="countries"
        [fieldValue]="country"
        (fieldValueModified)="setCountry($event)"
        [columnName]="'C_Country_ID'"
      ></iu-autocomplete-ui>
    </div>
  </div>
  <div class="actions">
    <a class="button secondary" style="cursor: pointer; width: 50%;" (click)="openMap()"
      ><span class="fas fa-map-marked-alt"></span> {{ 'locationpanel.map' | translate }}
    </a>
    <button
      pButton
      style="width: 50%;"
      type="button"
      class="primary"
      label="{{ 'generic.save' | translate }}"
      (click)="saveLocation($event)"
      data-cy="save-location"
    ></button>
  </div>
  <div>
    <button
      pButton
      style="width: 50%;"
      type="button"
      label="{{ 'generic.cancel' | translate }}"
      (click)="cancel($event)"
    ></button>
    <button
      pButton
      style="width: 50%;"
      type="button"
      label="{{ 'generic.clear' | translate }}"
      (click)="clearLocation($event)"
    ></button>
  </div>
</div>
<!-- #endregion custo -->