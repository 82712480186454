import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataStoreStatus } from '@compiere-ws/models/compiere-data-json';
import { PrimeBreadcrumbComponent } from '@iupics-components/overrided/prime-breadcrumb/prime-breadcrumb.component';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'iu-menu-bar-detail-ui',
  templateUrl: './menu-bar-detail-ui.component.html',
  styleUrls: ['./menu-bar-detail-ui.component.css']
})
export class MenuBarDetailUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() activateKeybind = true;
  @Input() activeTabID: string;
  @Input() arrowsDisplayed = true;
  @Input() breadcrumb: PrimeBreadcrumbComponent;
  @Input() changingMenuBar: Subject<any>;
  @Input() IsDeleteable = true;
  @Input() IsInsertRecord = true;
  @Input() isReadOnly = false;
  @Input() isSplitView = false;
  @Input() isZoomTarget = false;
  @Input() nbUploadedFiles: number;
  @Input() noData = false;
  @Input() status: DataStoreStatus;
  @Input() viewRecordChangeLogLabel: string;
  @Input() visibleButton = true;
  @Input() zoomButton = true;

  @Output() changeGridElementEvent = new EventEmitter<any>();
  @Output() changeGridViewVisibility = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();
  @Output() copyEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() emailEvent = new EventEmitter<any>();
  @Output() exportDataEvent = new EventEmitter<any>();
  @Output() joinFilesEvent = new EventEmitter<any>();
  @Output() newEvent = new EventEmitter<any>();
  @Output() printEvent = new EventEmitter<any>();
  @Output() refreshEvent = new EventEmitter<any>();
  @Output() saveEvent = new EventEmitter<any>();
  @Output() undoEvent = new EventEmitter<any>();
  @Output() viewRecordChangeLog = new EventEmitter<any>();

  showMoreActionEmitter = new EventEmitter<any>();
  isMobile = Global.isMobile();
  showMoreAction = false;
  isSync = DataStoreStatus.SYNC;

  mailBtnCondition: boolean;
  joinFileBtnCondition: boolean;
  exportBtnCondition: boolean;
  printBtnCondition: boolean;
  closeBtnCondition: boolean;
  newBtnCondition: boolean;
  saveBtnCondition: boolean;
  refreshBtnCondition: boolean;
  deleteBtnCondition: boolean;
  copyBtnCondition: boolean;
  showMoreBtnCondition: boolean;
  campEteAuthorizedRoles = ['1000032', '1000008'];
  canDeleteAuthorizedRoles = [];// Redmine #144470
  cantDeleteAuthorizedTabs = [];// Redmine #144470
  constructor(private translate: TranslateService, private connectorService: SecurityManagerService) {
    super();
  }

  ngOnInit() {
    const campEteAuthorizedRolesTxt = Global.ad_message_text.get('GUIDES_CAMP_ETE_CAN_ADD_ROLES');
    const canDeleteAuthorizedRolesTxt = Global.ad_message_text.get('GUIDES_CAN_DELETE_ROLES');// Redmine #144470
    const cantDeleteAuthorizedTabsTxt = Global.ad_message_text.get('GUIDES_CANT_DELETE_TAB');// Redmine #144470
    this.campEteAuthorizedRoles = campEteAuthorizedRolesTxt ? campEteAuthorizedRolesTxt.split(',') : [];
    this.canDeleteAuthorizedRoles = canDeleteAuthorizedRolesTxt ? canDeleteAuthorizedRolesTxt.split(',') : [];// Redmine #144470
    this.cantDeleteAuthorizedTabs = cantDeleteAuthorizedTabsTxt ? cantDeleteAuthorizedTabsTxt.split(',') : [];// Redmine #144470
    this.updateButtonLists();
    this.showMoreActionEmitter.subscribe(() => {
      this.showMoreAction = !this.showMoreAction;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Redmine #144470
    const canDeleteAuthorizedRolesTxt = Global.ad_message_text.get('GUIDES_CAN_DELETE_ROLES');
    const cantDeleteAuthorizedTabsTxt = Global.ad_message_text.get('GUIDES_CANT_DELETE_TAB');
    this.canDeleteAuthorizedRoles = canDeleteAuthorizedRolesTxt ? canDeleteAuthorizedRolesTxt.split(',') : [];
    this.cantDeleteAuthorizedTabs = cantDeleteAuthorizedTabsTxt ? cantDeleteAuthorizedTabsTxt.split(',') : [];
    // Redmine #144470 //

    if (changes.status) {
      const statusChange = changes.status;
      if (statusChange.previousValue !== statusChange.currentValue) {
        this.isSync = statusChange.currentValue;
        this.updateButtonLists();
      }
    } else if (
      (changes.IsDeleteable && changes.IsDeleteable.previousValue !== changes.IsDeleteable.currentValue) ||
      (changes.IsInsertRecord && changes.IsInsertRecord.previousValue !== changes.IsInsertRecord.currentValue) ||
      (changes.isReadOnly && changes.isReadOnly.previousValue !== changes.isReadOnly.currentValue)
    ) {
      this.updateButtonLists();
    }
  }

  updateButtonLists() {
    if (this.tabId == Global.camp_ete_first_tab_id) {
      this.newBtnCondition = this.campEteAuthorizedRoles.includes(
        this.connectorService.getIupicsUserContext()['#AD_Role_ID'].toString()
      );
    } else {
      this.newBtnCondition = this.visibleButton && !this.noData && this.zoomButton && !this.isReadOnly && this.IsInsertRecord;
    }

    this.saveBtnCondition = this.visibleButton && !this.noData && !this.isReadOnly;
    this.refreshBtnCondition = this.visibleButton && !this.noData && this.isSync !== DataStoreStatus.NEWRECORD;
    // Redmine #144470
    let tabId : string = this.tabId.toString();
    if((this.cantDeleteAuthorizedTabs.includes(tabId) && this.canDeleteAuthorizedRoles.includes(this.connectorService.getIupicsUserContext()['#AD_Role_ID'].toString())) || !this.cantDeleteAuthorizedTabs.includes(tabId))
      this.deleteBtnCondition = this.visibleButton && !this.noData && !this.isReadOnly && this.IsDeleteable && this.isSync !== DataStoreStatus.NEWRECORD;
    // Redmine #144470 //
    this.copyBtnCondition =
      this.visibleButton && !this.noData && this.zoomButton && !this.isReadOnly && this.isSync !== DataStoreStatus.NEWRECORD;
    this.mailBtnCondition = this.visibleButton && !this.noData && !this.isReadOnly && this.isSync !== DataStoreStatus.NEWRECORD;
    this.joinFileBtnCondition = this.visibleButton && !this.noData && this.isSync !== DataStoreStatus.NEWRECORD;
    this.exportBtnCondition = this.visibleButton && !this.noData && this.isSync !== DataStoreStatus.NEWRECORD;
    this.printBtnCondition = this.visibleButton && !this.noData && !this.isReadOnly && this.isSync !== DataStoreStatus.NEWRECORD;
    this.closeBtnCondition = this.visibleButton && !this.noData && this.zoomButton;
    this.showMoreBtnCondition = this.visibleButton && !this.noData && this.zoomButton;

    // todo: doit encore etre implémenter (stack des états des datastores et faire comme sur un clic sur un autre record)
    // {
    //   condition: this.visibleButton && !this.noData,
    //   icon: 'fa fa-undo',
    //   event: this.undoEvent,
    //   position: 'left',
    //   label: this.translate.instant('menuBarDetails.undo')
    // };
  }
  ngAfterViewInit() {
    // init keybind
    if (this.breadcrumb !== undefined && this.breadcrumb.model !== undefined && this.breadcrumb.model.length > 0) {
      const activeElement = this.breadcrumb.model.find((model) => model.disabled === false);
      if (activeElement !== undefined) {
        this.activateKeybind = parseInt(activeElement.id, 10) === this.tabId;
      }
    }
    // update keybind
    this.subscriptions.push(
      this.breadcrumb.breadcrumbEventEmitter.subscribe((item) => {
        if (item.event === IupicsTypeEvent.clickBreadCrumbItem || item.event === IupicsTypeEvent.updatedActiveItemBreadcrumb) {
          if (parseInt(item.tabId, 10) === this.tabId) {
            this.activateKeybind = true;
          } else {
            this.activateKeybind = false;
          }
        }
      })
    );
  }

  setVisibleButton(visible: boolean) {
    this.visibleButton = visible;
  }

  onChildUpdate(event) {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
}
