import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import { IupicsWidget } from '@web-desktop/models/iupics-widget';

@Component({
  selector: 'widget-ui',
  templateUrl: './widget-ui.component.html',
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ],
  styleUrls: ['./widget-ui.component.scss']
})
export class WidgetUiComponent implements OnInit, AfterViewInit {
  // @HostBinding('class')
  // class = 'ui-g-12 ui-md-6 ui-g';

  private _class = 'ui-g-12 ui-md-6';

  get class() {
    return this._class;
  }
  set class(value: string) {
    this._class = value;
    this.cssClassEdit.emit(this._class);
  }

  @ViewChild('widgetVCR', { read: ViewContainerRef, static: true })
  vcr: ViewContainerRef;
  isLoaded = false;
  @Input()
  widget: IupicsWidget;

  @Input()
  index: number;

  @Output() cssClassEdit = new EventEmitter<string>();

  @Output()
  deleteWidget = new EventEmitter<number>();

  @Output()
  openTabEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  changeWidgetColor: EventEmitter<any> = new EventEmitter<any>();

  new_widget_name = '';
  showColor = false;
  showMoreColor = false;
  oneDefaultColorIsSelected = false;
  '';
  colorpicked = '';
  defaultColor: string[];

  screenHeight: number;
  screenWidth: number;

  showMenu = false;

  constructor(
    private uiCreatorService: UICreatorService,
    private themeService: ThemeService,
    public ref: ElementRef,
    private resolver: ComponentFactoryResolver
  ) {
    this.defaultColor = this.themeService.getThemeProperty('colorsPalette');
  }

  ngOnInit() {
    this.new_widget_name = this.widget.title;
    this.class = this.class + ' ui-lg-' + this.widget.width * 4;
    this.colorpicked = this.widget.color;
    if (this.defaultColor.indexOf(this.colorpicked) !== -1) {
      this.oneDefaultColorIsSelected = true;
    }
    // hardcoded
    if (this.widget.viewType === ViewType.GRID) {
      this.uiCreatorService.getWindow(1000235);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoaded = true;
      if (this.widget.angularClass !== undefined) {
        const factory = this.resolver.resolveComponentFactory(Global.iupics_widgets.get(this.widget.angularClass));
        const compoRef = this.vcr.createComponent(factory);
        (<any>compoRef.instance).openTabEmitter.subscribe(item => this.openTab(item));
        (<any>compoRef.instance).widget = this.widget;
      }
    }, this.index * 10);
  }

  onDelete(index) {
    this.deleteWidget.emit(index);
  }

  changeDefaultColor(color: string) {
    this.colorpicked = color;
    this.onChangeColor(document.createEvent('Event'));
    this.showMenu = false;
  }

  onChangeColor(event: Event) {
    event.stopPropagation();
    this.widget.color = this.colorpicked;
    this.changeWidgetColor.emit({ color: this.colorpicked });
    this.showColor = false;
    this.showMoreColor = false;
  }

  onColorPicker($event: { originalEvent: MouseEvent; value: string }) {
    $event.originalEvent.stopPropagation();
    this.colorpicked = $event.value;
  }

  cancelColorChange() {
    this.showColor = false;
    this.showMoreColor = false;
    this.colorpicked = this.widget.color;
  }

  openTab(item: any) {
    this.openTabEmitter.emit(item);
  }

  isSelectedColor(color: string) {
    if (color === this.colorpicked) {
      return true;
    } else {
      return false;
    }
  }
}
