import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereNotification } from '@compiere-ws/models/compiere-notification-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { InfoDialogType } from '../info-dialog/info-dialog.component';
import { ProcessParams } from '../process-ui/process-ui.component';
import { AutocompleteRendererComponent } from '../renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from '../renderer/button-renderer.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-mass-complem-insurance',
  templateUrl: './mass-complem-insurance.component.html',
  styleUrls: ['./mass-complem-insurance.component.scss']
})
export class MassComplemInsuranceComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  vcrBot: ViewContainerRef;

  sectionList = [];
  fonctionList = [];
  frameworkComponents: any;

  updatePriceList_Process_ID = 0;
  updateAssurance_Process_ID = 0;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService
  ) {
    super(windowFactory, resolver, uiCreator, store, processService, socketService, connectorService, pp, translateService);
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent
    };

    const processTransfertRequest: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_Process',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: ['ZUpdateAssurance'],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };

    this.store.getDataGrid(processTransfertRequest).subscribe((res) => {
      if (res && res.data && res.data.length > 0) {
        this.updateAssurance_Process_ID = res.data[0]['AD_PROCESS_ID'];
      }
    });

    const processNewAnimRequest: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_Process',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: ['ZUpdatePriceList'],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };

    this.store.getDataGrid(processNewAnimRequest).subscribe((res) => {
      if (res && res.data && res.data.length > 0) {
        this.updatePriceList_Process_ID = res.data[0]['AD_PROCESS_ID'];
      }
    });
  }

  ngOnInit() {
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        if (item.component === 'GridViewUiComponent') {
          item.data.hasCheckbox = false;
          const editorColumns = [];

          const colAss = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'Assurance';
          });
          if (colAss) {
            const assDetail = item.data.columnsDetails.get('Assurance');
            editorColumns.push('Assurance');
            colAss.editable = false;
            colAss.cellRenderer = 'autocompleteRenderer';
            colAss.cellRendererParams = {
              onSelect: this.onSelect.bind(this),
              onSearch: this.onSearch.bind(this),
              closePanel: this.closePanel.bind(this),
              label: '',
              templates: {
                items: null,
                columnName: 'Assurance',
                urlList: assDetail.field.urlList,
                urlSearch: assDetail.field.urlSearch,
                isSearch: true,
                columnId: assDetail.field.field.AD_Column_ID,
                fieldTypeForm: true
              }
            };
          }

          const colMPL = item.data.columnsTableHeader.find(function (element) {
            return element.field === 'M_PriceList_ID';
          });
          if (colMPL) {
            const mplDetail = item.data.columnsDetails.get('M_PriceList_ID');
            editorColumns.push('M_PriceList_ID');
            colMPL.editable = false;
            colMPL.cellRenderer = 'autocompleteRenderer';
            colMPL.cellRendererParams = {
              onSelect: this.onSelect.bind(this),
              onSearch: this.onSearch.bind(this),
              closePanel: this.closePanel.bind(this),
              label: '',
              templates: {
                items: null,
                columnName: 'M_PriceList_ID',
                urlList: mplDetail.field.urlList,
                urlSearch: mplDetail.field.urlSearch,
                isSearch: true,
                columnId: mplDetail.field.field.AD_Column_ID,
                validationCode: mplDetail.field.field.ValidationCode,
                fieldTypeForm: true
              }
            };
          }

          this.customDesignArray.push({
            vcr: 'vcrMiddle',
            type: CustomDesignItemType.GRID,
            tableName: 'Mass Insurance - table',
            cssClass: 'ui-g-12',
            shouldSelectFirst: false,
            editorColumns: editorColumns,
            frameworkComponents: this.frameworkComponents,
            isFitResize: true
          });
        } else if (item.data.columnName === 'AD_Org_ID') {
          // const fieldSize = Global.isMobileWidth() ? 'ui-g-12' : 'ui-g-4';
          const fieldSize = 'ui-g-12 ';
          this.customDesignArray.push({
            vcr: 'vcrTop',
            type: CustomDesignItemType.FIELD,
            columnName: 'AD_Org_ID',
            cssClass: fieldSize
          });
        }
      });
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'ui-g-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };
      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
  }

  notifyFromDataChange(item?: any) {
    if (item.data.isLaunchSearchGrid) {
      this.refreshGrids(this.dataStore, false, item.data);
    }
  }

  onSelect(e) {
    if (e.columnName === 'M_PriceList_ID' && e.rowData.M_PriceList_ID) {
      Global.infoDialog.message = {
        summary: this.translateService.instant('massComplemInsurance.dialogs.priceList.title'),
        detail:
          this.translateService.instant('massComplemInsurance.dialogs.priceList.message') +
          e.rowData.Name +
          '  ' +
          e.rowData.FirstName +
          '  ?'
      };
      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();

      const valSubscr = Global.infoDialog.confirm.subscribe((event) => {
        this.processService.getProcess(this.updatePriceList_Process_ID).subscribe((success) => {
          if (success) {
            let paramsMap: ProcessParams = {
              ad_process_id: null,
              className: null,
              record_id: null,
              tableName: null,
              tables: null,
              params: null,
              windowCtx: null,
              ad_tab_id: null
            };
            const classname = success.process.ClassName;
            const tableName = this.isModal ? this.parentComponent.editTabs[0].data.TableName : null;
            paramsMap = {
              record_id: null,
              ad_process_id: null,
              params: {
                C_BPartner_ID: e.rowData.C_BPartner_ID,
                M_PriceList_ID: e.rowData.M_PriceList_ID
              },
              className: classname,
              tableName: tableName,
              tables: this.tablesForm,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };

            const obs = this.uiCreator.executeProcess(paramsMap);
            this.subscriptions.push(
              obs.subscribe((instance) => {
                (async () => {
                  for await (const notification of this.socketService.enableRoleNotifications()) {
                    if (
                      notification &&
                      notification.sourceTypeId &&
                      notification.sourceTypeId === this.updatePriceList_Process_ID
                    ) {
                      if (notification.isError) {
                        this.refreshGrids();
                      }
                    }
                  }
                })();
              })
            );
            this.tablesForm.pop();
          }
        });

        valSubscr.unsubscribe();
        canSubscr.unsubscribe();
      });

      const canSubscr = Global.infoDialog.cancel.subscribe((event) => {
        this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.purgeServerSideCache();
        canSubscr.unsubscribe();
        valSubscr.unsubscribe();
      });
    } else if (e.columnName === 'Assurance' && e.rowData.Assurance) {
      Global.infoDialog.message = {
        summary: this.translateService.instant('massComplemInsurance.dialogs.assurance.title'),
        detail:
          this.translateService.instant('massComplemInsurance.dialogs.assurance.message') +
          e.rowData.Name +
          '  ' +
          e.rowData.FirstName +
          '  ?'
      };
      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();

      const valSubscr = Global.infoDialog.confirm.subscribe((event) => {
        this.processService.getProcess(this.updateAssurance_Process_ID).subscribe((success) => {
          if (success) {
            let paramsMap: ProcessParams = {
              ad_process_id: null,
              className: null,
              record_id: null,
              tableName: null,
              tables: null,
              params: null,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };
            const classname = success.process.ClassName;
            const tableName = this.isModal ? this.parentComponent.editTabs[0].data.TableName : null;
            paramsMap = {
              record_id: null,
              ad_process_id: null,
              params: {
                C_BPartner_ID: e.rowData.C_BPartner_ID,
                assurance: e.rowData.Assurance
              },
              className: classname,
              tableName: tableName,
              tables: this.tablesForm,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };

            const obs = this.uiCreator.executeProcess(paramsMap);
            this.subscriptions.push(
              obs.subscribe((instance) => {
                (async () => {
                  const channel = this.socketService.openDataChannel<CompiereNotification>(
                    SocketService.ROLE_NOTIFICATION_CHANNEL
                  );
                  for await (const notification of channel) {
                    if (
                      notification.title.toLowerCase().indexOf(paramsMap.className.toLowerCase()) >= 0 ||
                      !paramsMap.className
                    ) {
                      // this.refreshGrids(this.dataStore, false);
                      channel.unsubscribe();
                    }
                  }
                })();
              })
            );
            this.tablesForm.pop();
          }
        });

        valSubscr.unsubscribe();
        canSubscr.unsubscribe();
      });

      const canSubscr = Global.infoDialog.cancel.subscribe((event) => {
        this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.purgeServerSideCache();
        canSubscr.unsubscribe();
        valSubscr.unsubscribe();
      });
    }
  }

  closePanel() {
    this.gridViews[0].overlayVisible = false;
  }
  onSearch(e) {
    this.gridViews[0].overlayVisible = e.overlayVisible;
    this.gridViews[0].label = e.label;
    this.gridViews[0].isLabelDisplay = e.isLabelDisplay;
    this.gridViews[0].scrollHeight = e.scrollHeight;
    this.gridViews[0].suggestions = e.suggestions;
    this.gridViews[0].field = e.field;
    this.gridViews[0].itemTemplate = e.itemTemplate;
    this.gridViews[0].noResults = e.noResults;
    this.gridViews[0].emptyMessage = e.emptyMessage;
    this.gridViews[0].setPosition(e.top, e.left, e.width);
    this.gridViews[0].linkAutocompleteRenderer = e.linkAutocomplete;
  }
}
