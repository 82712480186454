<span
  #spanRef
  [ngClass]="['ui-calendar', showIcon ? 'ui-calendar-w-btn' : '', timeOnly ? 'ui-calendar-timeonly' : '', styleClass || '']"
  [ngStyle]="style"
>
  <ng-template [ngIf]="!inline">
    <label for="float-input" *ngIf="isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0">{{
      label | textLimit: this.el.nativeElement.firstElementChild.firstElementChild.clientWidth / 7
    }}</label>
    <input
      #inputfield
      type="text"
      [ngClass]="[
        'ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-calendar',
        mandatoryCss || '',
        inputStyleClass || ''
      ]"
      [attr.id]="inputId"
      [attr.name]="name"
      [attr.required]="required"
      [value]="inputFieldValue"
      (focus)="onInputFocus($event)"
      (keydown)="onInputKeydown($event)"
      (click)="onInputClick($event)"
      (change)="onInputBlur($event)"
      [readonly]="readonlyInput"
      (input)="onUserInput($event)"
      [ngStyle]="inputStyle"
      [placeholder]="placeholder || ''"
      [disabled]="readonlyInput"
      [attr.tabindex]="tabindex"
      autocomplete="no"
      data-cy="input-text"
      [attr.data-cy-columnName]="columnName"
      tabindex="-1"
    /><i
      type="button"
      *ngIf="showIcon"
      (click)="!readonlyInput && onButtonClick($event, inputfield)"
      [ngClass]="[
        timeOnly ? 'fa-clock-o' : 'icon-ico-calendar',
        disabled ? 'ui-state-disabled' : '',
        'iconeCalendar ui-datepicker-trigger'
      ]"
      tabindex="-1"
      disabled="readonlyInput"
    ></i>
  </ng-template>
  <div
    *ngIf="(inline || overlayVisible) && !isGridRenderer"
    [ngStyle]="panelStyle"
    style="top: 48px;"
    [ngClass]="[
      'ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all',
      inline ? 'ui-datepicker-inline' : 'ui-shadow',
      disabled ? 'ui-state-disabled' : '',
      timeOnly ? 'ui-datepicker-timeonly' : '',
      this.numberOfMonths > 1 ? 'ui-datepicker-multiple-month' : '',
      view === 'month' ? 'ui-datepicker-monthpicker' : '',
      touchUI ? 'ui-datepicker-touch-ui' : '',
      panelStyleClass || ''
    ]"
    [@overlayAnimationCalendar]="
      touchUI
        ? {
            value: 'visibleTouchUI',
            params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
          }
        : {
            value: 'visible',
            params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
          }
    "
    [@.disabled]="inline === true"
    (@overlayAnimationCalendar.start)="onOverlayAnimationStart($event)"
    (@overlayAnimationCalendar.done)="onOverlayAnimationDone($event)"
  >
    <ng-container *ngIf="!timeOnly">
      <div class="ui-datepicker-group ui-widget-content" *ngFor="let month of months; let i = index">
        <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
          <ng-content select="p-header"></ng-content>
          <a
            class="ui-datepicker-prev ui-corner-all"
            href="#"
            (click)="$event.preventDefault(); navBackward($event)"
            *ngIf="i === 0"
            tabindex="-1"
          >
            <span class="pi pi-chevron-left"></span>
          </a>
          <a
            class="ui-datepicker-next ui-corner-all"
            href="#"
            (click)="$event.preventDefault(); navForward($event)"
            *ngIf="numberOfMonths === 1 ? true : i === numberOfMonths - 1"
            tabindex="-1"
          >
            <span class="pi pi-chevron-right"></span>
          </a>
          <div class="ui-datepicker-title">
            <span class="ui-datepicker-month" *ngIf="!monthNavigator && view !== 'month'">{{
              locale.monthNames[month.month]
            }}</span>
            <select
              tabindex="-1"
              class="ui-datepicker-month"
              *ngIf="monthNavigator && view !== 'month' && numberOfMonths === 1"
              (change)="onMonthDropdownChange($event.target.value)"
            >
              <option [value]="i" *ngFor="let monthName of locale.monthNames; let i = index" [selected]="i === month.month">{{
                monthName
              }}</option>
            </select>
            <select
              tabindex="-1"
              class="ui-datepicker-year"
              *ngIf="yearNavigator && numberOfMonths === 1"
              (change)="onYearDropdownChange($event.target.value)"
            >
              <option [value]="year" *ngFor="let year of yearOptions" [selected]="year === currentYear">{{ year }}</option>
            </select>
            <span class="ui-datepicker-year" *ngIf="!yearNavigator">{{ view === 'month' ? currentYear : month.year }}</span>
          </div>
        </div>
        <div class="ui-datepicker-calendar-container" *ngIf="view === 'date'">
          <table class="ui-datepicker-calendar">
            <thead>
              <tr>
                <th scope="col" *ngFor="let weekDay of weekDays; let begin = first; let end = last">
                  <span>{{ weekDay }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let week of month.dates">
                <td
                  *ngFor="let date of week"
                  [ngClass]="[
                    date.otherMonth ? 'ui-datepicker-other-month' : '',
                    isSelected(date) ? 'ui-datepicker-current-day' : '',
                    date.today ? 'ui-datepicker-today' : ''
                  ]"
                >
                  <ng-container *ngIf="date.otherMonth ? showOtherMonths : true">
                    <a
                      *ngIf="date.selectable"
                      [ngClass]="[
                        isSelected(date) ? 'ui-state-active' : '',
                        date.today ? 'ui-state-highlight' : '',
                        'ui-state-default'
                      ]"
                      (click)="onDateSelect($event, date)"
                      draggable="false"
                      tabindex="-1"
                    >
                      <ng-container *ngIf="!dateTemplate">{{ date.day }}</ng-container>
                      <ng-container *ngTemplateOutlet="dateTemplate; context: { $implicit: date }"></ng-container>
                    </a>
                    <span class="ui-state-default ui-state-disabled" *ngIf="!date.selectable"> {{ date.day }} </span>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="ui-monthpicker" *ngIf="view === 'month'">
        <a
          href="#"
          *ngFor="let m of monthPickerValues; let i = index"
          (click)="onMonthSelect($event, i)"
          [ngClass]="[isMonthSelected(i) ? 'ui-state-active' : '', 'ui-monthpicker-month']"
          tabindex="-1"
        >
          {{ m }}
        </a>
      </div>
    </ng-container>
    <div class="ui-timepicker ui-widget-header ui-corner-all" *ngIf="showTime || timeOnly">
      <div class="ui-hour-picker">
        <a (mousedown)="onTimePickerElementMouseDown($event, 0, 1)" (mouseup)="onTimePickerElementMouseUp($event)" tabindex="-1">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{ display: currentHour < 10 ? 'inline' : 'none' }">0</span><span>{{ currentHour }}</span>
        <a tabindex="-1" (mousedown)="onTimePickerElementMouseDown($event, 0, -1)" (mouseup)="onTimePickerElementMouseUp($event)">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-separator">
        <a tabindex="-1">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{ timeSeparator }}</span>
        <a tabindex="-1">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-minute-picker">
        <a tabindex="-1" (mousedown)="onTimePickerElementMouseDown($event, 1, 1)" (mouseup)="onTimePickerElementMouseUp($event)">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{ display: currentMinute < 10 ? 'inline' : 'none' }">0</span><span>{{ currentMinute }}</span>
        <a tabindex="-1" (mousedown)="onTimePickerElementMouseDown($event, 1, -1)" (mouseup)="onTimePickerElementMouseUp($event)">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-separator" *ngIf="showSeconds">
        <a tabindex="-1">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{ timeSeparator }}</span>
        <a tabindex="-1">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-second-picker" *ngIf="showSeconds">
        <a tabindex="-1" (mousedown)="onTimePickerElementMouseDown($event, 2, 1)" (mouseup)="onTimePickerElementMouseUp($event)">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{ display: currentSecond < 10 ? 'inline' : 'none' }">0</span><span>{{ currentSecond }}</span>
        <a tabindex="-1" (mousedown)="onTimePickerElementMouseDown($event, 2, -1)" (mouseup)="onTimePickerElementMouseUp($event)">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-ampm-picker" *ngIf="hourFormat == '12'">
        <a tabindex="-1" (click)="toggleAMPM($event)">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{ pm ? 'PM' : 'AM' }}</span>
        <a tabindex="-1" (click)="toggleAMPM($event)">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
    </div>
    <div class="ui-datepicker-buttonbar ui-widget-header" *ngIf="showButtonBar">
      <div class="ui-g">
        <div class="ui-g-6">
          <button
            tabindex="-1"
            type="button"
            [label]="_locale.today"
            (click)="onTodayButtonClick($event)"
            pButton
            [ngClass]="[todayButtonStyleClass || '']"
          ></button>
        </div>
        <div class="ui-g-6">
          <button
            tabindex="-1"
            type="button"
            [label]="_locale.clear"
            (click)="onClearButtonClick($event)"
            pButton
            [ngClass]="[clearButtonStyleClass || '']"
          ></button>
        </div>
      </div>
    </div>
    <ng-content select="p-footer"></ng-content>
  </div>
</span>
